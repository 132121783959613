import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'ui-lib';
import { SharedModule } from '@shared';

import { TagsControlComponent } from './tags-control.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		AutoCompleteModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [TagsControlComponent],
	exports: [TagsControlComponent]
})
export class TagsControlModule {}
