import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';

import { SharedModule } from '@shared';
import { EnButtonModule } from 'ui-lib/button/button';

import { EnSliderComponent } from './slider.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		InputTextModule,
		KeyFilterModule,
		EnButtonModule
	],
	exports: [EnSliderComponent],
	declarations: [EnSliderComponent]
})
export class EnSliderModule {}
