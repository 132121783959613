<tui-scrollbar class="scrollbar">
    <div class="wrapper-dropdown-year">
        <div *ngFor="let item of month"
             class="row"
             [class.t-row__choice]="date.month === item.key"
             [tuiScrollIntoView]="scrollItemIntoView(item.key)"
             (click)="onItemClick(item.key)">
            <div class="item">{{ item.name | translate }}</div>
        </div>
    </div>
</tui-scrollbar>
