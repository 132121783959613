import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EnInputModule } from 'ui-lib';

import { UtmControlComponent } from './utm-control.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, EnInputModule],
	declarations: [UtmControlComponent],
	exports: [UtmControlComponent]
})
export class UtmControlModule {}
