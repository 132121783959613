import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '@state-enKod/contacts';
import { CRUDService } from '@enkod-core/abstract';
import { ID } from '@datorama/akita';
import {
	FieldsViewSettings,
	MainFields,
	PersonData,
	PhoneData,
	RfmTagsModel
} from './contacts-detail.model';

interface UpdateContactField {
	[key: string]: string | number | boolean | undefined;
}

interface Email {
	email: string;
}

@Injectable({
	providedIn: 'root'
})
export class ContactsDetailDataService extends CRUDService<Contact> {
	constructor() {
		super('contact/');
	}

	updateContactField(
		value: UpdateContactField | PersonData | MainFields | PhoneData,
		uuid: string
	) {
		return this.http.put(`contact/${uuid}`, value);
	}

	getFieldsViewSettings(
		presetName = {
			name: 'person_card'
		}
	) {
		const params = new HttpParams({
			fromObject: presetName
		});

		return this.http.get(`enkod/preset/`, {
			params
		});
	}

	getRfmTags(id: ID): Observable<RfmTagsModel> {
		return this.http.get<RfmTagsModel>(`enkod/tag/contact/rfm/${id}/`);
	}

	setFieldsViewSettings(
		fieldsSettings: FieldsViewSettings,
		presetName = {
			name: 'person_card'
		}
	) {
		const params = new HttpParams({
			fromObject: presetName
		});
		return this.http.put(`enkod/preset/`, fieldsSettings, { params });
	}

	checkMxRecordEmail(email: Email) {
		return this.http.post('enkod/person/checkEmail/', email);
	}

	checkPhone(phone: PhoneData) {
		return this.http.post('enkod/person/checkPhone/', phone);
	}

	checkInRemoved(id: string): Observable<{ result: boolean }> {
		return this.http.get<{ result: boolean }>(
			`enkod/person/checkInRemoved/${id}/`
		);
	}

	removeContact(id: string) {
		const options = {
			headers: new HttpHeaders(),
			body: {
				id: +id,
				email: '',
				phone: ''
			}
		};
		return this.http.delete(`delete-contact/`, options);
	}

	updateTags(id: ID, data: object = {}) {
		return this.http.put(`enkod/tag/contact/${id}/`, data);
	}
}
