import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagsValue } from '@enkod-core/enums';
import { EnsendSharedStore, EnsendSharedState } from './ensend-shared.store';

@Injectable({ providedIn: 'root' })
export class EnsendSharedQuery extends Query<EnsendSharedState> {
	constructor(protected store: EnsendSharedStore) {
		super(store);
	}

	get hasTags() {
		// проверяем есть ли в Store теги и их длину, чтобы вернуть boolean
		return this.getValue().tags && this.getValue().tags?.length;
	}

	tagList$ = this.select('tags') as Observable<string[] | null>;

	tagListOption$ = this.tagList$.pipe(
		map(tags =>
			tags?.map(tag => ({
				label: tag,
				value: tag
			}))
		)
	) as Observable<SelectItem[]>;

	tagsWithoutBot$ = this.tagList$.pipe(
		map(tags => tags?.filter(tag => tag !== TagsValue.BOT)),
		map(tags =>
			tags?.map(tag => ({
				label: tag,
				value: tag
			}))
		)
	) as Observable<SelectItem[]>;

	domainsList$ = this.select('domains') as Observable<string[] | null>;
	sendingDomainsList$ = this.select('sendingDomains') as Observable<
		string[] | null
	>;

	domainsListOptions$ = this.domainsList$.pipe(
		map(tags =>
			tags?.map(tag => ({
				label: tag,
				value: tag
			}))
		)
	) as Observable<SelectItem[]>;

	sendingDomainsListOptions$ = this.sendingDomainsList$.pipe(
		map(tags =>
			tags?.map(tag => ({
				label: tag,
				value: tag
			}))
		)
	) as Observable<SelectItem[]>;
}
