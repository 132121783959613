/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable max-classes-per-file */
import {
	NgModule,
	Component,
	ElementRef,
	AfterContentInit,
	OnDestroy,
	Input,
	Output,
	EventEmitter,
	ContentChildren,
	QueryList,
	ChangeDetectorRef,
	Inject,
	forwardRef,
	TemplateRef,
	ChangeDetectionStrategy,
	ViewEncapsulation
} from '@angular/core';
import {
	trigger,
	state,
	style,
	transition,
	animate
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { SharedModule, Header, PrimeTemplate, BlockableUI } from 'primeng/api';

import { Subscription } from 'rxjs';
import {
	PolymorpheusContent,
	PolymorpheusModule
} from '@tinkoff/ng-polymorpheus';

let idx = 0;

@Component({
	selector: 'en-accordiontab',
	templateUrl: 'accordion.html',
	animations: [
		trigger('tabContent', [
			state(
				'hidden',
				style({
					height: '0',
					overflow: 'hidden',
					opacity: '0'
				})
			),
			state(
				'visible',
				style({
					height: '*',
					opacity: '1'
				})
			),
			transition('visible <=> hidden', [
				style({ overflow: 'hidden' }),
				animate('{{transitionParams}}')
			]),
			transition('void => *', animate(0))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class AccordionTab implements AfterContentInit, OnDestroy {
	@Input() header: string;

	@Input() disabled: boolean;

	@Input() customIcon: PolymorpheusContent;

	@Input() iconLeftOpened = 'icon-msg-api';

	@Input() iconLeftClosed = 'icon-msg-api--gray';

	@Input() cache = true;

	@Input() transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';

	@Input() confirmable = false;

	@Input() disableContent = false;

	@Output() selectedChange: EventEmitter<any> = new EventEmitter();

	@Output() confirmChange: EventEmitter<any> = new EventEmitter();

	@ContentChildren(Header) headerFacet: QueryList<Header>;

	@ContentChildren(PrimeTemplate) templates: QueryList<any>;

	private _selected: boolean;

	@Input() get selected(): any {
		return this._selected;
	}

	get isVisibleContent(): boolean {
		return this.selected && !this.disableContent;
	}

	set selected(val: any) {
		this._selected = val;

		if (!this.loaded) {
			this.changeDetector.detectChanges();
		}
	}

	contentTemplate: TemplateRef<any>;

	headerTemplate: TemplateRef<any>;

	id = `en-accordiontab-${idx++}`;

	loaded: boolean;

	accordion: Accordion;

	constructor(
		@Inject(forwardRef(() => Accordion)) accordion: any,
		public changeDetector: ChangeDetectorRef
	) {
		this.accordion = accordion as Accordion;
	}

	ngAfterContentInit() {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'content':
					this.contentTemplate = item.template;
					break;

				case 'header':
					this.headerTemplate = item.template;
					break;

				default:
					this.contentTemplate = item.template;
					break;
			}
		});
	}

	toggle(event: any) {
		if (this.disabled) {
			return false;
		}

		const index = this.findTabIndex();
		if (this.confirmable) {
			this.confirmChange.emit({ originalEvent: event, index });
			return false;
		}

		if (this.selected) {
			this.selected = false;
			this.accordion.onClose.emit({ originalEvent: event, index });
		} else {
			if (!this.accordion.multiple) {
				for (let i = 0; i < this.accordion.tabs.length; i++) {
					this.accordion.tabs[i].selected = false;
					this.accordion.tabs[i].selectedChange.emit(false);
					this.accordion.tabs[i].changeDetector.markForCheck();
				}
			}
			this.selected = true;
			this.loaded = true;
			this.accordion.onOpen.emit({ originalEvent: event, index });
		}

		this.selectedChange.emit(this.selected);
		this.accordion.updateActiveIndex();
		this.changeDetector.markForCheck();

		event.preventDefault();
		return true;
	}

	findTabIndex() {
		let index = -1;
		for (let i = 0; i < this.accordion.tabs.length; i++) {
			if (this.accordion.tabs[i] === this) {
				index = i;
				break;
			}
		}
		return index;
	}

	get hasHeaderFacet(): boolean {
		return this.headerFacet && this.headerFacet.length > 0;
	}

	onKeydown(event: KeyboardEvent) {
		if (event.which === 32 || event.which === 13) {
			this.toggle(event);
			event.preventDefault();
		}
	}

	ngOnDestroy() {
		this.accordion.tabs.splice(this.findTabIndex(), 1);
	}
}

@Component({
	selector: 'en-accordion',
	template: `
		<div
			class="en-accordion en-component"
			[ngStyle]="style"
			[class]="styleClass"
			role="tablist"
		>
			<ng-content></ng-content>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class Accordion implements BlockableUI, AfterContentInit, OnDestroy {
	@Input() multiple: boolean;

	@Input() iconMod: boolean;

	@Input() sizeMod: boolean;

	@Output() onClose: EventEmitter<any> = new EventEmitter();

	@Output() onOpen: EventEmitter<any> = new EventEmitter();

	@Input() style: any;

	@Input() styleClass: string;

	@Input() iconRightClosed = 'en-arrow-right';

	@Input() iconRightOpened = 'en-arrow-down';

	@Output() activeIndexChange: EventEmitter<any> = new EventEmitter();

	@ContentChildren(AccordionTab) tabList: QueryList<AccordionTab>;

	tabListSubscription: Subscription;

	private _activeIndex: any;

	preventActiveIndexPropagation: boolean;

	public tabs: AccordionTab[] = [];

	constructor(
		public el: ElementRef,
		public changeDetector: ChangeDetectorRef
	) {}

	ngAfterContentInit() {
		this.initTabs();

		this.tabListSubscription = this.tabList.changes.subscribe(() => {
			this.initTabs();
		});
	}

	initTabs(): any {
		this.tabs = this.tabList.toArray();
		this.updateSelectionState();
		this.changeDetector.markForCheck();
	}

	getBlockableElement(): HTMLElement {
		return this.el.nativeElement.children[0];
	}

	@Input() get activeIndex(): any {
		return this._activeIndex;
	}

	set activeIndex(val: any) {
		this._activeIndex = val;
		if (this.preventActiveIndexPropagation) {
			this.preventActiveIndexPropagation = false;
			return;
		}

		this.updateSelectionState();
	}

	updateSelectionState() {
		if (this.tabs && this.tabs.length && this._activeIndex != null) {
			for (let i = 0; i < this.tabs.length; i++) {
				const selected = this.multiple
					? this._activeIndex.includes(i)
					: i === this._activeIndex;
				const changed = selected !== this.tabs[i].selected;

				if (changed) {
					this.tabs[i].selected = selected;
					this.tabs[i].selectedChange.emit(selected);
					this.tabs[i].changeDetector.markForCheck();
				}
			}
		}
	}

	updateActiveIndex() {
		let index: any = this.multiple ? [] : null;
		this.tabs.forEach((tab, i) => {
			if (tab.selected) {
				if (this.multiple) {
					index.push(i);
				} else {
					index = i;
				}
			}
		});

		this.preventActiveIndexPropagation = true;
		this.activeIndexChange.emit(index);
	}

	ngOnDestroy() {
		if (this.tabListSubscription) {
			this.tabListSubscription.unsubscribe();
		}
	}
}

@NgModule({
	imports: [CommonModule, PolymorpheusModule],
	exports: [Accordion, AccordionTab, SharedModule],
	declarations: [Accordion, AccordionTab]
})
export class EnAccordionModule {}
