import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

@Injectable()
export class DevGuard implements CanActivate, CanLoad {
	constructor(private router: Router) {}

	canActivate(): boolean {
		if (!isDevMode()) this.router.navigateByUrl('');
		return isDevMode();
	}

	canLoad() {
		if (!isDevMode()) this.router.navigateByUrl('');
		return isDevMode();
	}
}
