<div [class]="maskStyleClass"
     [ngClass]="getMaskClass()"
     *ngIf="maskVisible">
    <div [ngClass]="{
        'p-dialog p-confirm-dialog p-component': true,
        'p-dialog-rtl': rtl
    }"
         [ngStyle]="style"
         [class]="styleClass"
         (mousedown)="moveOnTop()"
         [@animation]="{
        value: 'visible',
        params: {
            transform: transformOptions,
            transition: transitionOptions
        }
    }"
         (@animation.start)="onAnimationStart($event)"
         (@animation.done)="onAnimationEnd($event)"
         *ngIf="visible">
        <div class="p-dialog-header"
             [ngClass]="{
            'header-info': headerBackground === 'info',
            'header-success': headerBackground === 'success',
            'header-warn': headerBackground === 'warn',
            'header-danger': headerBackground === 'danger'
        }">
            <div class="p-dialog-title"
                 style="display: flex; align-items: center"
                 *ngIf="option('header')">
                <i *ngIf="showHeaderIcon"
                   class="en dialog-custom-icon"
                   [class]="iconHeader"></i>
                <span>
                    {{ option('header') }}
                </span>
            </div>
            <div class="p-dialog-header-icons">
                <button *ngIf="closable"
                        type="button"
                        [ngClass]="{
                    'p-dialog-header-icon p-dialog-header-close p-link': true
                }"
                        (click)="close($event)"
                        (keydown.enter)="close($event)">
                    <span class="pi pi-times"></span>
                </button>
            </div>
        </div>
        <div #content
             class="p-dialog-content dialog-confirm-content"
             [ngClass]="{'content-template': contentTemplate}">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            <ng-container *ngIf="!contentTemplate">
                <i [ngClass]="'p-confirm-dialog-icon'"
                   [class]="option('icon')"
                   *ngIf="option('icon')"></i>
                <span class="p-confirm-dialog-message"
                      [innerHTML]="option('message')"></span>
            </ng-container>
        </div>
        <div class="p-dialog-footer"
             *ngIf="footer || footerTemplate">
            <ng-content select="p-footer"></ng-content>
            <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
        </div>
        <div class="p-dialog-footer"
             *ngIf="!footer">
            <en-button styleClass="h36 en-button-secondary"
                       [style]="{ 'margin-right': '1rem' }"
                       [icon]="option('rejectIcon')"
                       [label]="option('rejectLabel')"
                       (click)="reject()"
                       *ngIf="option('rejectVisible')"
                       [attr.aria-label]="rejectAriaLabel"></en-button>
            <button enButton
                    class="h36"
                    [ngClass]="{'en-button-solid': (headerBackground === 'danger' && acceptSolid)}"
                    [enLoading]="loading"
                    [icon]="option('acceptIcon')"
                    [label]="option('acceptLabel')"
                    (click)="accept()"
                    *ngIf="option('acceptVisible')"
                    [attr.aria-label]="acceptAriaLabel"></button>
        </div>
    </div>
</div>