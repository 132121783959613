import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
	transform(value: number, among: number): number {
		if (among) {
			const percent = (100 * value) / among;
			return Math.trunc(percent * 100) / 100;
		}
		return 0;
	}
}
