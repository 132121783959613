import { Injectable } from '@angular/core';

import moment from 'moment-timezone';

import { AuthQuery } from '@enkod-core/authentication/_state';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class DateAccountTimezoneService {
	cachedTime: string;

	constructor(
		private auth: AuthQuery,
		private translateService: TranslateService
	) {}

	// таймзона с бэка вида Europe/Moscow
	get accountTimezone() {
		return this.auth.accountTimezone;
	}

	get momentGetter() {
		return moment;
	}

	// таймзона вида +0300
	getTimezoneWithSummerTime(timestamp?: number) {
		if (timestamp) {
			return moment
				.tz(timestamp * 1000, this.accountTimezone)
				.format('Z')
				.split(':')
				.join('');
		}

		return moment.tz(this.accountTimezone).format('Z').split(':').join('');
	}

	dateWithAccountTimezone(timestamp?: number) {
		let date = new Date();

		if (timestamp) {
			date = new Date(timestamp * 1000);
		}

		let clientTimezoneOffset = date.getTimezoneOffset() * 60000;

		const isPositiveClientTimezone =
			date.getTimezoneOffset().toString()[0] === '-';

		const accountTimezoneOffset =
			parseInt(this.getTimezoneWithSummerTime(timestamp), 10) * 36000; // ← смещение таймзоны в timestamp

		/** Для отрицательной таймзоны @example (UTC-03:00)
		 * Добавляем к дате таймзону с пк, т.к new Date(...) автоматически вычитает эту таймзону, мы ее возвращаем руками,
		 * дальше прибавляем и таймзону аккаунта
		 */

		let dateWithAccountTimezone =
			date.getTime() + clientTimezoneOffset + accountTimezoneOffset;

		/** Для позитивной таймзоны @example (UTC+03:00) */
		if (isPositiveClientTimezone) {
			// Приводим к положительному, чтобы вычесть из даты
			clientTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
			// Вычитаем из даты таймзону пк, прибавляем таймзону аккаунта
			dateWithAccountTimezone =
				date.getTime() - clientTimezoneOffset + accountTimezoneOffset;
		}

		return dateWithAccountTimezone;
	}

	/** Метод для получения чистого времени, в нулевой таймзоне */
	toUTCTime(timestamp: number) {
		const date = new Date(timestamp * 1000);

		let clientTimezoneOffset = date.getTimezoneOffset() * 60000;

		const isPositiveClientTimezone =
			date.getTimezoneOffset().toString()[0] === '-';

		const accountTimezoneOffset =
			parseInt(this.getTimezoneWithSummerTime(timestamp), 10) * 36000; // ← смещение таймзоны в timestamp

		/** Для отрицательной таймзоны @example (UTC-03:00)
		 * Вычитаем из даты таймзону с пк, вычитаем и таймзону аккаунта
		 */
		let dateWithAccountTimezone =
			date.getTime() - clientTimezoneOffset - accountTimezoneOffset;

		/** Для позитивной таймзоны @example (UTC+03:00) */
		if (isPositiveClientTimezone) {
			// Приводим к положительному, чтобы вычесть из даты
			clientTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
			// Добавляем к дате таймзону пк, вычитаем таймзону аккаунта
			dateWithAccountTimezone =
				date.getTime() + clientTimezoneOffset - accountTimezoneOffset;
		}

		return +(dateWithAccountTimezone / 1000).toFixed();
	}

	toUtcMidnight(timestamp: number) {
		const date = new Date(timestamp * 1000);

		const clientTimezoneOffset = date.getTimezoneOffset() * 60000;
		const accountTimezoneOffset =
			parseInt(this.getTimezoneWithSummerTime(timestamp), 10) * 36000; // ← смещение таймзоны в timestamp

		const dateWithAccountTimezone =
			date.getTime() - clientTimezoneOffset - accountTimezoneOffset;

		return +(dateWithAccountTimezone / 1000).toFixed();
	}

	fromUtcWithAccountTz(timestamp: number) {
		const date = new Date(timestamp * 1000);
		const clientTimezoneOffset = date.getTimezoneOffset() * 60000;

		const accountTimezoneOffset =
			parseInt(this.getTimezoneWithSummerTime(timestamp), 10) * 36000; // ← смещение таймзоны в timestamp

		const dateWithAccountTimezone =
			timestamp * 1000 + clientTimezoneOffset + accountTimezoneOffset;

		return dateWithAccountTimezone;
	}

	// как пайп convertDate
	convertDate(
		timestamp: number,
		format: string,
		timezone?: string,
		locale?: string
	): string {
		if (!timestamp) return '';

		return moment
			.tz(+timestamp * 1000, timezone || this.accountTimezone)
			.locale(locale || this.translateService.currentLang)
			.format(format);
	}

	// если с бэка дата приходит вида "2023-09-08"
	convertDateFromString(
		date: string,
		format: string,
		timezone?: string,
		locale?: string
	) {
		if (!date) return '';

		return moment
			.tz(date, timezone || this.accountTimezone)
			.locale(locale || this.translateService.currentLang)
			.format(format);
	}

	getToday() {
		return moment.now();
	}
}
