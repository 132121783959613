export function isDate(input: any) {
	return Object.prototype.toString.call(input) === '[object Date]';
}

export function isEmpty(value: any) {
	return (
		value === null ||
		value === undefined ||
		value === '' ||
		(Array.isArray(value) && value.length === 0) ||
		(!isDate(value) &&
			typeof value === 'object' &&
			Object.keys(value).length === 0)
	);
}

export function isNotEmpty(value: any) {
	return !isEmpty(value);
}
