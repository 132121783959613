<en-dialog [header]="header"
           [(visible)]="dialogTagsVisible"
           [style]="{width: '33.75rem'}"
           [modal]="true"
           (onHide)="closeDialogTags.emit()">
    <div class="container">
        <en-tags-control [formTags]="tagsControl"
                         [showClear]="true"
                         [loadTags]="true"></en-tags-control>
    </div>
    <p-footer>
        <button enButton
                type="button"
                class="en-button-secondary h36"
                [style]="{'margin-right': '1rem'}"
                [label]="'common.cancel' | translate"
                (click)="closeDialogTags.emit()">
        </button>
        <button enButton
                type="button"
                class="h36"
                [label]="'common.save' | translate"
                (click)="submit()"></button>
    </p-footer>
</en-dialog>