import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared';

import { EnButtonModule, EnDialogModule } from 'ui-lib';

import { RelatedDataDialogComponent } from './related-data-dialog.component';

@NgModule({
	imports: [CommonModule, SharedModule, EnDialogModule, EnButtonModule],
	declarations: [RelatedDataDialogComponent],
	exports: [RelatedDataDialogComponent]
})
export class RelatedDataDialogModule {}
