import { NgModule } from '@angular/core';
import { TuiResizeModule } from '@taiga-ui/cdk';
import { HtmlPreviewDirective } from './html-preview.directive';

@NgModule({
	imports: [TuiResizeModule],
	declarations: [HtmlPreviewDirective],
	exports: [HtmlPreviewDirective]
})
export class HtmlPreviewDirectiveModule {}
