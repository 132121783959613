<en-dialog [(visible)]="modal"
           [modal]="true"
           [header]="'get-service-modal-component.title'  | translate: {service: modalTitle | translate}"
           closeIcon="en en-close"
           [style]="{width: '33.75rem',position:'relative'}"
           [focusOnShow]="false"
           [resizable]="false"
           (onHide)="hide.emit()"
           [class.hide-footer]="formSubmited">

    <div [ngClass]="{'submited': formSubmited}"
         class="modal__thanks">
        <ng-template #defaultThanksPage>

            <img src="assets/images/enkod-main/checked.svg"
                 alt=""
                 class="modal__checked">
            <p class="en-text-large modal__thanks-text">
                {{'get-service-modal-component.modal-thanks-text' | translate}}
            </p>
            <en-button routerLink="/"
                       styleClass="ui-button-secondary"
                       [label]="'get-service-modal-component.back-to-main' | translate"
                       (click)="hide.emit()"></en-button>
        </ng-template>

        <ng-container *ngTemplateOutlet="modalThanksTemplate? (modalThanksTemplate ): defaultThanksPage">
        </ng-container>
    </div>

    <form [formGroup]="form"
          id="formId"
          class="modal__body"
          [class.hideContent]="formSubmited"
          (ngSubmit)="submit()">
        <p class="en-text-large">
            {{description | translate}}
        </p>
        <div class="en-field">
            <label for="email">
                {{'get-service-modal-component.email-label' | translate}}
            </label>
            <input enInput
                   type="text"
                   placeholder=""
                   required
                   formControlName="email"
                   id="email">
            <en-error formControlName="email"></en-error>
            <!-- {{'get-service-modal-component.error-text' | translate}} -->

        </div>
        <div class="en-field">
            <label for="phone">
                {{'get-service-modal-component.phone-label' | translate}}
            </label>
            <input enInput
                   class="phone-input"
                   type="text"
                   placeholder="+7 (999) 999-99-99"
                   formControlName="phone"
                   id="phone"
                   [imask]="maskPhone">
            <en-error formControlName="phone"></en-error>

            <!--документация imask https://imask.js.org/guide.html -->
        </div>
        <p class="en-text-large text--center">
            {{'get-service-modal-component.text-center' | translate}}
        </p>
        <p class="en-text-medium modal__contact-us">
            {{'get-service-modal-component.contact-us' | translate}}
            <a class="contact-us-link"
               href="mailto:experts@enkod.io?subject={{'get-service-modal-component.mailto-text' | translate }} {{modalTitle | translate}}">experts@enkod.io</a>
        </p>
    </form>

    <p-footer *ngIf="!formSubmited">
        <en-button styleClass="en-button-secondary h36 margin--mod"
                   [label]="'get-service-modal-component.back-button' | translate"
                   (click)="hide.emit()"></en-button>
        <button enButton
                class="h36"
                type="submit"
                form="formId"
                [label]="'get-service-modal-component.submit-button' | translate"
                [enLoading]="loading$ | async"
                [disabled]="form.invalid"></button>
    </p-footer>

</en-dialog>