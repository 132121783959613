/* eslint-disable func-names */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { AnyObject, SelectOptionsConfig } from '@enkod-core/interfaces';

export const DEFAULT_SELECT_OPTIONS_CONFIG: SelectOptionsConfig = {
	label: 'name',
	value: 'id'
};

/**
 * Кастомный rxjs оператор для преобразования массива объектов в SelectItem массив;
 * @param config - объект, в который передается название ключа для label и value;
 * @default значение { label: 'name', value: 'id' };
 */
export function selectOptions(
	config: SelectOptionsConfig = DEFAULT_SELECT_OPTIONS_CONFIG
) {
	return function (
		source$: Observable<AnyObject[]>
	): Observable<SelectItem[]> {
		return source$.pipe(
			map(stream => {
				return stream.map(item => ({
					label: item[config.label],
					value: item[config.value]
				}));
			})
		);
	};
}
