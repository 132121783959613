import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

@Component({
	selector: 'en-mailing-group-info',
	templateUrl: './mailing-group-info.component.html',
	styleUrls: ['./mailing-group-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailingGroupInfoComponent {
	@Input() icon = '';
	@Input() name = '';
	@Input() id = '';
	@Input() isDelete = false;
	@Input() wasDeleted = false;
	@Input() tippyText: string;
	@Input() useMaxWidth85 = false;
	@Input() overflowTooltip = false;
	@Output() deleteItem = new EventEmitter<number>();

	get groupIcon() {
		return this.icon;
	}

	get groupName() {
		return this.name;
	}

	get groupId() {
		return this.id;
	}

	get tippy() {
		return this.wasDeleted ? this.tippyText : false;
	}
}
