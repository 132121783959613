<div class="en-message en-component"
     role="alert"
     [ngStyle]="style"
     [class]="styleClass"
     [ngSwitch]="viewMode">
    <ng-container *ngSwitchCase="'default'">
        <div *ngIf="hasMessages()">
            <div *ngFor="let msg of value; let i = index"
                 [style]="wrapperStyle"
                 [ngClass]="msg.severity | getClassList: isBackground"
                 [@messageAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
                 role="alert">
                <div class="en-message-wrapper"
                     [ngClass]="{
                         'en-message-flexable': flexable === true,
                         'en-message-wrapper--border': isBackground === true }">

                    <div class="en-message-summary">
                        <span class="en-message-icon en"
                              [ngClass]="{
                            'en-check-circle-o':
                                msg.severity === 'success',
                            'en-info-circle-o':
                                msg.severity === 'info',
                            'en-exclamation-circle-o':
                                msg.severity === 'error',
                            'en-exclamation-triangle-o':
                                msg.severity === 'warn',
                            'en-help-circle-o':
                                msg.severity === 'question'
                        }"></span>
                        <span *ngIf="msg.summary"
                              class="en-message-summary-text">
                            {{ msg.summary | translate }}
                        </span>
                    </div>
                    <span *ngIf="msg.detail"
                          class="en-message-detail-text"
                          [ngClass]="{
									'en-message-detail-text--flexable':
										flexable === true
								}">
                        {{ msg.detail | translate }}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'template'">
        <div *ngIf="visible"
             [@messageAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
             [ngClass]="severity | getClassList: isBackground"
             role="alert">
            <div class="en-message-wrapper">
                <span class="en-message-icon en en-message-icon--template"
                      [ngClass]="{  'en-check-circle-o': severity === 'success',
                                    'en-info-circle-o': severity === 'info',
                                    'en-exclamation-circle-o': severity === 'error',
                                    'en-exclamation-triangle': severity === 'warn',
                                    'en-help-circle': severity === 'question'   }"></span>
                <span *ngIf="summary"
                      class="en-message-summary-text">
                    {{summary }}
                </span>

                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>

                <span *ngIf="detail"
                      class="en-message-detail-text">
                    {{ detail }}
                </span>
            </div>
        </div>
    </ng-container>
</div>