import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EnInputModule, EnButtonModule } from 'ui-lib';
import { SharedModule } from '@shared';

import { UrlParamsControlComponent } from './url-params-control.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnInputModule,
		EnButtonModule,
		SharedModule
	],
	declarations: [UrlParamsControlComponent],
	exports: [UrlParamsControlComponent]
})
export class UrlParamsControlModule {}
