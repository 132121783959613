import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { KeyFilterModule } from 'primeng/keyfilter';

import { SharedModule } from '@shared';
import { EnInputModule } from '../input/input';
import { EnDropdownModule } from '../dropdown/dropdown';
import { EnButtonModule } from '../button/button';

import { PaginatorComponent } from './paginator.component';
import { PaginationService } from './pagination-service.class';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnInputModule,
		EnButtonModule,
		EnDropdownModule,
		SharedModule,
		KeyFilterModule
	],
	declarations: [PaginatorComponent],
	providers: [PaginationService],
	exports: [PaginatorComponent]
})
export class PaginatorModule {}
