import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'convertSize'
})
export class ConvertSizePipe implements PipeTransform {
	transform(bytes: number, decimals = 0): string {
		if (bytes === 0) return '0 KB';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['B', 'KB', 'MB', 'GB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
	}
}
