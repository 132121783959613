/* eslint-disable no-undef */
import { InjectionToken } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

export type SendTestEmailConfig = {
	bodyDescription: string;
	validators: ValidatorFn[];
	placeholder: string;
};

export const SEND_TEST_EMAIL = new InjectionToken<SendTestEmailConfig>(
	'Конфиг для компонента отправки теста по емейлу'
);
