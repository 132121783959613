export const archiveErrorMapper = (error: string) => {
	// ждем, пока на беке поменяют текст ошибки в импорте контактов, потом удалить условие с 'unsupported file type'
	const obj: { [key: string]: string } = {
		'unsupported file type': 'file_uploader.not_valid_archive',
		'unsupported file type in .zip archive':
			'file_uploader.not_valid_archive',
		// eslint-disable-next-line
		"can't find files of supported types in .zip archive":
			'file_uploader.no_file_archive',
		'Incorrect encoding, must be UTF - 8':
			'file_uploader.incorrect encoding'
	};
	return obj[error];
};
