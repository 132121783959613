import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { tap } from 'rxjs/operators';
import IMask, { AnyMaskedOptions } from 'imask';

import { AuthQuery } from '@enkod-core/authentication/_state';
import { DateAccountTimezoneService } from '@enkod-core/services';

import { DropdownCalendarComponent } from 'ui-lib/dropdown-calendar/dropdown-calendar.component';
import { CustomValidators } from 'custom-validators';

import {
	ErrorValidate,
	MainFields,
	Pipe,
	SelectEvent,
	SelectOptions,
	TypeView,
	UpdateField,
	Values
} from './contacts-field-editor.model';

@UntilDestroy()
@Component({
	selector: 'en-contacts-field-editor',
	templateUrl: './contacts-field-editor.component.html',
	styleUrls: ['./contacts-field-editor.component.scss'],
	animations: [
		trigger('viewBlockAnimation', [
			transition('void => *', [
				style({ height: 0, opacity: 0, margin: 0 }),
				animate('0.1s', style({ height: '*', opacity: 1 }))
			]),
			transition('* => void', [
				animate('0.1s', style({ height: 0, opacity: 0 }))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsFieldEditorComponent
	implements OnInit, OnChanges, AfterViewInit
{
	inputControl: UntypedFormControl = new UntypedFormControl();

	isEdit = true;

	typeView: TypeView = 'notNumber';

	newValue: Values | null;

	options: SelectOptions[];

	selectedBoolean: SelectOptions;

	time: Date;

	showTime: boolean;

	errorMessage: string;

	isError = false;

	isClosedEmailSwitch = true;

	isViewSaveButton = true;

	firstInitDropDown = true;

	mask: AnyMaskedOptions;

	pipe: Pipe;

	dateLocale: string = this.translate.instant('client_fields.date_locale');

	dateControl = new UntypedFormControl(null);

	@Input() values: Values | null;

	@Input() systemName: string;

	@Input() type: string;

	@Input() rows: number | string;

	@Input() editRows: number | string;

	@Input() set valueEmailValid(value: ErrorValidate | string) {
		const inputValue = this.inputControl.value;
		this.inputControl.markAsTouched();
		typeof value === 'string'
			? this.inputControl.setErrors(null)
			: this.inputControl.setErrors(value);
		if (inputValue) {
			if (this.inputControl.valid) {
				this.values = this.inputControl.value.toLowerCase();
				this.updateMainField(this.inputControl.value.toLowerCase());
				this.closedEditable();
			} else {
				this.isViewSaveButton = false;
				this.cd.detectChanges();
				this.input.nativeElement.focus();
			}
		}
		this.isClosedEmailSwitch = true;
	}

	@Input() set valuePhoneValid(value: ErrorValidate | string) {
		const inputValue = this.inputControl.value;
		this.inputControl.markAsTouched();
		typeof value === 'string'
			? this.inputControl.setErrors(null)
			: this.inputControl.setErrors(value);
		if (inputValue) {
			if (this.inputControl.valid) {
				this.values = this.inputControl.value.toLowerCase();
				this.updateMainField(this.inputControl.value.toLowerCase());
				this.closedEditable();
			} else {
				this.isViewSaveButton = false;
				this.cd.detectChanges();
				this.input.nativeElement.focus();
			}
		}
		this.isClosedEmailSwitch = true;
	}

	@Input() set closeEditable(value: boolean) {
		if (value) this.closedEditable();
	}

	@Output() save = new EventEmitter();

	@Output() updateMainFieldData = new EventEmitter<MainFields>();

	@Output() updateExtraFieldData = new EventEmitter<UpdateField>();

	@Output() checkEmailValid = new EventEmitter<string>();

	@Output() checkPhoneValid = new EventEmitter<string>();

	@ViewChild('input') input: ElementRef;

	@ViewChild('calendar') calendar: DropdownCalendarComponent;

	@ViewChild('enDropDown') enDropDown: Dropdown;

	get accountTimezone() {
		return this.authQuery.accountTimezone;
	}

	constructor(
		private translate: TranslateService,
		private authQuery: AuthQuery,
		private timezoneService: DateAccountTimezoneService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.newValue = this.values?.toString() as Values;
		this.isEdit = true;
	}

	ngAfterViewInit() {
		switch (this.type) {
			case 'bool':
				this.options = [
					{
						name: this.translate.instant(
							'client_fields.value_true'
						),
						value: true
					},
					{
						name: this.translate.instant(
							'client_fields.value_false'
						),
						value: false
					},
					{
						name: this.translate.instant(
							'client_fields.value_null'
						),
						value: null
					}
				];
				this.setBooleanValue();
				break;

			case 'date':
			case 'dateTime':
				this.dateControl.patchValue(this.values);
				this.calendarChangeListener(this.values as number | null);

				if (this.values === null || this.values === '') return;
				this.setDateValue(+this.values);

				break;

			case 'phone':
				this.typeView = 'number';
				this.pipe = {
					mask: '+0 000 000 00 00 00 00'
				};
				break;
			case 'float':
			case 'number':
				this.typeView = 'number';
				this.pipe = {
					mask: Number,
					signed: true,
					thousandsSeparator: ' ',
					scale: this.type === 'float' ? 10 : 0,
					radix: '.',
					mapToRadix: [','],
					normalizeZeros: this.type !== 'float'
				};
				if (
					this.values !== null &&
					this.values !== '' &&
					this.type === 'float'
				) {
					const number = +this.values;
					if (Number.isInteger(number)) {
						this.values = `${number.toString()}.00`;
					}
				}
				break;
			default:
				break;
		}
		this.cd.detectChanges();
	}

	ngOnChanges(): void {
		if (this.rows === this.editRows && this.inputControl.valid) {
			this.toggleEdit();
		}
	}

	toggleEdit(): void {
		this.isEdit = false;
		this.initEditPhone();
		if (this.type === 'email') this.initEditEmail();
		this.cd.detectChanges();
		/// ////  АВТОФОКУС НА ВСЕ ЭЛЕМЕНТЫ
		switch (this.type) {
			case 'text':
			case 'email':
				if (this.input !== undefined) {
					this.input.nativeElement.focus();
				}
				break;

			case 'date':
			case 'dateTime':
				this.calendar.calendarVisible = true;
				break;

			case 'float':
			case 'number':
				this.mask = IMask.createMask({
					mask: Number,
					scale: this.type === 'float' ? 7 : 0,
					signed: true,
					thousandsSeparator: '',
					radix: '.',
					mapToRadix: [','],
					max: this.type === 'float' ? 999999999 : 999999999999999,
					normalizeZeros: this.type !== 'float'
				});
				this.newValue =
					this.values === null ? '' : this.values?.toString();
				this.input.nativeElement.focus();
				break;

			case 'phone':
				this.mask = IMask.createMask({
					mask: '+0 000 000 00 00 00 00'
				});
				this.newValue =
					this.values === null ? '' : this.values?.toString();
				this.input.nativeElement.focus();

				break;

			case 'bool':
				this.enDropDown.show();
				this.enDropDown.focus();
				break;
			default:
				break;
		}
		this.cd.detectChanges();
	}

	initEditEmail(): void {
		this.inputControl = new UntypedFormControl(this.values, {
			validators: [CustomValidators.email]
		});
	}

	initEditPhone(): void {
		this.inputControl = new UntypedFormControl(this.values);
	}

	closedEditable(): void {
		this.isEdit = true;
		this.save.emit();
	}

	/**
	 * @cancelEditMainFIeld
	 * Метод для закрытия инпута по событию blur
	 * При отправки не валидного мейла/номера blur не срабатывает давая пользователю
	 * ввести правильные данные.
	 */

	cancelEditMainFIeld(): void {
		if (this.isClosedEmailSwitch) {
			this.inputControl.patchValue(this.values);
			this.isViewSaveButton = true;
			this.closedEditable();
			return;
		}
		this.isClosedEmailSwitch = true;
	}

	cancelEditText() {
		this.newValue = this.values;
		this.closedEditable();
	}

	updateMainField(data: Values) {
		this.updateMainFieldData.emit({
			[this.systemName]: data
		});
		this.closedEditable();
	}

	updateExtraField(data: Values) {
		this.updateExtraFieldData.emit({
			systemName: this.systemName,
			value: data
		});
		this.closedEditable();
	}

	setBooleanValue() {
		if (this.values !== null && this.values !== '') {
			const yes: string = this.translate.instant(
				'client_fields.value_true'
			);
			const no: string = this.translate.instant(
				'client_fields.value_false'
			);
			this.selectedBoolean =
				this.values === false
					? { name: no, value: false }
					: { name: yes, value: true };
			this.values = this.values === false ? no : yes;
		} else {
			this.values = null;
			this.selectedBoolean = {
				name: this.translate.instant('client_fields.value_null'),
				value: null
			};
		}
	}

	selectBoolean(event: SelectEvent): void {
		this.values = event?.value.value !== null ? event?.value.name : null;
		this.updateExtraField(
			event?.value.value === null ? '' : event?.value.value // чтобы удалить значение на бэк нужно отправить пустую строку.
		);
	}

	saveNumberValue() {
		let newNumberValue;
		const value: string = this.newValue as string;

		switch (this.type) {
			case 'float':
				if (Number.isInteger(+value) && this.newValue !== '') {
					newNumberValue = `${this.newValue}.00`;
				} else {
					newNumberValue = this.newValue;
				}
				break;
			case 'number':
				newNumberValue = this.newValue;
				break;
			default:
				break;
		}

		this.values = this.newValue;
		this.updateExtraField(newNumberValue as Values);
	}

	saveTextValue() {
		this.values = this.newValue as Values;
		this.updateExtraField(this.values);
	}

	checkEmail() {
		this.isClosedEmailSwitch = false;
		this.inputControl.markAllAsTouched();
		this.inputControl.markAsDirty();

		if (this.inputControl.hasError('invalidEmail')) return;

		this.checkEmailValid.emit(this.inputControl.value);
	}

	checkPhone() {
		this.isClosedEmailSwitch = false;
		this.inputControl.markAllAsTouched();
		this.inputControl.markAsDirty();
		this.checkPhoneValid.emit(this.inputControl.value);
	}

	private calendarChangeListener(previousValue: number | null) {
		this.dateControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value === previousValue) {
						return;
					}

					if (!value) {
						this.updateExtraField('');
						return;
					}

					if (
						value.toString().length >= 8 &&
						value.toString().length <= 10
					) {
						this.updateExtraField(value);
					}
				})
			)
			.subscribe();
	}

	setDateValue(value: number) {
		if (this.type === 'dateTime')
			this.values = this.timezoneService.convertDate(
				value,
				'D MMMM y, HH:mm'
			);
		else this.values = this.timezoneService.convertDate(value, 'D MMMM y');
	}
}
