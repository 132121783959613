import { InjectionToken, Provider } from '@angular/core';
import { Subject } from 'rxjs';
import { ClicksMapModifierInterface } from '../clicks-map.model';

export const CLICKS_MAP_MODIFIER_TOKEN = new InjectionToken(
	'token for clicks map modifier'
);

export const CLICKS_MAP_MODIFIER_PROVIDER: Provider = [
	{
		provide: CLICKS_MAP_MODIFIER_TOKEN,
		useValue: new Subject<ClicksMapModifierInterface>()
	}
];
