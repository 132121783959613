import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	NG_VALUE_ACCESSOR
} from '@angular/forms';
import { UrlSaverService } from '@enkod-core/services';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';

export const SEARCH_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SearchComponent),
	multi: true
};

@UntilDestroy()
@Component({
	selector: 'en-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	providers: [SEARCH_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, ControlValueAccessor {
	@Input() placeholder: string;

	@Input() resetButton = false;

	@Input() refresher = true;

	@Input() styleClass = 'h40';

	@Input() inputValue: string;

	@Input() resetValue$: Subject<void>;

	@Input() saveInUrl = true;

	@Output() refresh = new EventEmitter();

	@Output() onSearch = new EventEmitter<string>();

	value: string;

	blockSpecial = /^[^<>*!']+$/;

	search: UntypedFormControl = new UntypedFormControl('');

	// private onTouched: Function = () => {};

	private onChange: Function = () => {};

	constructor(private urlService: UrlSaverService) {}

	registerOnChange(onChange: Function) {
		this.onChange = onChange;
	}

	registerOnTouched() {
		// this.onTouched = onTouched;
	}

	writeValue(value: string) {
		this.value = value;
	}

	ngOnInit() {
		if (this.inputValue) this.search.patchValue(this.inputValue);
		this.search.valueChanges
			.pipe(untilDestroyed(this), debounceTime(200))
			.subscribe(value => {
				this.onChange(value);
				this.onSearch.emit(value);
			});
		if (this.resetValue$) {
			this.resetValue$
				.pipe(
					untilDestroyed(this),
					tap(() => this.search.patchValue(''))
				)
				.subscribe();
		}
		if (this.saveInUrl) {
			this.urlService
				.patchSearchControl(this.search)
				.pipe(take(1))
				.subscribe();
		}
	}

	clearSearch() {
		this.search.reset('');
	}
}
