import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AuthQuery } from '@enkod-core/authentication/_state';

@Injectable()
export class AccessEnrecomGuard implements CanActivate, CanLoad {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	private readonly isDev: boolean = isDevMode();

	canActivate() {
		const isAccessEnrecom = !!this.authQuery.isAccessEnrecom;
		if (!isAccessEnrecom) this.router.navigateByUrl('enrecom/get-service');

		return isAccessEnrecom;
	}

	canLoad() {
		const isAccessEnrecom = !!this.authQuery.isAccessEnrecom;
		if (!isAccessEnrecom) this.router.navigateByUrl('enrecom/get-service');

		return isAccessEnrecom;
	}
}
