import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnDropdownModule, EnInputModule, EnTooltipModule } from 'ui-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { FromEmailControlComponent } from './from-email-control.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnDropdownModule,
		ReactiveFormsModule,
		EnInputModule,
		EnTooltipModule
	],
	declarations: [FromEmailControlComponent],
	exports: [FromEmailControlComponent]
})
export class FromEmailControlModule {}
