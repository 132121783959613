import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { Observer } from 'rxjs';
import { NotificationContentContext } from 'ui-lib';
import {
	NotificationOptions,
	NotificationOptionsWithData
} from '../../notification.model';

export class NotificationAlert<O, I> {
	readonly status = this.options.status;

	readonly hasIcon = this.options.hasIcon;

	readonly position = this.options.position;

	readonly autoClose: boolean | number;

	readonly botText = this.options.botText;

	readonly hasCloseButton = this.options.hasCloseButton;

	readonly label = this.options.label;

	readonly style = this.options.style;

	readonly data!: I;

	constructor(
		readonly observer: Observer<O>,
		readonly content: PolymorpheusContent<NotificationContentContext<O, I>>,
		private readonly options: Required<
			NotificationOptions | NotificationOptionsWithData<I>
		>
	) {
		this.autoClose =
			typeof this.options.autoClose === 'function'
				? this.options.autoClose(this.options.status)
				: this.options.autoClose;

		if (options && this.withData(options)) {
			this.data = options.data;
		}
	}

	private withData(
		options: NotificationOptions | NotificationOptionsWithData<I>
	): options is NotificationOptionsWithData<I> {
		return 'data' in options;
	}
}
