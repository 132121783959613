import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'scroll-wrapper-settings',
	template: `
		<tui-scrollbar>
			<div class="content">
				<ng-content></ng-content>
			</div>
		</tui-scrollbar>
	`,
	styleUrls: ['./scroll-wrapper-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollWrapperSettingsComponent {}
