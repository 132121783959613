import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'extraFieldsDescriptionPipe'
})
export class ExtraFieldsDescriptionPipe implements PipeTransform {
	transform(dataType: string): string {
		switch (dataType) {
			case 'number':
				return 'extra_field_list.label_number';
			case 'float':
				return 'extra_field_list.label_float';
			case 'text':
			case 'string':
				return 'extra_field_list.label_text';
			case 'bool':
				return 'extra_field_list.label_boolean';
			case 'date':
				return 'extra_field_list.label_date';
			case 'dateTime':
				return 'extra_field_list.label_date_time';
			default:
				return 'extra_field_list.label_type_not_defined';
		}
	}
}
