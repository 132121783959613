import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment-timezone';

import { AuthQuery } from '@enkod-core/authentication/_state';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {
	constructor(
		private auth: AuthQuery,
		private translateService: TranslateService
	) {}

	get accountTimezone() {
		return this.auth.accountTimezone;
	}

	transform(
		timestamp: string | number,
		format: string,
		timezone?: string,
		locale?: string
	): string {
		if (!timestamp) return '';

		return moment
			.tz(+timestamp * 1000, timezone || this.accountTimezone)
			.locale(locale || this.translateService.currentLang)
			.format(format);
	}
}
