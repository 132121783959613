/* eslint-disable no-undef */
import { InjectionToken } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

export type SendTestPhoneConfig = {
	bodyDescription: string;
	inputMask: IMask.default.AnyMaskedOptions | null;
	validators: ValidatorFn[];
	placeholder: string;
};

export const SEND_TEST_PHONE = new InjectionToken<SendTestPhoneConfig>(
	'Конфиг для компонента отправки теста по телефону'
);
