<en-table-settings>
    <ng-container *tuiLet="columns$ | async as columns">
        <div cdkDropList
             class="columns__list"
             (cdkDropListDropped)="drop($event, columns)">

            <div *ngFor="let column of columns.blockColumns"
                 class="columns__item">
                <div class="item__content">
                    <i class="en en-lock"></i>
                    <span class="item__text">{{column.header | translate}}</span>
                </div>
            </div>

            <div *ngFor="let column of columns.enableColumns"
                 cdkDrag
                 class="columns__item columns__item_cursor"
                 [class.columns__item_disable]="column.disabled">
                <div class="item__content">
                    <i class="en en-drag-indicator"></i>

                    <span class="item__text">{{column.header | translate}}</span>
                </div>

                <button enButton
                        type="button"
                        class="en-button-lowercase columns__button h28"
                        [icon]="!column.disabled ? 'en en-eye' : 'en en-eye-close'"
                        [class.column__button_hidden]="column.disabled"
                        (click)="onToggleEnabled(column.field, columns)">
                </button>
            </div>
        </div>
    </ng-container>
</en-table-settings>