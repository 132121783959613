import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLetModule, TuiRepeatTimesModule } from '@taiga-ui/cdk';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { TuiScrollIntoViewModule } from '@taiga-ui/core/directives/scroll-into-view';
import { PrimitiveMonthPickerComponent } from './primitive-month-picker.component';

@NgModule({
	imports: [
		CommonModule,
		TuiRepeatTimesModule,
		TuiLetModule,
		TranslateModule,
		TuiScrollIntoViewModule,
		TuiScrollbarModule
	],
	declarations: [PrimitiveMonthPickerComponent],
	exports: [PrimitiveMonthPickerComponent]
})
export class EnPrimitiveMonthPickerModule {}
