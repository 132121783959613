import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiScrollbarModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { WhatsappPreviewComponent } from './whatsapp-preview.component';
import { PreviewButtonIconPipe } from './pipes/preview-button-icon.pipe';
import { PreviewTitleComponent } from './preview-title/preview-title.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		TuiScrollbarModule,
		TuiLetModule,
		TuiSvgModule
	],
	declarations: [
		WhatsappPreviewComponent,
		PreviewButtonIconPipe,
		PreviewTitleComponent
	],
	exports: [WhatsappPreviewComponent]
})
export class WhatsappPreviewModule {}
