import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnButtonModule } from '../../button/button';
import { PrimitiveSpinButtonComponent } from './primitive-spin-button.component';

@NgModule({
	imports: [CommonModule, EnButtonModule],
	declarations: [PrimitiveSpinButtonComponent],
	exports: [PrimitiveSpinButtonComponent]
})
export class EnPrimitiveSpinButtonModule {}
