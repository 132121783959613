import { EntityState, EntityStore, QueryEntity } from '@datorama/akita';

export abstract class ExpandedQuery<
	T extends EntityState
> extends QueryEntity<T> {
	constructor(protected store: EntityStore<T>) {
		super(store);
	}

	public hasAllEntities(): boolean {
		return !!this.store.getValue().allEntities;
	}

	public hasData(name: string): boolean {
		const data = this.store.getValue()[name];

		if (typeof data === 'object') {
			if (data === null) return false;
			if (Array.isArray(data)) return !!data.length;
			if (data.constructor === Object) return !!Object.keys(data).length;
		}

		return !!data;
	}
}
