<div *ngIf="maskVisible"
     [class]="maskStyleClass"
     [ngClass]="{
    'p-dialog-mask': true,
    'p-component-overlay': this.modal,
    'p-dialog-mask-scrollblocker': this.modal || this.blockScroll,
    'p-dialog-left': position === 'left',
    'p-dialog-right': position === 'right',
    'p-dialog-top': position === 'top',
    'p-dialog-top-left':
        position === 'topleft' || position === 'top-left',
    'p-dialog-top-right':
        position === 'topright' || position === 'top-right',
    'p-dialog-bottom': position === 'bottom',
    'p-dialog-bottom-left':
        position === 'bottomleft' || position === 'bottom-left',
    'p-dialog-bottom-right':
        position === 'bottomright' || position === 'bottom-right'
}">
    <div #container
         [ngClass]="{
        'p-dialog p-component': true,
        'p-dialog-rtl': rtl,
        'p-dialog-draggable': draggable,
        'p-dialog-resizable': resizable,
        'p-dialog-maximized': maximized
    }"
         [ngStyle]="style"
         [class]="styleClass"
         *ngIf="visible"
         pFocusTrap
         [pFocusTrapDisabled]="focusTrap === false"
         [@animation]="{
        value: 'visible',
        params: {
            transform: transformOptions,
            transition: transitionOptions
        }
    }"
         (@animation.start)="onAnimationStart($event)"
         (@animation.done)="onAnimationEnd($event)"
         role="dialog"
         [attr.aria-labelledby]="id + '-label'">
        <div #titlebar
             class="p-dialog-header"
             [ngClass]="{
                'header-info': headerBackground === 'info',
                'header-success': headerBackground === 'success',
                'header-warn': headerBackground === 'warn',
                'header-danger': headerBackground === 'danger'
            }"
             (mousedown)="initDrag($event)"
             *ngIf="showHeader">
            <div [attr.id]="id + '-label'"
                 class="p-dialog-title"
                 *ngIf="header">
                <i *ngIf="headerIcon"
                   [class]="headerIcon + ' dialog-custom-icon'"></i>
                <span>
                    {{ header | translate }}
                </span>
            </div>
            <span [attr.id]="id + '-label'"
                  class="p-dialog-title"
                  *ngIf="headerFacet">
                <ng-content select="p-header"></ng-content>
            </span>
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            <div class="p-dialog-header-icons">
                <button *ngIf="maximizable"
                        type="button"
                        [ngClass]="{
                    'p-dialog-header-icon p-dialog-header-maximize p-link': true
                }"
                        (click)="maximize()"
                        (keydown.enter)="maximize()"
                        tabindex="-1"
                        pRipple>
                    <span class="p-dialog-header-maximize-icon"
                          [ngClass]="
                        maximized ? minimizeIcon : maximizeIcon
                    "></span>
                </button>
                <button *ngIf="closable"
                        type="button"
                        [ngClass]="{
                    'p-dialog-header-icon p-dialog-header-close p-link': true
                }"
                        (click)="close($event)"
                        (keydown.enter)="close($event)"
                        tabindex="-1"
                        pRipple>
                    <span class="p-dialog-header-close-icon"
                          [ngClass]="closeIcon"></span>
                </button>
            </div>
        </div>
        <div #content
             [ngClass]="'p-dialog-content'"
             [ngStyle]="contentStyle"
             [class]="contentStyleClass"
             (scroll)="onScroll.emit($event)">
            <ng-content></ng-content>
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
        <div #footer
             class="p-dialog-footer"
             *ngIf="footerFacet || footerTemplate">
            <ng-content select="p-footer"></ng-content>
            <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
        </div>
        <div *ngIf="resizable"
             class="p-resizable-handle"
             style="z-index: 90;"
             (mousedown)="initResize($event)"></div>
    </div>
</div>
