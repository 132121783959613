<en-autoComplete [formControl]="formTags"
                 [suggestions]="filteredTagsMultiple"
                 [dropdown]="true"
                 [multiple]="true"
                 [showClear]="showClear"
                 appendTo="body"
                 (completeMethod)="filterTagMultiple($event)"
                 (onKeyUp)="addTag($event)"
                 (clickOnClear)="clearTags()"
                 [placeholder]="'autocomplete_component.tags_placeholder' | translate"></en-autoComplete>
