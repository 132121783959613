import { Injectable } from '@angular/core';
import { NotificationsService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, shareReplay, tap } from 'rxjs/operators';

import {
	DeleteSubmailerModel,
	DkimKey,
	DkimLength,
	IpList,
	ProviderList,
	Submailer,
	UndateSubmailerModel
} from './account-submailer-setting.model';

import { AccountSubmailersSettingsDataService } from './account-submailer-settings-data.service';
import { AccountSubmailersSettingsStore } from './account-submailer-settings.store';

@Injectable({ providedIn: 'root' })
export class AccountSubmailersSettingsService {
	constructor(
		private store: AccountSubmailersSettingsStore,
		private dataService: AccountSubmailersSettingsDataService,
		private notificationsService: NotificationsService
	) {}

	private ipList$: Observable<IpList[]> | null = null;

	private providerList$: Observable<ProviderList[]> | null = null;

	getSubmailersList(id: number): Observable<Submailer[]> {
		this.store.setLoading(true);
		return this.dataService.getSubmailers(id).pipe(
			tap(resp => this.store.set(resp)),
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		) as Observable<Submailer[]>;
	}

	createSubmailer(data: Submailer): Observable<Submailer> {
		this.store.setLoading(true);
		return this.dataService.create(data).pipe(
			tap(resp => this.store.add(resp)),
			finalize(() => this.store.setLoading(false))
		);
	}

	updateSubmailer(update: UndateSubmailerModel): Observable<Submailer> {
		this.store.setLoading(true);
		return this.dataService.updateSubmailer(update).pipe(
			tap(resp => this.store.upsert(resp.id, resp)),
			finalize(() => this.store.setLoading(false))
		);
	}

	deleteSubmailer(id: number, data: DeleteSubmailerModel): Observable<void> {
		this.store.setLoading(true);
		return this.dataService.removeSubmailer(id, data).pipe(
			tap(() => this.store.remove(id)),
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	getIpList(): Observable<IpList[]> {
		if (!this.ipList$)
			this.ipList$ = this.dataService.getIpList().pipe(
				shareReplay(1),
				catchError(e => {
					this.notificationsService
						.show('toast.detail_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return throwError(e);
				})
			);
		return this.ipList$;
	}

	getProviderList(): Observable<ProviderList[]> {
		if (!this.providerList$)
			this.providerList$ = this.dataService.getProviderList().pipe(
				shareReplay(1),
				catchError(e => {
					this.notificationsService
						.show('toast.detail_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return throwError(e);
				})
			);
		return this.providerList$;
	}

	generateDKIM(bit: DkimLength): Observable<DkimKey> {
		this.store.setLoading();
		return this.dataService.generateDKIM(bit).pipe(
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	resetCache(): void {
		this.ipList$ = null;
		this.providerList$ = null;

		/** В мсс при выборе доменов могут выводится домены прикрепленные к другому клиенту */
		this.store.remove();
	}
}
