import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from '@shared';

import {
	EnButtonModule,
	EnDropdownCalendarNewModule,
	EnDropdownModule,
	EnInputModule,
	EnSmallModule,
	ErrorModule
} from 'ui-lib';
import { ContactsFieldEditorComponent } from './contacts-field-editor.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		TranslateModule,
		EnDropdownModule,
		ReactiveFormsModule,
		EnButtonModule,
		CalendarModule,
		EnInputModule,
		IMaskModule,
		ErrorModule,
		EnDropdownCalendarNewModule,
		EnSmallModule
	],
	declarations: [ContactsFieldEditorComponent],
	exports: [ContactsFieldEditorComponent]
})
export class ContactsFieldEditorModule {}
