import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { SendTestPhoneConfig, SEND_TEST_PHONE } from '../tokens';
import { SendTestData } from '../types';
import { SendTestPhoneService } from './send-test-phone.service';

@UntilDestroy()
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'send-test-phone',
	templateUrl: './send-test-phone.component.html',
	styleUrls: ['./send-test-phone.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		SendTestPhoneService,
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				notValid: 'validators.invalid_phone',
				exist: 'Номер уже введен'
			}
		}
	]
})
export class SendTestPhoneComponent implements OnInit {
	filteredNumbersMultiple: string[];
	onInitNumbers = [];
	inputValue: string;

	readonly inputControl: UntypedFormControl = new UntypedFormControl('');

	@Output()
	onChange = new EventEmitter<string[]>();

	constructor(
		@Inject(SEND_TEST_PHONE)
		public readonly config: SendTestPhoneConfig,
		private sendTestPhoneService: SendTestPhoneService
	) {}

	ngOnInit() {
		this.sendTestPhoneService
			.getNumbers()
			.pipe(
				untilDestroyed(this),
				tap((numbers: any) => {
					if (numbers) this.onInitNumbers = numbers;
				})
			)
			.subscribe();

		this.inputControl.valueChanges
			.pipe(
				tap(phoneNumber => {
					this.onChange.emit(this.inputControl.value);
					if (
						phoneNumber.indexOf(
							this.inputValue?.replaceAll(' ', '')
						) !== -1
					) {
						this.inputControl.setErrors({ exist: true });
					}
				})
			)
			.subscribe();
	}

	onAdd(event: { key: string; target: { value: string } }): void {
		this.inputValue = event.target.value;
		const data: SendTestData = {
			channel: 'phone',
			value: this.inputValue
		};
		this.inputControl.markAsDirty();
		if (this.inputValue.length >= 16 && this.inputValue.length <= 22) {
			if (
				this.inputControl.value.indexOf(
					this.inputValue.replaceAll(' ', '')
				) !== -1
			) {
				this.inputControl.setErrors({ exist: true });
				return;
			}
			const selectedNumbers = [...this.inputControl.value] || [];
			selectedNumbers.push(this.inputValue);

			this.inputControl.patchValue(selectedNumbers);

			this.sendTestPhoneService
				.postNumbers(data)
				.pipe(untilDestroyed(this))
				.subscribe();

			// eslint-disable-next-line no-param-reassign
			event.target.value = '';
		} else {
			this.inputControl.setErrors({ notValid: true });
		}
	}

	filterNumberMultiple(event: any) {
		const { query } = event;
		this.filteredNumbersMultiple = this.filterNumber(
			query,
			this.onInitNumbers
		);
	}

	private filterNumber(query: any, numbers: string[] | null): string[] {
		const filtered: string[] = [];
		if (numbers) {
			for (let i = 0; i < numbers.length; i++) {
				const country = numbers[i];
				if (country.indexOf(query) === 0) {
					filtered.unshift(country);
				}
			}
		}
		return filtered;
	}
}
