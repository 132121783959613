/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-host-metadata-property */

import {
	NgModule,
	Component,
	Input,
	forwardRef,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUTSWITCH_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputSwitchComponent),
	multi: true
};

@Component({
	selector: 'en-inputswitch',
	template: `
		<div
			class="en-inputswitch en-component"
			[class]="styleClass"
			[ngClass]="{
				'en-inputswitch-checked': checked,
				'en-disabled': disabled,
				'en-focus': focused
			}"
			[ngStyle]="style"
			(click)="onClick($event, cb)"
		>
			<input
				class="en-switch-checkbox visually-hidden"
				#cb
				type="checkbox"
				[attr.id]="inputId"
				[attr.name]="name"
				[attr.tabindex]="tabindex"
				[checked]="checked"
				(change)="onInputChange($event)"
				(focus)="onFocus()"
				(blur)="onBlur()"
				[disabled]="disabled"
				role="switch"
				[attr.aria-checked]="checked"
				[attr.aria-labelledby]="ariaLabelledBy"
			/>
			<span class="en-inputswitch-slider"></span>
		</div>
	`,
	providers: [INPUTSWITCH_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class InputSwitchComponent implements ControlValueAccessor {
	@Input() style: any;

	@Input() styleClass: string;

	@Input() tabindex: number;

	@Input() inputId: string;

	@Input() name: string;

	@Input() disabled: boolean;

	@Input() readonly: boolean;

	@Input() ariaLabelledBy: string;

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	checked = false;

	focused = false;

	onModelChange: Function = () => {};

	onModelTouched: Function = () => {};

	constructor(private cd: ChangeDetectorRef) {}

	onClick(event: Event, cb: HTMLInputElement) {
		if (!this.disabled && !this.readonly) {
			event.preventDefault();
			this.toggle(event);
			cb.focus();
		}
	}

	onInputChange(event: Event) {
		if (!this.readonly) {
			const inputChecked = (<HTMLInputElement>event.target).checked;
			this.updateModel(event, inputChecked);
		}
	}

	toggle(event: Event) {
		this.updateModel(event, !this.checked);
	}

	updateModel(event: Event, value: boolean) {
		this.checked = value;
		this.onModelChange(this.checked);
		this.onChange.emit({
			originalEvent: event,
			checked: this.checked
		});
	}

	onFocus() {
		this.focused = true;
	}

	onBlur() {
		this.focused = false;
		this.onModelTouched();
	}

	writeValue(checked: any): void {
		this.checked = checked;
		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	setDisabledState(val: boolean): void {
		this.disabled = val;
		this.cd.markForCheck();
	}
}

@NgModule({
	imports: [CommonModule],
	exports: [InputSwitchComponent],
	declarations: [InputSwitchComponent]
})
export class EnSwitchModule {}
