import { NgModule } from '@angular/core';
import { TuiLetModule, TuiRepeatTimesModule } from '@taiga-ui/cdk';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { TuiScrollIntoViewModule } from '@taiga-ui/core/directives/scroll-into-view';

import { PrimitiveYearPickerComponent } from './primitive-year-picker.component';

@NgModule({
	imports: [
		TuiRepeatTimesModule,
		TuiLetModule,
		TuiScrollIntoViewModule,
		TuiScrollbarModule
	],
	declarations: [PrimitiveYearPickerComponent],
	exports: [PrimitiveYearPickerComponent]
})
export class EnPrimitiveYearPickerModule {}
