<tui-scrollbar automation-id="tui-calendar__scrollbar"
               class="scrollbar">
    <div class="wrapper-dropdown-year">
        <div *tuiRepeatTimes="let rowIndex of rows"
             class="row"
             [tuiScrollIntoView]="scrollItemIntoView(getItem(rowIndex))"
             [class.t-row__choice]="date.year === getItem(rowIndex)"
             (click)="onItemClick(getItem(rowIndex))">
            <div class="item">{{ getItem(rowIndex) }}</div>
        </div>
    </div>
</tui-scrollbar>
