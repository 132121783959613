<en-notification [options]="item"
                 [class]="item.status+' '+item.position"
                 (closeNotification)="closeNotification()">
    <label *ngIf="item.label"
           polymorpheus-outlet
           class="heading"
           [style]="item.style.label"
           [class.botText]="item.botText"
           [content]="item.label | translate"
           [context]="context">
    </label>
    <div polymorpheus-outlet
         class="content"
         [style]="item.style.content"
         [class.botText]="item.botText"
         [content]="item.content"
         [context]="context"></div>
</en-notification>