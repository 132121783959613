import { popperVariation, tooltipVariation } from '@ngneat/helipopper';
import { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';

export const HELIPOPPER_CONFIG: Partial<TippyConfig> = {
	defaultVariation: 'tooltip',
	variations: {
		tooltip: {
			...tooltipVariation,
			theme: 'default',
			arrow: true,
			delay: [500, 0]
		},
		popper: popperVariation,
		segmentTooltip: {
			...tooltipVariation,
			theme: 'light',
			offset: [0, 17]
		}
	}
};
