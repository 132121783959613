import { Pipe, PipeTransform } from '@angular/core';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'inboxTodayConvert'
})
export class InboxTodayPipe implements PipeTransform {
	constructor(
		private translate: TranslateService,
		private timezoneService: DateAccountTimezoneService
	) {}

	transform(value: number) {
		const valueDate = this.timezoneService.convertDate(value, 'DD.MM.yyyy');

		const today = this.timezoneService.convertDate(
			this.timezoneService.getToday() / 1000,
			'DD.MM.yyyy'
		);

		if (today === valueDate)
			return this.translate.instant('whatsapp_wizard.preview_today');
		return valueDate;
	}
}
