import { isDevMode, Inject, Injectable } from '@angular/core';
// https://github.com/Yaffle/EventSource

import { AuthQuery, AuthService } from '@enkod-core/authentication/_state';
import { SSE_ENDPOINT_TOKEN } from '@enkod-core/tokens';
import { environment } from 'environments/environment';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * @description
 * Для получения эндпоинта нужен провайд
 * SSE_ENDPOINT_TOKEN с useValue самого эндпоинта
 *
 * В эндпоинт можно подставлять id (пример: "endpoint/:id")
 *
 * Public API
 *
 * @onMessage  данные, присылаемые с бэка
 * @onError событие при возникновении ошибки
 * @onOpen событие при открытии sse
 * @loading - состояние открытия канала
 *
 * @Отписка не автоматическая! Необходимо отписаться от ивентов
 * для этого есть метод completeStreams
 *
 */
@Injectable()
export class ServerSentEventsService {
	private serverSentEvent: any;
	private fullUrl: string;
	private _onOpen$: Subject<any> = new Subject();
	private _onError$: Subject<any> = new Subject();
	private _onMessage$: Subject<any> = new Subject();
	private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	get onMessage$(): Observable<any> {
		return this._onMessage$.asObservable().pipe(
			tap(entity => {
				if (isDevMode()) console.log(entity);
			})
		);
	}

	get onError$(): Observable<any> {
		return this._onError$.asObservable();
	}

	get onOpen$(): Observable<any> {
		return this._onOpen$.asObservable();
	}

	get loading$(): Observable<boolean> {
		return this._loading$.asObservable();
	}

	constructor(
		@Inject(SSE_ENDPOINT_TOKEN)
		private readonly endpoint: string,
		private authQuery: AuthQuery,
		private authService: AuthService
	) {}

	init(id: number | string = ''): void {
		this.fullUrl =
			environment.baseUrl + this.endpoint.replace(':id', id.toString());
		this.assignSSE();
		this.registerMethods();
	}

	private assignSSE(): void {
		this._loading$.next(true);
		this.serverSentEvent = new EventSourcePolyfill(this.fullUrl, {
			headers: {
				Authorization: `Bearer ${this.authQuery.jwtToken}`
			}
		});
	}

	private registerMethods(): void {
		this.serverSentEvent.onopen = (event: any) => {
			this._onOpen$.next(event);
			this._loading$.next(false);
		};

		/** Баг библиотеки EventSourcePolyfill
		 * 	срабатывает ошибка в консоль (No activity within 45000 milliseconds)
		 * 	приблезительно через каждые 2 ~ 4 минуты
		 * 	после вызывает реконнект
		 * https://github.com/Yaffle/EventSource/issues/143 */
		this.serverSentEvent.onerror = (event: any) => {
			if (event?.status === 401) {
				this.authService
					.refreshToken()
					.subscribe(() => this.reopenStream());
			}
			this._onError$.next(event);
			this._loading$.next(false);
		};

		this.serverSentEvent.onmessage = (event: any) => {
			this._onMessage$.next(JSON.parse(event.data));
		};
	}

	public completeStreams(): void {
		this.serverSentEvent.close();
		this._onError$.complete();
		this._onMessage$.complete();
		this._onOpen$.complete();
		this._loading$.complete();
	}

	private reopenStream(): void {
		this.assignSSE();
		this.registerMethods();
	}
}
