import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';

import { EnTagComponent } from './tag.component';
import { EnTooltipModule } from '../tool-tip/tooltip';

@NgModule({
	imports: [CommonModule, SharedModule, EnTooltipModule],
	declarations: [EnTagComponent],
	exports: [EnTagComponent]
})
export class EnTagModule {}
