import { InjectionToken, Provider } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type LinkEditor = {
	linkId: number;
	url: string;
	user?: string;
	wasChanged?: boolean;
	isDynamic?: boolean;
};

export const OPEN_LINK_EDITOR_TOKEN = new InjectionToken(
	'token for open link editor'
);

export const OPEN_LINK_EDITOR_PROVIDER: Provider = [
	{
		provide: OPEN_LINK_EDITOR_TOKEN,
		useValue: new BehaviorSubject<LinkEditor>({
			linkId: -1,
			url: '',
			user: '',
			wasChanged: false
		})
	}
];
