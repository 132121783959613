import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
	TuiActiveZoneModule,
	TuiFocusableModule,
	TuiFocusVisibleModule
} from '@taiga-ui/cdk';
import { TranslateModule } from '@ngx-translate/core';
import { NumberRangeDirectiveModule } from '@enkod-core/directives';

import { SharedModule } from '@shared';
import { EnSplitSliderComponent } from './split-slider.component';
import { EnTooltipModule } from '../tool-tip/tooltip';
import { EnInputModule } from '../input/input';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		TuiActiveZoneModule,
		TuiFocusableModule,
		TuiFocusVisibleModule,
		TranslateModule,
		EnInputModule,
		EnTooltipModule,
		NumberRangeDirectiveModule
	],
	declarations: [EnSplitSliderComponent],
	exports: [EnSplitSliderComponent]
})
export class EnSplitSliderModule {}
