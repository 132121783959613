import { Injectable } from '@angular/core';
import {
	ClicksMapDetails,
	AllClicks,
	ShownClicksType
} from '../clicks-map.model';

@Injectable()
export class DataService {
	doc: Document;
	allLinksWrappers: HTMLElement[];
	allLinksData: ClicksMapDetails[] = [];
	allClicks: AllClicks;
	clientHeight: number;
	clientWidth: number;
	clicksType: ShownClicksType = 'general';

	reset() {
		this.allLinksData = [];
	}
}
