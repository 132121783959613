import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class GettedEnpopGuard implements CanActivate {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const isAccessEnpop = !!this.authQuery.isAccessEnpop;
		if (isAccessEnpop) this.router.navigateByUrl('enpop/popups');
		return !isAccessEnpop;
	}
}
