import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	CalendarService,
	MonthRange
} from 'app/ui-lib/new-calendar/calendar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiMonth, TuiYear } from '@taiga-ui/cdk';
import { Month } from '../../calendarNew/calendar.model';

@UntilDestroy()
@Component({
	selector: 'en-primitive-year-month-pagination',
	templateUrl: './primitive-year-month-pagination.component.html',
	styleUrls: ['./primitive-year-month-pagination.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveYearMonthPaginationComponent implements OnInit {
	choiceYearVisible = false;
	choiceMonthVisible = false;
	month = Month;

	date: TuiMonth;

	min: TuiYear = new TuiYear(1970);

	max: TuiYear = new TuiYear(TuiMonth.currentLocal().year + 20);

	@Input() position: number;

	@Output()
	readonly yearClick = new EventEmitter<TuiYear>();

	@Output()
	readonly monthClick = new EventEmitter<TuiMonth>();

	constructor(
		private calendarService: CalendarService,
		private cd: ChangeDetectorRef
	) {}

	get isSingle(): boolean {
		return this.calendarService.getIsSingle();
	}

	get dateShowRange() {
		return this.calendarService.getShowDateRange();
	}

	ngOnInit() {
		if (this.isSingle) {
			this.date = this.calendarService.getShowDate();
			this.calendarService.currentShowDate
				.pipe(untilDestroyed(this))
				.subscribe(date => {
					this.date = date;
					this.cd.markForCheck();
				});
		} else {
			this.date = this.calendarService.getShowDateRange()[this.position];
			this.calendarService.currentShowDateRange
				.pipe(untilDestroyed(this))
				.subscribe((date: MonthRange) => {
					this.date = date[this.position];
				});
		}
	}

	onYearClick() {
		this.choiceYearVisible = false;
	}

	onMonthClick() {
		this.choiceMonthVisible = false;
	}

	onPrevMonthClick() {
		this.calendarService.changeShowMonth(this.date.month - 1);
	}

	onNextMonthClick() {
		this.calendarService.changeShowMonth(this.date.month + 1);
	}
}
