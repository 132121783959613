import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShownClicksType } from 'app/ui-lib/clicks-map/clicks-map.model';

export function createMenu(
	renderer: Renderer2,
	clicksType: ShownClicksType,
	translate: TranslateService
): HTMLElement {
	const menu = renderer.createElement('div');
	renderer.addClass(menu, 'enkod-menu');

	const clicksItem = renderer.createElement('div');
	renderer.addClass(clicksItem, 'enkod-menu__item');
	if (clicksType === 'general')
		renderer.addClass(clicksItem, 'enkod-menu__item-active');
	const firstItemText = renderer.createText(
		translate.instant('clicks_map.menu_show_general')
	);
	renderer.appendChild(clicksItem, firstItemText);

	const uniqClicksItem = renderer.createElement('div');
	renderer.addClass(uniqClicksItem, 'enkod-menu__item');
	if (clicksType === 'uniq')
		renderer.addClass(uniqClicksItem, 'enkod-menu__item-active');
	const secondItemText = renderer.createText(
		translate.instant('clicks_map.menu_show_uniqs')
	);
	renderer.appendChild(uniqClicksItem, secondItemText);

	renderer.appendChild(menu, clicksItem);
	renderer.appendChild(menu, uniqClicksItem);

	return menu;
}
