import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'buttonIcon',
	pure: true
})
export class PreviewButtonIconPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'PHONE_NUMBER':
				return 'enIconPhone';
			case 'URL':
				return 'enIconExternalLink';
			default:
				return 'enIconReply';
		}
	}
}
