import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

type ID = number | string;
type EntityWithId = { id: ID };

/**
 * @deprecated
 * новый тип в папке core/types/common.ts
 */
export interface PaginationResponse<T> {
	total: number;
	result: T[];
}

/**
 * @deprecated
 * новый сервис в папке core/abstract/crud-service.ts
 */
export abstract class RestService<T extends EntityWithId> {
	constructor(private httpClient: HttpClient, private endpoint: string) {}

	create(item: T): Observable<T> {
		return this.httpClient.post<T>(`${this.endpoint}/`, item);
	}

	update(item: T): Observable<T> {
		return this.httpClient.put<T>(`${this.endpoint}/${item.id}`, item);
	}

	detail(id: ID, queryParams?: any) {
		const params = queryParams
			? new HttpParams({
					fromObject: convertPaginationAndSortParams(queryParams)
			  })
			: {};

		return this.httpClient.get<T>(`${this.endpoint}/${id}`, {
			params
		});
	}

	list(queryParams: any = {}) {
		const params = new HttpParams({
			fromObject: convertPaginationAndSortParams(
				deleteEmptyKeys(queryParams)
			)
		});
		return this.httpClient.get<PaginationResponse<T>>(`${this.endpoint}/`, {
			params
		});
	}

	remove(id: ID) {
		return this.httpClient.delete<void>(`${this.endpoint}/${id}`);
	}
}

// TODO: рефактор функций
export function convertPaginationAndSortParams(params: any) {
	let copy = { ...params };
	if (
		// copy.hasOwnProperty('pageIndex') &&         линтер ругается на hasOwnProperty
		// copy.hasOwnProperty('pageSize')
		copy.pageIndex &&
		copy.pageSize
	) {
		copy.offset = copy.pageIndex * copy.pageSize;
		copy.limit = copy.pageSize;
		delete copy.pageIndex;
		delete copy.pageSize;
	}
	if (copy.sort) {
		copy.sortKey = copy.sort.field;
		copy.sortOrder = copy.sort.order === 1 ? 'asc' : 'desc';
		delete copy.sort;
	}
	if (copy.requestString) {
		delete copy.requestString;
		copy = { ...params.requestString };
	}
	return copy;
}

export function deleteEmptyKeys(object: object): object {
	const filtredObject: any = {};

	Object.entries(object).forEach(([key, value]) => {
		switch (true) {
			case value && typeof value === 'object':
				if (Object.keys(value).length) filtredObject[key] = value;
				break;
			case Array.isArray(value) && value.length:
			case typeof value === 'number' && !Number.isNaN(value):
			case typeof value === 'boolean':
			case typeof value === 'string' && value !== '':
				filtredObject[key] = value;
				break;

			default:
				break;
		}
	});

	return filtredObject;
}
