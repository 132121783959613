import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	Inject,
	Input
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiHandler, TuiMapper } from '@taiga-ui/cdk';

import { Access } from '@enkod-core/authentication/models';
import { AuthQuery } from '@enkod-core/authentication/_state';
import { NotificationsService, UrlSaverService } from '@enkod-core/services';
import { MessageType } from '@enSend/message/kit';

import { NotificationStatus } from 'ui-lib';

import { MESSAGE_WIZARD_SERVICE, MESSAGE_WIZARD_DTO } from '../../tokens';
import {
	CommonMessageModel,
	CommonWizardFormValue,
	WizardService
} from '../../interfaces';
import { DataWizardService } from '../../services';
import { MessageTypeChoose } from '../../types';

export interface Types {
	value: string;
	name: string;
	icon: string;
	isDisabled: boolean;
}

@UntilDestroy()
@Component({
	selector: 'en-send-method-dialog',
	templateUrl: './send-method-dialog.component.html',
	styleUrls: ['./send-method-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendMethodDialogComponent implements OnInit {
	display = true;
	loading = false;
	choosenTypeName: string;

	form: UntypedFormGroup;

	@Input() types: MessageTypeChoose[];

	@Input() url: string;

	@Output()
	private readonly hide = new EventEmitter<void>();

	constructor(
		@Inject(MESSAGE_WIZARD_SERVICE)
		private readonly wizardService: WizardService<CommonMessageModel>,
		@Inject(MESSAGE_WIZARD_DTO)
		private readonly wizardDTO: TuiHandler<
			CommonWizardFormValue,
			CommonMessageModel
		>,
		private dataWizard: DataWizardService<CommonMessageModel>,
		private router: Router,
		private notificationsService: NotificationsService,
		private cd: ChangeDetectorRef,
		private authQuery: AuthQuery,
		private urlService: UrlSaverService
	) {}

	get typeControl() {
		return this.wizardService.form.get('type') as UntypedFormControl;
	}

	ngOnInit() {
		this.wizardService.initForm();
		this.form = this.wizardService.form;
	}

	chooseType(type: MessageType): void {
		if (this.loading) return;
		this.choosenTypeName = type;
		this.typeControl.patchValue(type);
		this.wizardService.initMessage({ type });
		this.createMessage();
	}

	private createMessage(): void {
		this.loading = true;
		const message = this.wizardDTO(this.form.value);
		this.dataWizard
			.createMessage(message)
			.pipe(untilDestroyed(this))
			.subscribe({
				next: resp => {
					this.urlService.saveCurrentUrl();
					this.router.navigate([
						this.url,
						resp.type,
						resp.id,
						this.url.includes('scenario') ? 'editor' : 'condition'
					]);
					this.loading = false;
				},
				error: () => {
					this.notificationsService
						.show('toast.detail_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.pipe(untilDestroyed(this))
						.subscribe();
					this.display = false;
					this.cd.markForCheck();
				}
			});
	}

	closeDialog(): void {
		this.wizardService.resetMessageFormGroup();
		this.hide.emit();
	}

	disableChannel: TuiMapper<boolean, boolean> = (
		isDisabled,
		channelType: string
	) => {
		switch (channelType) {
			case Access.SMS:
				return !this.authQuery.isAccessSms || isDisabled;
			case Access.MAIL:
				return !this.authQuery.isAccessMail || isDisabled;
			case 'push':
				return !this.authQuery.isAccessWebPush || isDisabled;
			case Access.MOBPUSH:
				return !this.authQuery.isAccessMobPush || isDisabled;
			case Access.WHATSAPP:
				return !this.authQuery.isAccessWhatsApp || isDisabled;
			default:
				return isDisabled;
		}
	};
}
