import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import { TuiMonth, TuiMonthRange, TuiYear } from '@taiga-ui/cdk';
import { yearMaxFromCurrent, yearMin } from '../../calendarNew/calendar.model';

const LIMIT = 100;

@Component({
	selector: 'en-primitive-year-picker',
	templateUrl: './primitive-year-picker.component.html',
	styleUrls: ['./primitive-year-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveYearPickerComponent implements OnInit {
	date: TuiMonth;

	initialItem = TuiMonth.currentLocal();

	min: TuiYear = new TuiYear(yearMin);

	max: TuiYear = new TuiYear(
		TuiMonth.currentLocal().year + yearMaxFromCurrent
	);

	@Input() position: number;

	@Output()
	readonly yearClick = new EventEmitter<void>();

	constructor(private calendarService: CalendarService) {}

	get rows(): number {
		return Math.ceil(this.calculatedMax - this.calculatedMin);
	}

	get calculatedMin(): number {
		const initial = this.initialItem.year - LIMIT;

		return this.min.year > initial ? this.min.year : initial;
	}

	get calculatedMax(): number {
		const initial = this.initialItem.year + LIMIT;

		return this.max.year < initial ? this.max.year + 1 : initial;
	}

	get isSingle() {
		return this.calendarService.getIsSingle();
	}

	ngOnInit() {
		if (this.isSingle) this.date = this.calendarService.getShowDate();
		else this.date = this.calendarService.getShowDateRange()[this.position];
	}

	getItem(rowIndex: number): number {
		return rowIndex + this.calculatedMin;
	}

	isRange(item: TuiMonthRange | TuiYear): item is TuiMonthRange {
		return item instanceof TuiMonthRange;
	}

	scrollItemIntoView(item: number): boolean {
		return this.initialItem.year === item;
	}

	onItemClick(item: number) {
		if (this.isSingle) this.calendarService.changeShowYear(item);
		else {
			this.position
				? this.calendarService.changeShowDoubleYearRange(item)
				: this.calendarService.changeShowFirstYearRange(item);
		}
		this.yearClick.emit();
	}
}
