import { InjectionToken } from '@angular/core';
import { EntityState, PaginatorPlugin, QueryEntity } from '@datorama/akita';

export const PAGINATOR_PLUGIN_TOKEN = new InjectionToken<
	QueryEntity<EntityState>
>('Paginator plugin token');

export function paginatorPluginFactory(
	query: QueryEntity<EntityState>
): PaginatorPlugin<EntityState> {
	return new PaginatorPlugin(query).withControls().withRange();
}
