import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tuiAssert } from '@taiga-ui/cdk';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { NotificationAlert } from 'app/ui-lib/notification/modules/notification-alert/Notification-alert';
import { NotificationContentContext } from 'app/ui-lib/notification/modules/notifications-content-context';
import {
	NotificationOptionsWithData,
	NotificationTokenOptions,
	NOTIFICATION_OPTIONS,
	NotificationOptions
} from 'app/ui-lib/notification/notification.model';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

const NO_HOST =
	'Notifications are disabled, enable support by adding NotificationsModule to your main app module';
@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	readonly items$ = new BehaviorSubject<
		ReadonlyArray<NotificationAlert<any, any>>
	>([]);

	constructor(
		@Inject(NOTIFICATION_OPTIONS)
		public readonly options: NotificationTokenOptions,
		private translate: TranslateService
	) {}

	show<O = void>(
		content: PolymorpheusContent<NotificationContentContext<O>>
	): Observable<O>;
	show<O = void>(
		content: PolymorpheusContent<NotificationContentContext<O>>,
		options: NotificationOptions
	): Observable<O>;
	show<O, I>(
		content: PolymorpheusContent<NotificationContentContext<O, I>>,
		options: NotificationOptions | NotificationOptionsWithData<I> = {}
	): Observable<O> {
		return new Observable((observer: Observer<O>) => {
			tuiAssert.assert(!!this.items$.observers.length, NO_HOST);
			let translateContent: any;
			if (typeof content === 'string')
				translateContent =
					options.params !== {} && content.toString() !== ''
						? this.translate.instant(
								content.toString(),
								options.params
						  )
						: content.toString();
			else translateContent = content;
			const notification = new NotificationAlert(
				observer,
				translateContent,
				{
					...this.options,
					...options
				}
			);
			this.items$.next([...this.items$.value, notification]);

			return () => {
				this.items$.next(
					this.items$.value.filter(item => item !== notification)
				);
			};
		});
	}
}
