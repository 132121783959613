import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	OnInit
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { WINDOW } from '@enkod-core/utils';
import { Message } from '@enSend/message/_states/_state-message';
import { TemplateDataService } from '@enSend/message/whatsapp-template-wizard/services';
import {
	ButtonParam,
	WaTemplateDTO
} from '@enSend/message/whatsapp-template-wizard/models';

import { WhatsappVariableService } from '../../services/whatsapp-variable.service';
import { PreviewContentService } from './services/preview-content.service';

@UntilDestroy()
@Component({
	selector: 'en-whatsapp-preview',
	templateUrl: './whatsapp-preview.component.html',
	styleUrls: ['./whatsapp-preview.component.scss'],
	providers: [
		WhatsappVariableService,
		TemplateDataService,
		PreviewContentService
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappPreviewComponent implements OnInit {
	get textContent$(): Subject<string> {
		return this.contentService.textContent$;
	}

	get footerContent$(): Subject<string> {
		return this.contentService.footerContent$;
	}

	get buttonArray$(): BehaviorSubject<ButtonParam[]> {
		return this.contentService.buttonArray$;
	}

	// Собирает превью из сообщения
	@Input() staticMessage: Message;
	// Собирает превью из шаблона
	@Input() staticTemplate: WaTemplateDTO;

	@Input() form: UntypedFormGroup;
	@Input() loadBeforeInit = false;
	@Input() detailsMode = false;
	@Input() isTemplateWizard = true;

	constructor(
		@Inject(WINDOW) readonly window: Window,
		public variableService: WhatsappVariableService,
		public contentService: PreviewContentService,
		private templateService: TemplateDataService
	) {}

	get titleTypeValue(): string {
		return this.form.controls.titleType?.value;
	}

	get textControl(): UntypedFormControl {
		return this.form.get('text') as UntypedFormControl;
	}

	get footerControl(): UntypedFormControl {
		return this.form?.get('footer') as UntypedFormControl;
	}

	get messageTemplateParams(): UntypedFormGroup {
		return this.form.get('params') as UntypedFormGroup;
	}

	get textVariableArray(): UntypedFormArray {
		return this.messageTemplateParams?.get('body_text') as UntypedFormArray;
	}

	get buttonArray(): UntypedFormArray {
		return this.form?.controls.buttons as UntypedFormArray;
	}

	get buttonVariableArray(): UntypedFormArray {
		return this.messageTemplateParams.controls
			.url_button_params as UntypedFormArray;
	}

	ngOnInit(): void {
		if (this.staticMessage) {
			this.useStaticMessage();
		} else if (this.staticTemplate) {
			this.contentService.showStaticBodyFromTemplate(this.staticTemplate);
		} else {
			this.setListeners();
			if (this.loadBeforeInit) {
				this.textControl.patchValue(this.textControl.value);
				this.footerControl.patchValue(this.footerControl.value);
				this.buttonArray.patchValue(this.buttonArray.value);
			}
		}
	}

	private setListeners(): void {
		this.footerControl.valueChanges
			.pipe(untilDestroyed(this), debounceTime(300))
			.subscribe(text => {
				this.footerContent$.next(text);
			});

		this.textControl.valueChanges
			.pipe(untilDestroyed(this), debounceTime(300))
			.subscribe(() => {
				// Сортируем переменные в тексте
				const correctedText = this.variableService.sortVariablesInText(
					this.textControl.value || '',
					5
				);
				this.textControl.patchValue(correctedText, {
					emitEvent: false
				});
				this.setVariableControl();
				// Подставляем переменные для превью
				this.textContent$.next(
					this.variableService.replaceVariablesInText(
						this.textControl.value,
						this.textVariableArray?.value
					)
				);
			});

		this.textVariableArray?.valueChanges
			.pipe(untilDestroyed(this), debounceTime(300))
			.subscribe(variables =>
				this.textContent$.next(
					this.variableService.replaceVariablesInText(
						this.textControl.value,
						variables
					)
				)
			);

		this.buttonArray?.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				this.buttonArray$.next(value);
			});
	}

	private setVariableControl(): void {
		const variables = this.variableService.getVariables(
			this.textControl.value
		);
		const lastValue = this.textVariableArray?.value;
		this.textVariableArray?.clear();
		variables.forEach((_variable, index) => {
			this.textVariableArray?.push(
				new UntypedFormControl('', [
					Validators.required,
					Validators.maxLength(1024)
				])
			);
			this.textVariableArray?.controls[index].patchValue(
				lastValue[index]
			);
		});
	}

	private useStaticMessage(): void {
		this.contentService
			.showStaticBodyFromMessage(this.staticMessage)
			.pipe(untilDestroyed(this))
			.subscribe();
	}

	onButtonClick(button: ButtonParam): void {
		if (this.form && button.type === 'URL') {
			let url = button.url || '';
			if (this.buttonVariableArray?.length)
				url = url.replace('{{1}}', this.buttonVariableArray.value[0]);
			this.window.open(url, '_blank');
		}
	}
}
