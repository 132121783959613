import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagsControlModule } from '@enSend/message/message-wizard/kit';
import { SharedModule } from '@shared';
import { EnButtonModule, EnDialogModule } from 'ui-lib';
import { EditTagsComponent } from './edit-tags.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		TagsControlModule,
		EnDialogModule,
		EnButtonModule
	],
	declarations: [EditTagsComponent],
	exports: [EditTagsComponent]
})
export class EditTagsModule {}
