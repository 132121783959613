import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {
	enableAkitaProdMode,
	persistState,
	akitaConfig
} from '@datorama/akita';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	enableAkitaProdMode();
}

akitaConfig({
	resettable: true
});

const storage = persistState({
	key: 'enKod',
	include: [
		'auth',
		'user-settings',
		'layout-settings',
		'table-columns-settings'
	]
});

const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers)
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
