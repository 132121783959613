<div
     class="wrapper"
     (keydown.arrowLeft)="onLeftClick()"
     (keydown.arrowRight)="onRightClick()">
    <button enButton
            type="button"
            icon="en en-arrow-left"
            class="arrow"
            [disabled]="leftComputedDisabled"
            [class.arrow__hidden]="leftComputedDisabled"
            (click)="onLeftClick()"></button>
    <span class="content">
        <ng-content></ng-content>
    </span>
    <button enButton
            type="button"
            icon="en en-arrow-right"
            class="arrow"
            [disabled]="rightComputedDisabled"
            [class.arrow__hidden]="rightComputedDisabled"
            (click)="onRightClick()"></button>
</div>
