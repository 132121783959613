import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef
} from '@angular/core';
import { animate, trigger, transition, style } from '@angular/animations';

@Component({
	selector: 'en-table-settings',
	templateUrl: './table-settings.component.html',
	styleUrls: ['./table-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('overlayAnimation', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(0.5rem)' }),
				animate('.12s cubic-bezier(0, 0, 0.2, 1)')
			]),
			transition(':leave', [
				style({ opacity: 0, transform: 'translateY(0.5rem)' }),
				animate('.3s linear')
			])
		])
	]
})
export class EnTableSettingsComponent {
	visibleTableSettings = false;

	@ViewChild('button') private buttonRef: ElementRef;

	constructor(private cd: ChangeDetectorRef) {}

	tableDisplaySettings() {
		if (!this.visibleTableSettings) {
			this.visibleTableSettings = true;
		} else this.visibleTableSettings = false;
	}

	outsideClick(event: MouseEvent) {
		if (this.buttonRef.nativeElement.contains(<Node>event.target)) return;
		this.hideOverlay();
	}

	hideOverlay() {
		this.visibleTableSettings = false;
		this.cd.markForCheck();
	}
}
