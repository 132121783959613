import { Renderer2 } from '@angular/core';

export function createHeatScale(renderer: Renderer2): HTMLElement {
	const heatScale = renderer.createElement('div');
	renderer.addClass(heatScale, 'enkod-heat-scale');

	const max = renderer.createElement('div');
	const maxText = renderer.createText('Max');
	renderer.appendChild(max, maxText);

	const gradient = renderer.createElement('div');
	renderer.addClass(gradient, 'enkod-heat-scale__gradient');

	const min = renderer.createElement('div');
	const minText = renderer.createText('Min');
	renderer.appendChild(min, minText);

	renderer.appendChild(heatScale, max);
	renderer.appendChild(heatScale, gradient);
	renderer.appendChild(heatScale, min);

	return heatScale;
}
