import { Injectable, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../data.service';
import { ChangeClicksTypeService } from '../change-clicks-type.service';
import { createHeatScale, createMenu, createMenuButton } from './components';

@Injectable()
export class AsideService {
	constructor(
		private renderer: Renderer2,
		private changeTypeService: ChangeClicksTypeService,
		private data: DataService,
		private translate: TranslateService
	) {}

	sidebar: HTMLElement;
	menu: HTMLElement | null;
	menuButton: HTMLElement;
	menuVisible = false;
	removeMenuTimeout: ReturnType<typeof setTimeout>;
	menuSubscriptions: Subscription[] = [];
	openMenuSubscription: Subscription | null;

	createAside(): HTMLElement {
		this.sidebar = this.renderer.createElement('div');
		this.renderer.addClass(this.sidebar, 'enkod-sidebar');

		const heatScale = createHeatScale(this.renderer);
		this.menuButton = createMenuButton(this.renderer);

		const click$ = fromEvent(this.menuButton, 'click');

		this.openMenuSubscription = click$
			.pipe(
				throttleTime(20),
				tap(() => this.changeMenuVisible())
			)
			.subscribe();

		this.renderer.appendChild(this.sidebar, heatScale);
		this.renderer.appendChild(this.sidebar, this.menuButton);

		return this.sidebar;
	}

	changeMenuVisible() {
		this.menuVisible ? this.hideMenu() : this.showMenu();
	}

	unsubscribeAll() {
		this.menuSubscriptions.forEach(subscription =>
			subscription.unsubscribe()
		);
		this.menuSubscriptions = [];

		this.openMenuSubscription?.unsubscribe();
		this.openMenuSubscription = null;
	}

	private showMenu() {
		this.menuVisible = true;
		if (this.removeMenuTimeout) clearTimeout(this.removeMenuTimeout);

		if (this.menu) {
			this.renderer.removeClass(this.menu, 'enkod-menu-hide');
			return;
		}

		this.menu = createMenu(
			this.renderer,
			this.data.clicksType,
			this.translate
		);
		this.renderer.appendChild(this.sidebar, this.menu);

		const clickSubsctiption = fromEvent(this.data.doc, 'click')
			.pipe(
				throttleTime(20),
				tap(event => this.menuEventsHandler(event))
			)
			.subscribe();
		this.menuSubscriptions.push(clickSubsctiption);
	}

	// todo анимация нажатия кнопки
	private hideMenu() {
		this.menuVisible = false;
		this.renderer.addClass(this.menu, 'enkod-menu-hide');

		this.removeMenuTimeout = setTimeout(() => {
			this.menu?.remove();
			this.menu = null;
			this.menuSubscriptions.forEach(subscription =>
				subscription.unsubscribe()
			);
		}, 400);
	}

	private menuEventsHandler(event: Event) {
		if (this.isOneOfChildren(event.target, this.menuButton)) return;
		if (event.target === this.menu) return;

		const clicks = this.menu?.firstElementChild as HTMLElement;
		const uniqClicks = this.menu?.lastElementChild as HTMLElement;

		if (event.target === clicks) {
			this.changeTypeService.changeToGeneral();
			this.changeActiveItem(clicks, uniqClicks);
		}

		if (event.target === uniqClicks) {
			this.changeTypeService.changeToUniq();
			this.changeActiveItem(uniqClicks, clicks);
		}

		this.hideMenu();
	}

	private changeActiveItem(active: HTMLElement, nonActive: HTMLElement) {
		this.renderer.removeClass(nonActive, 'enkod-menu__item-active');
		this.renderer.addClass(active, 'enkod-menu__item-active');
	}

	// проверяет равен ли таргет элементу или хотя бы одному из потомков элемента
	private isOneOfChildren(
		target: EventTarget | null,
		element: HTMLElement
	): boolean {
		let isOneOfChildren = target === element;
		if (isOneOfChildren) return isOneOfChildren;

		function checkChildren(children: HTMLCollection) {
			for (let i = 0; i < children.length; i++) {
				if (target === children[i]) {
					isOneOfChildren = true;
					return;
				}
				if (children[i].children.length)
					checkChildren(children[i].children);
			}
		}

		if (element.children.length) checkChildren(element.children);
		return isOneOfChildren;
	}
}
