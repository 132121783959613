import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@enkod-core/components';
import { HorizontalScrollWrapperComponent } from './horizontal-scroll-wrapper.component';

@NgModule({
	imports: [CommonModule, TuiScrollbarModule],
	declarations: [HorizontalScrollWrapperComponent],
	exports: [HorizontalScrollWrapperComponent]
})
export class HorizontalScrollWrapperModule {}
