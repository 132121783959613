export const ALL_WEEKDAYS: { [key: string]: string } = {
	1: 'calendar.days_monday',
	2: 'calendar.days_tuesday',
	3: 'calendar.days_wednesday',
	4: 'calendar.days_thursday',
	5: 'calendar.days_friday',
	6: 'calendar.days_saturday',
	7: 'calendar.days_sunday'
};

export const ALL_MONTH: { [key: string]: string } = {
	1: 'calendar.months_january',
	2: 'calendar.months_february',
	3: 'calendar.months_march',
	4: 'calendar.months_april',
	5: 'calendar.months_may',
	6: 'calendar.months_june',
	7: 'calendar.months_july',
	8: 'calendar.months_august',
	9: 'calendar.months_september',
	10: 'calendar.months_october',
	11: 'calendar.months_november',
	12: 'calendar.months_december'
};
