import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserSettingsState } from '@state-user-settings';

@Injectable({
	providedIn: 'root'
})
export class UserSettingsDataService {
	constructor(private http: HttpClient) {}

	get() {
		return this.http.get(`enpop/settings/userSettings/`);
	}

	set(settings: Partial<UserSettingsState>) {
		return this.http.post(`enpop/settings/userSettings/`, settings);
	}
}
