import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnTableListComponent } from './en-table-list.component';
import { EnTableListItemModule } from '../table-list-item/en-table-list-item.module';
import { SpinLoaderModule } from '../spin-loader/spin-loader.module';

@NgModule({
	imports: [CommonModule, EnTableListItemModule, SpinLoaderModule],
	declarations: [EnTableListComponent],
	exports: [EnTableListComponent]
})
export class EnTableListModule {}
