import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/** Оставлю в руте, т.к. мб понадобится с других мест создавать сообщения */
@Injectable({
	providedIn: 'root'
})
export class DataWizardService<T> {
	private readonly endpoint = 'ensend/message/';

	constructor(private http: HttpClient) {}

	createMessage(body: T) {
		return this.http.post<T>(this.endpoint, body);
	}

	getMessage(id: number, params = {}) {
		return this.http.get<T>(`${this.endpoint}${id}`, params);
	}
}
