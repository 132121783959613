import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NotificationAlertModule } from './notification-alert/notification-alert.module';
import { NotificationHostComponent } from './notification-host/notification-host.component';

export const NOTIFICATIONS_HOST = NotificationHostComponent;

@NgModule({
    imports: [CommonModule, NotificationAlertModule, TuiLetModule],
    declarations: [NotificationHostComponent],
    exports: [NotificationHostComponent],
    providers: [
        {
            provide: NotificationHostComponent,
            useValue: NOTIFICATIONS_HOST
        }
    ]
})
export class NotificationsModule {}
