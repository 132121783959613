import { Injectable } from '@angular/core';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { ChartDataSets } from 'chart.js';
import { defaultColors } from 'ui-lib/chart.js/timeline-chart/constants';
import { getRandomColor } from '../functions';
import {
	BackendChartData,
	BackendChartStatistic,
	ChartData,
	ChartLineType,
	GroupOptions,
	LinesLabels,
	LinesLabelsInfoId,
	MessageBackendChartData,
	UnixFromLabel
} from '../models';

// базовые стили для графика
const datasetConstants = {
	fill: false,
	borderWidth: 1.5,
	lineTension: 0.05,
	pointHitRadius: 32,
	pointRadius: 0,
	pointHoverRadius: 5
};
// у каждого label к графику типа (30.04) есть его unixFromLabel - это реальная дата в unix формате
// почему не объект { label: 30.04, unix: 1651276800} - потому что Chart.js работает только с массивом строк или чисел.
// поэтому сделан отдельный объект, где ключ это лейбл, а значение - его unix
// это может быть не безопасно, если ключ с таким же значением перезапишет его unix

// что такое datasets - это массив из ВСЕХ данных, которые отображает Chart.js на графике
// при этом внутри каждого элемента массива datasets, есть ключ data - это массив - все точки одной линии графика, которые могут быть отображены
// каждый элемент data по индексу соответствует элементам массива labels.
// т.е. datasets[0].data[index] соотносится к labels[index]

export type ChartOTDOptions = {
	chartStyle?: Partial<ChartDataSets>;
	linesLabels?: LinesLabels;
	labelInfoIds?: LinesLabelsInfoId;
	isNewChart?: boolean; // заменяет все данные графика, если true. Также нужно использовать при первой инициализации
};
@Injectable({
	providedIn: 'root'
})
export class TimelineChartOTD {
	private previousLineType: ChartLineType[] = [];

	constructor(private timezoneService: DateAccountTimezoneService) {}

	get accountTimezone() {
		return this.timezoneService.accountTimezone;
	}

	getDataAndLabels<T extends BackendChartData = MessageBackendChartData>(
		backData: T[],
		group: GroupOptions = 'day',
		options: ChartOTDOptions = {}
	): ChartData {
		if (!backData?.length)
			return {
				datasets: [],
				labels: [],
				unixFromLabel: {},
				isNewChart: !!options.isNewChart
			};
		if (options.isNewChart) this.previousLineType = [];

		const lines = backData.map((line: T, i: number) => ({
			...datasetConstants,
			...options.chartStyle,
			label:
				options.linesLabels?.[i] ||
				`id ${(line as unknown as MessageBackendChartData).messageId} ${
					(line as unknown as MessageBackendChartData).name
				}`,
			borderColor: defaultColors[i] || getRandomColor(),
			labelInfoId: options.labelInfoIds ? options.labelInfoIds[i] : null,
			...this.getData(line.statistic, i)
		}));
		const unixAndLabels = this.getUnixAndLabels(
			group,
			backData[0].statistic
		);
		return {
			datasets: lines,
			labels: unixAndLabels.labels,
			unixFromLabel: unixAndLabels.unixFromLabel,
			isNewChart: !!options.isNewChart
		};
	}

	private getData(
		backData: BackendChartStatistic[],
		i: number
	): {
		data: (null | number)[];
		pointRadius: number;
		lineType: ChartLineType;
	} {
		// если нет данных, то обозначаем ('noValues').
		// если одно значение ('oneValue') и оно единственное на графике, подставляем pointerRadius 4, иначе 0.
		// если найдено 2 и более точек ('solid'), показываем на графике сплошной линией.

		let lineType = backData.reduce((accumulator, currentValue) => {
			switch (accumulator) {
				case 'solid':
					return 'solid';
				case 'oneValue': {
					if (currentValue.count) return 'solid';
					return 'oneValue';
				}
				default: {
					if (currentValue.count) return 'oneValue';
					return 'noValues';
				}
			}
		}, 'noValues') as unknown as ChartLineType;

		// учитываем предыдущие значения, чтобы формировать новые
		switch (this.previousLineType[i]) {
			case 'solid': {
				lineType = 'solid';
				break;
			}
			case 'oneValue':
			default:
				break;
		}
		this.previousLineType[i] = lineType;

		return {
			data:
				lineType === 'solid'
					? getDataWithZeros(backData)
					: getDataWithNulls(backData),
			pointRadius: lineType === 'oneValue' ? 4 : 0,
			lineType
		};

		function getDataWithZeros(backData: BackendChartStatistic[]) {
			return backData.map(statistic => {
				return statistic.count;
			});
		}

		function getDataWithNulls(backData: BackendChartStatistic[]) {
			return backData.map(statistic => {
				return statistic.count ? statistic.count : null;
			});
		}
		// важно, чтобы длинна моканных данных была равна MAX_REQUEST_POINTS
		// return [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0]
	}

	private getUnixAndLabels(
		group: GroupOptions,
		statistic: BackendChartStatistic[]
	): {
		labels: string[]; // это значения по оси икс (день.месяц)
		unixFromLabel: UnixFromLabel;
	} {
		const unixFromLabel: { [key: string]: number } = {};

		switch (group) {
			case 'month': {
				return {
					labels: statistic.map(item => {
						const label = this.timezoneService.convertDate(
							item.date,
							'MM.yyyy'
						);

						unixFromLabel[label] = item.date * 1000;
						return label;
					}),
					unixFromLabel
				};
			}
			case 'week':
			case 'day':
			default: {
				return {
					labels: statistic.map(item => {
						const label = this.timezoneService.convertDate(
							item.date,
							'DD.MM'
						);

						unixFromLabel[label] = item.date * 1000;
						return label;
					}),
					unixFromLabel
				};
			}
		}
	}
}
