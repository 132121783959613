import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	TuiDropdownControllerModule,
	TuiHostedDropdownModule
} from '@taiga-ui/core';
import { DropdownCalendarComponent } from './dropdown-calendar.component';
import { EnCalendarModule } from '../calendar/calendar.module';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnCalendarModule,
		TranslateModule,
		TuiHostedDropdownModule,
		TuiDropdownControllerModule
	],
	declarations: [DropdownCalendarComponent],
	exports: [DropdownCalendarComponent]
})
export class EnDropdownCalendarModule {}
