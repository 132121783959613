import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class GettedEnsendGuard implements CanActivate {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const { isAccessEnsend } = this.authQuery;

		if (isAccessEnsend) this.router.navigateByUrl('ensend/messages');
		return !isAccessEnsend;
	}
}
