import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListActionsComponent } from './list-actions.component';
import { EnButtonModule } from '../button/button';

@NgModule({
	imports: [CommonModule, EnButtonModule],
	declarations: [ListActionsComponent],
	exports: [ListActionsComponent]
})
export class ListActionsModule {}
