import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import { TuiDayRange, TuiMonth, TuiYear } from '@taiga-ui/cdk';
import {
	Month,
	yearMaxFromCurrent,
	yearMin
} from '../../calendarNew/calendar.model';

const LIMIT = 100;

@Component({
	selector: 'en-primitive-month-picker',
	templateUrl: './primitive-month-picker.component.html',
	styleUrls: ['./primitive-month-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveMonthPickerComponent implements OnInit {
	month = Month;
	date: TuiMonth;

	initialItem = TuiMonth.currentLocal();

	min: TuiYear = new TuiYear(yearMin);

	max: TuiYear = new TuiYear(
		TuiMonth.currentLocal().year + yearMaxFromCurrent
	);

	@Input() position: number;
	@Output()
	readonly monthClick = new EventEmitter<Number | TuiDayRange>();

	constructor(private calendarService: CalendarService) {}

	ngOnInit() {
		if (this.isSingle) this.date = this.calendarService.getShowDate();
		else this.date = this.calendarService.getShowDateRange()[this.position];
	}

	get isSingle() {
		return this.calendarService.getIsSingle();
	}

	get rows(): number {
		return Math.ceil(this.calculatedMax - this.calculatedMin);
	}

	get calculatedMin(): number {
		const initial = this.initialItem.year - LIMIT;

		return this.min.year > initial ? this.min.year : initial;
	}

	get calculatedMax(): number {
		const initial = this.initialItem.year + LIMIT;

		return this.max.year < initial ? this.max.year + 1 : initial;
	}

	scrollItemIntoView(item: number): boolean {
		return this.initialItem.year === item;
	}

	onItemClick(item: number) {
		if (this.isSingle) this.calendarService.changeShowMonth(item);
		else if (this.position) this.calendarService.changeShowRightMonth(item);
		else this.calendarService.changeShowLeftMonth(item);

		this.monthClick.emit(item);
	}
}
