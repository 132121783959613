import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MessageType } from '@enSend/message/kit';

@Injectable()
export class MessageTypeService {
	constructor(private fb: UntypedFormBuilder) {}

	private form: UntypedFormControl;

	get messageTypeControl(): UntypedFormControl {
		return this.form as UntypedFormControl;
	}

	get messageType(): MessageType {
		return this.form?.value;
	}

	init() {
		this.form = this.initType();
	}

	private initType(): UntypedFormControl {
		return this.fb.control('');
	}
}
