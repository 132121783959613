import { Renderer2 } from '@angular/core';
import { TippyInstance } from '@ngneat/helipopper';
import Chart, { ChartTooltipModel } from 'chart.js';
import tippy from 'tippy.js';

interface BaseContext {
	renderer: Renderer2;
	documentRef: Window;
	chart: Chart;
	tippyInstance: TippyInstance[];
}

// !!! я подразумеваю, что этой функцией будут пользоваться другие chartjs графики, где нужны будут кастомные тултипы
export function generateChartJsTooltip<T extends BaseContext>(
	this: T,
	tooltipModel: ChartTooltipModel,
	htmlCode: string,
	options?: {
		appendTo?: HTMLElement;
		zIndex?: number;
	}
) {
	// hide if no tooltip
	if (tooltipModel.opacity === 0) {
		this.tippyInstance.forEach(instance => instance.hide());
		return;
	}
	this.tippyInstance.forEach(instance => instance.destroy());
	this.tippyInstance = [];

	let tooltipEl =
		this.documentRef.document.getElementById('en-chart-tooltip');

	if (!tooltipEl) {
		tooltipEl = this.renderer.createElement('div') as HTMLElement;
		this.renderer.setAttribute(tooltipEl, 'id', 'en-chart-tooltip');
		tooltipEl.style.opacity = '0';

		options?.appendTo
			? this.renderer.appendChild(options.appendTo, tooltipEl)
			: this.renderer.appendChild(
					this.documentRef.document.body,
					tooltipEl
			  );
	}

	const position = (
		this.chart.canvas as HTMLCanvasElement
	).getBoundingClientRect();

	// Display & position
	tooltipEl.style.position = 'absolute';
	tooltipEl.style.left = `${
		position.left +
		this.documentRef.window.pageXOffset +
		tooltipModel.caretX
	}px`;

	tooltipEl.style.top = `${
		position.top + this.documentRef.window.pageYOffset + tooltipModel.caretY
	}px`;

	tooltipEl.style.pointerEvents = 'none';

	this.tippyInstance = tippy('#en-chart-tooltip', {
		content: htmlCode,
		allowHTML: true,
		showOnCreate: true,
		theme: 'default',
		appendTo: options?.appendTo,
		zIndex: options?.zIndex || 0
	});
}
