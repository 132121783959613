<div #container
     [ngStyle]="style"
     [class]="styleClass"
     data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body"
     [ngClass]="{
				'p-datatable p-component': true,
				'p-datatable-hoverable-rows': rowHover || selectionMode,
				'p-datatable-auto-layout': autoLayout,
				'p-datatable-resizable': resizableColumns,
				'p-datatable-resizable-fit':
					resizableColumns && columnResizeMode === 'fit',
				'p-datatable-scrollable': scrollable,
				'p-datatable-flex-scrollable':
					scrollable && scrollHeight === 'flex',
				'p-datatable-responsive': responsive
               }">

     <en-loader-overlay *ngIf="loading && showLoader"></en-loader-overlay>

     <div *ngIf="captionTemplate"
          class="p-datatable-header">
          <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
     </div>
     <p-paginator [rows]="rows"
                  [first]="first"
                  [totalRecords]="totalRecords"
                  [pageLinkSize]="pageLinks"
                  styleClass="p-paginator-top"
                  [alwaysShow]="alwaysShowPaginator"
                  (onPageChange)="onPageChange($event)"
                  [rowsPerPageOptions]="rowsPerPageOptions"
                  *ngIf="
					paginator &&
					(paginatorPosition === 'top' || paginatorPosition == 'both')
				"
                  [templateLeft]="paginatorLeftTemplate"
                  [templateRight]="paginatorRightTemplate"
                  [dropdownAppendTo]="paginatorDropdownAppendTo"
                  [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                  [currentPageReportTemplate]="currentPageReportTemplate"
                  [showCurrentPageReport]="showCurrentPageReport"
                  [showJumpToPageDropdown]="showJumpToPageDropdown"
                  [showPageLinks]="showPageLinks"></p-paginator>
     <div class="p-datatable-wrapper"
          *ngIf="!scrollable">
          <table role="grid"
                 #table
                 [ngClass]="tableStyleClass"
                 [ngStyle]="tableStyle">
               <ng-container *ngTemplateOutlet="
							colGroupTemplate;
							context: { $implicit: columns }
						"></ng-container>
               <thead class="p-datatable-thead">
                    <ng-container *ngTemplateOutlet="
								headerTemplate;
								context: { $implicit: columns }
							"></ng-container>
               </thead>
               <tbody class="p-datatable-tbody"
                      [pTableBody]="columns"
                      [pTableBodyTemplate]="bodyTemplate"></tbody>
               <tfoot *ngIf="footerTemplate"
                      class="p-datatable-tfoot">
                    <ng-container *ngTemplateOutlet="
								footerTemplate;
								context: { $implicit: columns }
							"></ng-container>
               </tfoot>
          </table>
     </div>
     <div class="p-datatable-scrollable-wrapper"
          *ngIf="scrollable">
          <div class="p-datatable-scrollable-view p-datatable-frozen-view"
               *ngIf="frozenColumns || frozenBodyTemplate"
               #scrollableFrozenView
               [pScrollableView]="frozenColumns"
               [frozen]="true"
               [ngStyle]="{ width: frozenWidth }"
               [scrollHeight]="scrollHeight"></div>
          <div class="p-datatable-scrollable-view"
               #scrollableView
               [pScrollableView]="columns"
               [frozen]="false"
               [scrollHeight]="scrollHeight"
               [ngStyle]="{
						left: frozenWidth,
						width: 'calc(100% - ' + frozenWidth + ')'
					}"></div>
     </div>
     <p-paginator [rows]="rows"
                  [first]="first"
                  [totalRecords]="totalRecords"
                  [pageLinkSize]="pageLinks"
                  styleClass="p-paginator-bottom"
                  [alwaysShow]="alwaysShowPaginator"
                  (onPageChange)="onPageChange($event)"
                  [rowsPerPageOptions]="rowsPerPageOptions"
                  *ngIf="
					paginator &&
					(paginatorPosition === 'bottom' ||
						paginatorPosition == 'both')
				"
                  [templateLeft]="paginatorLeftTemplate"
                  [templateRight]="paginatorRightTemplate"
                  [dropdownAppendTo]="paginatorDropdownAppendTo"
                  [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                  [currentPageReportTemplate]="currentPageReportTemplate"
                  [showCurrentPageReport]="showCurrentPageReport"
                  [showJumpToPageDropdown]="showJumpToPageDropdown"
                  [showPageLinks]="showPageLinks"></p-paginator>
     <div *ngIf="summaryTemplate"
          class="p-datatable-footer">
          <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
     </div>
     <div #resizeHelper
          class="p-column-resizer-helper"
          style="display:none"
          *ngIf="resizableColumns"></div>
     <span #reorderIndicatorUp
           class="pi pi-arrow-down p-datatable-reorder-indicator-up"
           style="display:none"
           *ngIf="reorderableColumns"></span>
     <span #reorderIndicatorDown
           class="pi pi-arrow-up p-datatable-reorder-indicator-down"
           style="display:none"
           *ngIf="reorderableColumns"></span>
</div>