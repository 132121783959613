export const css = `
    @font-face {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        src: local('Inter'), local('Inter-Regular'),
            url('/assets/fonts/main/Inter-Regular-400.woff2') format('woff2'),
            url('/assets/fonts/main/Inter-Regular-400.woff') format('woff');
    }
    @font-face {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        src: local('Inter Medium'), local('Inter-Medium'),
            url('/assets/fonts/main/Inter-Medium-500.woff2') format('woff2'),
            url('/assets/fonts/main/Inter-Medium-500.woff') format('woff');
    }

    .enkod-clicks-map-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left:0;
        right: 0;
        z-index: 9998;
        background: rgba(30, 30, 30, 0.5)
    }

    .enkod-link-wrapper {
        position: absolute;
        border: 2px solid rgba(0,0,0,0);
        z-index: 0;
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        box-sizing: border-box;
    }

    .enkod-link-wrapper:hover {
        border-color: #83CC3A;
        z-index: 1;
    }

    .enkod-link-wrapper-link-update {
        position: absolute;
        cursor: pointer;
        border: 2px solid #234EC4;

        &:hover {
            background-color: rgba(227, 235, 252, 0.3);
        }
    }

    @keyframes show-circle-view {
        from {
            opacity: 0.2;
            transform: translate(-50%, -50%) scale(0.85);
        }
        to {
            opacity: 1;
        }
    }

    .enkod-link-view {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    .enkod-link-view__animation {
        animation: show-circle-view 0.2s ease-in-out;
    }

    .enkod-statistic-tex-wrapper {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
    }
    @keyframes show-tooltip-bottom {
        from {
            opacity: 0;
            transform: translate(-50%, 100%) scale(0.85);
        }
        to {
            opacity: 1;
        }
    }
    @keyframes show-tooltip-top {
        from {
            opacity: 0;
            transform: translate(-50%, -100%) scale(0.85);
        }
        to {
            opacity: 1;
        }
    }

    @keyframes hide-tooltip-bottom {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
            transform: translate(-50%, 100%) scale(0.85);
        }
    }
    @keyframes hide-tooltip-top {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
            transform: translate(-50%, -100%) scale(0.85);
        }
    }


    .enkod-statistics {
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        width: 310px;
        height: 96px;
        border-radius: 4px;
        padding: 8px 16px;
        background-color: #434652;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .enkod-statistics-hide-bottom {
        animation: hide-tooltip-bottom 0.15s ease-in-out !important;
    }
    .enkod-statistics-hide-top {
        animation: hide-tooltip-top 0.15s ease-in-out !important;
    }

    .enkod-statistics-bottom {
        bottom: -25px;
        transform: translate(-50%, 100%);
        animation: show-tooltip-bottom 0.15s ease-in-out;
    }

    .enkod-statistics-top {
        top: -25px;
        transform: translate(-50%, -100%);
        animation: show-tooltip-top 0.15s ease-in-out;
    }

    .enkod-statistics-arrow {
        position: absolute;
        left: 50%;
        border: 8px solid transparent;
    }
    .enkod-statistics-arrow-bottom {
        top: 0;
        border-bottom: 4px solid #434652;
        transform: translate(-50%, -100%);
    }
    .enkod-statistics-arrow-top {
        bottom: 0;
        border-top: 4px solid #434652;
        transform: translate(-50%, 100%);
    }
    .enkod-statistics__item {
        display: flex;
        justify-content: space-between;
        line-height: 22px;
    }

    .enkod-color-grey {
        color: #bcbfcc;
    }

    .enkod-sidebar {
        position: absolute;
        top: 12px;
        right: 20px;
        width: 40px;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }
    .enkod-heat-scale {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3);
        border-radius: 4px;
        padding: 12px 4px;
        height: 236px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #757885;
    }
    .enkod-heat-scale__gradient {
        margin: 6px 0;
        width: 16px;
        height: 168px;
        background: linear-gradient(180deg, #CC3E00 0%, #CC7A00 16.22%, #CCB600 38.03%, #A3CC00 55.11%, #5BCC82 79.83%, #7476CC 100%);
    }

    .enkod-menu-button {
        margin-top: 8px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        transition: background-color 0.2s ease-in-out;
    }
    .enkod-menu-button:hover {
        background-color: #f3f3f5;
    }
    .enkod-menu-button:active:hover {
        transition: background-color 0.1s ease-in-out;
        background-color: #d8dae0;
    }

    @keyframes enkod-menu-show {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .enkod-menu {
        position: absolute;
        bottom: -46px;
        right: 0;
        transform: translate(0, -100%);
        width: 220px;
        background: #FFFFFF;
        color: #434652;
        border-radius: 4px;
        padding: 8px 0;
        animation: enkod-menu-show 0.1s ease-in-out;
    }
    .enkod-menu-hide {
        transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        opacity: 0;
    }
    .enkod-menu__item {
        height: 40px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
    }
    .enkod-menu__item:hover {
        background-color:  #f3f3f5;
    }
    .enkod-menu__item-active {
        background-color: #f3f3f5;
        font-weight: 600;
    }
`;
