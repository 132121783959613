<ng-container *tuiLet="service.items$ | async as items">
    <div
        *ngIf="items.length"
        class="wrapper"
        [@tuiFadeInList]="getAnimation(items.length)"
        [@tuiSlideInTopList]="getAnimation(items.length)"
    >
        <en-notification-alert
            *ngFor="let item of items"
            role="alert"
            [class]='item.position'
            [item]="item"
        ></en-notification-alert>
    </div>
</ng-container>
