import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageType, RoundingNumberType } from '../model/storage-type';

@Pipe({
	name: 'convertStrgSize'
})
export class ConvertStorageSizePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(value: StorageType, roundingNumbers: RoundingNumberType): string {
		const { currentSize, maxSize } = value;
		const { currRoundNum, maxRoundNum } = roundingNumbers;
		const sizes = ['B', 'KB', 'MB', 'GB'];
		const k = 1024;
		const dmCurr = currRoundNum < 0 ? 0 : currRoundNum;
		const dmMax = maxRoundNum < 0 ? 0 : maxRoundNum;

		const idxCurrentSize = currentSize
			? Math.floor(Math.log(currentSize) / Math.log(k))
			: 0;
		const idxMaxSize = maxSize
			? Math.floor(Math.log(maxSize) / Math.log(k))
			: 0;

		const convCurrentSize = `${parseFloat(
			(currentSize / k ** idxCurrentSize).toFixed(dmCurr)
		)}${sizes[idxCurrentSize]}`;
		const convMaxSize = `${parseFloat(
			(maxSize / k ** idxMaxSize).toFixed(dmMax)
		)}${sizes[idxMaxSize]}`;

		return this.translate.instant('image_storage.storage_size_text', {
			convCurrentSize,
			convMaxSize
		});
	}
}
