<tui-hosted-dropdown [tuiDropdownMaxHeight]="500"
                     [content]="dropdown"
                     [(open)]="calendarVisible">
    <span class="wrapper-input"
          [class.isInvalid]="invalidControl"
          [class.focus]="calendarVisible"
          [class.disabled-input]="disabled"
          [ngClass]="{'h32': size === 'm', 'h28': size === 's'}"
          (click)="calendarVisible=true">
        <input enInput
               class="input-date-time"
               [ngClass]="{'h32': size === 'm', 'h28': size === 's'}"
               [placeholder]="placeholder | translate"
               [value]="date"
               [textMask]="textMaskOptions"
               [attr.disabled]="disableInput"
               (input)="changeDateInput($event.target.value)"
               (focusout)="focusout()">
        <i *ngIf="showReset && date && !disabled"
           class="cancel-icon en en-cancel-fill"
           (click)="resetCalendar()"></i>
        <i class="calendar-icon en en-date"></i>
    </span>
</tui-hosted-dropdown>

<ng-template #dropdown
             let-activeZone>
    <en-calendar-range-new *ngIf="!disabled"
                           [tuiActiveZoneParent]="activeZone"
                           [min]="min"
                           [max]="max"
                           [type]="type"
                           (closeCalendar)="calendarVisible = false"
                           (onSelect)="onSelect()"></en-calendar-range-new>
</ng-template>