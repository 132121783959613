/* eslint-disable */
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Optional,
	Self,
	Inject,
	ChangeDetectorRef,
	Input,
	InjectionToken,
	isDevMode
} from '@angular/core';
import {
	NgControl,
	FormArrayName,
	FormGroupName,
	FormGroupDirective,
	AbstractControl
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { heightCollapse, fadeIn } from 'animations';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';
import { EnStatusT } from 'ui-lib/types';

export class TuiValidationError<T extends object = {}> {
	constructor(
		readonly message: PolymorpheusContent<T>,
		readonly context?: T
	) {}
}

export const TUI_VALIDATION_ERRORS = new InjectionToken<
	Record<string, PolymorpheusContent>
>('Validation errors', {
	factory: () => ({})
});

/**
 * This class is needed for its getter functionality, so we check
 * for DevMode only after application is bootstrapped
 */
export class TuiAssertHelper {
	bootstrapped = false;

	get assert(): (assertion: boolean, ...args: any[]) => void {
		return !this.bootstrapped || !isDevMode()
			? EMPTY_FUNCTION
			: Function.prototype.bind.call(console.assert, console);
	}
}

export const tuiAssert = new TuiAssertHelper();

export type ErrorMode = 'basic' | 'advanced' | 'custom';

@Component({
	selector: 'en-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
	// @bad TODO: find a way to get 'touched' state change
	// https://github.com/angular/angular/issues/10887
	// changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [heightCollapse, fadeIn]
})
export class ErrorComponent implements OnInit {
	@Input()
	set order(value: readonly string[]) {
		this.errorsOrder = value;
		this.updateErrorText();
	}

	@Input() mode: ErrorMode = 'basic';

	@Input() status: EnStatusT = 'error';

	@Input() showIcon = true;

	@Input() background: boolean = false;

	@Input() flexable: boolean = true;

	private firstError: TuiValidationError | null = null;
	private errorsOrder: readonly string[] = [];
	private destroy$ = new Subject<void>();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		@Optional()
		@Self()
		@Inject(FormArrayName)
		private formArrayName: FormArrayName | null,
		@Optional()
		@Self()
		@Inject(FormGroupName)
		private formGroupName: FormGroupName | null,
		@Optional()
		@Self()
		@Inject(FormGroupDirective)
		private formGroup: FormGroupDirective | null,
		@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
		@Inject(TUI_VALIDATION_ERRORS)
		private readonly validationErrors: Record<string, PolymorpheusContent>
	) {
		tuiAssert.assert(
			!!this.ngControl,
			`NgControl not injected in ${this.constructor.name}!` +
				' Use [(ngModel)] or [formControl] or formControlName for correct work.'
		);

		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit() {
		const control = this.control;

		if (!control) {
			return;
		}

		// Temporary workaround until issue with async validators will be resolved.
		// https://github.com/angular/angular/issues/13200
		if (control.asyncValidator) {
			control.updateValueAndValidity();
		}

		this.updateErrorText();

		control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateErrorText();
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	get computedError(): TuiValidationError | null {
		return this.invalid && this.dirty && this.firstError
			? this.firstError
			: null;
	}

	get invalid(): boolean {
		const control = this.control;

		return control && control.invalid !== null ? control.invalid : false;
	}

	get touched(): boolean {
		const control = this.control;

		return control && control.touched !== null ? control.touched : false;
	}

	get dirty(): boolean {
		const control = this.control;

		return control && control.dirty !== null ? control.dirty : false;
	}

	get control(): AbstractControl | null {
		if (this.ngControl) {
			return this.ngControl.control;
		}

		if (this.formArrayName) {
			return this.formArrayName.control;
		}

		if (this.formGroupName) {
			return this.formGroupName.control;
		}

		if (this.formGroup) {
			return this.formGroup.control;
		}

		return null;
	}

	registerOnChange() {
		this.markForCheck();
	}

	registerOnTouched() {
		this.markForCheck();
	}

	setDisabledState() {
		this.markForCheck();
	}

	writeValue() {
		this.markForCheck();
	}

	private get firstErrorIdByOrder(): string | null {
		const firstErrorId =
			this.errorsOrder &&
			this.errorsOrder.find(errorId => !!this.controlErrors[errorId]);

		return firstErrorId || null;
	}

	private get firstErrorId(): string | null {
		const errorIds = Object.keys(this.controlErrors);

		return errorIds[0];
	}

	private get controlErrors(): { [key: string]: any } {
		const control = this.control;

		return (control && control.errors) || {};
	}

	private updateErrorText() {
		this.firstError = this.getErrorText();
	}

	private getErrorText(): TuiValidationError | null {
		const firstErrorId = this.firstErrorIdByOrder || this.firstErrorId;
		const firstError = firstErrorId && this.controlErrors[firstErrorId];

		// @bad TODO: Remove firstError.message check after everybody migrates to TuiValidationError
		if (
			firstError &&
			(firstError instanceof TuiValidationError ||
				typeof firstError.message === 'string')
		) {
			return firstError;
		}

		return firstErrorId
			? new TuiValidationError(
					this.validationErrors[firstErrorId],
					firstError
			  )
			: null;
	}

	private markForCheck() {
		this.changeDetectorRef.markForCheck();
	}
}
