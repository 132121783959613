/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-host-metadata-property */

import {
	NgModule,
	Component,
	Input,
	Output,
	EventEmitter,
	forwardRef,
	ChangeDetectorRef,
	ContentChild,
	TemplateRef,
	SimpleChanges,
	OnChanges,
	ChangeDetectionStrategy,
	ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';
import { RippleModule } from 'primeng/ripple';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

export const SELECTBUTTON_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SelectButtonComponent),
	multi: true
};

@Component({
	selector: 'en-selectbutton',
	template: `
		<div
			[ngClass]="'en-selectbutton en-buttonset en-component'"
			[ngStyle]="style"
			[class]="styleClass"
			role="group"
		>
			<div
				*ngFor="let option of options; let i = index"
				#btn
				class="en-button en-component"
				[class]="option.styleClass"
				role="button"
				[attr.aria-pressed]="isSelected(option)"
				[ngClass]="{
					'en-highlight': isSelected(option),
					disabled: disabled || option.disabled,
					'en-button-icon-only': option.icon && !option.label
				}"
				(click)="onItemClick($event, option, i)"
				(keydown.enter)="onItemClick($event, option, i)"
				[attr.title]="option.title"
				[attr.aria-label]="option.label"
				(blur)="onBlur()"
				[attr.tabindex]="disabled ? null : tabindex"
				[attr.aria-labelledby]="ariaLabelledBy"
			>
				<ng-container *ngIf="!itemTemplate; else customcontent">
					<span
						[ngClass]="'en-button-icon en-button-icon-left'"
						[class]="option.icon"
						*ngIf="option.icon"
					></span>
					<span class="en-button-label">
						{{ option.label | translate }}
					</span>
				</ng-container>
				<ng-template #customcontent>
					<ng-container
						*ngTemplateOutlet="
							itemTemplate;
							context: { $implicit: option, index: i }
						"
					></ng-container>
				</ng-template>
			</div>
		</div>
	`,
	providers: [SELECTBUTTON_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class SelectButtonComponent implements ControlValueAccessor, OnChanges {
	@Input() tabindex = 0;

	@Input() multiple: boolean;

	@Input() style: any;

	@Input() styleClass: string;

	@Input() ariaLabelledBy: string;

	@Input() disabled: boolean;

	@Input() dataKey: string;

	@Input() optionLabel: string;

	@Input() setSelectOption: string;

	@Output() onOptionClick: EventEmitter<any> = new EventEmitter();

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ContentChild(TemplateRef) itemTemplate: any;

	value: any;

	_options: any[];

	onModelChange: Function = () => {};

	onModelTouched: Function = () => {};

	constructor(public cd: ChangeDetectorRef) {}

	@Input() get options(): any[] {
		return this._options;
	}

	set options(_val: any[]) {
		// NoOp
	}

	ngOnChanges(simpleChange: SimpleChanges) {
		if (simpleChange.options) {
			this._options = this.optionLabel
				? this.generateSelectItems(
						simpleChange.options.currentValue,
						this.optionLabel
				  )
				: simpleChange.options.currentValue;
		}
	}

	writeValue(value: any): void {
		this.value = value;
		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	setDisabledState(val: boolean): void {
		this.disabled = val;
		this.cd.markForCheck();
	}

	generateSelectItems(val: any[], field: string): SelectItem[] {
		let selectItems: SelectItem[] = [];
		if (val && val.length) {
			selectItems = [];
			val.forEach(item => {
				selectItems.push({
					label: ObjectUtils.resolveFieldData(item, field),
					value: item
				});
			});
		}

		return selectItems;
	}

	onItemClick(event: Event, option: SelectItem, index: number) {
		if (this.disabled || option.disabled) {
			return;
		}

		if (this.multiple) {
			const itemIndex = this.findItemIndex(option);
			if (itemIndex !== -1)
				this.value = this.value.filter(
					(_val: any, i: number) => i !== itemIndex
				);
			else this.value = [...(this.value || []), option.value];
		} else {
			this.value = option.value;
		}

		this.onOptionClick.emit({
			originalEvent: event,
			option,
			index
		});

		this.onModelChange(this.value);

		this.onChange.emit({
			originalEvent: event,
			value: this.value
		});
	}

	onBlur() {
		this.onModelTouched();
	}

	isSelected(option: SelectItem) {
		if (this.setSelectOption) this.value = this.setSelectOption;
		if (this.multiple) return this.findItemIndex(option) !== -1;
		return ObjectUtils.equals(option.value, this.value, this.dataKey);
	}

	findItemIndex(option: SelectItem) {
		let index = -1;
		if (this.value) {
			for (let i = 0; i < this.value.length; i++) {
				if (this.value[i] === option.value) {
					index = i;
					break;
				}
			}
		}
		return index;
	}
}

@NgModule({
	imports: [CommonModule, RippleModule, TranslateModule],
	exports: [SelectButtonComponent],
	declarations: [SelectButtonComponent]
})
export class EnSelectButtonModule {}
