import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { Router } from '@angular/router';

import { RelatedDataItem } from './models/related-data.model';

@Component({
	selector: 'en-related-data-dialog',
	templateUrl: './related-data-dialog.component.html',
	styleUrls: ['./related-data-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedDataDialogComponent {
	@Input() dialogVisible: boolean;
	@Input() readonly navigateUrl: string;
	@Input() readonly infoText: string;
	@Input() readonly relatedData: RelatedDataItem[];

	@Output() closeDialog = new EventEmitter<void>();

	constructor(private router: Router) {}

	clickOnItem(id: number) {
		this.router.navigateByUrl(`${this.navigateUrl}${id}`);
	}
}
