import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InboxEmoji } from './inbox.model';

@Injectable()
export class InboxEmojiService {
	EMOJI_REG = /(\p{EPres}|\p{ExtPict})(\u200d(\p{EPres}|\p{ExtPict}))*/gu;

	constructor(private translate: TranslateService) {}

	getEmojiMartLocale() {
		return {
			search: this.translate.instant('inbox.search'),
			emojilist: this.translate.instant('inbox.emoji_list'),
			notfound: this.translate.instant('inbox.notfound'),
			clear: this.translate.instant('inbox.clear'),
			categories: {
				search: this.translate.instant('inbox.search_result'),
				recent: this.translate.instant('inbox.resent')
			}
		};
	}

	getSpanEmojiText(text: string): string {
		const emojis = text.match(this.EMOJI_REG);
		if (!emojis) return text;

		let resultText = '';
		let tempText = text;

		emojis.forEach(emoji => {
			const index = tempText.search(this.EMOJI_REG);
			resultText = `${
				resultText + tempText.slice(0, index)
			}<span class="emoji">${emoji}</span>`;
			tempText = tempText.slice(index + emoji.length);

			if (tempText.search(this.EMOJI_REG) === -1) {
				resultText += tempText;
			}
		});
		return resultText;
	}

	getPastedEmojiText(
		text: string,
		position: number,
		emojiBody: InboxEmoji
	): string {
		const emoji = emojiBody.emoji.native;
		if (!text?.length) return emoji;
		const textArray = text.split('');
		textArray.splice(position, 0, emoji);
		return textArray.join('');
	}
}
