import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { skltnConfig } from '@enkod-core/utils';
import { NgxSkltnModule } from 'ngx-skltn';
import { ConvertStorageSizePipe } from './pipe/convert-storage-size.pipe';
import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
	imports: [CommonModule, NgxSkltnModule.forRoot(skltnConfig)],
	exports: [ProgressBarComponent],
	declarations: [ProgressBarComponent, ConvertStorageSizePipe]
})
export class EnProgressBarModule {}
