import { TuiDay } from '@taiga-ui/cdk';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';

@Component({
	selector: 'en-primitive-date-time-picker',
	templateUrl: './primitive-date-time-picker.component.html',
	styleUrls: ['./primitive-date-time-picker.component.scss']
})
export class PrimitiveDateTimePickerComponent implements OnInit {
	date: TuiDay;
	firstInput = 0;
	doubleInput = 1;

	@Input() value: TuiDay;
	@Input() type = 'date';

	@Output() changeDate = new EventEmitter();

	constructor(private calendarService: CalendarService) {}

	get isSingle() {
		return this.calendarService.getIsSingle();
	}

	get showTime() {
		return this.calendarService.showTime;
	}

	ngOnInit(): void {
		if (this.type === 'dateTime') {
			this.calendarService.showTime = true;
		}
	}

	checkedTime() {
		this.calendarService.showTime = !this.showTime;
	}

	onChangeDate(date: TuiDay) {
		this.changeDate.emit(date);
	}
}
