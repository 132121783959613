import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	TuiLetModule,
	TuiMapperPipeModule,
	TuiRepeatTimesModule
} from '@taiga-ui/cdk';
import { TuiDropdownControllerModule } from '@taiga-ui/core';
import { TuiCalendarSheetPipeModule } from '@taiga-ui/core/pipes';
import { PrimitiveCalendarComponent } from './primitive-calendar.component';

@NgModule({
	imports: [
		CommonModule,
		TuiLetModule,
		TuiMapperPipeModule,
		TuiRepeatTimesModule,
		TranslateModule,
		TuiCalendarSheetPipeModule,
		TuiDropdownControllerModule
	],
	declarations: [PrimitiveCalendarComponent],
	exports: [PrimitiveCalendarComponent]
})
export class EnPrimitiveCalendarModule {}
