import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TippyModule } from '@ngneat/helipopper';

import { SharedModule } from '@shared';
import { EnTooltipModule } from 'ui-lib';
import { MailingGroupInfoComponent } from './mailing-group-info.component';

@NgModule({
	imports: [CommonModule, SharedModule, TippyModule, EnTooltipModule],
	exports: [MailingGroupInfoComponent],
	declarations: [MailingGroupInfoComponent]
})
export class MailingGroupInfoModule {}
