import { DOCUMENT } from '@angular/common';
import {
	Component,
	ChangeDetectionStrategy,
	Inject,
	isDevMode
} from '@angular/core';

import { UpdateService } from '@enkod-core/services';

@Component({
	selector: 'en-root',
	template: `
		<router-outlet></router-outlet>
		<tui-root>
			<ng-container ngProjectAs="tuiOverPortals"></ng-container>
			<en-notification-root></en-notification-root>
		</tui-root>
	`,
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
	constructor(
		@Inject(DOCUMENT) _document: Document,
		private updateService: UpdateService
	) {
		// Проверяем на новую задеплоенную версию на сервер при старте приложения
		this.updateService.checkUpdates();

		if (isDevMode()) {
			const faviconEl = _document.getElementById('appFavicon');

			faviconEl?.setAttribute('href', '../assets/icons/favicon.ico');
		}
	}
}
