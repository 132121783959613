/* eslint-disable func-names */
import { inject, InjectionToken } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export const FORM_TOKEN = new InjectionToken<UntypedFormGroup | UntypedFormArray>(
	'Token for form group or array'
);

export function plainFormGroup(plainFormGroup: object): Function {
	return function (): UntypedFormGroup {
		return inject(UntypedFormBuilder).group(plainFormGroup);
	};
}
