import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

import {
	TuiBooleanHandler,
	TuiDay,
	tuiDefaultProp,
	TuiMapper,
	TuiMonth,
	TuiYear
} from '@taiga-ui/cdk';

@Component({
	selector: 'en-calendar-range-new',
	templateUrl: './calendar-range.component.html',
	styleUrls: ['./calendar-range.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnCalendarRangeComponent {
	month: TuiMonth = TuiMonth.currentLocal();
	year: TuiYear | null = null;
	date: TuiDay;

	@Input() type = 'date';

	@Input()
	@tuiDefaultProp()
	hoveredItem: TuiDay | null = null;

	@Input()
	@tuiDefaultProp()
	showAdjacent = true;

	isFirst = 0;
	isDouble = 1;

	@Output()
	readonly dayClick = new EventEmitter<TuiDay>();

	@Output()
	readonly monthChange = new EventEmitter<TuiMonth>();

	@Output() closeCalendar = new EventEmitter();

	@Output() onSelect = new EventEmitter();

	readonly disabledItemHandlerMapper: TuiMapper<
		TuiBooleanHandler<TuiDay>,
		TuiBooleanHandler<TuiDay>
	> = (disabledItemHandler, min: TuiDay, max: TuiDay) => item =>
		item.dayBefore(min) || item.dayAfter(max) || disabledItemHandler(item);

	onPaginationYearClick(year: TuiYear) {
		this.year = year;
	}

	onPickerYearClick({ year }: TuiYear) {
		this.year = null;
		this.updateViewedMonth(new TuiMonth(year, this.month.month));
	}

	onPickerMonthClick(date: TuiMonth) {
		this.updateViewedMonth(date);
	}

	onPaginationValueChange(month: TuiMonth) {
		this.updateViewedMonth(month);
	}

	onDayClick(day: TuiDay) {
		this.date = day;
	}

	private updateViewedMonth(month: TuiMonth) {
		if (this.month.monthSame(month)) {
			return;
		}

		this.month = month;
		this.monthChange.emit(month);
	}
}
