import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const TAGS_DATA_SERVICE_TOKEN = new InjectionToken<DetailsService>(
	'Токен для работы с тегами'
);

export interface DetailsService {
	updateTags: (id: number, data: { tags: string[] }) => Observable<any>;
}
