<div [ngClass]="{
                    'en-togglebutton': true,
                    'en-button-icon-only': (onIcon && offIcon && !hasOnLabel && !hasOffLabel),
                    'en-highlight': checked,'en-disabled':disabled
                }"
     [ngStyle]="style"
     [class]="styleClass"
     (click)="toggle($event)"
     (keydown.enter)="toggle($event)"
     [attr.tabindex]="disabled ? null : '0'"
     role="checkbox"
     [attr.aria-checked]="checked"
     pRipple>
    <span *ngIf="onIcon||offIcon"
          [class]="checked ? this.onIcon : this.offIcon"
          [ngClass]="{
                        'en-button-icon': true,
                        'en-button-icon-left': (iconPos === 'left'),
                        'en-button-icon-right': (iconPos === 'right')
                    }"></span>
    <span class="en-button-label">{{checked ? hasOnLabel ? onLabel : '' : hasOffLabel ? offLabel : ''}}</span>
</div>
