<div class="en-field test-body">
    <span>{{config.bodyDescription | translate}}</span>
    <en-autoComplete class="main-input"
                     [formControl]="inputControl"
                     [suggestions]="filteredEmailsMultiple"
                     [placeholder]="config.placeholder | translate"
                     [dropdown]="true"
                     [multiple]="true"
                     appendTo="body"
                     (completeMethod)="filterEmailMultiple($event)"
                     (keyup.enter)="onAdd($event)"></en-autoComplete>
    <en-error [formControl]="inputControl"></en-error>
    <en-small [visible]="isVisibleInfo"
              severity="info"
              [detail]="'message_wizard_edit_step.sendtest_tooltip' | translate">
    </en-small>
</div>