import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TippyModule } from '@ngneat/helipopper';

import { IMaskModule } from 'angular-imask';
import {
	AutoCompleteModule,
	EnButtonModule,
	EnDialogModule,
	EnInputModule,
	EnSmallModule,
	ErrorModule
} from 'ui-lib';

import { SharedModule } from '@shared';
import { SendTestComponent } from './send-test.component';
import { SendTestEmailComponent } from './send-test-email/send-test-email.component';
import { SendTestPhoneComponent } from './send-test-phone/send-test-phone.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		TranslateModule,
		ReactiveFormsModule,
		EnDialogModule,
		EnButtonModule,
		EnInputModule,
		TippyModule,
		IMaskModule,
		AutoCompleteModule,
		ErrorModule,
		EnSmallModule
	],
	declarations: [
		SendTestComponent,
		SendTestEmailComponent,
		SendTestPhoneComponent
	],
	exports: [SendTestComponent]
})
export class SendTestModule {}
