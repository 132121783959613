import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthQuery } from '../authentication/_state/auth.query';

@Injectable()
export class AccessEnsendGuard implements CanActivate {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(_: any, state: RouterStateSnapshot): boolean {
		const { isAccessEnsend } = this.authQuery;

		// навигацию на смтп стату всегда разрешаем
		if (state.url.includes('smtp-statistic')) {
			return true;
		}

		if (!isAccessEnsend) this.router.navigateByUrl('ensend/get-service');
		return isAccessEnsend;
	}
}
