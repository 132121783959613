import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TableColumnsSettingsState } from './table-columns-settings.model';
import { TableColumnsSettingsStore } from './table-columns-settings.store';

@Injectable({ providedIn: 'root' })
export class TableColumnsSettingsQuery extends Query<TableColumnsSettingsState> {
	constructor(protected store: TableColumnsSettingsStore) {
		super(store);
	}
}
