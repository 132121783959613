import { InjectionToken } from '@angular/core';
import { SelectOptionsConfig } from '@enkod-core/interfaces';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';

import { DEFAULT_SELECT_OPTIONS_CONFIG, selectOptions } from '../observables';

export const SELECT_OPTIONS_TOKEN = new InjectionToken('Select options token');

export const SELECT_OPTIONS_TOKEN_CONFIG = new InjectionToken(
	'Select options token config'
);

export function selectOptionFactory(
	service: any,
	config: SelectOptionsConfig = DEFAULT_SELECT_OPTIONS_CONFIG,
	filters?: any
): Observable<SelectItem[]> {
	return service.getAllList(filters).pipe(selectOptions(config));
}
