<div class="group"
     [class.group_width-limit]="useMaxWidth85"
     [class.deleted]="wasDeleted"
     [tippy]="tippy">
    <div class="group__left">
        <span class="group__id">{{groupId}}</span>
    </div>
    <div class="group__right">
        <i class="{{groupIcon | typeIcon}} group-icon"></i>
        <span class="group__name"
              [class.name_width-limit]="useMaxWidth85"
              tooltipPosition="bottom"
              tooltipStyleClass="tag__tooltip"
              [enTooltip]="overflowTooltip && groupName"
              [withOverflow]="!wasDeleted">{{groupName}}</span>
        <span *ngIf="isDelete"
              [attr.data-clickable]="true"
              class="en en-cancel-fill button-deleted"
              (click)="deleteItem.emit(id)"></span>
    </div>
</div>