import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SearchService {
	private search$ = new BehaviorSubject('');

	private refresh$ = new Subject();

	get selectSearchValue() {
		return this.search$.asObservable();
	}

	get selectRefresh() {
		return this.refresh$.asObservable();
	}

	setSearchValue(value: string) {
		this.search$.next(value);
	}

	refresh() {
		this.refresh$.next();
	}
}
