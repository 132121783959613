<en-primitive-spin-button [position]="position">
    <tui-hosted-dropdown tuiDropdownAlign="left"
                         tuiDropdownDirection="bottom"
                         [content]="choiceMonth"
                         class="dropdown-month"
                         [(open)]="choiceMonthVisible"
                         (click)="choiceYearVisible=false">
        <button class="dropdown-month__button"
                [class.focus]="choiceMonthVisible"
                tuiDropdownLimitAlign="right">
            <span class="label">{{month[date.month].name | translate}}</span>
            <span class="drop-down-icon en en-sort-down"></span>
        </button>
    </tui-hosted-dropdown>
    <tui-hosted-dropdown tuiDropdownAlign="right"
                         tuiDropdownDirection="bottom"
                         [content]="choiceYear"
                         class="dropdown-year"
                         [(open)]="choiceYearVisible"
                         (click)="choiceMonthVisible=false">
        <button class="dropdown-year__button"
                [class.focus]="choiceYearVisible"
                tuiDropdownLimitAlign="right">
            <span class="label">{{date.formattedYear}}</span>
            <span class="drop-down-icon en en-sort-down"></span>
        </button>
    </tui-hosted-dropdown>
    <ng-template #choiceYear
                 let-activeZone>
        <en-primitive-year-picker [tuiActiveZoneParent]="activeZone"
                                  [position]="position"
                                  (yearClick)=onYearClick()></en-primitive-year-picker>
    </ng-template>
    <ng-template #choiceMonth
                 let-activeZone>
        <en-primitive-month-picker [tuiActiveZoneParent]="activeZone"
                                   [position]="position"
                                   (monthClick)=onMonthClick()></en-primitive-month-picker>
    </ng-template>
</en-primitive-spin-button>
