<ng-container *tuiLet="refresh$ | async as bars">
    <div
        *ngIf="bars[0]"
        tuiScrollbarWrapper
        class="bar bar_vertical"
        [class.bar_has-horizontal]="bars[1]"
        [@tuiFadeIn]="animation"
    >
        <div tuiScrollbar="vertical" class="thumb"></div>
    </div>
    <div
        *ngIf="bars[1]"
        tuiScrollbarWrapper
        class="bar bar_horizontal"
        [class.bar_has-vertical]="bars[0]"
        [@tuiFadeIn]="animation"
    >
        <div tuiScrollbar="horizontal" class="thumb"></div>
    </div>
</ng-container>