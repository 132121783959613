import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { NotificationTokenOptions, STATUS_ICON } from './notification.model';

@Component({
	selector: 'en-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
	@Input() options: NotificationTokenOptions;

	@Output()
	readonly closeNotification = new EventEmitter<void>();

	get icon(): string {
		return STATUS_ICON[this.options.status];
	}
}
