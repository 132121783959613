import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TuiMapperPipeModule } from '@taiga-ui/cdk';
import { DialogModule } from 'primeng/dialog';

import { SharedModule } from '@shared';
import { SendMethodDialogComponent } from './send-method-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		DialogModule,
		SharedModule,
		TuiMapperPipeModule
	],
	declarations: [SendMethodDialogComponent],
	exports: [SendMethodDialogComponent]
})
export class SendMethodDialogModule {}
