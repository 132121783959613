import { TuiDay, TuiTimeMode } from '@taiga-ui/cdk';
import { TuiTextMaskOptions } from '@taiga-ui/core';
import { TIME_MASK } from 'app/ui-lib/calendarNew/calendar.model';
import { DATE_MASK_RANGE, DATE_TIME_MASK_RANGE } from '../calendar-range.model';
import { tuiCreateAutoCorrectedDateTimeRangePipe } from '../pipes/calendar-date-time.pipe';
import { tuiCreateAutoCorrectedDateRangePipe } from '../pipes/calendar-date.pipe';
import { tuiCreateAutoCorrectedTimeRangePipe } from '../pipes/calendar-time.pipe';

export function calculateMaskDateRange(
	day: TuiDay | null,
	min: TuiDay,
	max: TuiDay
): TuiTextMaskOptions {
	return {
		mask: [...DATE_MASK_RANGE],
		pipe: tuiCreateAutoCorrectedDateRangePipe({ value: day, min, max }),
		guide: false
	};
}

export function calculateMaskDateTimeRange(
	day: TuiDay | null,
	min: TuiDay,
	max: TuiDay,
	timeMode: TuiTimeMode
): TuiTextMaskOptions {
	return {
		mask: [...DATE_TIME_MASK_RANGE],
		pipe: tuiCreateAutoCorrectedDateTimeRangePipe(
			{ value: day, min, max },
			timeMode
		),
		guide: false
	};
}

export function calculateMaskTimeRange(
	timeMode: TuiTimeMode
): TuiTextMaskOptions {
	return {
		mask: [...TIME_MASK],
		pipe: tuiCreateAutoCorrectedTimeRangePipe(timeMode),
		guide: false
	};
}
