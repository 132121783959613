import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TableColumnsSettingsState } from './table-columns-settings.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'table-columns-settings' })
export class TableColumnsSettingsStore extends Store<TableColumnsSettingsState> {
	constructor() {
		super({});
	}
}
