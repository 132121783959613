import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit
} from '@angular/core';
import { EnStatusT } from 'ui-lib/types';

interface IErrorMessage {
	title?: string;
	desc?: string;
	status?: EnStatusT;
	titleParam?: string[];
}

@Component({
	selector: 'en-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {
	_message: IErrorMessage | string;

	@Input()
	@HostBinding('attr.data-en-host-status')
	status: EnStatusT = 'error';

	@Input()
	@HostBinding('class.alert-background')
	background = false;

	@Input()
	@HostBinding('class.alert-flexable')
	flexable = true;

	@Input()
	set message(value: IErrorMessage | string) {
		this._message = value;
		if (this.isAdvancedMessage(value) && value.status)
			this.status = value.status;
	}

	get message() {
		return this._message;
	}

	get advancedMessage() {
		return this._message as IErrorMessage;
	}

	isAdvancedMessage(
		message: IErrorMessage | string
	): message is IErrorMessage {
		return typeof message !== 'string';
	}

	ngOnInit() {}
}
