import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { SendTestEmailConfig, SEND_TEST_EMAIL } from '../tokens';
import { SendTestData } from '../types';
import { SendTestEmailService } from './send-test-email.service';

@UntilDestroy()
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'send-test-email',
	templateUrl: './send-test-email.component.html',
	styleUrls: ['./send-test-email.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		SendTestEmailService,
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				notValid: 'message_wizard_edit_step.test_email_not_valid',
				maxLength: 'message_wizard_edit_step.test_email_max_length',
				exist: 'message_wizard_edit_step.test_email_exist'
			}
		}
	]
})
export class SendTestEmailComponent implements OnInit {
	filteredEmailsMultiple: string[];
	onInitEmails = [];

	readonly inputControl: UntypedFormControl = new UntypedFormControl('');

	@Output()
	onChange = new EventEmitter<string[]>();

	constructor(
		@Inject(SEND_TEST_EMAIL)
		public readonly config: SendTestEmailConfig,
		private sendTestEmailService: SendTestEmailService
	) {}

	get isVisibleInfo() {
		return !this.inputControl.errors;
	}

	ngOnInit() {
		this.sendTestEmailService
			.getEmails()
			.pipe(
				untilDestroyed(this),
				tap((emails: any) => {
					if (emails) this.onInitEmails = emails;
				})
			)
			.subscribe();

		this.inputControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.onChange.emit(this.inputControl.value);
				})
			)
			.subscribe();
	}

	onAdd(event: { key: string; target: { value: string } }): void {
		const inputValue = event.target.value.trim().toLowerCase();
		const data: SendTestData = {
			channel: 'email',
			value: inputValue
		};
		this.inputControl.markAsDirty();
		if (this.fullValidationPassed(inputValue)) {
			if (this.inputControl.value.indexOf(inputValue) !== -1) {
				this.inputControl.setErrors({ exist: true });
				return;
			}

			const selectedMails = [...this.inputControl.value] || [];
			selectedMails.push(inputValue);

			this.inputControl.patchValue(selectedMails);

			this.sendTestEmailService
				.postMails(data)
				.pipe(untilDestroyed(this))
				.subscribe();

			// eslint-disable-next-line no-param-reassign
			event.target.value = '';
		}
	}

	fullValidationPassed(inputValue: string): boolean {
		switch (true) {
			case this.validateEmail(inputValue):
				this.inputControl.setErrors({ notValid: true });
				return false;
			case this.inputControl.value.length >= 20:
				this.inputControl.setErrors({ maxLength: true });
				return false;
			default:
				return true;
		}
	}

	filterEmailMultiple(event: any) {
		const { query } = event;
		this.filteredEmailsMultiple = this.filterEmail(
			query,
			this.onInitEmails
		);
	}

	private filterEmail(query: any, emails: string[] | null): string[] {
		const filtered: string[] = [];
		if (emails) {
			for (let i = 0; i < emails.length; i++) {
				const country = emails[i];
				if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
					filtered.unshift(country);
				}
			}
		}
		return filtered;
	}

	validateEmail(email: string): boolean {
		const EMAIL_REGEXP =
			/^([A-Z0-9._%+-]+){2,}@[A-Z0-9-.]+(\w+\.{1}?)+[A-Z]{2,}$/i;
		switch (true) {
			case email === '':
				return true;
			case email.includes(',') || email.includes(' '):
				return true;
			case !EMAIL_REGEXP.test(email):
				return true;
			default:
				return false;
		}
	}
}
