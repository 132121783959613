import {
	Component,
	ChangeDetectionStrategy,
	Optional,
	Self,
	Inject,
	ChangeDetectorRef,
	Output,
	EventEmitter
} from '@angular/core';
import {
	NgControl,
	ControlValueAccessor,
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators
} from '@angular/forms';
import { AuthQuery } from '@enkod-core/authentication/_state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	AccountSubmailersSettingsQuery,
	AccountSubmailersSettingsService,
	Submailer
} from '@state-admin/accounts/account-maker';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';

import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'en-from-email-control',
	templateUrl: './from-email-control.component.html',
	styleUrls: ['./from-email-control.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FromEmailControlComponent implements ControlValueAccessor {
	readonly form: UntypedFormGroup = this.fb.group({
		email: ['', Validators.required],
		domain: ['', Validators.required]
	});

	accountId: number;
	submailersList$: Observable<SelectItem<string>[]>;
	isEmptyDomain: boolean = false;
	isCurrentDomainInSubmailers: boolean = false;

	private onChange = EMPTY_FUNCTION;
	private onTouched = EMPTY_FUNCTION;

	@Output() changeDomain = new EventEmitter<string>();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder,
		private submailerService: AccountSubmailersSettingsService,
		private submailerQuery: AccountSubmailersSettingsQuery,
		private authQuery: AuthQuery
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
		this.setupFormListeners();
		this.getAccountId();
		this.setSubmailersList();
	}

	registerOnChange(onChange: () => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	writeValue(value: string): void {
		const [email, domain] = value.split('@');
		if (!domain) this.isEmptyDomain = true;
		this.form.patchValue(
			{
				email,
				domain: domain ? `@${domain}` : ''
			},
			{ emitEvent: false }
		);
	}

	markForCheck(): void {
		this.cd.markForCheck();
	}

	private setupFormListeners(): void {
		this.form.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(({ email, domain }) => {
				this.onChange(email + domain);
				if (domain) {
					this.changeDomain.emit(domain.split('@')[1]);
					this.isCurrentDomainInSubmailers = true;
				}
			});
	}

	private getAccountId(): void {
		this.authQuery.currentAccountId$
			.pipe(untilDestroyed(this))
			.subscribe(id => {
				this.accountId = +id;
			});
	}

	private setSubmailersList(): void {
		this.submailersList$ = this.authQuery.currentAccountId$.pipe(
			switchMap(id => {
				this.accountId = +id;
				return this.submailerQuery.hasEntity()
					? of(this.submailerQuery.getAll())
					: this.submailerService.getSubmailersList(this.accountId);
			}),
			map(submailers => this.initializeFormDomain(submailers)),
			tap(submailersList => this.handleFormDomainState(submailersList))
		);
	}

	private initializeFormDomain(
		submailers: Submailer[]
	): SelectItem<string>[] {
		const currentDomain = this.form.value.domain;
		const submailersList = submailers.map(submailer => ({
			label: `@${submailer.sendingDomain}`,
			value: `@${submailer.sendingDomain}`
		}));

		this.isCurrentDomainInSubmailers = submailersList.some(
			submailer => submailer.value === currentDomain
		);

		if (currentDomain && !this.isCurrentDomainInSubmailers) {
			this.form.patchValue(submailersList[0]);
			submailersList.unshift({
				label: currentDomain,
				value: currentDomain
			});
		}
		return submailersList;
	}

	private handleFormDomainState(selectItems: SelectItem<string>[]): void {
		if (selectItems.length && this.isEmptyDomain) {
			this.form.get('domain')?.patchValue(selectItems[0].value);
		}
	}
}
