<form [formGroup]="formParams">
    <div class="container"
         *ngFor="let param of formParams.controls; index as i; first as isFirst; trackBy: trackByFn">
        <ng-container [formGroupName]="i">

            <div class="en-field">
                <label for="key">{{'message_wizard_edit_step.url_param_index' | translate}} {{i+1}}</label>
                <span>{{'message_wizard_edit_step.url_param_name' | translate}}</span>
                <input id="key"
                       class="h32"
                       type="text"
                       enInput
                       maxlength="50"
                       formControlName="key">
            </div>

            <div class="en-field">
                <span>{{'message_wizard_edit_step.url_param_value' | translate}}</span>
                <input id="value"
                       class="h32"
                       type="text"
                       enInput
                       maxlength="50"
                       formControlName="value">
            </div>
        </ng-container>

        <button *ngIf="!isFirst"
                enButton
                type="button"
                class="container__delete-button en-button-lowercase h32"
                icon="en en-minus-circle-o"
                iconColor="red-button-icon"
                (click)="removeGroupParam(i)"></button>
    </div>
    <button *ngIf="formParams.value.length < 10"
            enButton
            type="button"
            class="en-button-lowercase"
            icon="en en-plus-circle-o"
            iconColor="blue-button-icon"
            iconPos="left"
            [label]="'message_wizard_edit_step.url_param_add_label' | translate"
            (click)="addGroupParam()"></button>
</form>