import {
	Component,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	Input,
	ContentChild,
	TemplateRef
} from '@angular/core';

import { Subject } from 'rxjs';

import { EnStep, EnStepper } from 'app/ui-lib/stepper/en-stepper.directive';

@Component({
	selector: 'en-stepper-navigator',
	templateUrl: './stepper-navigator.component.html',
	styleUrls: ['./stepper-navigator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperNavigatorComponent {
	readonly stateChange$: Subject<void> | null;

	@ContentChild('customPrevButton')
	public customPrevButton: TemplateRef<any>;

	@ContentChild('customNextButton')
	public customNextButtons: TemplateRef<any>;

	@Input()
	readonly prevButtonLabel = 'stepper_navigator.prev_button_label';

	@Input()
	readonly nextButtonLabel = 'stepper_navigator.next_button_label';

	@Input()
	readonly submitButtonLabel = 'stepper_navigator.submit_button_label';

	@Input()
	readonly saveAsDraft = true;

	@Input()
	readonly loadingNext: boolean;

	@Output()
	readonly onExit = new EventEmitter<void>();

	@Output()
	readonly onSaveAsDraft = new EventEmitter<void>();

	@Output()
	readonly onSubmit = new EventEmitter<void>();

	constructor(private stepper: EnStepper) {
		if (this.stepper.globalStateChange$)
			this.stateChange$ = this.stepper.globalStateChange$;
	}

	get steps(): EnStep[] {
		return this.stepper.stepsArray;
	}

	get selectedIndex(): number {
		return this.stepper.selectedIndex;
	}

	set selectedIndex(value: number) {
		this.stepper.selectedIndex = value;
	}

	get disablePrev(): boolean {
		return this.selectedIndex === this.firstIndex;
	}

	get lastStep(): boolean {
		return this.selectedIndex === this.lastIndex;
	}

	get calculateWidth(): number {
		return this.selectedIndex * (100 / (this.steps.length - 1));
	}

	get loading(): boolean {
		return this.stepper.loading;
	}

	get firstIndex() {
		/** Ищу первый индекс т.к. могут быть задизейбленные шаги */
		return this.stepper.stepsArray.findIndex(item => !item.disabled);
	}

	get lastIndex() {
		return this.stepper.stepsArray
			.map(step => step.disabled)
			.lastIndexOf(false);
	}
}
