import { UntypedFormGroup } from '@angular/forms';

export function markAllAsDirty(form: UntypedFormGroup) {
	Object.values(form.controls).forEach(control => {
		if (control.status !== 'DISABLED') control.markAsDirty();
		if ((control as UntypedFormGroup).controls) {
			markAllAsDirty(control as UntypedFormGroup);
		}
	});
}
