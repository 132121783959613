import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthenticateModel, LoginResponse } from '../models';

@Injectable({
	providedIn: 'root'
})
export class AuthDataService {
	constructor(private http: HttpClient) {}

	login(model: AuthenticateModel) {
		return this.http.post<LoginResponse>(`auth/login/`, model);
	}

	refreshToken(refreshToken: string) {
		return this.http.post<LoginResponse>(
			`auth/refresh_token/${refreshToken}`,
			''
		);
	}

	changeAccount(accountId: ID) {
		return this.http.post<{
			accessToken: string;
			expire: number;
			accountId: number;
			limitSettings: {
				baseLimit: number;
			};
		}>(`auth/switch/${accountId}`, '');
	}
}
