import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, catchError, debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NotificationsService } from '@enkod-core/services';
import { PaginationResponse } from '@enkod-core/types';
import {
	MessageResponse,
	ScenarioQueryParams
} from '@enSend/message/_states/_state-message';

import { NotificationStatus } from 'ui-lib';

import {
	convertPaginationAndSortParams,
	deleteEmptyKeys
} from '@enkod-core/utils';

import { Scenario } from '@enSend/scenario/_state';
import { EnsendSharedStore } from './ensend-shared.store';

@UntilDestroy()
@Injectable()
export class EnsendSharedService {
	constructor(
		private store: EnsendSharedStore,
		private http: HttpClient,
		private notificationsService: NotificationsService
	) {}

	scenarios$: BehaviorSubject<PaginationResponse<Scenario>> =
		new BehaviorSubject({
			result: [],
			total: 0
		} as PaginationResponse<Scenario>);

	queryParams$: BehaviorSubject<ScenarioQueryParams> = new BehaviorSubject({
		limit: 50
	});

	searchValue$: BehaviorSubject<string> = new BehaviorSubject('');

	getMessages(params: any = {}): Observable<MessageResponse> {
		return this.http.get<MessageResponse>('ensend/message/', {
			params
		});
	}

	async getTags(): Promise<void> {
		try {
			const resp = (await this.http
				.get('ensend/tag/')
				.toPromise()) as string[];

			this.store.update({ tags: resp });
		} catch (e) {
			this.notificationsService
				.show('ensend_statistics.shared_service_tag_request_error', {
					label: 'toast.summary_try_later',
					status: NotificationStatus.ERROR
				})
				.subscribe();
		}
	}

	getTagObjects(): Observable<{ name: string; id: number }[]> {
		return this.http.get<{ name: string; id: number }[]>(
			'enkod/tag/object/'
		);
	}

	getDomains() {
		return this.http
			.get<PaginationResponse<string>>('ensend/statistic/domains/')
			.pipe(
				tap(resp => {
					this.store.update({ domains: resp.result });
				}),
				catchError(error => {
					this.notificationsService
						.show(
							'ensend_statistics.shared_service_sending_domain_request_error',
							{
								label: 'toast.summary_try_later',
								status: NotificationStatus.ERROR
							}
						)
						.subscribe();
					return throwError(error);
				})
			);
	}

	getScenarios() {
		const params = new HttpParams({
			fromObject: convertPaginationAndSortParams(
				deleteEmptyKeys(this.queryParams$.getValue())
			)
		});

		return this.http
			.get<PaginationResponse<Scenario>>('ensend/scenario/', {
				params
			})
			.pipe(
				tap(resp => {
					this.scenarios$.next(resp);
				}),
				catchError(error => {
					this.notificationsService
						.show(
							'ensend_statistics.shared_service_scenario_request_error',
							{
								label: 'toast.summary_try_later',
								status: NotificationStatus.ERROR
							}
						)
						.subscribe();
					return throwError(error);
				})
			);
	}

	getSendingDomains() {
		return this.http.get(`ensend/submailer-domains/`).pipe(
			tap(resp => {
				this.store.update({ sendingDomains: resp as any });
			}),
			catchError(error => {
				this.notificationsService
					.show(
						'ensend_statistics.shared_service_sending_domain_request_error',
						{
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						}
					)
					.subscribe();
				return throwError(error);
			})
		);
	}

	resetStore(): void {
		this.store.update({ tags: null, domains: null, sendingDomains: null });
	}

	updateParams() {
		this.queryParams$.next({
			limit: this.queryParams$.getValue().limit + 50,
			search: this.searchValue$.getValue()
		});
		this.getScenarios().pipe(untilDestroyed(this)).subscribe();
	}

	onSearch(search: string) {
		this.searchValue$.next(search);
		this.queryParams$.next({
			search,
			limit: 50
		});
		this.getScenarios()
			.pipe(untilDestroyed(this), debounceTime(300))
			.subscribe();
	}
}
