import { DatePipe } from '@angular/common';

import { NgModule } from '@angular/core';

import { ConvertDatePipe } from './convert-date.pipe';

@NgModule({
	declarations: [ConvertDatePipe],
	exports: [ConvertDatePipe],
	providers: [DatePipe]
})
export class ConvertDateModule {}
