import { inject, InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export const GET_ROUTE_UUID = new InjectionToken<number>(
	'Inject token for get route uuid'
);

function getRouteUuid(): string {
	const routeUUId = inject(ActivatedRoute).snapshot.params.id;
	if (routeUUId) return routeUUId;
	throw new Error('В параметрах не найден UUID');
}

export const GET_ROUTE_UUID_PROVIDER: Provider = {
	provide: GET_ROUTE_UUID,
	useFactory: getRouteUuid
};
