import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnTableListItemComponent } from './en-table-list-item.component';

/** Есть небольшая дока для использования компонента,
 * лежит в файле ./doc.md */
@NgModule({
	imports: [CommonModule],
	declarations: [EnTableListItemComponent],
	exports: [EnTableListItemComponent]
})
export class EnTableListItemModule {}
