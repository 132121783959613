/* eslint-disable @angular-eslint/directive-class-suffix */
// eslint-disable-next-line max-classes-per-file
import {
	coerceBooleanProperty,
	coerceNumberProperty
} from '@angular/cdk/coercion';
import {
	AfterContentInit,
	ChangeDetectorRef,
	ContentChildren,
	Directive,
	EventEmitter,
	Inject,
	Input,
	Optional,
	Output,
	QueryList,
	Self,
	TemplateRef
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SUBJECT_TOKEN } from '@enkod-core/tokens';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

export type StepperSelectionChange = {
	index: number;
	routePath?: string;
	selectedStep: EnStep;
	currentIndex: number;
};

@Directive({
	selector: '[enStep]',
	exportAs: 'enStep'
})
export class EnStep {
	private interacted = false;

	private completedOverride: boolean | null = null;

	@Input()
	readonly stepControl: AbstractControl | AbstractControl[];

	@Input()
	readonly stepLabel: string;

	@Input()
	readonly routePath?: string;

	@Input()
	readonly disabled = false;

	@Input()
	readonly validateFrom = false;

	@Input()
	get completed(): boolean {
		return this.completedOverride == null
			? this.getDefaultCompleted()
			: this.completedOverride;
	}

	set completed(value: boolean) {
		this.completedOverride = coerceBooleanProperty(value);
	}

	constructor(public template: TemplateRef<any>) {}

	get isStepValid(): boolean {
		if (this.stepControl) {
			if (Array.isArray(this.stepControl) && this.validateFrom) {
				return this.stepControl.some(control => control.valid);
			}

			if (Array.isArray(this.stepControl)) {
				return this.stepControl.every(control => control.valid);
			}

			return this.stepControl.valid;
		}
		return true;
	}

	private getDefaultCompleted() {
		return this.stepControl
			? this.isStepValid && this.interacted
			: this.interacted;
	}

	markAsInteracted() {
		if (!this.interacted) {
			this.interacted = true;
		}
	}
}

@Directive({
	selector: '[enStepper]'
})
export class EnStepper implements AfterContentInit {
	private _selectedIndex = 0;

	private _loading = false;

	private viewInited = false;

	private waitingIndex: number | null = null;

	@ContentChildren(EnStep, { descendants: true })
	protected steps: QueryList<EnStep>;

	@Input()
	private linear = true;

	@Input()
	set rerender(_: any) {
		this.stateChanged();
	}

	@Input() set syncRoute(routePath: string) {
		if (routePath) this.syncIndexWithRoute(routePath);
	}

	@Input()
	get loading() {
		return this._loading;
	}

	set loading(value: boolean) {
		this._loading = value;
		this.stateChanged();
	}

	@Input()
	get selectedIndex(): number {
		return this._selectedIndex;
	}

	set selectedIndex(index: number) {
		const newIndex = Math.max(coerceNumberProperty<number>(index, 0), 0);
		if (!this.viewInited || !this.steps) {
			this.waitingIndex = newIndex;
			return;
		}

		if (newIndex > this.steps.length - 1) {
			throw new Error('Индекс вышел за границы длины степпов');
		}

		if (this.stepsArray[newIndex].disabled) {
			if (newIndex > this.selectedIndex) {
				this.selectedIndex = newIndex + 1;
				return;
			}

			if (newIndex < this.selectedIndex) {
				this.selectedIndex = newIndex - 1;
				return;
			}

			return;
		}

		this.selected.markAsInteracted();

		const previous = this.stepsArray[Math.max(newIndex - 1, 0)];

		if (this.linear && !previous.disabled) {
			if (
				newIndex >= this.selectedIndex &&
				(!previous.isStepValid || !this.selected.completed)
			) {
				this.onInvalidStep.emit({
					index: newIndex,
					currentIndex: this.selectedIndex,
					selectedStep: this.stepsArray[newIndex],
					routePath: this.selected.routePath
				});
				return;
			}
		}

		this.setSelectedIndex(newIndex);
		this.stateChanged();
	}

	set allowNextStep(index: number) {
		this.setSelectedIndex(index);
		this.stateChanged();
	}

	@Output()
	readonly selectionChange = new EventEmitter<StepperSelectionChange>();

	@Output()
	readonly onInvalidStep = new EventEmitter<StepperSelectionChange>();

	constructor(
		@Optional()
		@Self()
		@Inject(SUBJECT_TOKEN)
		public readonly globalStateChange$: Subject<void> | null,
		private _cdr: ChangeDetectorRef
	) {}

	get stepsArray(): EnStep[] {
		return this.steps?.toArray() || [];
	}

	get selected(): EnStep {
		return this.stepsArray[this.selectedIndex];
	}

	ngAfterContentInit() {
		this.viewInited = true;
		this.steps.changes.pipe(startWith(this.steps)).subscribe(() => {
			if (this.waitingIndex !== null) {
				this.selectedIndex = this.waitingIndex;
				this.waitingIndex = null;
			}
			this.stateChanged();
		});
	}

	previous(): void {
		this.selectedIndex = Math.max(this._selectedIndex - 1, 0);
	}

	next(): void {
		this.selectedIndex = Math.min(
			this._selectedIndex + 1,
			this.steps.length - 1
		);
	}

	stateChanged(): void {
		this._cdr.markForCheck();
		this.globalStateChange$?.next();
	}

	protected syncIndexWithRoute(routePath: string): void {
		const index = this.stepsArray.findIndex(
			item => item.routePath === routePath
		);

		if (
			index !== -1 &&
			index !== this.selectedIndex &&
			!this.stepsArray[index].disabled
		)
			this.selectedIndex = index;
	}

	private selectedIndexChange(index: number): void {
		this.selectionChange.emit({
			index,
			routePath: this.selected.routePath,
			selectedStep: this.stepsArray[index],
			currentIndex: this.selectedIndex
		});
	}

	private setSelectedIndex(index: number): void {
		this._selectedIndex = index;
		this.selectedIndexChange(index);
	}
}
