import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	NgZone,
	OnDestroy,
	Output,
	PLATFORM_ID,
	Renderer2,
	ViewChild,
	forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DomHandler } from 'primeng/dom';

const SLIDER_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => EnSliderComponent),
	multi: true
};

@Component({
	selector: 'en-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	providers: [SLIDER_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnSliderComponent implements ControlValueAccessor, OnDestroy {
	@Input() disabled: boolean | undefined;

	@Input() min = 0;

	@Input() max = 100;

	@Input() step = 1;

	@Input() style: { [klass: string]: any } | null | undefined;

	@Input() styleClass: string | undefined;

	@Input() sliderWidth = 20.5;

	@Input() tabindex = 0;

	@Output() onChange: EventEmitter<any> = new EventEmitter<any>();

	@Output() onSlideEnd: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('sliderHandle') sliderHandle: ElementRef;

	public value: number;

	public handleValue: number;

	bottom: number;

	public dragging: boolean;

	public dragListener: any;

	public mouseupListener: any;

	public initX: number;

	public initY: number;

	public barWidth: number;

	public barHeight: number;

	public sliderHandleClick: boolean;

	public handleIndex = 0;

	public startHandleValue: any;

	public startx: number;

	public starty: number;

	public onModelChange: Function = () => {};

	public onModelTouched: Function = () => {};

	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: any,
		public el: ElementRef,
		public renderer: Renderer2,
		private ngZone: NgZone,
		public cd: ChangeDetectorRef
	) {}

	increment() {
		if (this.disabled) return;
		this.updateDomData();
		this.handleValue = this.normalizeValue(this.handleValue + this.step);
		this.updateValue(this.handleValue);
	}

	decrement() {
		if (this.disabled) return;
		this.updateDomData();
		this.handleValue = this.normalizeValue(this.handleValue - this.step);
		this.updateValue(this.handleValue);
	}

	onMouseDown(event: Event, index?: number) {
		if (this.disabled) {
			return;
		}

		this.dragging = true;
		this.updateDomData();
		this.sliderHandleClick = true;
		(this.handleIndex as any) = index;

		this.bindDragListeners();
		(event.target as HTMLInputElement).focus();
		event.preventDefault();
	}

	onTouchStart(event: TouchEvent, index?: number) {
		if (this.disabled) {
			return;
		}

		const touchobj = event.changedTouches[0];
		this.startHandleValue = this.handleValue;
		this.dragging = true;
		this.handleIndex = index as number;

		this.startx = parseInt((touchobj as any).clientX, 10);
		this.barWidth = this.el.nativeElement.children[0].width;

		event.preventDefault();
	}

	onTouchMove(event: TouchEvent) {
		if (this.disabled) {
			return;
		}

		const touchobj = event.changedTouches[0];
		let handleValue = 0;

		handleValue =
			Math.floor(
				((parseInt((touchobj as any).clientX, 10) -
					(this.startx as number)) *
					100) /
					(this.barWidth as number)
			) + this.startHandleValue;

		this.setValueFromHandle(event, handleValue);

		event.preventDefault();
	}

	onTouchEnd(event: TouchEvent) {
		if (this.disabled) {
			return;
		}

		this.dragging = false;

		this.onSlideEnd.emit({
			originalEvent: event,
			value: this.value as number
		});

		event.preventDefault();
	}

	onBarClick(event: Event) {
		if (this.disabled) {
			return;
		}

		if (!this.sliderHandleClick) {
			this.updateDomData();
			this.handleChange(event);

			this.onSlideEnd.emit({
				originalEvent: event,
				value: this.value as number
			});
		}

		this.sliderHandleClick = false;
	}

	onHandleKeydown(event: KeyboardEvent) {
		if (this.disabled) {
			return;
		}
		if (event.which === 38 || event.which === 39) {
			this.spin(event, 1);
		} else if (event.which === 37 || event.which === 40) {
			this.spin(event, -1);
		}
	}

	spin(event: Event, dir: number) {
		const step = (this.step || 1) * dir;

		this.updateValue((this.value as number) + step);
		this.sliderHandle?.nativeElement.focus();
		this.updateHandleValue();

		event.preventDefault();
	}

	handleChange(event: Event) {
		const handleValue = this.calculateHandleValue(event);
		this.setValueFromHandle(event, handleValue);
	}

	bindDragListeners() {
		if (isPlatformBrowser(this.platformId)) {
			this.ngZone.runOutsideAngular(() => {
				const documentTarget: any = this.el
					? this.el.nativeElement.ownerDocument
					: this.document;

				if (!this.dragListener) {
					this.dragListener = this.renderer.listen(
						documentTarget,
						'mousemove',
						event => {
							if (this.dragging) {
								this.ngZone.run(() => {
									this.handleChange(event);
								});
							}
						}
					);
				}

				if (!this.mouseupListener) {
					this.mouseupListener = this.renderer.listen(
						documentTarget,
						'mouseup',
						event => {
							if (this.dragging) {
								this.dragging = false;
								this.ngZone.run(() => {
									this.onSlideEnd.emit({
										originalEvent: event,
										value: this.value as number
									});
								});
							}
						}
					);
				}
			});
		}
	}

	unbindDragListeners() {
		if (this.dragListener) {
			this.dragListener();
			this.dragListener = null;
		}

		if (this.mouseupListener) {
			this.mouseupListener();
			this.mouseupListener = null;
		}
	}

	setValueFromHandle(event: Event, handleValue: any) {
		const newValue = this.getValueFromHandle(handleValue);
		if (this.step) {
			this.handleStepChange(newValue, this.value as any);
		} else {
			this.handleValue = handleValue;
			this.updateValue(newValue, event);
			this.sliderHandle?.nativeElement.focus();
		}

		this.cd.markForCheck();
	}

	handleStepChange(newValue: number, oldValue: number) {
		const diff = newValue - oldValue;
		let val = oldValue;
		const step = this.step as number;

		if (diff < 0) {
			val =
				oldValue + Math.ceil(newValue / step - oldValue / step) * step;
		} else if (diff > 0) {
			val =
				oldValue + Math.floor(newValue / step - oldValue / step) * step;
		}

		this.updateValue(val);
		this.sliderHandle?.nativeElement.focus();
		this.updateHandleValue();
	}

	writeValue(value: any): void {
		this.value = value || 0;

		this.updateHandleValue();
		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	setDisabledState(val: boolean): void {
		this.disabled = val;
		this.cd.markForCheck();
	}

	get rangeStartBottom() {
		return 'auto';
	}

	get rangeEndBottom() {
		return 'auto';
	}

	updateDomData(): void {
		const rect =
			this.el.nativeElement.children[0].children[1].getBoundingClientRect();
		this.initX = rect.left + DomHandler.getWindowScrollLeft();
		this.initY = rect.top + DomHandler.getWindowScrollTop();
		this.barWidth =
			this.el.nativeElement.children[0].children[1].offsetWidth;
		this.barHeight = this.el.nativeElement.children[0].offsetHeight;
	}

	calculateHandleValue(event: Event): number {
		return (
			(((event as MouseEvent).pageX - (this.initX as number)) * 100) /
			(this.barWidth as number)
		);
	}

	updateHandleValue(): void {
		if ((this.value as number) < this.min) this.handleValue = 0;
		else if ((this.value as number) > this.max) this.handleValue = 100;
		else
			this.handleValue =
				(((this.value as number) - this.min) * 100) /
				(this.max - this.min);
	}

	updateValue(val: number, event?: Event): void {
		if (val < this.min) {
			// eslint-disable-next-line
			val = this.min;
			this.handleValue = 0;
		} else if (val > this.max) {
			// eslint-disable-next-line
			val = this.max;
			this.handleValue = 100;
		}

		this.value = this.getNormalizedValue(val);

		this.onModelChange(this.value);
		this.onChange.emit({ event: event as Event, value: this.value });
	}

	getValueFromHandle(handleValue: number): number {
		return (this.max - this.min) * (handleValue / 100) + this.min;
	}

	getDecimalsCount(value: number): number {
		if (value && Math.floor(value) !== value)
			return value.toString().split('.')[1].length || 0;
		return 0;
	}

	getNormalizedValue(val: number): number {
		const decimalsCount = this.getDecimalsCount(this.step as number);
		if (val && decimalsCount > 0) {
			return +parseFloat(val.toString()).toFixed(decimalsCount);
		}
		return Math.floor(val);
	}

	private normalizeValue(value: number) {
		return Number(value.toFixed(1));
	}

	ngOnDestroy() {
		this.unbindDragListeners();
	}
}
