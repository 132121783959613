import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';

import { EnCalendarComponent } from './calendar.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, CalendarModule],
	declarations: [EnCalendarComponent],
	exports: [EnCalendarComponent]
})
export class EnCalendarModule {}
