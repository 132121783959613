<button #button
        #trigger="cdkOverlayOrigin"
        enButton
        cdkOverlayOrigin
        icon="en en-columns"
        class="en-button-secondary h28 table-settings"
        (click)="tableDisplaySettings()">
</button>

<ng-template cdkConnectedOverlay
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayOpen]="visibleTableSettings"
             [cdkConnectedOverlayPositions]="[{originX: 'end',originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: 4}]"
             (overlayOutsideClick)="outsideClick($event)">

    <div class="columns">
        <ng-content></ng-content>
    </div>
</ng-template>
