<div class="wrapper">
    <div class="wrapper__date-time">
        <div class="wrapper__date-time_input">
            <en-primitive-input-date-time class="input-date"
                                          type="date"
                                          [numberInput]="firstInput"
                                          (changeDate)="onChangeDate($event)"></en-primitive-input-date-time>
            <en-primitive-input-date-time *ngIf="showTime"
                                          type="time"
                                          [value]="value"
                                          [numberInput]="firstInput"
                                          class="input-time"></en-primitive-input-date-time>
        </div>
        <div *ngIf="!isSingle"
             class="wrapper__date-time_input">
            <div class="line-between-date">—</div>
            <en-primitive-input-date-time class="input-date"
                                          type="date"
                                          [numberInput]="doubleInput"
                                          (changeDate)="onChangeDate($event)"></en-primitive-input-date-time>
            <en-primitive-input-date-time *ngIf="showTime"
                                          type="time"
                                          [value]="value"
                                          [numberInput]="doubleInput"
                                          class="input-time"></en-primitive-input-date-time>
        </div>
    </div>
    <!-- <div class="wrapper__date-time_checkbox">
        <p-checkbox checkboxIcon="en en-done"
                    binary="true"
                    class="checkbox-time"
                    styleClass="newClass"
                    inputId="checkboxTime"
                    [ngModel]="showTime"
                    (click)="checkedTime()"></p-checkbox>
        <label for="checkboxTime">{{'calendar.time' | translate}}</label>
    </div> -->
</div>
