import {
	Component,
	ChangeDetectionStrategy,
	OnInit,
	ViewChild,
	ElementRef,
	AfterViewInit,
	ViewEncapsulation,
	ChangeDetectorRef,
	Renderer2,
	Inject,
	Input,
	EventEmitter,
	Output
} from '@angular/core';
import {
	Chart,
	ChartDataSets,
	ChartTooltipModel,
	ChartXAxe,
	ChartYAxe
} from 'chart.js';
import 'chartjs-plugin-zoom';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WINDOW } from '@enkod-core/utils';
import { TippyInstance } from '@ngneat/helipopper';
import { UnixPeriod } from './constants';
import { generateChartJsTooltip } from '../functions';
import {
	ChartData,
	ChartLineType,
	ChartRenderData,
	GroupOptions,
	UnixFromLabel
} from './models';

@Component({
	selector: 'en-timeline-chart',
	templateUrl: './timeline-chart.component.html',
	styleUrls: ['./timeline-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class TimelineChartComponent implements OnInit, AfterViewInit {
	hiddenChart = false;
	chart: Chart;
	legend: SafeHtml;
	tippyInstance: TippyInstance[] = [];

	index = 0;
	private panStarted = false;
	private unixFromLabel: UnixFromLabel;
	private actualMinX: number;
	// private actualMaxX = this.getTodayTimestamp();
	private endOfData = false;
	private previousLineType: ChartLineType[] = [];

	@ViewChild('tooltipWrapper') private tooltipWrapper: ElementRef;

	@Input() set chartData(data: ChartData) {
		if (!data) return;
		if (!data?.datasets?.length) {
			this.endOfData = true;
		}

		this.updateChart(data);
		this.unixFromLabel = data.unixFromLabel;
	}

	@Input() group: GroupOptions;

	@Output() onRequest = new EventEmitter();

	@Input() tooltipCallback: (item: ChartRenderData) => string = () => '';

	constructor(
		private sanitizer: DomSanitizer,
		private cd: ChangeDetectorRef,
		readonly renderer: Renderer2,
		@Inject(WINDOW) readonly documentRef: Window
	) {}

	ngOnInit(): void {
		this.chartInit();
	}

	ngAfterViewInit() {
		this.updateLegend();
		// даже через async без detectChanges не работает
		this.cd.detectChanges();
	}

	legendHandler(event: Event) {
		const legendItem = (event.target as HTMLElement).closest(
			'.legend-item'
		);
		if (!legendItem) return;

		const index = Number(legendItem.getAttribute('data-index') as string);

		const meta = this.chart.getDatasetMeta(index);
		meta.hidden = !meta.hidden;
		this.updateLegend();

		this.autoScale();
	}

	private updateChart(data: ChartData): void {
		const { datasets } = this.chart.data;
		if (!datasets) return;

		this.actualMinX = data.unixFromLabel[data.labels[0]];

		if (data.isNewChart) {
			this.endOfData = false;

			this.chart.data.labels = data.labels;
			datasets.length = 0;
			data.datasets.forEach(dataset => {
				datasets.push(dataset);
			});

			const minDate =
				data.labels.length >= 15
					? data.labels[data.labels.length - 15]
					: data.labels[0];

			if (this.chart.options.scales) {
				(this.chart.options.scales.xAxes as ChartXAxe[])[0].ticks = {
					min: minDate
				};
			}
			this.chart.update();
			this.updateLegend();
			this.autoScale();
		} else {
			const newLabels = data.labels.concat(
				this.chart.data.labels as string[]
			);
			this.chart.data.labels = newLabels;
			data.datasets.forEach((newDataset, index) => {
				datasets[index].pointRadius = newDataset.pointRadius;
				datasets[index].data = (
					newDataset.data as (number | null)[]
				)?.concat(
					this.updatePreviousValues(
						datasets[index].data as (number | null)[],
						newDataset.lineType,
						index
					)
				);
				this.previousLineType[index] = newDataset.lineType;
			});

			this.chart.update({ duration: 0, lazy: true });
			this.updateLegend();
		}
	}

	private chartInit() {
		this.chart = new Chart('canvas', {
			type: 'line',
			data: {
				labels: [],
				datasets: []
			},
			options: {
				legend: {
					display: false
				},
				legendCallback(chart: Chart) {
					const data = chart.data.datasets as (ChartDataSets & {
						labelInfoId: number | null;
					})[];

					// todo: если больше 9 (для одной строки) 18(для двух в full hd) отрисовывать label только айди
					const legend = data.map((item, index) => {
						const meta = chart.getDatasetMeta(index);
						const style = meta.controller.getStyle();
						const checkBoxStyle = chart.isDatasetVisible(index)
							? `'background-color: ${style.backgroundColor}'`
							: `'border: 2px solid ${style.backgroundColor}'`;

						return `<div class="legend-item" data-index=${index}>
									<div class="legent-item__check-box" style=${checkBoxStyle}></div>
									<div class="legent-item__label text-overflow"'>${item.label}</div>
                                    ${
										item.labelInfoId
											? '<div>Дописать подсказку, прикрутить тултип на класс/id</div>'
											: ''
									}
					  			</div>`;
					});
					return legend.join('');
				},
				tooltips: {
					enabled: false,
					custom: tooltipModel => {
						if (this.panStarted) return;
						const innerHtml =
							this.generateTooltipHtml(tooltipModel);

						generateChartJsTooltip.call(
							this,
							tooltipModel,
							innerHtml,
							{
								appendTo: this.tooltipWrapper.nativeElement
							}
						);
					}
				},

				plugins: {
					zoom: {
						pan: {
							enabled: true,
							mode: 'x',
							onPan: () => {
								if (this.panStarted) return;
								this.panStarted = true;
								this.hideTooltip();
							},
							onPanComplete: () => {
								this.panStarted = false;
								this.autoScale();
							}
						},
						zoom: {
							enabled: false
						}
					}
				},
				scales: {
					xAxes: [
						{
							ticks: {},
							beforeUpdate: evt => this.requestOnPan(evt.min), // evt.max
							gridLines: {
								lineWidth: 1.5,
								color: '#EDEEF2',
								borderDash: [9, 8]
							}
						}
					],
					yAxes: [
						{
							ticks: {}
						}
					]
				}
			}
		});

		this.autoScale();
	}

	private autoScale() {
		// берем все видимые тики по оси X (это дни или месяца)
		const { ticks } = (this.chart.options.scales?.xAxes as ChartYAxe[])[0];

		// ищем индекс самого левого и правого тика сопоставляя его с массивом всех лейблов
		let minIndex = this.chart.data.labels?.findIndex(
			el => el === ticks?.min
		);
		if (minIndex === -1) minIndex = 0;

		let maxIndex = this.chart.data.labels?.findIndex(
			el => el === ticks?.max
		);
		if (maxIndex === -1) maxIndex = this.chart.data.labels?.length;

		// пробегаемся по всем datasets и берем из них наибольшее значение, записываем в массив
		const maxData: number[] = [];
		this.chart.data.datasets?.forEach((dataset, index) => {
			if (!this.chart.isDatasetVisible(index)) return;
			const currentData = (dataset.data as number[]).slice(
				minIndex,
				(maxIndex as number) + 1
			);
			maxData.push(Math.max(...currentData));
		});

		// определяем наибольшее значение из всех
		const maxValue = Math.max(...maxData);

		// задаем новые параметры графику
		this.chart.options.scales = {
			...this.chart.options.scales,
			yAxes: [
				{
					ticks: {
						min: 0,
						max: getMaxScale(maxValue)
					}
				}
			]
		};
		this.chart.update();

		// создаем максимальный тик по оси Y у графика
		function getMaxScale(value: number): number {
			const valueLength = String(value).length;
			const firstLetter = Number(String(value)[0]);

			if (valueLength > 2) {
				const secondLetter = Number(String(value)[1]);
				if (secondLetter < 5)
					return Number(`${firstLetter}5`) * 10 ** (valueLength - 2);
			}
			return (firstLetter + 1) * 10 ** (valueLength - 1);
		}
	}

	private updateLegend() {
		this.legend = this.sanitizer.bypassSecurityTrustHtml(
			this.chart.generateLegend() as string
		);
	}

	private hideTooltip() {
		this.tippyInstance.forEach(instance => instance.hide());
	}

	private generateTooltipHtml(tooltipModel: ChartTooltipModel): string {
		if (tooltipModel.opacity === 0) return '';

		const dataIndex = tooltipModel.dataPoints[0].index || 0;

		// const valueIndex =
		// 	tooltipModel.dataPoints[0].datasetIndex;
		const data =
			this.chart.data.datasets?.map(dataset => {
				if (!dataset.data)
					return {
						count: 0,
						color: dataset.borderColor
					};
				return {
					count: dataset.data[dataIndex],
					color: dataset.borderColor
				};
			}) || [];

		const innerHtml = data
			.map(
				item => this.tooltipCallback(item)
				// пример:
				// `<div style="display: flex; align-items: center;">
				//     <div style="width: 0.75rem; height: 0.75rem; border-radius: 4px; background-color: ${item.color};"></div>
				//     <div style="margin-left: 0.5rem; font-size: 0.813rem; line-height: 1.25rem;">
				//         Клики: ${item.count}
				//     </div>
				// </div>`
			)
			.join('');

		return innerHtml;
	}

	// maxShownLabel: string
	private requestOnPan(minShownLabel: string) {
		if (!this.unixFromLabel) return;
		if (this.endOfData) return;
		const minShown = this.unixFromLabel[minShownLabel];
		// const maxShown = this.unixFromLabel[maxShownLabel];

		const periodMultiple = UnixPeriod[this.group];

		const distanceLeft = Math.floor(
			Math.abs(minShown - this.actualMinX) / periodMultiple
		);
		// const distanceRight = Math.floor(
		// 	Math.abs(maxShown - this.actualMaxX) / periodMultiple
		// );
		if (distanceLeft <= 20) {
			// взять доп данные
			this.onRequest.emit(this.actualMinX);
		}
	}

	private updatePreviousValues(
		datasetData: (number | null)[],
		lineType: ChartLineType,
		index: number
	): (number | null)[] {
		if (
			this.previousLineType[index] === 'solid' ||
			lineType === this.previousLineType[index]
		)
			return datasetData;
		switch (lineType) {
			case 'solid': {
				return datasetData.map(element =>
					element === null ? 0 : element
				);
			}
			default:
				return datasetData;
		}
	}
}
