import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
	EnButtonModule,
	EnDialogModule,
	EnInputModule,
	ErrorModule
} from 'ui-lib';

import { SharedModule } from '@shared';
import { DialogBlackListAddComponent } from './dialog-black-list-add.component';

@NgModule({
	imports: [
		CommonModule,
		EnDialogModule,
		EnButtonModule,
		EnInputModule,
		ReactiveFormsModule,
		SharedModule,
		ErrorModule
	],
	declarations: [DialogBlackListAddComponent],
	exports: [DialogBlackListAddComponent]
})
export class DialogBlackListAddModule {}
