import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Submailer } from './account-submailer-setting.model';

export interface AccountSubmailersSettingsState
	extends EntityState<Submailer> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'account-submailers-settings'
})
export class AccountSubmailersSettingsStore extends EntityStore<
	AccountSubmailersSettingsState
> {
	constructor() {
		super();
		this.setLoading(false);
	}
}
