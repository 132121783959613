/* eslint-disable @angular-eslint/directive-selector */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { isPresent, tuiDefaultProp } from '@taiga-ui/cdk';

import { TuiTreeItemComponent } from '../components/tree-item/tree-item.component';
import { TuiTreeAccessor, TuiTreeController } from '../misc/tree.interfaces';
import { TUI_TREE_ACCESSOR, TUI_TREE_CONTROLLER } from '../misc/tree.tokens';

@Directive({
	selector: '[tuiTreeController][map]',
	exportAs: 'tuiTreeController',
	providers: [
		{
			provide: TUI_TREE_ACCESSOR,
			useExisting: TuiTreeControllerDirective
		},
		{
			provide: TUI_TREE_CONTROLLER,
			useExisting: TuiTreeControllerDirective
		}
	]
})
export class TuiTreeControllerDirective<T>
	implements TuiTreeController, TuiTreeAccessor<T>
{
	@Input('tuiTreeController')
	@tuiDefaultProp()
	fallback = true;

	@Input()
	@tuiDefaultProp()
	map: Map<T, boolean> = new Map();

	@Output()
	readonly toggled = new EventEmitter<T>();

	readonly items = new Map<TuiTreeItemComponent, T>();

	register(item: TuiTreeItemComponent, value: T) {
		this.items.set(item, value);
	}

	unregister(item: TuiTreeItemComponent) {
		this.items.delete(item);
	}

	isExpanded(item: TuiTreeItemComponent): boolean {
		const value = this.items.get(item);

		return (value && this.map.get(value)) ?? this.fallback;
	}

	toggle(item: TuiTreeItemComponent) {
		const value = this.items.get(item);
		const expanded = this.isExpanded(item);

		if (!isPresent(value)) {
			return;
		}

		this.toggled.emit(value);
		this.map.set(value, !expanded);
	}
}
