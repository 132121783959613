import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	TuiDropdownControllerModule,
	TuiHostedDropdownModule
} from '@taiga-ui/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { DropdownFilterCalendarComponent } from './dropdown-filter-calendar.component';
import { EnCalendarRangeNewModule } from '../calendar-range-new/calendar-range.module';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnCalendarRangeNewModule,
		TranslateModule,
		TuiHostedDropdownModule,
		TuiActiveZoneModule,
		TuiDropdownControllerModule
	],
	declarations: [DropdownFilterCalendarComponent],
	exports: [DropdownFilterCalendarComponent]
})
export class EnDropdownFilterCalendarModule {}
