import { Injectable } from '@angular/core';

@Injectable()
export class WhatsappVariableService {
	private FIND_REG = /\{\{[1-9]\}\}/g;
	private FIND_INCORRECT_REG = /\{\{[1-9][0-9]+\}\}/g;

	getVariables(text: string): string[] {
		return (text.match(this.FIND_REG) as string[]) || [];
	}

	sortVariablesInText(text: string, limit: number): string {
		// Убираем переменные с числом больше 9
		const correctText = text.replace(this.FIND_INCORRECT_REG, '');
		// Находим корректные переменные в тексте
		const variables: string[] | null = correctText.match(
			this.FIND_REG
		) as string[];
		if (!variables) return correctText;

		let resultText = '';
		let tempText = correctText;

		// Дробим текст на части и выставляем переменные в правильном порядке
		for (let i = 0; i < variables.length && i < limit; i++) {
			// Находим первую переменную в тексте
			const index = tempText.search(this.FIND_REG);
			// Выделяем текст перед переменной и сохраняем в результат
			resultText = `${resultText + tempText.slice(0, index)}{{${i + 1}}}`;
			// Остаток текста сохраняем в темп текст
			tempText = tempText.slice(index + 5);
			// Добавляем остаток текста если в нем нет переменных
			if (tempText.search(this.FIND_REG) === -1) {
				resultText += tempText;
			}
		}
		return resultText;
	}

	replaceVariablesInText(text: string, variables: string[]): string {
		let resultText = text;
		variables?.forEach((value, index) => {
			const variableValue = value || `{{${index + 1}}}`;
			resultText = resultText?.replace(`{{${index + 1}}}`, variableValue);
		});
		return resultText;
	}

	highlightVariablesInText(text: string, variables: string[]): string {
		let resultText = text;
		variables.forEach((value, index) => {
			const variableWithSpan = `<span class="variable">${value}</span>`;
			resultText = resultText?.replace(
				`{{${index + 1}}}`,
				variableWithSpan
			);
		});
		return resultText;
	}
}
