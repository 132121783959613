<en-dialog [(visible)]="manualAddDialog"
           [header]="'black_list_dialog.manual_add_header' | translate"
           [modal]="true"
           [style]="{ width: '33rem' }"
           (onHide)="closeDialogMaker()">
    <form [formGroup]="form">
        <div class="en-field">
            <label for="email">{{'black_list_dialog.label_enter_email' | translate}}</label>
            <input id="email"
                   class="h32"
                   type="text"
                   enInput
                   formControlName="email" />
            <en-error formControlName="email"></en-error>

        </div>
        <div class="en-field">
            <label for="reasonInfo"> {{'black_list_dialog.label_enter_reason' | translate}}</label>
            <input id="reasonInfo"
                   class="h32"
                   type="text"
                   enInput
                   formControlName="reasonInfo" />
        </div>
    </form>

    <p-footer>
        <button type="button"
                enButton
                class="h36 en-button-secondary"
                [style]="{ 'margin-right': '1rem' }"
                [label]="'black_list_dialog.button_back' | translate"
                (click)="closeDialogMaker()"></button>
        <button type="button"
                enButton
                class="h36"
                [label]="'black_list_dialog.button_create' | translate"
                (click)="submit()"></button>
    </p-footer>
</en-dialog>
