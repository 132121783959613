import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineChartComponent } from './timeline-chart.component';

@NgModule({
	imports: [CommonModule],
	declarations: [TimelineChartComponent],
	exports: [TimelineChartComponent]
})
export class TimelineChartModule {}
