import { AnimationOptions } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	NgZone,
	Optional
} from '@angular/core';
import { ANIMATION_FRAME } from '@ng-web-apis/common';
import { tuiZoneOptimized } from '@taiga-ui/cdk';
import { tuiFadeIn } from '@taiga-ui/core/animations';
import { MODE_PROVIDER } from '@taiga-ui/core/providers';
import {
	TUI_ANIMATION_OPTIONS,
	TUI_MODE,
	TUI_SCROLL_REF
} from '@taiga-ui/core/tokens';
import { TuiBrightness } from '@taiga-ui/core/types';
import { Observable } from 'rxjs';
import {
	distinctUntilChanged,
	map,
	startWith,
	throttleTime
} from 'rxjs/operators';

// @dynamic
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'tui-scroll-controls',
	templateUrl: './scroll-controls.template.html',
	styleUrls: ['./scroll-controls.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [tuiFadeIn],
	providers: [MODE_PROVIDER],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'($.data-mode.attr)': 'mode$'
	}
})
export class TuiScrollControlsComponent {
	readonly refresh$ = this.animationFrame$.pipe(
		throttleTime(300),
		map(() => this.scrollbars),
		startWith([false, false]),
		distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
		tuiZoneOptimized(this.ngZone)
	);

	readonly animation = {
		value: '',
		...this.options
	} as const;

	constructor(
		@Inject(TUI_ANIMATION_OPTIONS)
		private readonly options: AnimationOptions,
		@Inject(NgZone) private readonly ngZone: NgZone,
		@Inject(DOCUMENT) private readonly documentRef: Document,
		@Optional()
		@Inject(TUI_SCROLL_REF)
		private readonly scrollRef: ElementRef<HTMLElement> | null,
		@Inject(ANIMATION_FRAME)
		private readonly animationFrame$: Observable<number>,
		@Inject(TUI_MODE) readonly mode$: Observable<TuiBrightness | null>
	) {}

	private get scrollbars(): [boolean, boolean] {
		const { clientHeight, scrollHeight, clientWidth, scrollWidth } = this
			.scrollRef
			? this.scrollRef.nativeElement
			: this.documentRef.documentElement;

		return [
			Math.ceil((clientHeight / scrollHeight) * 100) < 100,
			Math.ceil((clientWidth / scrollWidth) * 100) < 100
		];
	}
}
