import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SHARED_PIPES } from './pipes';
import { SHARED_DIRECTIVES } from './directives';

@NgModule({
	imports: [CommonModule, TranslateModule],
	declarations: [...SHARED_PIPES, ...SHARED_DIRECTIVES],
	exports: [...SHARED_PIPES, ...SHARED_DIRECTIVES, TranslateModule]
})
export class SharedModule {}
