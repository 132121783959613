import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { EnCalendarRangeFutureComponent } from './calendar-range-future.component';
import { EnCalendarModule } from '../calendar/calendar.module';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnCalendarModule,
		TranslateModule
	],
	declarations: [EnCalendarRangeFutureComponent],
	exports: [EnCalendarRangeFutureComponent]
})
export class EnCalendarRangeFutureModule {}
