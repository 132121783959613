import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
/** Именно из модуля, т.к. WARNING in Circular dependency detected */
import { EnCalendarModule } from '../calendar/calendar.module';

import { CalendarRangeComponent } from './calendar-range.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnCalendarModule,
		TranslateModule
	],
	declarations: [CalendarRangeComponent],
	exports: [CalendarRangeComponent]
})
export class EnCalendarRangeModule {}
