<div class="en-calendar-range"
     [formGroup]="dateRange">
    <div class="en-field-medium">
        <en-calendar formControlName="startDate"
                     [showTime]="showTime"
                     [timeOnly]="timeOnly"
                     [placeholder]="'calendar.date_start' | translate"
                     [minDate]="today"
                     [maxDate]="maxDate"
                     #startDate></en-calendar>
    </div>
    <div class="en-field-medium">
        <en-calendar formControlName="endDate"
                     [showTime]="showTime"
                     [timeOnly]="timeOnly"
                     [minDate]="minDate"
                     [placeholder]="'calendar.date_end' | translate"
                     #endDate></en-calendar>
    </div>
</div>