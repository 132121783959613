import { Pipe, PipeTransform } from '@angular/core';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'inboxConvertDate'
})
export class InboxConvertDatePipe implements PipeTransform {
	constructor(
		private translate: TranslateService,
		private timezoneService: DateAccountTimezoneService
	) {}

	transform(value: number) {
		const valueDate = this.timezoneService.convertDate(value, 'DD.MM.yyyy');

		const today = this.timezoneService.convertDate(
			this.timezoneService.getToday() / 1000,
			'DD.MM.yyyy'
		);

		if (today === valueDate)
			return this.translate.instant('whatsapp_wizard.preview_today');

		if (valueDate) {
			const dateArray = valueDate.split(' ');
			switch (dateArray[1]) {
				case '01':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_january')
					);
					break;
				case '02':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_february')
					);
					break;
				case '03':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_march')
					);
					break;
				case '04':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_april')
					);
					break;
				case '05':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_may')
					);
					break;
				case '06':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_june')
					);
					break;
				case '07':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_july')
					);
					break;
				case '08':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_august')
					);
					break;
				case '09':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_september')
					);
					break;
				case '10':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_october')
					);
					break;
				case '11':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_november')
					);
					break;
				case '12':
					dateArray.splice(
						1,
						1,
						this.translate.instant('calendar.months_december')
					);
					break;
				default:
					break;
			}
			return dateArray.join(' ');
		}
		return valueDate;
	}
}
