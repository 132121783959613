import {
	Component,
	ChangeDetectionStrategy,
	ContentChildren,
	QueryList
} from '@angular/core';
import { ListActionsComponent } from '../list-actions/list-actions.component';

import { StatusTagComponent } from '../status-tag/status-tag.component';

/** Есть небольшая дока для использования компонента,
 * лежит в файле ./doc.md */
@Component({
	selector: 'en-table-list-item',
	templateUrl: './en-table-list-item.component.html',
	styleUrls: ['./en-table-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnTableListItemComponent {
	/** Кейс, когда list-actions вложен в другой компонент */
	@ContentChildren('actions')
	private readonly listActionNested: QueryList<Component>;

	@ContentChildren(ListActionsComponent)
	private readonly listAction: QueryList<ListActionsComponent>;

	@ContentChildren(StatusTagComponent)
	private readonly statuses: QueryList<StatusTagComponent>;

	get hasListAction(): boolean {
		return (
			this.listAction.length !== 0 || this.listActionNested.length !== 0
		);
	}

	get hasStatuses(): boolean {
		return this.statuses.length !== 0;
	}
}
