import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import localeRu from '@angular/common/locales/ru';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'environments/environment';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';

import { NG_EVENT_PLUGINS } from '@tinkoff/ng-event-plugins';

import { EnTranslateModule } from '@enkod-core/translate';

import { HelipoppperModule, NotificationsModule } from 'ui-lib';

import { TuiRootModule } from '@taiga-ui/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnNotificationRootModule } from './ui-lib/notification/root/root.module';
import { ICONS_PATH } from './core/providers/provide-icon-path';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localePt, 'pt');

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		AkitaNgRouterStoreModule,
		HttpClientJsonpModule,
		HttpClientModule,
		EnTranslateModule,
		CoreModule,
		SharedModule,
		HelipoppperModule,
		NotificationsModule,
		EnNotificationRootModule,
		environment.production
			? []
			: AkitaNgDevtools.forRoot({
					maxAge: 25
			  }),
		TuiRootModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production
		})
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'ru-RU' },
		NG_EVENT_PLUGINS,
		ICONS_PATH
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
