<div class="slider">
    <div class="slider__label">
        <span>{{ (labels?.group || 'split_slider_ui.label_test_group') | translate }}</span>
        <span>{{ (labels?.restGroup || 'split_slider_ui.label_winner') | translate }}</span>
    </div>

    <div #rail
         class="slider__rail"
         [tuiFocusable]="focusable"
         (tuiFocusedChange)="onFocused($event)"
         (tuiActiveZoneChange)="onActiveZone($event)">
        <div class="slider__left"
             [enTooltip]="'split_slider_ui.tooltip_test_group' | translate"
             tooltipPosition="bottom"
             showDelay="300">
            <div class="slider__test-group"
                 *ngFor="let split of splitLabels">{{ split | uppercase }}</div>
        </div>

        <div class="slider__splitter">
            <div class="slider__dot">
                <span class="en en-chevron-left"></span>
                <span class="en en-chevron-right"></span>
            </div>
        </div>

        <div #highlight
             class="slider__right"
             [enTooltip]="'split_slider_ui.tooltip_winner' | translate"
             tooltipPosition="top"
             showDelay="300">
            {{ (100 - formatValue) + ' %'}}
        </div>
    </div>
</div>

<div class="input-block">
    <input type="number"
           class="h32"
           enInput
           enNumberRange
           [rangeConfig]="rangeConfigTest"
           [ngModel]="leftValue"
           (ngModelChange)="onModelLeftChange($event)">
    <input type="number"
           class="h32"
           enInput
           enNumberRange
           [rangeConfig]="rangeConfigWinner"
           [ngModel]="rightValue"
           (ngModelChange)="onModelRightChange($event)">
</div>

<div class="info">
    <div class="info__row"
         *ngFor="let split of splitLabels">
        <div class="info__cell">
            {{ ('split_slider_ui.split_version' | translate) + ' ' + (split | uppercase) }}
        </div>
        <div class="info__cell info__cell_percent">
            {{ splitPercentage + ' %'}}
        </div>
    </div>
    <div class="info__row">
        <div class="info__cell">
            {{ (labels?.restGroupFinally || 'split_slider_ui.split_winner') | translate }}
        </div>
        <div class="info__cell info__cell_percent">
            {{ (100 - formatValue) + ' %'}}
        </div>
    </div>
</div>
