import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { TagsValue } from '@enkod-core/enums';

@Component({
	selector: 'en-edit-tags',
	templateUrl: './edit-tags.component.html',
	styleUrls: ['./edit-tags.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTagsComponent {
	systemTags: string[] = [];
	tagsControl = new UntypedFormControl([]);

	@Input() header = 'message_detail_info.edit_tags_dialog_header';
	@Input() dialogTagsVisible: boolean;
	@Input() emptyInit: boolean;
	@Input() addTagAB = true;
	@Input() isPolling = false;

	@Input() set tags(value: string[]) {
		this.systemTags = value?.filter(
			tag => tag === TagsValue.SPLIT_TEST || tag === TagsValue.BOT
		);

		if (this.emptyInit) {
			this.tagsControl.patchValue([]);
			return;
		}

		/** чтобы не сбрасывались выбираемые и удаляемые теги при открытой модалке в деталях сообщения
		 *  (из-за поллинга и апдейта стора) */
		if (this.isPolling) {
			this.tagsControl.patchValue(this.tagsControl.value);
			return;
		}
		this.tagsControl.patchValue(value);
	}

	@Output() closeDialogTags = new EventEmitter();
	@Output() onSubmit = new EventEmitter();

	submit() {
		let tags: string[] = [];
		tags = [...this.systemTags, ...this.tagsControl.value];

		this.onSubmit.emit(tags);
	}
}
