import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TableToolsService {
	offsetTop: string;

	tool = false;

	trHeight: string;

	chosenTr: number;

	rowStatus: string;

	rowSendingType: string;

	rowAlert: boolean;

	rowType: string;

	tooltipVisible: boolean;

	hideTools() {
		this.tooltipVisible = false;
		this.tool = false;
	}

	showTools(index: number, event?: any, rowData?: any) {
		this.tool = true;
		this.chosenTr = index;
		this.tooltipVisible = true;

		if (rowData) {
			this.rowStatus = rowData.status;
			this.rowSendingType = rowData.sendingType;
			this.rowAlert = rowData.alert;
			this.rowType = rowData.type;
		}
		if (event) {
			this.offsetTop = `${event.srcElement.offsetTop}px`;
			this.trHeight = `${event.srcElement.clientHeight}px`;
		}
	}
}
