import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { TranslateService } from '@ngx-translate/core';

import { WINDOW } from '@enkod-core/utils';
import { AuthService } from '@enkod-core/authentication/_state';

@Injectable({ providedIn: 'root' })
export class UpdateService {
	/** Если после запуска приложения прошло 3 и более секунд,
	 *  но обновления не применились - выводим алерт */
	private readonly SECONDS_TO_SHOW_ALERT = 3;

	constructor(
		@Inject(WINDOW) private readonly window: Window,
		private updates: SwUpdate,
		private translate: TranslateService,
		private authService: AuthService
	) {}

	checkUpdates() {
		const initTime = +(new Date().getTime() / 1000).toFixed();

		if (!this.updates.isEnabled) {
			return;
		}

		this.updates.checkForUpdate();

		// this.updates.available.subscribe(() => {
		// 	this.updates.activateUpdate().then(() => {
		// 		setTimeout(() => {
		// 			const translate = this.translate.instant(
		// 				'common.update_alert_logout'
		// 			);
		// 			this.window.alert(translate);
		// 			this.authService.logout();
		// 			setTimeout(() => {
		// 				this.window.location.reload();
		// 			}, 0);
		// 		}, 0);
		// 	});
		// });

		// this.updates.activated.subscribe(ev => {
		// 	console.log('Previous version: ', ev.previous);
		// 	console.log('Current version: ', ev.current);
		// });

		this.updates.available.subscribe(() => {
			console.log('New version available');

			this.updates.activateUpdate().then(() => {
				const activateUpdateTime = +(
					new Date().getTime() / 1000
				).toFixed();

				// считаем сколько прошло секунд после загрузки приложения и до проверки полученных обновлений
				if (
					activateUpdateTime - initTime >=
					this.SECONDS_TO_SHOW_ALERT
				) {
					const translate = this.translate.instant(
						'common.update_alert'
					);

					const confirmApprove = this.window.confirm(translate);

					if (confirmApprove) {
						this.window.location.reload();
					}
				} else {
					this.window.location.reload();
				}

				console.log('Activate new version and reload');
			});
		});

		this.updates.activated.subscribe(ev => {
			console.log('Previous version: ', ev.previous);
			console.log('Current version: ', ev.current);
		});
	}
}
