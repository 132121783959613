import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { UserSettingsQuery } from '@state-user-settings';

@Injectable()
export class LanguageService {
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private userSettingsQuery: UserSettingsQuery,
		private translate: TranslateService
	) {}

	init() {
		const language =
			this.userSettingsQuery.getValue().language ||
			this.translate.getBrowserLang();

		this.translate.setDefaultLang(language);
		this.translate.use(language);
		this.document.documentElement.lang = language;
	}
}
