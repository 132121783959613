import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Optional,
	Output,
	Self
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY_FUNCTION, TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import {
	nullDate,
	yearMaxFromCurrent,
	yearMin
} from '../calendarNew/calendar.model';

@UntilDestroy()
@Component({
	selector: 'en-dropdown-filter-calendar',
	templateUrl: './dropdown-filter-calendar.component.html',
	styleUrls: ['./dropdown-filter-calendar.component.scss'],
	providers: [CalendarService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterCalendarComponent implements OnInit {
	calendarVisible = false;
	showClearIcon = false;
	today: Date = new Date();

	value: TuiDayRange | null = null;

	private onTouched = EMPTY_FUNCTION;
	private onChange = EMPTY_FUNCTION;

	@Input() min = new TuiDay(yearMin, 0, 1);
	@Input() max = new TuiDay(
		TuiDay.currentLocal().year + yearMaxFromCurrent,
		11,
		31
	);

	hoveredItem: TuiDay | null = null;

	@Input() period = 'past';
	@Input() type = 'date';

	@Input()
	set dateRange(value: [number, number]) {
		if (value) {
			this.showClearIcon = true;
			this.calendarService.patchDate(value[0], value[1]);
		}
	}

	@Output() onPeriodChange = new EventEmitter();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		private calendarService: CalendarService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
		this.calendarService.changeFormat('range');
	}

	get dropdownLabel() {
		return this.showClearIcon
			? 'date-range-calendar.during_the_period'
			: 'date-range-calendar.for_all_the_time';
	}

	get control(): AbstractControl | null {
		if (this.ngControl) {
			return this.ngControl.control;
		}
		return null;
	}

	ngOnInit() {
		this.calendarService.changeMinMaxValue(this.min, this.max);
		this.calendarService.changePeriod(this.period);
		this.calendarService.changeType(this.type);

		this.calendarService.currentDateRange
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				this.showClearIcon = true;

				const startDate = this.calendarService.getNumberDate(
					value[0].date,
					value[0].time
				);

				const endDate = this.calendarService.getNumberDate(
					value[1].date,
					value[1].time
				);

				this.onChange([startDate, endDate]);

				value[0].date.daySame(nullDate)
					? (this.showClearIcon = false)
					: (this.showClearIcon = true);

				this.onPeriodChange.emit({ startDate, endDate });
			});
	}

	writeValue(): void {}

	resetDateRange() {
		this.showClearIcon = false;
		this.calendarService.resetDateRange();
	}

	registerOnChange(onChange: () => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}
}
