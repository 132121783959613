import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

import { MultiMenuItem, SelectType } from './dropdown-multilevel.model';

@UntilDestroy()
@Component({
	selector: 'en-multilevel-dropdown',
	templateUrl: './dropdown-multilevel.component.html',
	styleUrls: ['./dropdown-multilevel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnMultilevelDropdownComponent implements OnInit, AfterViewInit {
	isOpenMenu = false;
	labelControl: UntypedFormControl;
	_options: MultiMenuItem[];

	@Input() set options(items: MultiMenuItem[]) {
		this._options = this.addParamsToOptions(items);
	}

	@Input() valueControl: UntypedFormControl;
	@Input() placeholder = 'common.select';
	@Input() addInLabelSubmenuTitle = true;
	@Input() disabled = false;

	// Можно ли выбирать вложенное меню
	@Input() selectType: SelectType = 'noSubmenu';

	@Input() isButtonMode = false;
	@Input() label = '';
	@Input() useExtraButtonText: string;
	@Input() icon = 'en-plus';
	// Стили
	@Input() menuWidth: string;
	@Input() useMainMenuStyle = false;
	@Input() useSegmentsButton = false;
	@Input() useSecondaryButton = false;
	@Input() hideRightBorder = false;

	@ViewChild('input') input: ElementRef;

	constructor(
		private renderer: Renderer2,
		private translate: TranslateService,
		private cd: ChangeDetectorRef
	) {}

	@Output() itemClick = new EventEmitter<string>();

	@Output() extraButtonClick = new EventEmitter<void>();

	@Output() onButtonClick = new EventEmitter<void>();

	@Output() onChange = new EventEmitter<void>();

	get value(): string {
		return this.valueControl.value;
	}

	get disableInput() {
		return this.disabled ? true : null;
	}

	ngOnInit() {
		this.labelControl = new UntypedFormControl('');
		this._options = this.addParamsToOptions(this._options);

		this.valueControl?.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (!value.length) {
						this.labelControl.patchValue('');
						this.setInputWidth(16);
						this.cd.markForCheck();
					}
				})
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		if (!this.isButtonMode && this.value) this.setValue(this.value);
	}

	openSecondMenu(option: MultiMenuItem) {
		this._options = this._options.map(item => {
			const itemCopy = item;
			if (option.id === item.id) {
				itemCopy.isOpen = true;
				return itemCopy;
			}
			itemCopy.isOpen = false;
			return itemCopy;
		});
	}

	openTrirdMenu(secondOption: MultiMenuItem) {
		this._options = this._options.map(item => {
			if (secondOption.parentId === item.id) {
				item.submenuItems?.map(secondItem => {
					const secondItemCopy = secondItem;
					if (secondOption.id === secondItem.id) {
						secondItemCopy.isOpen = true;
						return secondItemCopy;
					}
					secondItemCopy.isOpen = false;
					return secondItemCopy;
				});
			}
			return item;
		});
	}

	selectOption(option: MultiMenuItem) {
		if (option.isTitle) return;
		if (option.isSubmenu && this.selectType === 'noSubmenu') return;

		const translatedParentLabel =
			this.getParentLabel(option.parentId).length > 0
				? `${this.translate.instant(
						this.getParentLabel(option.parentId)
				  )} `
				: '';
		this.labelControl.patchValue(
			this.addInLabelSubmenuTitle
				? `${translatedParentLabel}${this.translate.instant(
						option.label
				  )}`
				: option.label
		);

		this.isButtonMode
			? this.itemClick.emit(option.value)
			: this.valueControl.patchValue(option.value);
		if (!this.isButtonMode) this.resizeInputWidth();
		if (this.isOpenMenu) this.onChange.emit();
		this.isOpenMenu = false;
	}

	onExtraButtonClick() {
		this.extraButtonClick.emit();
		this.isOpenMenu = false;
	}

	private setValue(value: string) {
		const filtredItems = this._options.map(item => {
			let findedSubItem: MultiMenuItem | undefined;
			if (item.isSubmenu)
				findedSubItem = item.submenuItems?.find(
					subItem => subItem.value === value
				);
			if (findedSubItem) return findedSubItem;
			if (item.value === value) return item;
			return null;
		});
		const findedItem = filtredItems.find(item => item !== null);

		if (findedItem) this.selectOption(findedItem);
		this.cd.markForCheck();
	}

	private addParamsToOptions(
		options: MultiMenuItem[],
		parentId?: number
	): MultiMenuItem[] {
		let lastID = 1;
		return options.map(item => {
			const itemCopy = item;
			itemCopy.id = lastID;
			itemCopy.isOpen = false;
			if (parentId) itemCopy.parentId = parentId;
			if (itemCopy.submenuItems)
				itemCopy.submenuItems = this.addParamsToOptions(
					itemCopy.submenuItems,
					lastID
				);
			lastID++;
			return itemCopy;
		});
	}

	private resizeInputWidth(): void {
		this.setInputWidth(this.input.nativeElement.value.length + 3);
	}

	private getParentLabel(parentId: number | undefined): string {
		return this._options.find(item => item.id === parentId)?.label || '';
	}

	private setInputWidth(width: number): void {
		this.renderer.setStyle(this.input.nativeElement, 'width', `${width}ch`);
	}
}
