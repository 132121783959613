<tui-hosted-dropdown [tuiDropdownAlign]="dropdownAlign"
                     [tuiDropdownDirection]="dropdownDirection"
                     [content]="dropdown"
                     [(open)]="calendarVisible">
    <button [class]="'dropdown ' + class"
            [class.focus]="calendarVisible"
            tuiDropdownLimitAlign="right">
        <span class="label">{{dropdownLabel | translate}}</span>

        <span *ngIf="showClearIcon"
              class="clear-icon-wrapper">
            <span class="clear-icon en en-cancel-fill"
                  (click)="resetDateRange($event)">
            </span>
        </span>
        <span class="drop-down-icon en en-sort-down"></span>
    </button>
</tui-hosted-dropdown>

<ng-template #dropdown>
    <en-calendar selectionMode="range"
                 [styleClass]="styleClass"
                 [inline]="true"
                 [showButtonBar]="false"
                 [maxDate]="today"
                 [formControl]="dateRange"
                 (onSelect)="onSelect()"></en-calendar>
</ng-template>
