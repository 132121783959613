import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Inject,
	Input,
	Optional,
	Output,
	Provider,
	Self,
	ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { InboxEmojiService } from '@enSend/inbox/_state/inbox-emoji.service';
import { InboxEmoji } from '@enSend/inbox/_state/inbox.model';
import {
	AbstractTuiControl,
	isNativeFocused,
	setNativeFocused,
	tuiDefaultProp,
	TUI_FOCUSABLE_ITEM_ACCESSOR
} from '@taiga-ui/cdk';
import {
	TEXTFIELD_CONTROLLER_PROVIDER,
	TuiTextfieldController,
	TUI_TEXTFIELD_WATCHED_CONTROLLER
} from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';

export const DEFAULT_ROWS = 20;
export const LINE_HEIGHT = 20;

export const TUI_TEXT_AREA_PROVIDERS: Provider[] = [
	{
		provide: TUI_FOCUSABLE_ITEM_ACCESSOR,
		useExisting: forwardRef(() => EnTextAreaComponent)
	},
	TEXTFIELD_CONTROLLER_PROVIDER
];

@Component({
	selector: 'en-text-area',
	templateUrl: './text-area.component.html',
	styleUrls: ['./text-area.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TUI_TEXT_AREA_PROVIDERS, InboxEmojiService]
})
export class EnTextAreaComponent
	extends AbstractTuiControl<string>
	implements AfterViewInit
{
	isOpenEmoji = false;
	isNewCursorPosition = true;
	textArea: ElementRef<HTMLTextAreaElement>;
	cursorPosition = 0;
	emojiExclude = ['flags'];

	@Input() placeholder = '';
	@Input() useOutlineColor = true;
	@Input() areaDisabled = false;
	@Input() showEmojiSelector = false;

	@Input()
	@tuiDefaultProp()
	rows = DEFAULT_ROWS;

	@Input()
	@HostBinding('class._expandable')
	@tuiDefaultProp()
	expandable = false;

	@Input() manualInvalid$: BehaviorSubject<boolean>;

	@ViewChild('focusableElement')
	private readonly focusableElement?: ElementRef<HTMLTextAreaElement>;

	@Output() focusChange = new EventEmitter();

	@Output() textAreaElement = new EventEmitter<
		ElementRef<HTMLTextAreaElement>
	>();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		control: NgControl | null,
		@Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
		@Inject(TUI_TEXTFIELD_WATCHED_CONTROLLER)
		readonly controller: TuiTextfieldController,
		public emojiService: InboxEmojiService
	) {
		super(control, changeDetectorRef);
	}

	@HostBinding('class._has-value')
	get hasValue(): boolean {
		return this.value !== '';
	}

	get hasExampleText(): boolean {
		return (
			!!this.controller.exampleText &&
			this.focused &&
			!this.hasValue &&
			!this.readOnly
		);
	}

	get nativeFocusableElement(): HTMLTextAreaElement | null {
		return this.computedDisabled || !this.focusableElement
			? null
			: this.focusableElement.nativeElement;
	}

	get focused(): boolean {
		return isNativeFocused(this.nativeFocusableElement);
	}

	get computeMaxHeight(): number | null {
		return this.expandable ? this.rows * this.lineHeight : null;
	}

	ngAfterViewInit() {
		this.textAreaElement.emit(this.focusableElement);
	}

	onFocused(focused: boolean) {
		this.updateFocused(focused);
		this.isNewCursorPosition = true;
	}

	private getCursorPosition() {
		return this.focusableElement?.nativeElement.selectionStart;
	}

	onHovered(hovered: boolean) {
		this.updateHovered(hovered);
	}

	onPressed(pressed: boolean) {
		this.updatePressed(pressed);
	}

	onValue(value: string) {
		this.updateValue(value);
		this.focusChange.emit(this.getCursorPosition());
	}

	onMouseDown(event: MouseEvent) {
		// при использовании в менеджере дк, после выбора опции и закрытия окна, если нажать на инпут,
		// то onMouseDown вызывается раньше, чем onFocused, соответсвенно у элемента focusableElement
		// показывает selectionStart = 0
		setTimeout(() => {
			this.focusChange.emit(this.getCursorPosition());
		}, 0);

		if (event.target === this.nativeFocusableElement) {
			return;
		}

		event.preventDefault();

		if (this.nativeFocusableElement) {
			setNativeFocused(this.nativeFocusableElement);
		}
	}

	addEmoji(emoji: InboxEmoji): void {
		if (this.isNewCursorPosition) {
			this.cursorPosition =
				this.focusableElement?.nativeElement.selectionStart || 0;
			this.isNewCursorPosition = false;
		}
		const text = this.emojiService.getPastedEmojiText(
			this.control?.value,
			this.cursorPosition,
			emoji
		);
		this.control?.patchValue(text);
	}

	setTextAreaElement(textArea: ElementRef<HTMLTextAreaElement>): void {
		this.textArea = textArea;
	}

	protected getFallbackValue(): string {
		return '';
	}

	private get lineHeight(): number {
		return LINE_HEIGHT;
	}
}
