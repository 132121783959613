<div #container
     [@messageState]="{
    value: 'visible',
    params: {
        showTransformParams: showTransformOptions,
        hideTransformParams: hideTransformOptions,
        showTransitionParams: showTransitionOptions,
        hideTransitionParams: hideTransitionOptions
    }
}"
     [attr.id]="message.id"
     class="en-toast-item"
     [ngClass]="'en-toast-' + message.severity "
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()">
    <div class="en-toast-content"
         role="alert"
         aria-live="assertive"
         aria-atomic="true">
        <ng-container *ngIf="!template">
            <div class="en-toast-summary"
                 [ngClass]="{ 'en-toast--flexable': flexable === true }">
                <span class="en-toast-icon en"
                      [ngClass]="{
								'en-check-circle-o':
									message.severity === 'success',
								'en-info-circle-o':
									message.severity === 'info' ||
									message.severity === 'error',
								'en-exclamation-triangle-o':
									message.severity === 'warn',
								'en-help-circle-o':
									message.severity === 'question'
                                    }"></span>
                <span class="en-toast-summary-text">{{ message.summary }}</span>

                <div class="en-toast-detail-text">
                    {{ message.detail }}
                </div>
                <button class="en-toast-closed en en-close"
                        [ngClass]="'en-toast-closed--' + message.severity"
                        (click)="onCloseIconClick($event)"
                        (keydown.enter)="onCloseIconClick($event)"
                        *ngIf="message.closable !== false"></button>
            </div>
        </ng-container>
        <ng-container *ngTemplateOutlet="
            template;
            context: { $implicit: message }
        "></ng-container>
    </div>
</div>
