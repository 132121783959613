import { DateAccountTimezoneService } from '@enkod-core/services';
import { MAX_REQUEST_POINTS, UnixPeriod } from '../constants';
import { GroupOptions } from '../models';

export function getStartDate(
	endDate: number,
	group: GroupOptions = 'day',
	timezoneService: DateAccountTimezoneService
) {
	const endDateAccountTz = +(
		timezoneService.toUTCTime(endDate / 1000) * 1000
	).toFixed();

	switch (group) {
		case 'month': {
			const date = new Date(endDateAccountTz);

			const monthDate = new Date(
				`${date.getFullYear() - MAX_REQUEST_POINTS / 12}/${
					date.getMonth() + 1
				}/01`
			).getTime();

			const timeUtc = timezoneService
				? timezoneService?.toUTCTime(monthDate / 1000) * 1000
				: monthDate;

			return timeUtc;
		}

		case 'week': {
			const date = new Date(
				endDateAccountTz -
					MAX_REQUEST_POINTS * Number(UnixPeriod[group])
			);
			const day = date.getDay(); // 👉️ get day of week

			// 👇️ day of month - day of week (-6 if Sunday), otherwise +1
			const diff = date.getDate() - day + (day === 0 ? -6 : 1);

			return new Date(date.setDate(diff)).getTime();
		}
		case 'day':
		default: {
			return (
				endDateAccountTz -
				MAX_REQUEST_POINTS * Number(UnixPeriod[group])
			);
		}
	}
}
