export type EnStatusT =
	| 'default'
	| 'message'
	| 'messageSplit'
	| 'messageBot'
	| 'primary'
	| 'custom'
	| 'success'
	| 'error'
	| 'warning'
	| 'info'
	| 'question'
	| 'pause'
	| 'loading';
