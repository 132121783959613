import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TuiHostedDropdownModule, TuiWrapperModule } from '@taiga-ui/core';

import {
	TuiFocusableModule,
	TuiFocusedModule,
	TuiHoveredModule
} from '@taiga-ui/cdk';

import { TuiScrollbarModule } from '@enkod-core/components';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EnButtonModule } from 'ui-lib/button/button';
import { EnTextAreaComponent } from './text-area.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TuiFocusableModule,
		TuiFocusedModule,
		TuiHoveredModule,
		TuiScrollbarModule,
		TuiWrapperModule,
		TuiHostedDropdownModule,
		EnButtonModule,
		PickerModule
	],
	declarations: [EnTextAreaComponent],
	exports: [EnTextAreaComponent]
})
export class EnTextAreaModule {}
