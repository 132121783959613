import { Pipe, PipeTransform } from '@angular/core';
import { TagsValue } from '@enkod-core/enums';

@Pipe({
	name: 'systemTag'
})
export class SystemTagPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case TagsValue.SPLIT_TEST:
				return 'A/B';
			case TagsValue.BOT:
				return 'tags.sys_tag_bot';
			default:
				return value;
		}
	}
}
