import { Renderer2 } from '@angular/core';
import { iconToggle } from '../../../icons';

export function createMenuButton(renderer: Renderer2): HTMLElement {
	const menuButton = renderer.createElement('div');
	renderer.addClass(menuButton, 'enkod-menu-button');

	renderer.setProperty(menuButton, 'innerHTML', iconToggle);

	return menuButton;
}
