import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	TuiHoveredModule,
	TuiLetModule,
	TuiMapperPipeModule,
	TuiPressedModule,
	TuiRepeatTimesModule
} from '@taiga-ui/cdk';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { TuiScrollIntoViewModule } from '@taiga-ui/core/directives/scroll-into-view';
import { HorizontalScrollWrapperModule } from 'ui-lib/horizontal-scroll-wrapper/horizontal-scroll-wrapper.module';
import { PrimitiveRangePickerComponent } from './primitive-range-picker.component';
import { EnRangeTagModule } from './range-tag/range-tag.module';

@NgModule({
	imports: [
		CommonModule,
		TuiHoveredModule,
		TuiPressedModule,
		TuiRepeatTimesModule,
		TuiMapperPipeModule,
		TuiLetModule,
		TuiScrollIntoViewModule,
		TuiScrollbarModule,
		TranslateModule,
		HorizontalScrollWrapperModule,
		EnRangeTagModule
	],
	declarations: [PrimitiveRangePickerComponent],
	exports: [PrimitiveRangePickerComponent]
})
export class EnPrimitiveRangePickerModule {}
