import { TuiDay, TuiTimeMode } from '@taiga-ui/cdk';
import { TuiTextMaskOptions } from '@taiga-ui/core';
import { DATE_MASK, DATE_TIME_MASK, TIME_MASK } from '../calendar.model';
import { tuiCreateAutoCorrectedDateTimePipe } from '../pipes/calendar-date-time.pipe';
import { tuiCreateAutoCorrectedDatePipe } from '../pipes/calendar-date.pipe';
import { tuiCreateAutoCorrectedTimePipe } from '../pipes/calendar-time.pipe';

export function calculateMaskDate(
	day: TuiDay | null,
	min: TuiDay,
	max: TuiDay
): TuiTextMaskOptions {
	return {
		mask: [...DATE_MASK],
		pipe: tuiCreateAutoCorrectedDatePipe({ value: day, min, max }),
		guide: false
	};
}

export function calculateMaskDateTime(
	day: TuiDay | null,
	min: TuiDay,
	max: TuiDay,
	timeMode: TuiTimeMode
): TuiTextMaskOptions {
	return {
		mask: [...DATE_TIME_MASK],
		pipe: tuiCreateAutoCorrectedDateTimePipe(
			{ value: day, min, max },
			timeMode
		),
		guide: false
	};
}

export function calculateMaskTime(timeMode: TuiTimeMode): TuiTextMaskOptions {
	return {
		mask: [...TIME_MASK],
		pipe: tuiCreateAutoCorrectedTimePipe(timeMode),
		guide: false
	};
}
