import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { TuiTextMaskList, TUI_DIGIT_REGEXP } from '@taiga-ui/core';

export type DateTimeRange = [
	{ date: TuiDay; time: TuiTime },
	{ date: TuiDay; time: TuiTime }
];

export const nullDateRange: DateTimeRange = [
	{ date: new TuiDay(0, 0, 1), time: TuiTime.currentLocal() },
	{ date: new TuiDay(0, 0, 1), time: TuiTime.currentLocal() }
];

export const DATE_MASK: TuiTextMaskList = [
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP
];

export const DATE_MASK_RANGE: TuiTextMaskList = [
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'—',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP
];

export const DATE_TIME_MASK_RANGE: TuiTextMaskList = [
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	',',
	' ',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	':',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	' ',
	'-',
	' ',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	',',
	' ',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	':',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP
];
export type DateRangeName = { dateValue: number; name: string };

export const LENGTH_DATE_RANGE = 22;
export const LENGTH_DATE_TIME_RANGE = 37;

export const itemsLast = [
	{ dateValue: 0, name: 'date-range-calendar.today' },
	{ dateValue: 7, name: 'date-range-calendar.last_days' },
	{ dateValue: 0, name: 'date-range-calendar.last_week' },
	{ dateValue: 30, name: 'date-range-calendar.last_days' },
	{ dateValue: 0, name: 'date-range-calendar.last_month' },
	{ dateValue: 12, name: 'date-range-calendar.last_months' },
	{ dateValue: 0, name: 'date-range-calendar.last_year' }
];

export const itemsFuture = [
	{ dateValue: 0, name: 'date-range-calendar.today' },
	{ dateValue: 7, name: 'date-range-calendar.future_days' },
	{ dateValue: 0, name: 'date-range-calendar.future_week' },
	{ dateValue: 30, name: 'date-range-calendar.future_days' },
	{ dateValue: 0, name: 'date-range-calendar.future_month' },
	{ dateValue: 12, name: 'date-range-calendar.future_months' },
	{ dateValue: 0, name: 'date-range-calendar.future_year' }
];
