import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnToggleButtonComponent } from './togglebutton.component';

@NgModule({
	imports: [CommonModule],
	declarations: [EnToggleButtonComponent],
	exports: [EnToggleButtonComponent]
})
export class EnToggleButtonModule {}
