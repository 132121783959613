import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable()
export class EditExtafieldNameServiceForm {
	_form = this.fb.group({
		id: 0,
		name: ['', Validators.required],
		serviceName: '',
		dataType: '',
		description: '',
		value: ''
	});

	constructor(private fb: UntypedFormBuilder) {}

	get form() {
		return this._form;
	}
}
