import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';

import { AuthQuery } from '../authentication/_state/auth.query';

@Injectable()
export class IsSmtpGuard implements CanActivate, CanLoad {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const { isSmtpOnly } = this.authQuery;
		if (isSmtpOnly) this.router.navigateByUrl('ensend/smtp-statistic');
		return !isSmtpOnly;
	}

	canLoad(): boolean {
		const { isSmtpOnly } = this.authQuery;
		if (isSmtpOnly) this.router.navigateByUrl('ensend/smtp-statistic');
		return !isSmtpOnly;
	}
}
