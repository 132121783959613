import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[enTemplate]'
})
export class EnTempalateDirective {
	constructor(public template: TemplateRef<any>) {}

	@Input() enTemplate: string;

	getType(): string {
		return this.enTemplate;
	}
}
