import { DateAccountTimezoneService } from '@enkod-core/services';
import { GroupOptions } from '../models';

export function getTodayTimestamp(
	group: GroupOptions = 'day',
	timezoneService?: DateAccountTimezoneService
) {
	const date = new Date();

	const today = new Date(
		`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
	);

	switch (group) {
		case 'month': {
			const monthDate = new Date(
				`${today.getFullYear()}/${today.getMonth() + 1}/01`
			).getTime();

			const timeUtc = timezoneService
				? timezoneService?.toUTCTime(monthDate / 1000) * 1000
				: monthDate;

			return timeUtc;
		}
		case 'week': {
			const day = today.getDay(); // 👉️ get day of week

			// 👇️ day of month - day of week (-6 if Sunday), otherwise +1
			const diff = today.getDate() - day + (day === 0 ? -6 : 1);

			const timeUtc = timezoneService
				? timezoneService?.toUTCTime(
						new Date(today.setDate(diff)).getTime() / 1000
				  ) * 1000
				: new Date(today.setDate(diff)).getTime();

			return timeUtc;
		}
		case 'day':
		default: {
			const todayUtc = timezoneService
				? timezoneService.toUTCTime(today.getTime() / 1000) * 1000
				: today.getTime();

			return todayUtc;
		}
	}
}
