/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { NgModule, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive({
	selector: '[enInput]',
	host: {
		class: 'en-input'
	}
})
export class InputDirective {}

@NgModule({
	imports: [CommonModule],
	exports: [InputDirective],
	declarations: [InputDirective]
})
export class EnInputModule {}
