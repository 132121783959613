import { Pipe, PipeTransform } from '@angular/core';
import { Channel } from '@state-enKod/mailing-groups';
import { MessageType } from '@enSend/message/_states/_state-message';

@Pipe({
	name: 'typeIcon'
})
export class TypeIconPipe implements PipeTransform {
	transform(value: MessageType | Channel): string {
		switch (value) {
			case 'mail':
			case 'email':
				return 'en en-email';

			case 'push':
				return 'en en-push';

			case 'mobPush':
				return 'en en-mobpush';

			case 'sms':
			case 'phone':
				return 'en en-sms';

			case 'whatsapp':
				return 'en en-whatsapp';

			case 'telegram':
				return 'en en-telegram';

			default:
				return value;
		}
	}
}
