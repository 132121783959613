import { Pipe, PipeTransform } from '@angular/core';
import { EnStatusT } from 'ui-lib/types';

@Pipe({
	name: 'alertIcon'
})
export class AlertIconPipe implements PipeTransform {
	transform(status: EnStatusT): string {
		switch (status) {
			case 'success':
				return 'en-check-circle-o';
			case 'error':
				return 'en-exclamation-circle-o';
			case 'warning':
				return 'en-exclamation-triangle-o';
			case 'info':
				return 'en-info-circle-o';
			case 'question':
				return 'en-help-circle-o';

			default:
				return status;
		}
	}
}
