import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TippyModule } from '@ngneat/helipopper';

import { SharedModule } from '@shared';

import { NavButtonComponent, StepperNavigatorComponent } from './components';
import { EnButtonModule } from '../button/button';
import { EnStepperModule } from '../stepper/en-stepper.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnButtonModule,
		EnStepperModule,
		TippyModule
	],
	declarations: [StepperNavigatorComponent, NavButtonComponent],
	exports: [StepperNavigatorComponent, NavButtonComponent]
})
export class StepperNavigatorModule {}
