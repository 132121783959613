<div class="en-field test-body">
    <span>{{config.bodyDescription | translate}}</span>
    <en-autoComplete class="main-input"
                     [formControl]="inputControl"
                     [suggestions]="filteredNumbersMultiple"
                     [placeholder]="config.placeholder | translate"
                     [mask]="config.inputMask"
                     [dropdown]="true"
                     [multiple]="true"
                     appendTo="body"
                     (completeMethod)="filterNumberMultiple($event)"
                     (keyup.enter)="onAdd($event)"></en-autoComplete>
    <en-error [formControl]="inputControl"></en-error>
</div>
