import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'monthTranslate'
})
export class MonthTranslatePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}
	transform(value: string) {
		const splited = value.split(' ');
		switch (splited[1]) {
			case 'января':
				return this.translate.instant(
					'contacts_base_table_month.january',
					{
						year: splited[0]
					}
				);
			case 'февраля':
				return this.translate.instant(
					'contacts_base_table_month.february',
					{
						year: splited[0]
					}
				);
			case 'марта':
				return this.translate.instant(
					'contacts_base_table_month.march',
					{
						year: splited[0]
					}
				);
			case 'апреля':
				return this.translate.instant(
					'contacts_base_table_month.april',
					{
						year: splited[0]
					}
				);
			case 'мая':
				return this.translate.instant('contacts_base_table_month.may', {
					year: splited[0]
				});
			case 'июня':
				return this.translate.instant(
					'contacts_base_table_month.june',
					{
						year: splited[0]
					}
				);
			case 'июля':
				return this.translate.instant(
					'contacts_base_table_month.july',
					{
						year: splited[0]
					}
				);
			case 'августа':
				return this.translate.instant(
					'contacts_base_table_month.august',
					{
						year: splited[0]
					}
				);
			case 'сентября':
				return this.translate.instant(
					'contacts_base_table_month.september',
					{
						year: splited[0]
					}
				);
			case 'октября':
				return this.translate.instant(
					'contacts_base_table_month.october',
					{
						year: splited[0]
					}
				);
			case 'ноября':
				return this.translate.instant(
					'contacts_base_table_month.november',
					{
						year: splited[0]
					}
				);
			case 'декабря':
				return this.translate.instant(
					'contacts_base_table_month.december',
					{
						year: splited[0]
					}
				);
			default:
				return value;
		}
	}
}
