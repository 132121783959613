<div *tuiLet="titleTextContent$ | async as titleTextContent"
     [class.wrapper-margin]="titleTextContent?.length">
    <ng-container>
        <span *ngIf="titleTextContent?.length"
              class="message__title">{{ titleTextContent }}</span>
    </ng-container>
    <ng-container *tuiLet="titleImageUrl$ | async as titleImageUrl">
        <img *ngIf="titleImageUrl"
             class="message__image"
             [src]="titleImageUrl">
    </ng-container>
    <ng-container *tuiLet="titleDocumentName$ | async as titleDocumentName">
        <div *ngIf="titleDocumentName"
             class="message__document">
            <div class="document__footer">
                <i class="en en-list-o document-icon"></i>
                <span class="document__title">{{ titleDocumentName }}</span>
            </div>
        </div>
    </ng-container>
    <ng-container *tuiLet="titleVideoUrl$ | async as titleVideoUrl">
        <div *ngIf="titleVideoUrl"
             class="message__video">
            <img src="/assets/images/whatsapp/template-video.jpg">
        </div>
    </ng-container>
</div>