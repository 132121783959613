import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import { Query, ID } from '@datorama/akita';
import { AccountModel } from '@state-admin/accounts';
import { AuthStore, AuthState } from './auth.store';
import { Access } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
	constructor(protected store: AuthStore) {
		super(store);
	}

	currentId$: Observable<ID | null> = this.select(
		state => state.currentAccountId
	);

	accounts$: Observable<AccountModel[]> = this.select(
		state => state.accounts
	);

	currentAccount$: Observable<AccountModel | undefined> =
		this.currentId$.pipe(
			switchMap(id =>
				this.accounts$.pipe(
					map(accounts => accounts.find(account => account.id === id))
				)
			)
		);

	currentAccountDisplayName$: Observable<string> = this.currentAccount$.pipe(
		filter((account): account is AccountModel => account !== undefined),
		map((account: AccountModel) => account.displayName)
	);

	currentAccountId$: Observable<ID> = this.currentAccount$.pipe(
		filter((account): account is AccountModel => account !== undefined),
		map((account: AccountModel) => account.id)
	);

	isAdmin$: Observable<boolean> = this.select(
		state => state.user?.role === 'admin'
	);

	user$ = this.select(state => state.user);

	isAccessEnpop$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.POPUP)
	);

	isAccessEnsend$: Observable<boolean> = this.select(
		state =>
			state.access.includes(Access.MAIL) ||
			state.access.includes(Access.SMS) ||
			state.access.includes(Access.WEBPUSH) ||
			state.access.includes(Access.WHATSAPP)
	);

	isAccessEnrecom$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.ENRECOM)
	);

	isAccessTables$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.DATATABLES)
	);

	isAccessMobPush$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.MOBPUSH)
	);

	isAccessWebPush$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.WEBPUSH)
	);

	isAccessWhatsApp$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.WHATSAPP)
	);

	isAccessTracking$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.TRACKING)
	);

	isAccessMail$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.MAIL)
	);

	isAccessSms$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.SMS)
	);

	isAccessSql$: Observable<boolean> = this.isAccessTables$;

	isSmtp$: Observable<boolean> = this.select(state =>
		state.access.includes(Access.SMTP)
	);

	isSmtpOnly$: Observable<boolean> = this.select(
		state =>
			state.access.includes(Access.SMTP) &&
			!state.access.includes(Access.SMTPINTERFACE)
	);

	servicesAvaible$: Observable<{
		enPop: boolean;
		enSend: boolean;
		enRecom: boolean;
	}> = combineLatest([
		this.isAccessEnpop$,
		this.isAccessEnsend$,
		this.isAccessEnrecom$
	]).pipe(
		map(([enPop, enSend, enRecom]) => ({
			enPop,
			enSend,
			enRecom
		}))
	);

	get jwtToken(): string {
		return this.getValue().accessToken;
	}

	get isLoggedIn(): boolean {
		return !!this.getValue().accessToken;
	}

	get isAdmin(): boolean {
		const { user } = this.getValue();
		return user?.role === 'admin';
	}

	get isAccessEnpop(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.POPUP);
	}

	get isAccessEnrecom(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.ENRECOM);
	}

	get isAccessEnsend(): boolean {
		const { access } = this.getValue();
		return (
			access.includes(Access.MAIL) ||
			access.includes(Access.SMS) ||
			access.includes(Access.WEBPUSH) ||
			access.includes(Access.WHATSAPP)
		);
	}

	get isAccessTables(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.DATATABLES);
	}

	get isAccessMail(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.MAIL);
	}

	get isAccessSms(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.SMS);
	}

	get isAccessWebPush(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.WEBPUSH);
	}

	get isAccessWhatsApp(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.WHATSAPP);
	}

	get isAccessMobPush(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.MOBPUSH);
	}

	get isAccessTracking(): boolean {
		const { access } = this.getValue();
		return access.includes(Access.TRACKING);
	}

	get isAccessSql(): boolean {
		return this.isAccessTables;
	}

	get currentEmail() {
		return this.getValue().user?.login;
	}

	get currentAccountId() {
		return this.getValue().currentAccountId;
	}

	get currentAccount() {
		return this.getValue().accounts.find(
			account => account.id === this.currentAccountId
		);
	}

	get currentAccountDisplayName() {
		return this.currentAccount?.displayName || '';
	}

	get currentUserId() {
		return this.getValue().user?.userId;
	}

	get accountTimezone() {
		return this.getValue().timeZone;
	}

	get enableCustomId() {
		return this.getValue().enableCustomId;
	}

	get isSmtp() {
		const { access } = this.getValue();
		return access.includes(Access.SMTP);
	}

	get isSmtpOnly() {
		const { access } = this.getValue();
		return this.isSmtp && !access.includes(Access.SMTPINTERFACE);
	}
}
