import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface EnsendSharedState {
	domains: string[] | null;
	tags: string[] | null;
	sendingDomains: string[] | null;
}

export function createInitialState(): EnsendSharedState {
	return {
		domains: null,
		tags: null,
		sendingDomains: null
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ensend-shared' })
export class EnsendSharedStore extends Store<EnsendSharedState> {
	constructor() {
		super(createInitialState());
	}
}
