import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

@Component({
	selector: 'en-calendar-new',
	templateUrl: './calendar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnCalendarComponent {
	@Input() type: string;

	@Output() onSelect = new EventEmitter();
}
