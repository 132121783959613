import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { AlertIconPipe } from './alert-icon.pipe';

@NgModule({
	imports: [CommonModule, TranslateModule],
	declarations: [AlertComponent, AlertIconPipe],
	exports: [AlertComponent]
})
export class AlertModule {}
