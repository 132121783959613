import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CustomLoader } from '@enkod-core/utils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MISSING_TRANSLATION_HANDLER } from './missing-translation-handler';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomLoader,
				deps: [HttpClient]
			},
			...MISSING_TRANSLATION_HANDLER
		})
	]
})
export class EnTranslateModule {}
