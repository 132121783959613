import { inject } from '@angular/core';
import {
	DEFAULT_ID_KEY,
	EntityState,
	EntityStore,
	PaginationResponse,
	PaginatorPlugin,
	QueryEntity
} from '@datorama/akita';
import { UrlSaverService } from '@enkod-core/services';
import { Observable } from 'rxjs';
import { paginationDataFactory, PaginationService } from 'ui-lib';

interface AbstractTab<S> {
	assingDataStream: (service: S) => void;
}

/**
 * Обязателен вызов метода assingDataStream
 *  @example
 * class ClassName extends Tab<T, S> {
 *      constructor() {
 *          super("storeName")
 *          super.assingDataStream(serviceForFactory)
 *      }
 * }
 */
export abstract class Tab<T, S> implements AbstractTab<S> {
	protected readonly store: EntityStore;
	readonly query: QueryEntity<EntityState<T>>;
	readonly paginationService: PaginationService;
	readonly paginationRef: PaginatorPlugin<EntityState>;
	data$: Observable<PaginationResponse<T>>;

	constructor(storeName: string, idKey: string = DEFAULT_ID_KEY) {
		this.store = new EntityStore({}, { name: storeName, idKey });
		this.query = new QueryEntity<EntityState>(this.store);

		const urlService = inject(UrlSaverService);
		this.paginationService = new PaginationService(urlService);

		this.paginationRef = new PaginatorPlugin(this.query)
			.withControls()
			.withRange();
	}

	assingDataStream(factoryService: S) {
		this.data$ = paginationDataFactory(
			this.paginationRef,
			this.paginationService,
			factoryService
		);
	}
}
