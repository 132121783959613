import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PhoneModel } from '@enSend/message/message-wizard/message-wizard-common/models/view-mail.model';

@Component({
	selector: 'en-mobile-mock',
	template: `
		<en-iphone-view *ngIf="iphone" [isDetail]="isDetail">
			<ng-content></ng-content>
		</en-iphone-view>
	`,
	styleUrls: ['./mobile-mock.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMockComponent {
	colorMain: string;
	colorBg: string;

	get iphone() {
		return this.phoneModel === 'iphone';
	}

	@Input() isDetail: boolean;
	@Input() phoneModel: PhoneModel = 'iphone';
}
