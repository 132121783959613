import { Injectable, Renderer2 } from '@angular/core';
import { concat, fromEvent, NEVER, timer } from 'rxjs';
import {
	distinctUntilChanged,
	map,
	switchMap,
	takeUntil
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './data.service';
import { getClicksPercent, getElementId, getUniqClicksPercent } from '../utils';

@Injectable()
export class TooltipService {
	private tooltipeShowen = false;

	constructor(
		private renderer: Renderer2,
		private data: DataService,
		private translate: TranslateService
	) {}

	showTooltip(elem: HTMLElement): void {
		if (this.tooltipeShowen) return;
		this.tooltipeShowen = true;
		const tooltip = this.renderer.createElement('div');
		const statisticCircle = elem.lastElementChild as HTMLElement;
		const id = getElementId(elem);
		const details = this.data.allLinksData[id];
		this.renderer.addClass(tooltip, 'enkod-statistics');

		const firstItem = this.createStatisticsItem(
			this.translate.instant('clicks_map.tooltip_clicks'),
			details?.clicks
		);
		const secondtItem = this.createStatisticsItem(
			this.translate.instant('clicks_map.tooltip_from_general'),
			getClicksPercent(details?.clicks, this.data.allClicks),
			true
		);
		const thirdItem = this.createStatisticsItem(
			this.translate.instant('clicks_map.tooltip_uniq_clicks'),
			details?.uniqClicks
		);
		const fourthtItem = this.createStatisticsItem(
			this.translate.instant('clicks_map.tooltip_from_uniqs'),
			getUniqClicksPercent(details?.uniqClicks, this.data.allClicks),
			true
		);

		const arrow = this.renderer.createElement('div');
		this.renderer.addClass(arrow, 'enkod-statistics-arrow');

		const clientRect = statisticCircle.getBoundingClientRect();
		const tooltipWidth = 310;
		const tooltipHeight = 96;
		const tooltipMargin = 25;
		const clientRectCenter =
			(clientRect.right - clientRect.left) / 2 + clientRect.left;

		const spaceBottom = this.data.clientHeight - clientRect.bottom;
		const spaceRight = this.data.clientWidth - clientRectCenter;

		let direction = 'bottom';
		switch (true) {
			case spaceBottom < tooltipHeight + tooltipMargin: {
				// снизу недостаточно места
				direction = 'top';
				this.renderer.addClass(tooltip, 'enkod-statistics-top');
				this.renderer.addClass(arrow, 'enkod-statistics-arrow-top');
				break;
			}
			default:
				this.renderer.addClass(tooltip, 'enkod-statistics-bottom');
				this.renderer.addClass(arrow, 'enkod-statistics-arrow-bottom');
				break;
		}
		if (spaceRight - tooltipWidth / 2 < 0) {
			// если справа недостаточно места
			this.renderer.setAttribute(
				tooltip,
				'style',
				`left: ${
					statisticCircle.clientWidth / 2 +
					(spaceRight - tooltipWidth / 2)
				}px`
			);
			this.renderer.setAttribute(
				arrow,
				'style',
				`left: ${tooltipWidth / 2 - (spaceRight - tooltipWidth / 2)}px`
			);
		}

		if (clientRectCenter - tooltipWidth / 2 < 0) {
			// если слева недостаточно места
			this.renderer.setAttribute(
				tooltip,
				'style',
				`left: ${
					statisticCircle.clientWidth / 2 -
					(clientRectCenter - tooltipWidth / 2)
				}px`
			);
			this.renderer.setAttribute(
				arrow,
				'style',
				`left: ${
					tooltipWidth / 2 + (clientRectCenter - tooltipWidth / 2)
				}px`
			);
		}

		this.renderer.appendChild(tooltip, arrow);
		this.renderer.appendChild(tooltip, firstItem);
		this.renderer.appendChild(tooltip, secondtItem);
		this.renderer.appendChild(tooltip, thirdItem);
		this.renderer.appendChild(tooltip, fourthtItem);

		this.renderer.appendChild(statisticCircle, tooltip);

		const hideTooltip = (tooltip: HTMLElement) => {
			direction === 'bottom'
				? this.renderer.addClass(
						tooltip,
						'enkod-statistics-hide-bottom'
				  )
				: this.renderer.addClass(tooltip, 'enkod-statistics-hide-top');
			setTimeout(() => tooltip.remove(), 150);
			this.tooltipeShowen = false;
			mouseleaveListener.unsubscribe();
		};

		const mouseenter$ = fromEvent(elem, 'mouseenter');
		const mouseleaveListener = fromEvent(elem, 'mouseleave')
			.pipe(
				switchMap(() =>
					concat(timer(220), NEVER).pipe(
						map(() => hideTooltip(tooltip)),
						takeUntil(mouseenter$)
					)
				),
				distinctUntilChanged()
			)
			.subscribe();
	}

	private createStatisticsItem(
		title: string,
		value = 0,
		percent = false
	): HTMLElement {
		const statisticsItem = this.renderer.createElement('div');
		this.renderer.addClass(statisticsItem, 'enkod-statistics__item');
		if (percent) this.renderer.addClass(statisticsItem, 'enkod-color-grey');

		const titleSpan = this.renderer.createElement('span');
		const titleText = this.renderer.createText(title);
		this.renderer.appendChild(titleSpan, titleText);

		const valueSpan = this.renderer.createElement('span');
		const textWithPercent = percent ? `${value}%` : `${value}`;
		const valueText = this.renderer.createText(textWithPercent);
		this.renderer.appendChild(valueSpan, valueText);

		this.renderer.appendChild(statisticsItem, titleSpan);
		this.renderer.appendChild(statisticsItem, valueSpan);

		return statisticsItem;
	}
}
