/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable */
import {
	Input,
	Output,
	AfterViewInit,
	OnDestroy,
	ElementRef,
	ViewChild,
	EventEmitter,
	TemplateRef,
	NgZone,
	Component,
	ViewEncapsulation,
	ChangeDetectionStrategy
} from '@angular/core';

interface Message {
	severity: string;
	summary?: string;
	detail?: string;
	id?: any;
	key?: string;
	life?: number;
	sticky?: boolean;
	closable?: boolean;
	data?: any;
}

import {
	trigger,
	state,
	style,
	transition,
	animate
} from '@angular/animations';
@Component({
	selector: 'en-toastItem',
	templateUrl: './toast-item.html',
	animations: [
		trigger('messageState', [
			state(
				'visible',
				style({
					transform: 'translateY(0)',
					opacity: 1
				})
			),
			transition('void => *', [
				style({ transform: '{{showTransformParams}}', opacity: 0 }),
				animate('{{showTransitionParams}}')
			]),
			transition('* => void', [
				animate(
					'{{hideTransitionParams}}',
					style({
						height: 0,
						opacity: 0,
						transform: '{{hideTransformParams}}'
					})
				)
			])
		])
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnToastItem implements AfterViewInit, OnDestroy {
	@Input() message: Message;

	@Input() index: number;

	@Input() template: TemplateRef<any>;

	@Input() showTransformOptions: string;

	@Input() hideTransformOptions: string;

	@Input() showTransitionOptions: string;

	@Input() hideTransitionOptions: string;

	@Input() flexable: boolean = false;

	@Output() onClose: EventEmitter<any> = new EventEmitter();

	@ViewChild('container') containerViewChild: ElementRef;

	timeout: any;

	constructor(private zone: NgZone) {}

	ngAfterViewInit() {
		this.initTimeout();
	}

	initTimeout() {
		if (!this.message.sticky) {
			this.zone.runOutsideAngular(() => {
				this.timeout = setTimeout(() => {
					this.onClose.emit({
						index: this.index,
						message: this.message
					});
				}, this.message.life || 3000);
			});
		}
	}

	clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	onMouseEnter() {
		this.clearTimeout();
	}

	onMouseLeave() {
		this.initTimeout();
	}

	onCloseIconClick(event: any) {
		this.clearTimeout();

		this.onClose.emit({
			index: this.index,
			message: this.message
		});

		event.preventDefault();
	}

	ngOnDestroy() {
		this.clearTimeout();
	}
}
