import { DATE_FILLER_LENGTH, TuiDay } from '@taiga-ui/cdk';
import {
	TuiTextMaskPipeHandler,
	TuiWithOptionalMinMaxWithValue
} from '@taiga-ui/core';
import { normalizeDateValue } from './calendar-date-time.pipe';

export function tuiCreateAutoCorrectedDateRangePipe(
	config: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay>
): TuiTextMaskPipeHandler {
	return value => {
		if (value.length < DATE_FILLER_LENGTH) {
			return { value };
		}

		const formattedDate = normalizeDateValue(
			value.substring(0, 10),
			config
		);
		if (value.length < 21) {
			return { value: formattedDate + value.substring(10) };
		}
		const configTo: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay> =
			{
				value: config.value,
				min: TuiDay.normalizeParse(formattedDate),
				max: config.max
			};
		const formattedDateTo = normalizeDateValue(
			value.substring(11, 21),
			configTo
		);
		return { value: `${formattedDate}—${formattedDateTo}` };
	};
}
