import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'rfmTagName'
})
export class RfmTagNamePipe implements PipeTransform {
	transform(tag: string): string {
		switch (tag) {
			case 'champions':
				return 'rf_chart.champions_option';
			case 'loyal':
				return 'rf_chart.loyal_option';
			case 'potential_loyal':
				return 'rf_chart.maybe_loyal_option';
			case 'new_customers':
				return 'rf_chart.beginners_option';
			case 'promising':
				return 'rf_chart.promising_option';
			case 'need_attention':
				return 'rf_chart.attention_option';
			case 'about_to_sleep':
				return 'rf_chart.asleep_soon_option';
			case 'cannot_loose':
				return 'rf_chart.no_lose_option';
			case 'at_risk':
				return 'rf_chart.at_risk_option';
			case 'sleeping':
				return 'rf_chart.sleeping_option';
			default:
				return tag;
		}
	}
}
