import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IphoneViewComponent } from './iphone-view.component';

@NgModule({
	imports: [CommonModule],
	declarations: [IphoneViewComponent],
	exports: [IphoneViewComponent]
})
export class EnIphoneViewModule {}
