import {
	Component,
	ChangeDetectionStrategy,
	Input,
	HostBinding
} from '@angular/core';
import { tuiDefaultProp } from '@taiga-ui/cdk';
import { EnStatusT } from 'ui-lib/types';

@Component({
	selector: 'en-status-tag',
	templateUrl: './status-tag.component.html',
	styleUrls: ['./status-tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusTagComponent {
	@Input() label = '';

	@Input() showLoader = false;

	@Input() forceLoaderVisible = false;

	@Input()
	@tuiDefaultProp()
	showIcon = true;

	@Input()
	@HostBinding('attr.data-en-host-status')
	status: EnStatusT = 'default';

	get visibleLoader() {
		return this.showLoader && this.status === 'loading';
	}

	get displayText(): string {
		return this.label;
	}
}
