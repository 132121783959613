import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class AccessEnpopGuard implements CanActivate, CanLoad {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const isAccessEnpop = !!this.authQuery.isAccessEnpop;
		if (!isAccessEnpop) this.router.navigateByUrl('enpop/get-service');

		return isAccessEnpop;
	}

	canLoad() {
		const isAccessEnpop = !!this.authQuery.isAccessEnpop;
		if (!isAccessEnpop) this.router.navigateByUrl('enpop/get-service');

		return isAccessEnpop;
	}
}
