/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable max-classes-per-file */
import { Directive, HostListener } from '@angular/core';
import { EnStepper } from './en-stepper.directive';

@Directive({
	selector: '[nextStep]'
})
export class EnStepperButtonNext {
	constructor(private readonly stepper: EnStepper) {}

	@HostListener('click')
	handleClick() {
		this.stepper.next();
	}
}

@Directive({
	selector: '[previousStep]'
})
export class EnStepperButtonPrev {
	constructor(private readonly stepper: EnStepper) {}

	@HostListener('click')
	handleClick() {
		this.stepper.previous();
	}
}
