/* eslint-disable no-undef */
export function download(
	blobResponse: Blob | null,
	fileName: string | null,
	fileType: string = 'text/csv'
) {
	if (!blobResponse) return;
	const link = document.createElement('a');
	link.download = fileName || `default-name${new Date()}`;
	const blob = new Blob([blobResponse], { type: fileType });
	link.href = URL.createObjectURL(blob);
	link.click();
	URL.revokeObjectURL(link.href);
}
