import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input
} from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

const oneDay = 86340;

@Component({
	selector: 'en-dropdown-calendar',
	templateUrl: './dropdown-calendar.component.html',
	styleUrls: ['./dropdown-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownCalendarComponent),
			multi: true
		}
	]
})
export class DropdownCalendarComponent {
	@Input() class = ''; // применяется к dropdown
	@Input() styleClass = 'standart'; // прокидывается дальше в p-calendar
	@Input() dropdownDirection: 'top' | 'bottom' = 'bottom';
	@Input() dropdownAlign: 'left' | 'right' = 'left';

	calendarVisible = false;
	showClearIcon = false;
	today: Date = new Date();
	dateRange = this.fb.control([this.today, null]);

	constructor(private fb: UntypedFormBuilder) {}

	get dropdownLabel() {
		return this.showClearIcon
			? 'date-range-calendar.during_the_period'
			: 'date-range-calendar.for_all_the_time';
	}

	writeValue(): void {}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	onSelect() {
		this.showClearIcon = true;

		if (this.isOneDateSelected()) {
			this.onChange([
				this.dateRange.value[0],
				this.dateRange.value[0] + oneDay
			]);
			return;
		}

		this.onChange(this.dateRange.value);
	}

	resetDateRange(event: Event) {
		this.showClearIcon = false;
		this.dateRange.reset([this.today, null]);
		this.onChange([null, null]);
		event.stopPropagation();
	}

	private isOneDateSelected(): boolean {
		return (
			!this.dateRange.value[1] ||
			this.dateRange.value[0] === this.dateRange.value[1]
		);
	}
}
