<div [ngClass]="'en-fileupload en-fileupload-advanced en-component'"
     [ngStyle]="style"
     [class]="styleClass"
     *ngIf="mode === 'advanced'">
    <div class="en-fileupload-buttonbar">
        <span class="en-button en-component en-fileupload-choose"
              [ngClass]="{
						'en-focus': focus,
						'en-disabled': disabled || isChooseDisabled()
					}"
              (focus)="onFocus()"
              (blur)="onBlur()"
              pRipple
              (click)="choose()"
              (keydown.enter)="choose()"
              tabindex="0">
            <input #advancedfileinput
                   type="file"
                   (change)="onFileSelect($event)"
                   [multiple]="multiple"
                   [accept]="accept"
                   [disabled]="disabled || isChooseDisabled()"
                   [attr.title]="''" />
            <span [ngClass]="'en-button-icon en-button-icon-left'"
                  [class]="chooseIcon"></span>
            <span class="en-button-label">{{ chooseLabel }}</span>
        </span>

        <en-button *ngIf="!auto && showUploadButton"
                   type="button"
                   [label]="uploadLabel"
                   [icon]="uploadIcon"
                   (onClick)="upload()"
                   [disabled]="!hasFiles() || isFileLimitExceeded()"></en-button>
        <en-button *ngIf="!auto && showCancelButton"
                   type="button"
                   [label]="cancelLabel"
                   [icon]="cancelIcon"
                   (onClick)="clear()"
                   [disabled]="!hasFiles() || uploading"></en-button>

        <ng-container *ngTemplateOutlet="toolbarTemplate"></ng-container>
    </div>
    <div #content
         class="en-fileupload-content"
         (dragenter)="onDragEnter($event)"
         (dragleave)="onDragLeave($event)"
         (drop)="onDrop($event)">
        <p-progressBar [value]="progress"
                       [showValue]="false"
                       *ngIf="hasFiles()"></p-progressBar>

        <p-messages [value]="msgs"
                    [enableService]="false"></p-messages>

        <div class="en-fileupload-files"
             *ngIf="hasFiles()">
            <div *ngIf="!fileTemplate">
                <div class="en-fileupload-row"
                     *ngFor="let file of files; let i = index">
                    <div>
                        <img [src]="file.objectURL"
                             *ngIf="isImage(file)"
                             [width]="previewWidth" />
                    </div>
                    <div>{{ file.name }}</div>
                    <div>{{ formatSize(file.size) }}</div>
                    <div>
                        <button type="button"
                                icon="pi pi-times"
                                pButton
                                (click)="remove($event, i)"
                                [disabled]="uploading"></button>
                    </div>
                </div>
            </div>
            <div *ngIf="fileTemplate">
                <ng-template ngFor
                             [ngForOf]="files"
                             [ngForTemplate]="fileTemplate"></ng-template>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="
						contentTemplate;
						context: { $implicit: files }
					"></ng-container>
    </div>
</div>
<div class="en-fileupload en-fileupload-basic en-component"
     *ngIf="mode === 'basic'">
    <span [ngClass]="{
					'en-button en-component en-fileupload-choose': true,
					'en-fileupload-choose-selected': hasFiles(),
					'en-focus': focus,
					'en-disabled': disabled
				}"
          [ngStyle]="style"
          [class]="styleClass"
          (mouseup)="onBasicUploaderClick()"
          (keydown)="onBasicUploaderClick()"
          tabindex="0"
          pRipple>
        <span class="en-button-icon en-button-icon-left pi"
              [ngClass]="hasFiles() && !auto ? uploadIcon : chooseIcon"></span>
        <span class="en-button-label">
            {{
            auto
            ? chooseLabel
            : hasFiles()
            ? files[0].name
            : chooseLabel
            }}
        </span>
        <input #basicfileinput
               type="file"
               [accept]="accept"
               [multiple]="multiple"
               [disabled]="disabled"
               (change)="onFileSelect($event)"
               *ngIf="!hasFiles()"
               (focus)="onFocus()"
               (blur)="onBlur()" />
    </span>
    <en-alert *ngIf="msgs && msgs.length"
              style="margin-top: 0.75rem;"
              [message]="msgs[0]?.summary || ''"
              status="warning"></en-alert>
</div>