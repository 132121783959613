import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SendTestData } from '../types';

@Injectable()
export class SendTestPhoneService {
	constructor(private http: HttpClient) {}

	getNumbers() {
		const channelPhone = {
			channel: 'phone'
		};
		const params = new HttpParams({ fromObject: channelPhone });
		return this.http.get('user/test_contacts/', { params });
	}

	postNumbers(numbers: SendTestData) {
		return this.http.post('user/test_contacts/', numbers);
	}
}
