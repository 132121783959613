import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StorageType, RoundingNumberType } from './model/storage-type';

@Component({
	selector: 'en-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
	@Input() storageSizes: StorageType;
	@Input() loading: boolean;
	@Input() isTextShowed = true;
	@Input() errorText: string;
	@Input() roundingNumbers: RoundingNumberType = {
		currRoundNum: 0,
		maxRoundNum: 0
	};
}
