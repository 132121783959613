import { InjectionToken, Provider } from '@angular/core';
import { Subject } from 'rxjs';
import { ClicksMapInterface } from '../clicks-map.model';

export const CLICKS_MAP_TOKEN = new InjectionToken('token for clicks map');

export const CLICKS_MAP_PROVIDER: Provider = [
	{
		provide: CLICKS_MAP_TOKEN,
		useValue: new Subject<ClicksMapInterface>()
	}
];
