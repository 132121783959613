import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinLoaderComponent } from './spin-loader.component';

@NgModule({
	imports: [CommonModule],
	declarations: [SpinLoaderComponent],
	exports: [SpinLoaderComponent]
})
export class SpinLoaderModule {}
