import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, finalize, tap } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';

import { NotificationsService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib';
import {
	ContactExtraFields,
	ContactsDetailStore
} from '../../_state/contacts-detail';

@Injectable({
	providedIn: 'root'
})
export class EditExtrafieldNameService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private storeContactsDetails: ContactsDetailStore,
		private http: HttpClient,
		private notificationsService: NotificationsService
	) {}

	updateField(extraField: ContactExtraFields) {
		this.loading$.next(true);
		return this.http
			.put(`enkod/extrafield/${extraField.id}`, extraField)
			.pipe(
				tap(() => {
					this.updateExtrafieldName(extraField);
					this.notificationsService
						.show('toast.detail_extra_field_edit', {
							label: 'extra_field_list.edit_success',
							status: NotificationStatus.SUCCESS,
							params: {
								id: extraField.id,
								name: extraField.name
							}
						})
						.subscribe();
				}),
				catchError(error => {
					this.notificationsService
						.show('toast.detail_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return throwError(error);
				}),
				finalize(() => {
					this.loading$.next(false);
				})
			);
	}

	private updateExtrafieldName(extraField: ContactExtraFields) {
		this.storeContactsDetails.update(undefined, state => {
			return {
				...state,
				extraFields: {
					...state.extraFields,
					[extraField?.serviceName]: {
						...state?.extraFields[extraField?.serviceName],
						name: extraField.name
					}
				} as ContactExtraFields
			};
		});
	}
}
