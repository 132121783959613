/* eslint-disable max-classes-per-file */
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	NgModule,
	Inject,
	Output,
	EventEmitter
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { SharedModule } from '@shared';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { EnDropdownModule } from '../dropdown';

@Component({
	selector: 'en-type-dropdown',
	template: `
		<en-dropdown
			class="h32 full-width"
			[placeholder]="'table_dropdown_datatype' | translate"
			[formControl]="control"
			[options]="options"
			appendTo="body"
			[disabled]="disabled"
			(onShow)="emitElement.emit($event.element)"
		>
			<ng-template pTemplate="selectedItem">
				<div *ngIf="selectedItem as item">
					<div class="flex-row">
						<span class="icon" [ngClass]="item.icon"></span>
						<span>{{ item.label | translate }}</span>
					</div>
				</div>
			</ng-template>
			<ng-template let-item pTemplate="item">
				<div class="flex-row">
					<span>{{ item.label | translate }}</span>
					<span class="icon" [ngClass]="item.icon"></span>
				</div>
			</ng-template>
		</en-dropdown>
	`,
	styles: [
		`
			.flex-row {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
			}

			.icon {
				margin-right: 0.5rem;
				color: var(--color-icon-secondary);
			}

			.full-width {
				width: 100%;
			}
		`
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeDropdownComponent {
	constructor(
		@Inject(SELECT_OPTIONS_TOKEN) public readonly options: SelectItem[]
	) {}

	@Input() control: UntypedFormControl;

	@Input() disabled = false;

	@Output() emitElement = new EventEmitter<HTMLElement>();

	get selectedItem(): SelectItem | undefined {
		return this.options.find(item => item.value === this.control.value);
	}
}

@NgModule({
	declarations: [TypeDropdownComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnDropdownModule,
		SharedModule
	],
	exports: [TypeDropdownComponent]
})
export class TypeDropdownModule {}
