export const DATA_TYPE_OPTIONS = [
	{
		label: 'events.label_number',
		value: 'number'
	},
	{
		label: 'events.label_float',
		value: 'float'
	},
	{
		label: 'events.label_text',
		value: 'text'
	},
	{
		label: 'events.label_boolean',
		value: 'bool'
	},
	{
		label: 'events.label_date',
		value: 'date'
	},
	{
		label: 'events.label_date_time',
		value: 'dateTime'
	}
];

export const DATA_TYPE_OPTIONS_WITH_SESSION = [
	{
		label: 'events.label_number',
		value: 'number'
	},
	{
		label: 'events.label_float',
		value: 'float'
	},
	{
		label: 'events.label_text',
		value: 'text'
	},
	{
		label: 'events.label_boolean',
		value: 'bool'
	},
	{
		label: 'events.label_date',
		value: 'date'
	},
	{
		label: 'events.label_date_time',
		value: 'dateTime'
	},
	{
		label: 'events.session',
		value: 'sessionId'
	}
];
