import {
	Directive,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output
} from '@angular/core';

@Directive({
	selector: '[enImgDef]'
})
export class ImgDefDirective {
	@Input()
	@HostBinding('src')
	src: string;

	@Input() fallback: string;

	@Output() isError = new EventEmitter();

	@HostListener('error')
	onError() {
		this.src = this.fallback;
	}
}
