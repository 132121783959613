import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

export interface IToastMessage {
	summary: string;
	detail: TToastDetail;
}

export type TToastDetail = {
	message: string;
	params?: Object;
};

@Injectable()
export class ToastService {
	constructor(
		private messageService: MessageService,
		private translate: TranslateService
	) {}

	success({ summary, detail }: IToastMessage) {
		this.messageService.add({
			key: 'globalToast',
			severity: 'success',
			summary: this.translate.instant(summary),
			detail: detail.params
				? this.translate.instant(detail.message, {
						...detail.params
				  })
				: this.translate.instant(detail.message),
			life: 3000
		});
	}

	warning({ detail, life = 60000 }: { detail: string; life?: number }) {
		this.messageService.add({
			key: 'globalToast',
			severity: 'warn',
			summary: 'warning',
			detail,
			life
		});
	}

	error({ summary, detail }: { summary: string; detail: string }) {
		this.messageService.add({
			key: 'globalToast',
			severity: 'error',
			summary: this.translate.instant(summary),
			detail: this.translate.instant(detail),
			life: 10000
		});
	}

	errorRequestToast(detail = ''): void {
		this.messageService.add({
			key: 'globalToast',
			severity: 'error',
			detail:
				detail || this.translate.instant('toast.detail_request_error'),
			summary: this.translate.instant('toast.summary_try_later'),
			life: 5000
		});
	}
}
