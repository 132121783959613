import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FormSubmitService {
	private _loading$ = new BehaviorSubject(Boolean(false));

	constructor(private http: HttpClient) {}

	get loading$() {
		return this._loading$.asObservable();
	}

	sendMessage(data: any) {
		this._loading$.next(true);
		return this.http
			.post('ensend/send/form/', data)
			.pipe(finalize(() => this._loading$.next(false)));
	}
}
