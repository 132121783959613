<en-dialog [header]="'message_wizard_edit_step.sendtest_title' | translate"
           styleClass="dialog-medium-width"
           [(visible)]="showTest"
           [modal]="true"
           (onHide)="closeModal.emit()">

    <ng-container [ngSwitch]="testType">
        <ng-template ngSwitchCase="email">
            <send-test-email (onChange)="changeContactList($event)"></send-test-email>
        </ng-template>
        <ng-template ngSwitchCase="phone">
            <send-test-phone (onChange)="changeContactList($event)"></send-test-phone>
        </ng-template>
    </ng-container>

    <p-footer>
        <button enButton
                class="h36 en-button-secondary en-mr-3"
                [label]="'message_wizard_edit_step.sendtest_button_back' | translate"
                (click)="closeModal.emit()">
        </button>
        <button enButton
                class="h36"
                [label]="'message_wizard_edit_step.sendtest_button_send' | translate"
                [disabled]="disabledCondition"
                [enLoading]="isLoading"
                (click)="sendTest()">
        </button>
    </p-footer>
</en-dialog>