import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationAlertComponent } from './notification-alert.component';
import { NotificationModule } from '../../notification.module';

@NgModule({
	imports: [
		CommonModule,
		PolymorpheusModule,
		NotificationModule,
		TranslateModule
	],
	declarations: [NotificationAlertComponent],
	exports: [NotificationAlertComponent]
})
export class NotificationAlertModule {}
