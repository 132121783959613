import { ID } from '@datorama/akita';
import { ChartColor, ChartDataSets, ChartPoint, Scriptable } from 'chart.js';

export type ChartData = {
	datasets: (ChartDataSets & {
		lineType: ChartLineType;
	})[];
	labels: string[];
	unixFromLabel: UnixFromLabel;
	isNewChart: boolean;
};

export type UnixFromLabel = { [key: string]: number };

export type GroupOptions = 'week' | 'day' | 'month';

export type ChartRenderData = {
	count: number | number[] | ChartPoint | null | undefined;
	color: ChartColor | ChartColor[] | Scriptable<ChartColor> | undefined;
};

export type BackendChartData = {
	statistic: BackendChartStatistic[];
};

export type BackendChartStatistic = {
	date: number;
	count: number;
};

export type LinesLabels = string[];
export type LinesLabelsInfoId = ID[];

export type MessageBackendChartData = BackendChartData & {
	messageId: number;
	name: string;
};

export type ChartLineType = 'noValues' | 'oneValue' | 'severalValues' | 'solid';
