import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { TuiTextMaskList, TUI_DIGIT_REGEXP } from '@taiga-ui/core';

export type DateTime = { date: TuiDay; time: TuiTime };

export const nullDate = new TuiDay(0, 0, 1);

export const yearMin = 1970;
export const yearMaxFromCurrent = 8;

export const DaysOfWeek: Array<string> = [
	'calendar.days_monday',
	'calendar.days_tuesday',
	'calendar.days_wednesday',
	'calendar.days_thursday',
	'calendar.days_friday',
	'calendar.days_saturday',
	'calendar.days_sunday'
];

export const DaysOfWeekMin = [
	'calendar.days_min_monday',
	'calendar.days_min_tuesday',
	'calendar.days_min_wednesday',
	'calendar.days_min_thursday',
	'calendar.days_min_friday',
	'calendar.days_min_saturday',
	'calendar.days_min_sunday'
];

export const Month = [
	{ key: 0, name: 'calendar.months_january' },
	{ key: 1, name: 'calendar.months_february' },
	{ key: 2, name: 'calendar.months_march' },
	{ key: 3, name: 'calendar.months_april' },
	{ key: 4, name: 'calendar.months_may' },
	{ key: 5, name: 'calendar.months_june' },
	{ key: 6, name: 'calendar.months_july' },
	{ key: 7, name: 'calendar.months_august' },
	{ key: 8, name: 'calendar.months_september' },
	{ key: 9, name: 'calendar.months_october' },
	{ key: 10, name: 'calendar.months_november' },
	{ key: 11, name: 'calendar.months_december' }
];

export const DATE_MASK: TuiTextMaskList = [
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP
];

export const DATE_TIME_MASK: TuiTextMaskList = [
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	'.',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	',',
	' ',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	':',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP
];

export const TIME_MASK: TuiTextMaskList = [
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP,
	':',
	TUI_DIGIT_REGEXP,
	TUI_DIGIT_REGEXP
];

export const DATE_TIME_SEPARATOR = ', ';

export const LENGTH_DATE_TIME = 17;
export const LENGTH_DATE = 10;
export const LENGTH_TIME = 5;

export const MIN_DATE = new TuiDay(1970, 0, 1);

export const MAX_DATE = new TuiDay(TuiDay.currentLocal().year + 5, 0, 1);

export type AvailablePeriod = 'all' | 'future' | 'past';
