/* eslint-disable func-names */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, inject, InjectionToken } from '@angular/core';

import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { AnyObject } from '@enkod-core/interfaces';
import { timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export type AsyncValidatorConfig = {
	endpoint: string;
	requestName: string;
	responseName: string;
};

export const ASYNC_VALIDATE_TOKEN = new InjectionToken(
	'Async validation token'
);

/** Для ререндера можно прокинуть через deps ChangeDetectorRef */
export function asyncValidator(config: AsyncValidatorConfig): Function {
	/** Функция которая отработает в injection context и заинжектит httpClient */
	return function (cd?: ChangeDetectorRef): AsyncValidatorFn {
		const http = inject(HttpClient);
		/** Функция которая устанавливается в async validator */
		return function (control: AbstractControl) {
			return timer(300).pipe(
				switchMap(() =>
					http.post<AnyObject>(config.endpoint, {
						[config.requestName]: control.value
					})
				),
				map(resp =>
					resp[config.responseName] ? null : { alreadyExist: true }
				),
				tap(() => {
					if (cd) cd.markForCheck();
				})
			);
		};
	};
}
