import { Injectable } from '@angular/core';
import {
	NavigationCancel,
	NavigationEnd,
	NavigationStart,
	Router
} from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class RouterService {
	constructor(private router: Router) {
		this.init();
	}

	private routerLoading$: Subject<boolean> = new Subject();

	private init() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.setLoading(true);
			} else if (event instanceof NavigationEnd) {
				this.setLoading(false);
			} else if (event instanceof NavigationCancel) {
				this.setLoading(false);
			}
		});
	}

	private setLoading(state: boolean): void {
		this.routerLoading$.next(state);
	}

	selectRouterLoading(): Observable<boolean> {
		return this.routerLoading$.asObservable();
	}
}
