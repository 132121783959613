import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'userRole'
})
export class UserRolePipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'marketer':
				return 'admin_users_list.role_marketer';
			default:
				return value;
		}
	}
}
