import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SendTestData } from '@enSend/message/message-wizard/kit';

@Injectable()
export class SendTestEmailService {
	constructor(private http: HttpClient) {}

	getEmails() {
		const channelEmail = {
			channel: 'email'
		};
		const params = new HttpParams({ fromObject: channelEmail });
		return this.http.get('user/test_contacts/', { params });
	}

	postMails(emails: SendTestData) {
		return this.http.post('user/test_contacts/', emails);
	}
}
