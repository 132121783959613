<form [formGroup]="form"
      class="en-inputgroup form">
    <input id="fromEmail"
           class="h32 form__email"
           type="text"
           enInput
           formControlName="email" />
    <ng-container *ngIf="submailersList$ | async as submailersList">
        <en-dropdown class="form__domain"
                     [disabled]="submailersList.length <= 1"
                     [options]="submailersList"
                     [enTooltip]="form.value.domain"
                     tooltipPosition="top"
                     appendTo="body"
                     formControlName="domain">
        </en-dropdown>
    </ng-container>
</form>
