import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClicksMapDirective } from './clicks-map.directive';

@NgModule({
	imports: [CommonModule],
	exports: [ClicksMapDirective],
	declarations: [ClicksMapDirective]
})
export class EnClicksMapModule {}
