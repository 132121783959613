import { Provider } from '@angular/core';
import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams
} from '@ngx-translate/core';

export class MissingTranslation implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		return `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;
	}
}

export const MISSING_TRANSLATION_HANDLER: Provider[] = [
	{
		provide: MissingTranslationHandler,
		useClass: MissingTranslation
	}
];
