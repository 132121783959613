import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { KeyFilterModule } from 'primeng/keyfilter';

import { EnInputModule } from '../input/input';
import { EnButtonModule } from '../button/button';

import { SearchComponent } from './search.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		KeyFilterModule,
		EnInputModule,
		EnButtonModule
	],
	declarations: [SearchComponent],
	exports: [SearchComponent]
})
export class SearchModule {}
