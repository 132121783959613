import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'en-range-tag',
	templateUrl: './range-tag.component.html',
	styleUrls: ['./range-tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnRangeTagComponent {
	@Input() value = '';

	get displayText(): string {
		return this.value;
	}
}
