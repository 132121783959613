import {
	DATE_FILLER_LENGTH,
	TuiDay,
	TuiTime,
	TuiTimeMode
} from '@taiga-ui/cdk';
import {
	TuiTextMaskPipeHandler,
	TuiWithOptionalMinMaxWithValue
} from '@taiga-ui/core';
import { tuiCreateAutoCorrectedTimePipe } from 'app/ui-lib/calendarNew/pipes/calendar-time.pipe';

export function tuiCreateAutoCorrectedDateTimeRangePipe(
	config: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay>,
	timeMode: TuiTimeMode
): TuiTextMaskPipeHandler {
	const timePipe = tuiCreateAutoCorrectedTimePipe(timeMode);
	return value => {
		if (value.length < DATE_FILLER_LENGTH) {
			return { value };
		}
		const [date, time] = value.substring(0, 17).split(', ');
		const formattedDate = normalizeDateValue(date, config);
		if (!time) {
			return { value: formattedDate };
		}
		const pipedTime = timePipe(time, {} as any);

		if (!pipedTime || typeof pipedTime === 'string') {
			return false;
		}

		if (value.length < 30) {
			return { value };
		}

		const [dateTo, timeTo] = value.substring(20).split(', ');
		const configTo: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay> =
			{
				value: config.value,
				min: TuiDay.normalizeParse(formattedDate),
				max: config.max
			};

		const formattedDateTo = normalizeDateValue(dateTo, configTo);
		const pipedTimeTo = timePipe(timeTo, {} as any);

		if (!pipedTimeTo || typeof pipedTimeTo === 'string') {
			return value;
		}
		if (
			formattedDate === formattedDateTo &&
			pipedTime.value >= pipedTimeTo.value &&
			pipedTimeTo.value.length === 5
		) {
			const newTimeTo = TuiTime.fromString(pipedTime.value)
				.shift({
					minutes: 1
				})
				.toString();
			pipedTimeTo.value = newTimeTo;
		}

		return {
			value: `${formattedDate}, ${pipedTime.value} - ${formattedDateTo}, ${pipedTimeTo.value}`
		};
	};
}

export function normalizeDateValue(
	dateValue: string,
	{ value, min, max }: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay>
): string {
	return value && value.toString() === dateValue
		? dateValue
		: TuiDay.normalizeParse(dateValue).dayLimit(min, max).toString();
}
