<div class="en-error"
     role="alert"
     *ngIf="computedError"
     [@heightCollapse]
     [@fadeIn]>

    <div *ngIf="mode === 'basic'"
         class="en-error__wrapper">
        <span *ngIf="showIcon"
              class="en-error__icon en en-exclamation-circle-o"></span>
        <span class="en-error__message"
              [ngClass]="{'no-icon': !showIcon}">
            {{computedError?.message | translate}}
        </span>
    </div>

    <div *ngIf="mode === 'advanced'"
         class="en-error__wrapper">
        <en-alert [status]="status"
                  [background]="background"
                  [flexable]="flexable"
                  [message]="computedError?.message"></en-alert>
    </div>
</div>
