import { isNumber } from '@datorama/akita';

export const TIME_ZONE_NAME: string =
	Intl.DateTimeFormat().resolvedOptions().timeZone;

export function fromUnix<T = unknown>(timestamp: number): Date | T {
	return isNumber(timestamp) ? new Date(timestamp * 1000) : timestamp;
}

export function toUnix(dateValue: Date): number {
	return +(dateValue.getTime() / 1000).toFixed();
}
