import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Optional,
	Output,
	Self
} from '@angular/core';
import { AbstractControl, UntypedFormControl, NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TuiTextMaskOptions } from '@taiga-ui/core';
import { Subject } from 'rxjs';
import { TuiDay, TuiMonth, tuiPure, TuiTime, TuiTimeMode } from '@taiga-ui/cdk';

import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import { yearMaxFromCurrent, yearMin } from '../calendarNew/calendar.model';

import {
	calculateMaskDate,
	calculateMaskDateTime
} from '../calendarNew/mask/calendar-time-mask';

@UntilDestroy()
@Component({
	selector: 'en-dropdown-calendar-new',
	templateUrl: './dropdown-calendar.component.html',
	styleUrls: ['./dropdown-calendar.component.scss'],
	providers: [CalendarService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownCalendarComponent implements OnInit, AfterViewInit {
	calendarVisible = false;
	selected = false;
	date: string;
	currentDate: TuiDay;
	timeMode: TuiTimeMode = 'HH:MM';

	dateControl = new UntypedFormControl('');

	@Input() autofocus = false;
	@Input() disabled = false;
	@Input() period = 'all';
	@Input() placeholder = this.translate.instant('common.select');
	@Input() min = new TuiDay(yearMin, 0, 1);
	@Input() max = new TuiDay(
		TuiDay.currentLocal().year + yearMaxFromCurrent,
		11,
		31
	);

	@Input() forceInvalid = false;
	@Input() type = 'dateTime';
	@Input() size = 'm';
	@Input() showReset = true;
	@Input() forceMarkForCheck$: Subject<void>;
	@Input() reset$: Subject<void>;
	@Input() set reset(value: boolean) {
		if (value) this.resetCalendar();
	}

	@Output() onClose = new EventEmitter();

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		private ngControl: NgControl | null,
		@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
		private calendarService: CalendarService,
		private translate: TranslateService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
		this.calendarService.changeFormat('single');
	}

	get control(): AbstractControl | null {
		if (this.ngControl) {
			return this.ngControl.control;
		}
		return null;
	}

	get invalidControl() {
		return (
			(this.control?.invalid &&
				this.control?.untouched &&
				this.control?.dirty) ||
			this.forceInvalid
		);
	}

	get minDate() {
		return this.calendarService.getMinDate();
	}

	get maxDate() {
		return this.calendarService.getMaxDate();
	}

	get disableInput() {
		return this.disabled ? true : null;
	}

	ngOnInit(): void {
		this.calendarService.changeMinMaxValue(this.min, this.max);
		this.calendarService.changePeriod(this.period);
		this.calendarService.changeType(this.type);
		this.changeDate();
		this.registerOnChange();

		this.reset$
			?.pipe(untilDestroyed(this))
			.subscribe(() => this.resetCalendar());

		this.forceMarkForCheck$
			?.pipe(untilDestroyed(this))
			.subscribe(() => this.markForCheck());
	}

	ngAfterViewInit(): void {
		this.registerOnChange();
	}

	registerOnChange() {
		if (this.control?.value) {
			this.calendarService.changeDateTime(
				this.calendarService.getStringDateTime(
					this.control.value,
					this.type === 'dateTime'
				)
			);
			const dateMonth = TuiDay.normalizeParse(
				this.calendarService.getStringDate(this.control.value)
			);
			this.calendarService.changeShowDate(
				new TuiMonth(dateMonth.year, dateMonth.month)
			);
			if (this.type === 'dateTime') {
				this.date = this.calendarService.getDateTime(
					this.control?.value
				);
				this.control.markAsDirty();
			} else {
				this.date = this.calendarService.getDateTime(
					this.control?.value
				);
				this.control.markAsDirty();
			}
		}
		this.markForCheck();
	}

	private markForCheck() {
		this.changeDetectorRef.markForCheck();
	}

	writeValue(): void {}

	onTouch: Function = () => {};

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	changeDateInput(date: string) {
		if (this.type === 'date' && date.length === 10) {
			const tuiDay = TuiDay.normalizeParse(date.substring(0, 10));
			const tuiTime = TuiTime.fromString(date.substring(12, 17));

			this.calendarService.changeCurrentDayFromInput(tuiDay);

			this.control?.patchValue(
				this.calendarService.getNumberDate(tuiDay, tuiTime)
			);
			this.markForCheck();
			this.control?.markAsDirty();
		} else if (this.type === 'dateTime' && date.length === 17) {
			const tuiDay = TuiDay.normalizeParse(date.substring(0, 10));
			const tuiTime = TuiTime.fromString(date.substring(12, 17));

			this.calendarService.changeCurrentDayFromInput(tuiDay);

			this.control?.patchValue(
				this.calendarService.getNumberDate(tuiDay, tuiTime)
			);
			this.markForCheck();
			this.control?.markAsDirty();
		} else {
			this.control?.patchValue(+new Date() / 1000);
		}
	}

	onSelect() {
		this.selected = true;
	}

	changeDate() {
		this.calendarService.currentDate
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (this.type === 'dateTime') {
					this.control?.patchValue(
						this.calendarService.getNumberDate(
							value.date,
							value.time,
							true
						)
					);
					this.date = this.calendarService.getDateTime(
						this.control?.value
					);
					if (this.date.length === 17) {
						this.control?.markAsDirty();
					}
				} else {
					this.control?.patchValue(
						this.calendarService.getNumberDate(
							value.date,
							new TuiTime(0, 0)
						)
					);

					this.date = this.calendarService.getDateString(
						this.control?.value
					);
					if (this.date.length === 10) {
						this.control?.markAsDirty();
					}
				}
			});
	}

	resetCalendar() {
		this.control?.patchValue(null);
		this.dateControl.patchValue('');
		this.calendarService.resetDate();
		this.calendarService.resetDateRange();
		this.markForCheck();
	}

	onBlur() {
		this.onClose.emit();
	}

	@tuiPure
	get textMaskOptions(): TuiTextMaskOptions {
		if (this.type === 'dateTime') {
			return calculateMaskDateTime(
				this.calendarService.getDate(),
				this.minDate,
				this.maxDate,
				this.timeMode
			);
		}
		return calculateMaskDate(
			this.calendarService.getDate(),
			this.minDate,
			this.maxDate
		);
	}
}
