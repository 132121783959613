<ng-container [formGroup]="form">
    <p-dialog [modal]="true"
              styleClass="dialog-wide-width"
              [(visible)]="display"
              [header]="'message_wizard.dialog_header' | translate"
              closeIcon="en en-close"
              (onHide)="closeDialog()">
        <p class="send-title en-text-large">
            {{'message_wizard.dialog_text' | translate}}
        </p>
        <div class="container">
            <div class="buttons">
                <button *ngFor="let type of types"
                        class="type-button"
                        [enLoading]="type.value === choosenTypeName"
                        [disabled]="(loading && type.value !== choosenTypeName) || (type.isDisabled | tuiMapper:disableChannel:type.value)"
                        (click)="chooseType(type.value)">
                    <i class="en {{ type.icon }} type-icon"></i>
                    {{ type.value | channelName }}
                </button>
            </div>
        </div>
    </p-dialog>
</ng-container>