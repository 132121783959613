import { DATE_FILLER_LENGTH, TuiDay } from '@taiga-ui/cdk';
import {
	TuiTextMaskPipeHandler,
	TuiWithOptionalMinMaxWithValue
} from '@taiga-ui/core';
import { normalizeDateValue } from './calendar-date-time.pipe';

export function tuiCreateAutoCorrectedDatePipe(
	config: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay>
): TuiTextMaskPipeHandler {
	return value => {
		if (value.length !== DATE_FILLER_LENGTH) {
			return { value };
		}

		return {
			value: normalizeDateValue(value, config)
		};
	};
}
