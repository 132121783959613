import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';

import { EnInputModule } from '../../input/input';
import { EnPrimitiveInputDateTimeModule } from '../primitive-input-date-time/primitive-input-date-time.module';
import { PrimitiveDateTimePickerComponent } from './primitive-date-time-picker.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		EnInputModule,
		EnPrimitiveInputDateTimeModule,
		TranslateModule,
		CheckboxModule
	],
	declarations: [PrimitiveDateTimePickerComponent],
	exports: [PrimitiveDateTimePickerComponent]
})
export class EnPrimitiveDateTimeModule {}
