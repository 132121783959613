import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({
	name: 'getLabelById'
})
export class GetLabelByIdPipe implements PipeTransform {
	transform(value: number, options: SelectItem[]): string {
		return options?.find(item => item.value === value)?.label as string;
	}
}
