import { Pipe, PipeTransform } from '@angular/core';

import { AuthQuery } from '@enkod-core/authentication/_state';
import { DateAccountTimezoneService } from '@enkod-core/services';

@Pipe({
	name: 'weekPeriod'
})
export class WeekPeriodPipe implements PipeTransform {
	constructor(
		private auth: AuthQuery,
		private timezoneService: DateAccountTimezoneService
	) {}

	get accountTimezone() {
		return this.auth.accountTimezone;
	}

	transform(value: number) {
		const oneWeek = 518400; // Одна неделя в timestamp

		const now = this.timezoneService.getToday();

		const sundayTimestamp = value + oneWeek;

		const nowDay =
			this.timezoneService.convertDate(now, 'DD.MM.yyyy') || value;

		const monday =
			this.timezoneService.convertDate(value, 'DD.MM.yyyy') || value;

		const sunday = this.timezoneService.convertDate(
			sundayTimestamp,
			'DD.MM.yyyy'
		);

		if (sundayTimestamp > now) {
			return `${monday} - ${nowDay}`;
		}

		return `${monday} - ${sunday}`;
	}
}
