export interface Submailer {
	id: number;
	clientId: number;
	sendingDomain: string;
	envelopeDomain: string;
	trackingDomain: string;
	limitType: LimitType;
	limitSettings: LimitSetting;
	doubleDkim: boolean;
	dkimSelector: string;
	dkimPubKey: string;
	dkimPrivKey: string;
	ips: string[];
	isActive: boolean;
	verificationDomainMail: string;
	verificationDomainGoogle: string;
	withSettings: boolean;
}

export interface UndateSubmailerModel {
	data: Submailer;
	domainId: string;
}

export interface IpList {
	id: number;
	mtaId: number;
	ip: string;
	name: string;
}

export interface ProviderList {
	name: string;
	suffixesMx: string[];
	domains: string[];
	warm: number;
	normal: number;
	fast: number;
}

export interface DkimKey {
	publicKey: string;
	privateKey: string;
}

export interface DeleteSubmailerModel {
	sendingDomain: string;
	withSettings: boolean;
}

export interface LimitSetting {
	[key: string]: number;
}

export type DkimLength = '1024' | '2048';

export type LimitType = 'warm' | 'norm' | 'fast';

export type DomainAction = 'create' | 'edit';
