import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class AccessMailingGroupsGuard implements CanActivate, CanLoad {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const isAccessEnsend = !!this.authQuery.isAccessEnsend;
		if (!isAccessEnsend) this.router.navigateByUrl('');

		return isAccessEnsend;
	}

	canLoad(): boolean {
		const isAccessEnsend = !!this.authQuery.isAccessEnsend;
		if (!isAccessEnsend) this.router.navigateByUrl('');

		return isAccessEnsend;
	}
}
