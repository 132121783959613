import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export const VALIDATION_TOKEN = new InjectionToken<Subject<void>>(
	'token for forced validation'
);

export const VALIDATION_PROVIDER = [
	{
		provide: VALIDATION_TOKEN,
		useClass: Subject
	}
];
