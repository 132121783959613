<div class="container">
    <en-button class="slider__button"
               styleClass="en-button-lowercase h36"
               icon="en en-arrow-left"
               [ngClass]="{ 'slider__button-disabled': disabled }"
               (click)="decrement()"></en-button>
    <div class="slider"
         [(ngModel)]="value"
         [ngStyle]="style"
         [ngStyle]="{ width: sliderWidth - 5.5 + 'rem' }"
         (click)="onBarClick($event)"
         ngDefaultControl>
        <span class="slider__range"
              [ngClass]="{ 'slider__range-disabled': disabled }"
              [ngStyle]="{ width: handleValue + '%' }"></span>
        <div>
            <span #sliderHandle
                  [attr.tabindex]="disabled ? null : tabindex"
                  (keydown)="onHandleKeydown($event)"
                  class="slider__handle"
                  (mousedown)="onMouseDown($event)"
                  (touchstart)="onTouchStart($event)"
                  (touchmove)="onTouchMove($event)"
                  (touchend)="onTouchEnd($event)"
                  [style.transition]="dragging ? 'none' : null"
                  [ngClass]="{ 'slider__handle-disabled': disabled }"
                  [ngStyle]="{ left: handleValue + '%', bottom: null }"
                  [attr.aria-valuemin]="min"
                  [attr.aria-valuenow]="value"
                  [attr.aria-valuemax]="max"></span>
            <span class="slider__percent"
                  [ngClass]="{ 'slider__percent-disabled': disabled }"
                  [ngStyle]="{ left: handleValue - 100 / sliderWidth + '%', bottom: null }">{{ handleValue }} %</span>
        </div>
    </div>
    <en-button class="slider__button"
               styleClass="en-button-lowercase h36"
               icon="en en-arrow-right"
               [ngClass]="{ 'slider__button-disabled': disabled }"
               (click)="increment()"></en-button>
</div>