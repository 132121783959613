import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@enkod-core/components';

import { ScrollWrapperSettingsComponent } from './scroll-wrapper-settings.component';

@NgModule({
	imports: [CommonModule, TuiScrollbarModule],
	declarations: [ScrollWrapperSettingsComponent],
	exports: [ScrollWrapperSettingsComponent]
})
export class ScrollWrapperSettingsModule {}
