import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnIphoneViewModule } from './iphone-view/iphone-view.module';
import { MobileMockComponent } from './mobile-mock.component';

@NgModule({
	imports: [CommonModule, EnIphoneViewModule],
	declarations: [MobileMockComponent],
	exports: [MobileMockComponent]
})
export class EnMobileMockModule {}
