import {
	Component,
	ChangeDetectionStrategy,
	HostListener,
	Input,
	HostBinding
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';

@Component({
	selector: 'en-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements ControlValueAccessor {
	private onTouched = EMPTY_FUNCTION;
	private onChange = EMPTY_FUNCTION;

	@Input()
	value: boolean | null;

	@Input()
	@HostBinding('class.disabled-state')
	readonly disabledState = false;

	constructor(private readonly ngControl: NgControl) {
		this.ngControl.valueAccessor = this;
	}

	get controlValue() {
		return this.ngControl?.control?.value;
	}

	@HostListener('click')
	clickHandler() {
		if (!this.disabledState) this.onChange(!this.controlValue);
	}

	writeValue(): void {}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouched = fn;
	}
}
