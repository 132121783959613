<tui-wrapper class="outline"
             [class.line-2-color]="!useOutlineColor"
             [class._invalid]="manualInvalid$ | async"
             [class._disabled]="areaDisabled"
             [disabled]="areaDisabled"
             [focused]="computedFocused && useOutlineColor"
             [hovered]="computedHovered"
             [readOnly]="readOnly"
             [invalid]="computedInvalid">
</tui-wrapper>

<span *ngIf="showEmojiSelector"
      class="content__emoji">
    <tui-hosted-dropdown tuiDropdownAlign="left"
                         [content]="dropdown"
                         [(open)]="isOpenEmoji">
        <button enButton
                type="button"
                class="en-button-lowercase h32 tools__icon emoji-button"
                icon="en en-emoji"></button>
    </tui-hosted-dropdown>
</span>

<label class="content"
       (tuiHoveredChange)="onHovered($event)"
       (mousedown)="onMouseDown($event)">
    <div class="wrapper">
        <div *ngIf="hasExampleText"
             automation-id="tui-text-area__example-text"
             class="example-text">
            <span class="example-text-inner"> {{controller.exampleText}} </span>
        </div>
        <tui-scrollbar class="box"
                       [style.maxHeight.px]="computeMaxHeight">
            <div class="input-wrapper">
                <div class="relative">
                    <div class="pseudo-content">{{value}}&nbsp;</div>
                    <textarea #focusableElement
                              class="input"
                              [attr.maxLength]="controller.maxLength"
                              [id]="id"
                              [placeholder]="placeholder"
                              [tuiFocusable]="focusable"
                              [disabled]="areaDisabled"
                              [ngModel]="value"
                              [readOnly]="readOnly"
                              (tuiFocusedChange)="onFocused($event)"
                              (ngModelChange)="onValue($event)"></textarea>
                </div>
            </div>
        </tui-scrollbar>
    </div>
</label>

<ng-template #dropdown
             let-activeZone>
    <emoji-mart class="picker"
                [darkMode]="false"
                [showPreview]="false"
                [exclude]="emojiExclude"
                [i18n]="emojiService.getEmojiMartLocale()"
                (emojiClick)="addEmoji($event)">
    </emoji-mart>
</ng-template>