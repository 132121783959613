import { Pipe, PipeTransform } from '@angular/core';
import { EnStatusT } from 'ui-lib';

export type IconMap = Record<EnStatusT, string>;

@Pipe({
	name: 'iconStatus'
})
export class GetIconPipe implements PipeTransform {
	transform(status: EnStatusT): string {
		const icons: IconMap = {
			info: 'en-info-circle-o',
			success: 'en-check-circle-o',
			error: 'en-exclamation-circle-o',
			warning: 'en-exclamation-triangle-o',
			pause: 'en-pause-circle-o',
			default: 'en-info-circle-o',
			primary: '',
			custom: '',
			question: '',
			loading: '',
			message: '',
			messageSplit: '',
			messageBot: ''
		};
		return icons[status];
	}
}
