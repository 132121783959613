import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class AccessInboxGuard implements CanActivate {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const isAccessWhatsApp = !!this.authQuery.isAccessWhatsApp;
		if (!isAccessWhatsApp) {
			this.router.navigateByUrl('ensend/inbox/get-service');
		}
		return isAccessWhatsApp;
	}

	canLoad(): boolean {
		const isAccessWhatsApp = !!this.authQuery.isAccessWhatsApp;
		if (!isAccessWhatsApp)
			this.router.navigateByUrl('ensend/inbox/get-service');
		return isAccessWhatsApp;
	}
}
