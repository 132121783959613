import {
	AnimationTriggerMetadata,
	trigger,
	transition,
	style,
	animate
} from '@angular/animations';

const TRANSITION = '300ms ease-in-out';

export const commonAnimation: {
	opacity: AnimationTriggerMetadata;
} = {
	opacity: trigger('opacity', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate('100ms', style({ opacity: 1 }))
		]),
		transition(':leave', [animate('100ms', style({ opacity: 0 }))])
	])
};

export const heightCollapse: AnimationTriggerMetadata = trigger(
	'heightCollapse',
	[
		transition(':enter', [
			style({ height: 0 }),
			animate(TRANSITION, style({ height: '*' }))
		]),
		transition(':leave', [
			style({ height: '*' }),
			animate(TRANSITION, style({ height: 0 }))
		])
	]
);

export const fadeIn: AnimationTriggerMetadata = trigger('fadeIn', [
	transition(':enter', [
		style({ opacity: 0 }),
		animate(TRANSITION, style({ opacity: 1 }))
	]),
	transition(':leave', [
		style({ opacity: 1 }),
		animate(TRANSITION, style({ opacity: 0 }))
	])
]);
