import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnCalendarComponent } from './calendar.component';
import { EnPrimitiveYearMonthPaginationModule } from '../new-calendar/primitive-year-month-pagination/primitive-year-month-pagination.module';
import { EnPrimitiveCalendarModule } from '../new-calendar/primitive-calendar/primitive-calendar.module';
import { EnPrimitiveYearPickerModule } from '../new-calendar/primitive-year-picker/primitive-year-picker.module';
import { EnPrimitiveDateTimeModule } from '../new-calendar/primitive-date-time-picker/primitive-date-time-picker.module';

@NgModule({
	imports: [
		CommonModule,
		EnPrimitiveYearMonthPaginationModule,
		EnPrimitiveCalendarModule,
		EnPrimitiveYearPickerModule,
		EnPrimitiveDateTimeModule
	],
	declarations: [EnCalendarComponent],
	exports: [EnCalendarComponent]
})
export class EnCalendarNewModule {}
