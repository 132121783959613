import { TableColumn } from '@enkod-core/interfaces';

export interface TableColumnsSettingsState {
	[key: string]: EnabledColumn[];
}

export type ColumnsSettings = {
	blockColumns: TableColumn[];
	enableColumns: TableColumn[];
};

export type EnabledColumn = {
	field: string;
	disabled: boolean;
};
