import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SystemTagModule } from '@enkod-core/pipes';
import { EditTagsModule } from '@shared-components/dialogs';
import { EnTagModule } from 'ui-lib/tag/tag.module';
import { TagsFieldComponent } from './tags-field.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		EnTagModule,
		EditTagsModule,
		SystemTagModule
	],
	declarations: [TagsFieldComponent],
	exports: [TagsFieldComponent]
})
export class TagsFieldModule {}
