import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';

import { TableColumn } from '@enkod-core/interfaces';
import {
	ColumnsSettings,
	TableColumnsSettingsService
} from '../services/_state';

@Component({
	selector: 'en-reorder',
	templateUrl: './reorder.component.html',
	styleUrls: ['./reorder.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnReorderComponent {
	columns$: Observable<ColumnsSettings> =
		this.tableColumnsSettingsService.getDisabledColumns();

	constructor(
		private tableColumnsSettingsService: TableColumnsSettingsService
	) {}

	drop(event: CdkDragDrop<TableColumn>, columns: ColumnsSettings): void {
		moveItemInArray(
			columns.enableColumns,
			event.previousIndex,
			event.currentIndex
		);
		this.changeTable(columns);
	}

	onToggleEnabled(columnField: string, columns: ColumnsSettings): void {
		const cols = columns;
		cols.enableColumns = columns.enableColumns.map(column => {
			if (columnField !== column.field) return column;
			return { ...column, disabled: !column.disabled };
		});
		this.changeTable(cols);
	}

	private changeTable(columns: ColumnsSettings): void {
		this.tableColumnsSettingsService.updateTableColumns(columns);
	}
}
