/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { toUnix } from '@enkod-core/utils/time';
import { AuthQuery, AuthService } from '@state-auth';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private authQuery: AuthQuery
	) {}

	async canActivate(): Promise<boolean> {
		if (!this.authQuery.isLoggedIn) {
			this.authService.logout();
			return false;
		}

		if (await this.isTokenExpired()) {
			this.authService.logout();
			return false;
		}

		return true;
	}

	private async isTokenExpired(): Promise<boolean> {
		const expireDate = this.authQuery.getValue()?.expire || 0;
		const now = toUnix(new Date());

		if (expireDate < now) {
			console.log('Guard: token expired');
			try {
				await this.authService.refreshToken().toPromise();
				console.log('Guard: token updated');
				return false;
			} catch {
				console.log('Guard: error updating token');
				return true;
			}
		}

		return false;
	}
}
