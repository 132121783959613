/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable */
import {
	NgModule,
	Component,
	Input,
	Output,
	OnInit,
	AfterViewInit,
	AfterContentInit,
	OnDestroy,
	ElementRef,
	ViewChild,
	EventEmitter,
	ContentChildren,
	QueryList,
	TemplateRef,
	ChangeDetectionStrategy,
	NgZone,
	ChangeDetectorRef,
	ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Message } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { SharedModule } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { RippleModule } from 'primeng/ripple';
import { Subscription } from 'rxjs';
import {
	trigger,
	transition,
	query,
	animateChild,
	AnimationEvent
} from '@angular/animations';
import { EnToastItem } from './toast-item/toast.item';
import { EnTempalateDirective } from '@shared';

@Component({
	selector: 'en-toast',
	template: `
		<div
			#container
			[ngClass]="'en-toast p-component en-toast-' + position"
			[ngStyle]="style"
			[class]="styleClass"
		>
			<en-toastItem
				*ngFor="let msg of messages; let i = index"
				[message]="msg"
				[index]="i"
				(onClose)="onMessageClose($event)"
				[template]="template"
				@toastAnimation
				(@toastAnimation.start)="onAnimationStart($event)"
				[showTransformOptions]="showTransformOptions"
				[hideTransformOptions]="hideTransformOptions"
				[showTransitionOptions]="showTransitionOptions"
				[hideTransitionOptions]="hideTransitionOptions"
				[flexable]="flexable"
			></en-toastItem>
		</div>
	`,
	animations: [
		trigger('toastAnimation', [
			transition(':enter, :leave', [query('@*', animateChild())])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class Toast implements OnInit, AfterContentInit, OnDestroy {
	@Input() key: string;

	@Input() autoZIndex: boolean = true;

	@Input() baseZIndex: number = 0;

	@Input() style: any;

	@Input() styleClass: string;

	@Input() position: string = 'top-right';

	@Input() preventOpenDuplicates: boolean = false;

	@Input() preventDuplicates: boolean = false;

	@Input() showTransformOptions: string = 'translateY(100%)';

	@Input() hideTransformOptions: string = 'translateY(-100%)';

	@Input() showTransitionOptions: string = '300ms ease-out';

	@Input() hideTransitionOptions: string = '250ms ease-in';

	@Input() flexable: boolean = false;

	@Output() onClose: EventEmitter<any> = new EventEmitter();

	@ViewChild('container') containerViewChild: ElementRef;

	@ContentChildren(EnTempalateDirective) templates: QueryList<any>;

	messageSubscription: Subscription;

	clearSubscription: Subscription;

	messages: Message[];

	messagesArchieve: Message[];

	template: TemplateRef<any>;

	constructor(
		public messageService: MessageService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.messageSubscription = this.messageService.messageObserver.subscribe(
			messages => {
				if (messages) {
					if (messages instanceof Array) {
						const filteredMessages = messages.filter(m =>
							this.canAdd(m)
						);
						this.add(filteredMessages);
					} else if (this.canAdd(messages)) {
						this.add([messages]);
					}
				}
			}
		);

		this.clearSubscription = this.messageService.clearObserver.subscribe(
			key => {
				if (key) {
					if (this.key === key) {
						this.messages = null!;
					}
				} else {
					this.messages = null!;
				}

				this.cd.markForCheck();
			}
		);
	}

	add(messages: Message[]): void {
		this.messages = this.messages
			? [...this.messages, ...messages]
			: [...messages];

		if (this.preventDuplicates) {
			this.messagesArchieve = this.messagesArchieve
				? [...this.messagesArchieve, ...messages]
				: [...messages];
		}

		this.cd.markForCheck();
	}

	canAdd(message: Message): boolean {
		let allow = this.key === message.key;

		if (allow && this.preventOpenDuplicates) {
			allow = !this.containsMessage(this.messages, message);
		}

		if (allow && this.preventDuplicates) {
			allow = !this.containsMessage(this.messagesArchieve, message);
		}

		return allow;
	}

	containsMessage(collection: Message[], message: Message): boolean {
		if (!collection) {
			return false;
		}

		return (
			collection.find(m => {
				return (
					m.summary === message.summary &&
					m.detail == message.detail &&
					m.severity === message.severity
				);
			}) != null
		);
	}

	ngAfterContentInit() {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'message':
					this.template = item.template;
					break;

				default:
					this.template = item.template;
					break;
			}
		});
	}

	onMessageClose(event: any) {
		this.messages.splice(event.index, 1);

		this.onClose.emit({
			message: event.message
		});

		this.cd.detectChanges();
	}

	onAnimationStart(event: AnimationEvent) {
		if (event.fromState === 'void' && this.autoZIndex) {
			this.containerViewChild.nativeElement.style.zIndex = String(
				this.baseZIndex + ++DomHandler.zindex
			);
		}
	}

	ngOnDestroy() {
		if (this.messageSubscription) {
			this.messageSubscription.unsubscribe();
		}

		if (this.clearSubscription) {
			this.clearSubscription.unsubscribe();
		}
	}
}

@NgModule({
	imports: [CommonModule, RippleModule],
	exports: [Toast, SharedModule],
	declarations: [Toast, EnToastItem]
})
export class ToastModule {}
