import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	forwardRef
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

export const URL_CONTROLS_ACCESS = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => UrlParamsControlComponent),
	multi: true
};

interface UrlParamsTypes {
	[key: string]: string;
}

@UntilDestroy()
@Component({
	selector: 'en-url-params-control',
	templateUrl: './url-params-control.component.html',
	styleUrls: ['./url-params-control.component.scss'],
	providers: [URL_CONTROLS_ACCESS],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlParamsControlComponent implements OnInit {
	constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {}

	formParams: UntypedFormArray = this.fb.array([]);

	urlsValue: UrlParamsTypes[];

	ngOnInit(): void {
		this.formParams.valueChanges
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe(value => {
				this.urlsValue = value;
				const urlParamsArray = this.transformToArray();
				this.transformToFinalObject(urlParamsArray);
				this.cd.markForCheck();
			});
	}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	writeValue(value: UrlParamsTypes) {
		this.formParams.clear();
		if (!value || !Object.keys(value).length) {
			this.addStartControl();
			return;
		}

		Object.entries(value).forEach(([key, value]) => {
			this.formParams.push(
				this.fb.group({
					key,
					value
				})
			);
		});
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	addStartControl(): void {
		if (!this.formParams.length) {
			this.addGroupParam();
		}
	}

	transformToArray(): UrlParamsTypes[] {
		const urlParamsArray: UrlParamsTypes[] = [];
		this.urlsValue.forEach((element: UrlParamsTypes) => {
			urlParamsArray.push(
				Object.fromEntries([[element.key, element.value]])
			);
		});

		return urlParamsArray;
	}

	transformToFinalObject(urlsArray: UrlParamsTypes[]): void {
		const finalParams: UrlParamsTypes = {};

		urlsArray.forEach((element: UrlParamsTypes) => {
			if (!this.checkEmptyKeyInParams(element)) {
				Object.assign(finalParams, element);
			}
		});

		this.onChange(finalParams);
	}

	checkEmptyKeyInParams(element: UrlParamsTypes): boolean {
		return Object.keys(element)[0].trim() === '';
	}

	addGroupParam() {
		this.formParams.push(
			this.fb.group({
				key: '',
				value: ''
			})
		);
	}

	removeGroupParam(i: number) {
		this.formParams.removeAt(i);
	}

	trackByFn(index: number) {
		return index;
	}
}
