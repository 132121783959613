import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserSettingsStore, UserSettingsState } from './user-settings.store';

@Injectable({ providedIn: 'root' })
export class UserSettingsQuery extends Query<UserSettingsState> {
	constructor(protected store: UserSettingsStore) {
		super(store);
	}
}
