<div [ngClass]="styleClass"
     class="search en-input-search en-input-search-page en-input-icon-left">
    <i class="en en-search"></i>
    <input type="text"
           enInput
           [placeholder]="placeholder"
           [pKeyFilter]="blockSpecial"
           [formControl]="search" />
    <i *ngIf="resetButton"
       class="en en-cancel-fill clear"
       (click)="clearSearch()"></i>
</div>

<button *ngIf="refresher"
        [ngClass]="styleClass"
        type="button"
        enButton
        class="en-button-secondary"
        icon="en en-refresh"
        (click)="refresh.emit()"></button>
