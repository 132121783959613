import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpTokenInterceptor } from './interceptors';
import { LanguageService } from './translate';
import { RouterService } from './router';
import { ToastService } from './services';
import { LogoutGuard, AuthGuard } from './guards';
import { ICONS_PATH } from './providers';

@NgModule({
	imports: [CommonModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpTokenInterceptor,
			multi: true
		},
		AuthGuard,
		LogoutGuard,
		ICONS_PATH,
		LanguageService,
		RouterService,
		ToastService,
		MessageService,
		ConfirmationService
	],
	declarations: []
})
export class CoreModule {
	constructor(private langaugeService: LanguageService) {
		this.langaugeService.init();
	}
}
