import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { SharedModule } from '@shared';

import { EnButtonModule } from '../button/button';
import { EnTableSettingsComponent } from './table-settings.component';

@NgModule({
	imports: [CommonModule, EnButtonModule, OverlayModule, SharedModule],
	declarations: [EnTableSettingsComponent],
	exports: [EnTableSettingsComponent]
})
export class EnTableSettingsModule {}
