<tui-tree-item *tuiLet="children$ | async as children"
               #view
               [tuiTreeNode]="value">
    <div *ngIf="value !== children"
         class="poly-container"
         polymorpheus-outlet
         [content]="content"
         [context]="{$implicit: value, node: view}"></div>
    <tui-tree *ngFor="let child of children"
              [value]="child"
              [content]="content"></tui-tree>
</tui-tree-item>