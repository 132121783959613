/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable */
import {
	NgModule,
	Component,
	Input,
	Optional,
	ElementRef,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	ChangeDetectorRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, style, transition, animate } from '@angular/animations';
import { Message, MessageService } from 'primeng/api';
import { RippleModule } from 'primeng/ripple';

@Component({
	selector: 'en-small',
	templateUrl: './small-messages.html',
	animations: [
		trigger('messageAnimation', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(-25%)' }),
				animate('{{showTransitionParams}}')
			]),
			transition(':leave', [
				animate(
					'{{ hideTransitionParams }}',
					style({
						height: 0,
						marginTop: 0,
						marginBottom: 0,
						marginLeft: 0,
						marginRight: 0,
						overflow: 'hidden',
						opacity: 0
					})
				)
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class SmallMessagesComponent {
	@Input() severity: string;

	@Input() visible: boolean = false;

	@Input() detail: string;

	@Input() showTransitionOptions = '300ms ease-out';

	@Input() hideTransitionOptions = '200ms cubic-bezier(0.86, 0, 0.07, 1)';

	constructor() {}
}

@NgModule({
	imports: [CommonModule, RippleModule],
	exports: [SmallMessagesComponent],
	declarations: [SmallMessagesComponent]
})
export class EnSmallModule {}
