import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';

import { convertPaginationAndSortParams, RestService } from '@enkod-core/utils';
import { Observable } from 'rxjs';

import {
	DeleteSubmailerModel,
	DkimKey,
	DkimLength,
	IpList,
	ProviderList,
	Submailer,
	UndateSubmailerModel
} from './account-submailer-setting.model';

@Injectable({
	providedIn: 'root'
})
export class AccountSubmailersSettingsDataService extends RestService<Submailer> {
	constructor(protected http: HttpClient) {
		super(http, 'enkod/submailer');
	}

	getSubmailers(id: number): Observable<Submailer[]> {
		return this.http.get<Submailer[]>(`enkod/submailers/clientId/${id}`);
	}

	updateSubmailer(update: UndateSubmailerModel): Observable<Submailer> {
		return this.http.put<Submailer>(
			`enkod/submailer/${update.domainId}`,
			update.data
		);
	}

	generateDKIM(bit: DkimLength): Observable<DkimKey> {
		return this.http.post<DkimKey>('enkod/dkims/keys/', null, {
			params: {
				bit
			}
		});
	}

	getIpList(): Observable<IpList[]> {
		return this.http.get<IpList[]>('enkod/ip/');
	}

	getProviderList(): Observable<ProviderList[]> {
		return this.http.get<ProviderList[]>('enkod/provider/');
	}

	removeSubmailer(id: ID, data: DeleteSubmailerModel) {
		const params = data
			? new HttpParams({
					fromObject: convertPaginationAndSortParams(data)
			  })
			: {};
		return this.http.delete<void>(`enkod/submailer/${id}`, { params });
	}
}
