<ng-template #template>
    <ng-content></ng-content>
</ng-template>
<div polymorpheus-outlet
     [content]="content"
     [context]="{$implicit: this, template: template}"></div>
<tui-expand *ngIf="isExpandable"
            role="group"
            class="t-children"
            [expanded]="expanded$ | async">
    <div>
        <ng-content select="tui-tree-item"></ng-content>
        <ng-content select="tui-tree"></ng-content>
    </div>
</tui-expand>
<ng-container *ngIf="attached$ | async"></ng-container>