import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';

import {
	EnButtonModule,
	EnDialogModule,
	EnInputModule,
	ErrorModule
} from 'ui-lib';
import { IMaskModule } from 'angular-imask';

import { GetServiceModalComponent } from './get-service-modal.component';
import { FormSubmitService } from './get-service-modal.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnButtonModule,
		ReactiveFormsModule,
		EnInputModule,
		IMaskModule,
		EnDialogModule,
		ErrorModule
	],
	declarations: [GetServiceModalComponent],
	exports: [GetServiceModalComponent],
	providers: [FormSubmitService]
})
export class GetServiceModalModule {}
