import { AnimationOptions } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { tuiFadeInList, tuiSlideInTopList } from '@taiga-ui/core/animations';
import { tuiPure } from '@taiga-ui/cdk';
import { TuiAnimationOptions } from '@taiga-ui/core/interfaces';
import { TUI_ANIMATION_OPTIONS } from '@taiga-ui/core/tokens';
import { NotificationsService } from '@enkod-core/services';

@Component({
	selector: 'en-notification-host',
	templateUrl: './notification-host.component.html',
	styleUrls: ['./notification-host.component.scss'],
	animations: [tuiFadeInList, tuiSlideInTopList],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationHostComponent {
	constructor(
		@Inject(TUI_ANIMATION_OPTIONS)
		private readonly options: AnimationOptions,
		@Inject(NotificationsService)
		readonly service: NotificationsService
	) {}

	@tuiPure
	getAnimation(value: number): TuiAnimationOptions {
		return {
			value: String(value),
			...this.options
		};
	}
}
