import { AllClicks } from '../clicks-map.model';

export function getClicksPercent(
	clicks: number = 0,
	allClicks: AllClicks
): number {
	const result = Number(((clicks / allClicks.allClicks) * 100).toFixed(2));

	if (Number.isFinite(result)) return result;
	return 0;
}

export function getUniqClicksPercent(
	clicks: number = 0,
	allClicks: AllClicks
): number {
	const result = Number(
		((clicks / allClicks.allUniqClicks) * 100).toFixed(2)
	);

	if (Number.isFinite(result)) return result;
	return 0;
}
