import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NotificationHostComponent } from '../modules/notification-host/notification-host.component';

@Component({
	selector: 'en-notification-root',
	templateUrl: 'root.template.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnNotificationRootComponent {
	constructor(
		@Inject(NotificationHostComponent)
		readonly notificationsHost: NotificationHostComponent
	) {}
}
