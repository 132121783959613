import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'enMultiplier'
})
export class EnMultiplierPipe implements PipeTransform {
	transform<T extends string, R>(
		option: T,
		multipleOptions: Record<T, R>
	): R {
		return multipleOptions[option];
	}
}
