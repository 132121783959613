<div *ngIf="total"
     class="en-paginator">

    <div *ngIf="!onPageCount"
         class="en-paginator__block">
        <span>{{'table_paginator.on_page_count' | translate}}</span>
        <en-dropdown styleClass="h28"
                     [options]="perPageItems"
                     [formControl]="perPageControl"></en-dropdown>
    </div>

    <div class="en-paginator__block">
        <span>{{'table_paginator.current_page' | translate}}</span>
        <input enInput
               type="number"
               class="en-paginator__input"
               min="1"
               [max]="total"
               pKeyFilter="pint"
               [formControl]="currentPage"
               (blur)="blurCurrentPage()" />
        <span class="en-paginator__from">{{'table_paginator.from' | translate}} {{ total }}</span>
    </div>

    <div class="en-paginator__block">
        <span class="en-buttonset">
            <button type="button"
                    enButton
                    class="en-button-secondary h28"
                    icon="en en-arrow-left"
                    [disabled]="paginatorRef.isFirst"
                    (click)="paginatorRef.prevPage()">

            </button>
            <button type="button"
                    enButton
                    class="en-button-secondary h28"
                    icon="en en-arrow-right"
                    [disabled]="paginatorRef.currentPage >= total"
                    (click)="paginatorRef.nextPage()">
            </button>
        </span>
    </div>
</div>
