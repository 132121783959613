import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	forwardRef,
	ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

import { Utm } from '../../../message-wizard-common/models';

export const UTM_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => UtmControlComponent),
	multi: true
};

@UntilDestroy()
@Component({
	selector: 'en-utm-control',
	templateUrl: './utm-control.component.html',
	styleUrls: ['./utm-control.component.scss'],
	providers: [UTM_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtmControlComponent implements OnInit {
	constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {}

	utmFormParams: UntypedFormGroup;

	readonly defaultUtm = {
		utm_campaign: '',
		utm_content: '',
		utm_source: '',
		utm_medium: ''
	};

	ngOnInit(): void {
		this.utmFormParams = this.fb.group(this.defaultUtm);

		this.utmFormParams.valueChanges
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe((value: Utm) => {
				const filtredUtmObject: Utm = this.removeEmptyValues(value);
				this.onChange(filtredUtmObject);
				this.cd.markForCheck();
			});
	}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	writeValue(value: Utm): void {
		if (!value) return;
		this.utmFormParams.reset();
		this.utmFormParams.patchValue({ ...this.defaultUtm, ...value });
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	removeEmptyValues(value: Utm): Utm {
		const filtredValue: Utm = Object.fromEntries(
			Object.entries(value).filter(keyValue => keyValue[1] !== '')
		);

		return filtredValue;
	}
}
