import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import {
	ChannelType,
	ContactExtraFields,
	ContactsDetail,
	FieldsViewSettings,
	MainFields,
	PersonData,
	PhoneData,
	TransformedViewSettings
} from './contacts-detail.model';

import {
	ContactsDetailStore,
	ContactsDetailState
} from './contacts-detail.store';

@Injectable({ providedIn: 'root' })
export class ContactsDetailQuery extends QueryEntity<
	ContactsDetailState,
	ContactsDetail
> {
	constructor(protected store: ContactsDetailStore) {
		super(store);
	}

	currentEntity$ = this.selectActive() as Observable<ContactsDetail>;

	extraFields$ = this.currentEntity$.pipe(
		switchMap(
			entity => of(entity?.extraFields) as Observable<ContactExtraFields>
		)
	);

	mainFields$ = this.currentEntity$.pipe(
		switchMap(
			entity =>
				of({
					email: entity?.email,
					phone: entity?.phone
				}) as Observable<MainFields>
		)
	);

	phoneData$ = this.currentEntity$.pipe(
		switchMap(
			entity =>
				of({
					countryISOCode: entity?.countryISOCode,
					phone: entity?.phone
				}) as Observable<PhoneData>
		)
	);

	personData$ = this.currentEntity$.pipe(
		switchMap(
			entity =>
				of({
					firstName: entity?.firstName,
					lastName: entity?.lastName,
					customId: entity?.customId,
					tags: entity?.tags
				}) as Observable<PersonData>
		)
	);

	personChannels$ = this.currentEntity$.pipe(
		switchMap(entity => of(entity?.channels) as Observable<ChannelType[]>)
	);

	viewSettings$ = combineLatest([
		this.select('viewSettings'),
		this.extraFields$
	]).pipe(
		filter<[FieldsViewSettings, ContactExtraFields]>(Boolean),
		map(
			([settings, extraFields]: [
				FieldsViewSettings,
				ContactExtraFields
			]) => {
				return this.transformSettings(settings, extraFields);
			}
		)
	);

	private transformSettings(
		settings: FieldsViewSettings,
		extraFields: ContactExtraFields
	): TransformedViewSettings | null {
		if (!extraFields) return null;

		this.putAllToHidden(extraFields);

		if (settings === null) return this.putAllToHidden(extraFields);
		return this.setViewSettings(settings, extraFields);
	}

	private putAllToHidden(
		extraFields: ContactExtraFields
	): TransformedViewSettings {
		const settedFields: TransformedViewSettings = {
			visible: [],
			hidden: []
		};

		Object.keys(extraFields).forEach(key => {
			settedFields.hidden.push({ ...extraFields[key], systemName: key });
		});

		return settedFields;
	}

	private setViewSettings(
		settings: FieldsViewSettings,
		extraFields: ContactExtraFields
	): TransformedViewSettings {
		const settedFields: TransformedViewSettings = {
			visible: [],
			hidden: []
		};

		settings.visible.forEach(sysName => {
			settedFields.visible.push({
				...extraFields[sysName],
				systemName: sysName
			});
		});

		settings.hidden.forEach(sysName => {
			settedFields.hidden.push({
				...extraFields[sysName],
				systemName: sysName
			});
		});

		return settedFields;
	}
}
