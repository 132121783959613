import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getChannelById'
})
export class GetChannelByIdPipe implements PipeTransform {
	transform(value: number, options: any[]): string {
		return options?.find(item => item.value === value)?.channel as string;
	}
}
