import { Injectable, Renderer2 } from '@angular/core';
import { StatisticsBackgroundService } from './statistics-barckground.service';
import { getClicksPercent, getUniqClicksPercent } from '../utils';
import { DataService } from './data.service';

@Injectable()
export class ChangeClicksTypeService {
	constructor(
		private renderer: Renderer2,
		private data: DataService,
		private statisticsBackground: StatisticsBackgroundService
	) {}

	changeToGeneral() {
		if (this.data.clicksType === 'general') return;
		this.data.clicksType = 'general';

		this.data.allLinksWrappers.forEach((elem: HTMLElement, index) => {
			const backgroundElement = elem.firstElementChild as HTMLElement;
			const contentElement = elem.lastElementChild as HTMLElement;
			let detail = this.data.allLinksData[index];
			if (!detail.clicks) {
				detail = {
					...detail,
					clicks: 0
				};
			}
			const percent = getClicksPercent(
				detail.clicks,
				this.data.allClicks
			);
			const statistic = `${detail.clicks}(${percent}%)`;

			this.addAnimation(backgroundElement, contentElement);
			this.statisticsBackground.generateBackground(
				backgroundElement,
				detail
			);

			contentElement.innerText = statistic;
		});
	}

	changeToUniq() {
		if (this.data.clicksType === 'uniq') return;
		this.data.clicksType = 'uniq';

		this.data.allLinksWrappers.forEach((elem: HTMLElement, index) => {
			const backgroundElement = elem.firstElementChild as HTMLElement;
			const contentElement = elem.lastElementChild as HTMLElement;
			let detail = this.data.allLinksData[index];
			if (!detail.clicks) {
				detail = {
					...detail,
					uniqClicks: 0
				};
			}
			const uniqPercent = getUniqClicksPercent(
				detail.uniqClicks,
				this.data.allClicks
			);

			const statistic = `${detail.uniqClicks}(${uniqPercent}%)`;

			this.addAnimation(backgroundElement, contentElement);
			this.statisticsBackground.generateBackground(
				backgroundElement,
				detail
			);

			contentElement.innerText = statistic;
		});
	}

	private addAnimation(
		backgroundElement: HTMLElement,
		contentElement: HTMLElement
	) {
		this.renderer.addClass(backgroundElement, 'enkod-link-view__animation');
		this.renderer.addClass(contentElement, 'enkod-link-view__animation');

		setTimeout(() => {
			this.renderer.removeClass(
				backgroundElement,
				'enkod-link-view__animation'
			);
			this.renderer.removeClass(
				contentElement,
				'enkod-link-view__animation'
			);
		}, 200);
	}
}
