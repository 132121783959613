export interface UnitsName {
	second: string[];
	minute: string[];
	hour: string[];
	day: string[];
	week: string[];
	month: string[];
	year: string[];
	event: string[];
	channel: string[];
	message: string[];
	status: string[];
	condition: string[];
	tag: string[];
	entity: string[];
	contact: string[];
	file: string[];
	picture: string[];
	type: string[];
	module: string[];
	scenario: string[];
	time: string[];
	count: string[];
	action: string[];
}

export const UNITS_NAME: UnitsName = {
	second: [
		'pluralization.second',
		'pluralization.seconds',
		'pluralization.seconds_third'
	],
	minute: [
		'pluralization.minute',
		'pluralization.minutes',
		'pluralization.minutes_third'
	],
	hour: [
		'pluralization.hour',
		'pluralization.hours',
		'pluralization.hours_third'
	],
	day: [
		'pluralization.day',
		'pluralization.days',
		'pluralization.days_third'
	],
	week: [
		'pluralization.week',
		'pluralization.weeks',
		'pluralization.weeks_third'
	],
	month: [
		'pluralization.month',
		'pluralization.months',
		'pluralization.months_third'
	],
	year: [
		'pluralization.year',
		'pluralization.years',
		'pluralization.years_third'
	],
	module: [
		'pluralization.module',
		'pluralization.modules',
		'pluralization.modules_third'
	],
	event: [
		'pluralization.event',
		'pluralization.events',
		'pluralization.events_third'
	],
	channel: [
		'pluralization.channel',
		'pluralization.channels',
		'pluralization.channels_third'
	],
	message: [
		'pluralization.message',
		'pluralization.messages',
		'pluralization.messages_third'
	],
	scenario: [
		'pluralization.scenario',
		'pluralization.scenarios',
		'pluralization.scenarios_third'
	],
	status: [
		'pluralization.status',
		'pluralization.statuses',
		'pluralization.statuses_third'
	],
	condition: [
		'pluralization.condition',
		'pluralization.conditions',
		'pluralization.conditions_third'
	],
	tag: [
		'pluralization.tag',
		'pluralization.tags',
		'pluralization.tags_third'
	],
	entity: [
		'pluralization.entity',
		'pluralization.entitys',
		'pluralization.entitys_third'
	],
	contact: [
		'file_uploader_remove.dialog_message_middle_1',
		'file_uploader_remove.dialog_message_middle_2_4',
		'file_uploader_remove.dialog_message_middle_5_10'
	],
	file: [
		'pluralization.file',
		'pluralization.files',
		'pluralization.files_third'
	],
	picture: [
		'pluralization.picture',
		'pluralization.pictures',
		'pluralization.pictures_third'
	],
	type: [
		'pluralization.type',
		'pluralization.types',
		'pluralization.types_third'
	],
	time: [
		'pluralization.time',
		'pluralization.times',
		'pluralization.times_third'
	],
	count: ['pluralization.times', 'pluralization.time', 'pluralization.time'],
	action: [
		'pluralization.action',
		'pluralization.actions',
		'pluralization.actions_third'
	]
};

export function declinationWord(word: keyof UnitsName, n: number): string {
	if (!word) return '';
	if (!UNITS_NAME[word]) return word;

	const num = Math.abs(n) % 100;

	const n1 = num % 10;

	if (num > 10 && num < 20) {
		return UNITS_NAME[word][2];
	}
	if (n1 > 1 && n1 < 5) {
		return UNITS_NAME[word][1];
	}
	if (n1 === 1) {
		return UNITS_NAME[word][0];
	}
	return UNITS_NAME[word][2];
}
