<div class="en-calendar-range"
     [ngClass]="styleClass"
     [formGroup]="dateRange">
     <div class="en-field-medium">
          <en-calendar formControlName="startDate"
                       [styleClass]="styleClass"
                       [showTime]="showTime"
                       [timeOnly]="timeOnly"
                       [placeholder]="'calendar.date_start' | translate"
                       [maxDate]="today"
                       #startDate></en-calendar>
     </div>
     <div class="en-field-medium">
          <en-calendar formControlName="endDate"
                       [styleClass]="styleClass"
                       [showTime]="showTime"
                       [timeOnly]="timeOnly"
                       [minDate]="minDate"
                       [maxDate]="today"
                       [placeholder]="'calendar.date_end' | translate"
                       #endDate></en-calendar>
     </div>
</div>