import { InjectionToken, Provider } from '@angular/core';
import { ID } from '@datorama/akita';
import { BehaviorSubject } from 'rxjs';
import { ShownClicksType } from '../clicks-map.model';

export type OpenClickDetails = {
	id?: ID;
	link: string;
	clicksType: ShownClicksType;
};

export const OPEN_CLICK_DETAILS_TOKEN = new InjectionToken(
	'token for open click details'
);

export const OPEN_CLICK_DETAILS_PROVIDER: Provider = [
	{
		provide: OPEN_CLICK_DETAILS_TOKEN,
		useValue: new BehaviorSubject<OpenClickDetails>({
			id: -1,
			link: '',
			clicksType: 'general'
		})
	}
];
