<span *ngIf="options.hasIcon"
      [ngClass]="icon"
      class='icon-notification'
      [class]="options.status+'-icon'"></span>
<div [class.botText]="options.botText"
     class="wrapper">
    <ng-content></ng-content>
</div>
<span *ngIf="options.hasCloseButton"
      [ngClass]="'en-button-icon-close'"
      class="en en-close"
      (click)="closeNotification.emit()"></span>
