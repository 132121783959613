import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import {
	EnButtonModule,
	EnDialogModule,
	EnDropdownModule,
	EnInputModule,
	EnSmallModule,
	EnTextAreaModule,
	ErrorModule
} from 'ui-lib';

import { ContactsDialogEditFieldNameComponent } from './contacts-dialog-edit-field-name.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		EnSmallModule,
		TranslateModule,
		EnDropdownModule,
		ReactiveFormsModule,
		EnButtonModule,
		CalendarModule,
		EnInputModule,
		EnTextAreaModule,
		ErrorModule,
		EnDialogModule
	],
	declarations: [ContactsDialogEditFieldNameComponent],
	exports: [ContactsDialogEditFieldNameComponent]
})
export class ContactsDialogEditFieldNameModule {}
