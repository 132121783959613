import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
	return new TranslateHttpLoader(http, 'translation', '.json');
}

export class CustomLoader implements TranslateLoader {
	constructor(private http: HttpClient) {}

	getTranslation(lang: string) {
		return this.http.get<string>(`translate/?lang=${lang}`);
	}
}
