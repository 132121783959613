<div class="en-accordion-border"
     [ngClass]="{'en-disabled': disabled, 'opened': selected,
                'icon-mod': accordion.iconMod}">
    <div class="en-accordion-tab"
         [ngClass]="{ 'en-accordion-tab-active': selected}">
        <div class="en-accordion-header"
             [ngClass]="{
					'en-highlight': selected,
					'en-disabled': disabled
				}">
            <a role="tab"
               class="en-accordion-header-link"
               [ngClass]="{'icon-mod': accordion.iconMod,
                            'size-mod': accordion.sizeMod}"
               (click)="toggle($event)"
               (keydown)="onKeydown($event)"
               [attr.tabindex]="disabled ? null : 0"
               [attr.id]="id"
               [attr.aria-controls]="id + '-content'"
               [attr.aria-expanded]="selected">
                <div *ngIf="accordion.iconMod"
                     class="en-accordion-left-icon"
                     [ngClass]="selected
                      ? iconLeftOpened
                      : iconLeftClosed">
                </div>

                <span class="en-accordion-header-text"
                      *ngIf="!hasHeaderFacet">
                    <span *ngIf="customIcon"
                          class="custom-icon"
                          polymorpheus-outlet
                          [content]="customIcon"></span>
                    {{ header }}
                </span>
                <span *ngIf="!accordion.iconMod && !headerTemplate"
                      class="en-accordion-toggle-icon en"
                      [ngClass]="
							selected
								? accordion.iconRightOpened
								: accordion.iconRightClosed
						"></span>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                <ng-content select="en-header"
                            *ngIf="hasHeaderFacet"></ng-content>
            </a>
        </div>
        <div [attr.id]="id + '-content'"
             class="en-toggleable-content"
             [@tabContent]="
					isVisibleContent
						? {
								value: 'visible',
								params: { transitionParams: transitionOptions }
						  }
						: {
								value: 'hidden',
								params: { transitionParams: transitionOptions }
						  }
				"
             role="region"
             [attr.aria-hidden]="!selected"
             [attr.aria-labelledby]="id">
            <div class="en-accordion-content"
                 [ngClass]="{'icon-mod': accordion.iconMod,
                 'size-mod': accordion.sizeMod}">
                <ng-content></ng-content>
                <ng-container *ngIf="contentTemplate && (cache ? loaded : selected)">
                    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
