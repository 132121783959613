<div class="item-content-container"
     [ngClass]="{
                    'full-row': !hasStatuses && !hasListAction,
                    'status-row': !hasListAction,
                    'tools-row': !hasStatuses
    }">
    <ng-content></ng-content>
    <div *ngIf="hasStatuses"
         class="status-container">
        <ng-content select="en-status-tag"></ng-content>
    </div>
    <div *ngIf="hasListAction"
         class="actions-container">
        <ng-content select="[actions], en-list-actions"></ng-content>
    </div>
</div>