<div class="tags__wrapper">
    <div class="add-tags"
         (click)="dialogTagsVisible = true">
    </div>
    <en-tag *ngFor="let tag of tags; index as idx"
            [value]="tag | systemTag"
            [defaultValue]="tag"
            [deletable]="true"
            showTooltip="true"
            status="message"
            (removeItem)="deleteTag(idx)">
    </en-tag>
    <span *ngIf="!tags.length"
          class="tags__no-tags">{{'client_tags.tags_no_selected' | translate}}</span>
</div>


<en-edit-tags *ngIf="dialogTagsVisible"
              [dialogTagsVisible]="dialogTagsVisible"
              [tags]="tags"
              [header]="'client_tags.tags_dialog_header'| translate"
              (closeDialogTags)="dialogTagsVisible = false"
              (onSubmit)="updateTags($event)"></en-edit-tags>
