<div class="container"
     [class.details-mode]="detailsMode">
    <img class="phone"
         src="/assets/images/whatsapp/preview-phone.svg">
    <div class="screen">
        <img class="screen__header"
             src="/assets/images/whatsapp/screen-header.svg">
        <tui-scrollbar class="screen__scroll">
            <span class="today-mark">{{'whatsapp_wizard.preview_today' | translate}}</span>
            <div class="message">
                <div class="message__wrapper">
                    <div class="message__wrapper-top">
                        <div class="message__content">
                            <en-preview-title [form]="form"
                                              [staticTemplate]="staticTemplate"
                                              [isTemplateWizard]="isTemplateWizard"
                                              [loadBeforeInit]="loadBeforeInit">
                            </en-preview-title>
                            <span class="message__text">{{ textContent$ | async }}</span>
                            <span class="message__footer">{{ footerContent$ | async }}</span>
                        </div>
                        <span class="message__date">9:39 AM</span>
                    </div>
                    <ng-container *tuiLet="buttonArray$ | async as buttonArray">
                        <div *ngIf="buttonArray?.length"
                             class="message__buttons">
                            <div *ngFor="let button of buttonArray"
                                 class="button__item"
                                 [class.url-button]="this.form && button.url && button.type === 'URL'">
                                <tui-svg class="margin-right"
                                         [src]="button.type | buttonIcon"></tui-svg>
                                <span class="button__text"
                                      (click)="onButtonClick(button)">
                                    {{ button.text?.length ? button.text : ('whatsapp_wizard_buttons.default_empty'
                                    | translate)}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </tui-scrollbar>
    </div>
</div>