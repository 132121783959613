import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';

@UntilDestroy()
@Component({
	selector: 'en-primitive-spin-button',
	templateUrl: './primitive-spin-button.template.html',
	styleUrls: ['./primitive-spin-button.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveSpinButtonComponent implements OnInit {
	@Input() position: number;

	constructor(
		private cd: ChangeDetectorRef,
		private calendarService: CalendarService
	) {}

	get showMonth() {
		return this.calendarService.getShowDate();
	}

	get showMonthRange() {
		return this.calendarService.getShowDateRange();
	}

	get isSingle() {
		return this.calendarService.getIsSingle();
	}

	get intervalMonth(): boolean {
		return (
			this.showMonthRange[1].month - this.showMonthRange[0].month === 1 &&
			this.showMonthRange[0].year === this.showMonthRange[1].year
		);
	}

	ngOnInit() {
		this.calendarService.currentShowDateRange
			?.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.cd.markForCheck();
			});
	}

	get leftComputedDisabled(): boolean {
		if (this.intervalMonth && this.position === 1) return true;
		return false;
	}

	get rightComputedDisabled(): boolean {
		if (this.intervalMonth && this.position === 0) return true;
		return false;
	}

	onLeftClick() {
		if (this.isSingle)
			this.calendarService.changeShowMonth(this.showMonth.month - 1);
		else if (this.position)
			this.calendarService.changeShowRightMonth(
				this.showMonthRange[this.position].month - 1
			);
		else
			this.calendarService.changeShowLeftMonth(
				this.showMonthRange[this.position].month - 1
			);
	}

	onRightClick() {
		if (this.isSingle)
			this.calendarService.changeShowMonth(this.showMonth.month + 1);
		else if (this.position)
			this.calendarService.changeShowRightMonth(
				this.showMonthRange[this.position].month + 1
			);
		else
			this.calendarService.changeShowLeftMonth(
				this.showMonthRange[this.position].month + 1
			);
	}
}
