import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit
} from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormControl,
	UntypedFormArray,
	Validators
} from '@angular/forms';
import { WaTemplateDTO } from '@enSend/message/whatsapp-template-wizard/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { WhatsappVariableService } from '../../../services';
import { PreviewContentService } from '../services/preview-content.service';

@UntilDestroy()
@Component({
	selector: 'en-preview-title',
	templateUrl: './preview-title.component.html',
	styleUrls: ['./preview-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewTitleComponent implements OnInit {
	lastTitleVariable = '';
	isFirstLoad = true;

	@Input() form: UntypedFormGroup;
	// Собирает превью из шаблона
	@Input() staticTemplate: WaTemplateDTO;

	@Input() loadBeforeInit = false;
	@Input() isTemplateWizard = true;

	constructor(
		private variableService: WhatsappVariableService,
		private contentService: PreviewContentService
	) {}

	get titleTypeControl(): UntypedFormControl {
		return this.form?.controls.titleType as UntypedFormControl;
	}

	get titleForm(): UntypedFormGroup {
		return this.form.controls.title as UntypedFormGroup;
	}

	get titleTextControl(): UntypedFormControl {
		return this.titleForm.controls.text as UntypedFormControl;
	}

	get titleMediaArray(): UntypedFormArray {
		return (this.form.controls.params as UntypedFormGroup).controls
			.header_handle as UntypedFormArray;
	}

	get titleVariableArray(): UntypedFormArray {
		return (this.form?.controls.params as UntypedFormGroup)?.controls
			.header_text as UntypedFormArray;
	}

	get titleTextContent$(): BehaviorSubject<string> {
		return this.contentService.titleTextContent$;
	}

	get titleImageUrl$(): BehaviorSubject<string> {
		return this.contentService.titleImageUrl$;
	}

	get titleVideoUrl$(): BehaviorSubject<string> {
		return this.contentService.titleVideoUrl$;
	}

	get titleDocumentName$(): BehaviorSubject<string> {
		return this.contentService.titleDocumentName$;
	}

	get isMediaTitle(): boolean {
		return (
			this.titleTypeControl.value !== 'none' &&
			this.titleTypeControl.value !== 'TEXT'
		);
	}

	ngOnInit(): void {
		if (this.staticTemplate) {
			this.useStaticTemplate();
		} else {
			this.setListeners();
			if (this.loadBeforeInit) {
				this.titleForm.patchValue(this.titleForm.value);
				this.titleTypeControl.patchValue(this.titleTypeControl.value);
			}
		}
	}

	private useStaticTemplate() {
		let link = '';
		if (this.staticTemplate.params?.header_handle?.length) {
			link = this.staticTemplate.params?.header_handle[0];
		}

		this.staticTemplate.components.forEach(component => {
			switch (component.format) {
				case 'IMAGE':
					this.titleImageUrl$.next(link);
					break;
				case 'VIDEO':
					this.titleVideoUrl$.next(link);
					break;
				case 'DOCUMENT':
					this.titleDocumentName$.next(
						this.contentService.getDocumentName(link)
					);
					break;
				case 'TEXT':
					this.titleTextContent$.next(component.text || '');
					break;
				default:
					break;
			}
		});
	}

	private setListeners(): void {
		this.titleTypeControl?.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(300),
				switchMap(type => {
					this.isTemplateWizard
						? this.prepareForTemplateWizard()
						: this.prepareForMessageWizard();
					if (type === 'TEXT') {
						return this.titleTextControl.valueChanges.pipe(
							debounceTime(300),
							tap(value => {
								this.correctAndFindVariables(value);
								this.titleTextContent$.next(
									this.variableService.replaceVariablesInText(
										this.titleTextControl?.value,
										this.titleVariableArray?.value
									)
								);
							})
						);
					}
					return this.titleMediaArray.valueChanges.pipe(
						debounceTime(300),
						tap(value => {
							switch (type) {
								case 'IMAGE':
									this.titleImageUrl$.next(value[0]);
									break;
								case 'VIDEO':
									this.titleVideoUrl$.next(value[0]);
									break;
								case 'DOCUMENT':
									this.titleDocumentName$.next(
										this.contentService.getDocumentName(
											value[0]
										)
									);
									break;
								default:
									break;
							}
						})
					);
				})
			)
			.subscribe();

		this.titleVariableArray?.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(300),
				tap(value => {
					this.titleTextContent$.next(
						this.variableService.replaceVariablesInText(
							this.titleTextControl?.value,
							value
						)
					);
				})
			)
			.subscribe();
	}

	private loadTitleFields(): void {
		this.isFirstLoad = false;
		switch (this.titleTypeControl.value) {
			case 'IMAGE':
				this.titleImageUrl$.next(this.titleMediaArray.value[0]);
				break;
			case 'VIDEO':
				this.titleVideoUrl$.next(this.titleMediaArray.value[0]);
				break;
			case 'DOCUMENT':
				this.titleDocumentName$.next(
					this.contentService.getDocumentName(
						this.titleMediaArray.value[0] || ''
					)
				);
				break;
			default:
				break;
		}
	}

	private correctAndFindVariables(text: string): void {
		if (!text) return;
		const lastVariableValue = this.titleVariableArray.value;
		this.titleVariableArray.clear();

		const correctedText = this.variableService.sortVariablesInText(text, 1);
		this.titleTextControl?.patchValue(correctedText, {
			emitEvent: false
		});
		const findedVariables = this.variableService.getVariables(text);

		if (findedVariables.length) {
			this.titleVariableArray.push(
				new UntypedFormControl('', Validators.required)
			);
			if (lastVariableValue.length) {
				this.titleVariableArray.controls[0].patchValue(
					lastVariableValue[0]
				);
			}
		}
	}

	private prepareForTemplateWizard(): void {
		if (this.isFirstLoad) {
			this.loadTitleFields();
		} else {
			this.resetTitleContent();
		}

		if (this.isMediaTitle) {
			this.titleVariableArray.disable();
		} else {
			this.titleVariableArray.enable();
		}
	}

	private prepareForMessageWizard(): void {
		this.resetTitleContent();
		this.loadTitleFields();
	}

	private resetTitleContent(): void {
		this.titleImageUrl$.next('');
		this.titleTextContent$.next('');
		this.titleVideoUrl$.next('');
		this.titleDocumentName$.next('');
	}
}
