import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatorPlugin } from '@datorama/akita';
import { WINDOW } from '@enkod-core/utils';
import { tap } from 'rxjs/operators';

export interface UrlParams {
	perPage?: number;
	sortKey?: string;
	sortOrder?: number;
	limit?: number;
	search?: string;
	offset?: number;
	page?: number;
	location?: string;

	// Типы для фильтров
	startDate?: number;
	endDate?: number;
	channel?: string;
	groupBy?: string;
	domain?: string;
	sendingDomain?: string;
	os?: string;
	sendingType?: string;
	sendingTypes?: string;
	events?: Array<string>;
	userSegment?: Array<string>;
	subscribe?: Array<string>;
	tag?: Array<string>;
	channels?: Array<string>;
	byScenarioGrouping?: string;
	messageIds?: Array<string>;
	period?: string;
	status?: string;
}

@Injectable({
	providedIn: 'root'
})
export class UrlSaverService {
	previousUrl: string;

	constructor(
		@Inject(WINDOW) readonly window: Window,
		private router: Router,
		private route: ActivatedRoute
	) {}

	public setParamsToUrl(params: UrlParams, filters?: object): void {
		let filterWithNull = {};

		//  Заменяем пустые значения на null
		if (filters)
			filterWithNull = Object.entries(filters).reduce(
				(acc, [key, val]) =>
					val === 0 || val === '' || val === null
						? { ...acc, [key]: null }
						: { ...acc, [key]: val },
				{}
			);

		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {
				...filterWithNull,
				location: params.location,
				perPage: params.perPage || null,
				sortKey: params.sortKey || null,
				sortOrder: params.sortOrder || null,
				limit: params.limit || null,
				search: params.search || null,
				offset: params.offset || null
			},
			queryParamsHandling: 'merge',
			replaceUrl: true
		});
	}

	public patchPaginatorControls(
		perPageControl?: UntypedFormControl,
		pageControl?: UntypedFormControl
	) {
		return this.route.queryParams.pipe(
			tap(params => {
				perPageControl?.patchValue(Number(params.perPage), {
					emitEvent: false
				});
				pageControl?.patchValue(Number(params.page) || 1, {
					emitEvent: false
				});
			})
		);
	}

	public patchSearchControl(searchControl: UntypedFormControl) {
		return this.route.queryParams.pipe(
			tap(params => {
				if (params.search) {
					searchControl.patchValue(params.search);
				}
			})
		);
	}

	public setPageToUrl(paginatorRef: PaginatorPlugin<any>) {
		return paginatorRef.pageChanges.pipe(
			tap(page => {
				this.router.navigate([], {
					relativeTo: this.route,
					queryParams: {
						page: page || null
					},
					queryParamsHandling: 'merge',
					replaceUrl: true
				});
			})
		);
	}

	public setFirstPageToUrl(paginatorRef: PaginatorPlugin<any>) {
		paginatorRef.setFirstPage();
		const perPage = this.window.localStorage.getItem('perPage') || 10;
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {
				page: 1,
				perPage
			},
			queryParamsHandling: 'merge',
			replaceUrl: true
		});
	}

	public getQueryParams(): UrlParams {
		return this.route.snapshot.queryParams;
	}

	public saveCurrentUrl(): void {
		this.previousUrl = this.router.url;
	}

	public getPreviousUrl(): string {
		const lastUrl = this.previousUrl;
		this.previousUrl = '';
		return lastUrl;
	}

	public convertStringParamsToArrays(): Object {
		const params = this.route.snapshot.queryParams;
		return Object.entries(params).reduce(
			(acc, [key, val]) =>
				typeof val === 'string'
					? { ...acc, [key]: val.split(',') }
					: { ...acc, [key]: val },
			{}
		);
	}

	public convertToArrayNumber(
		value: string | Array<string> | undefined
	): Array<number> | null {
		if (value)
			return typeof value === 'string'
				? [Number(value)]
				: value.map(item => Number(item));
		return null;
	}

	public convertToArrayString(
		value: string | Array<string> | undefined
	): Array<string> | null {
		if (value) return typeof value === 'string' ? [value] : value;
		return null;
	}
}
