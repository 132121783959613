import { NgControl, UntypedFormControl } from '@angular/forms';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
	selector: '[enInputTrim]'
})
export class InputTrimDirective {
	constructor(private elementRef: ElementRef, private ngControl: NgControl) {}

	get formControl(): UntypedFormControl {
		return this.ngControl.control as UntypedFormControl;
	}

	@HostListener('blur')
	onBlur() {
		this.formControl.patchValue(this.elementRef.nativeElement.value.trim());
	}
}
