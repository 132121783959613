import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fromUnix'
})
export class FromUnixPipe implements PipeTransform {
	transform(value: number): number | string {
		if (value === null || value === 0) return '';
		return value * 1000;
	}
}
