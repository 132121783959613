import { TUI_ICONS_PATH } from '@taiga-ui/core';

const MAPPER: Record<string, string> = {
	enIconCancel: 'enIconCancel',
	enIconStripo: 'enIconStripo',
	enIconShop: 'enIconShop',
	enIconLineWrapping: 'enIconLineWrapping',
	enIconDone: 'enIconDone',
	enIconDoubleDone: 'enIconDoubleDone',
	enIconExclamation: 'enIconExclamation',
	enIconExternalLink: 'enIconExternalLink',
	enIconPhone: 'enIconPhone',
	enIconReply: 'enIconReply',
	enInteractiveTemplate: 'enInteractiveTemplate',
	enInteractiveTemplateDisabled: 'enInteractiveTemplateDisabled',
	enStaticTemplate: 'enStaticTemplate',
	enStaticTemplateDisabled: 'enStaticTemplateDisabled'
};

function iconsPath(name: string): string {
	return `assets/svg-icons/${MAPPER[name]}.svg#${MAPPER[name]}`;
}

export const ICONS_PATH = {
	provide: TUI_ICONS_PATH,
	useValue: iconsPath
};
