import {
	AfterViewInit,
	Directive,
	ElementRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Renderer2
} from '@angular/core';
import {
	BehaviorSubject,
	concat,
	fromEvent,
	NEVER,
	Subject,
	Subscription,
	timer
} from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	switchMap,
	takeUntil,
	tap
} from 'rxjs/operators';
import { WINDOW } from '@enkod-core/utils';
import { TranslateService } from '@ngx-translate/core';
import { css } from './clicks-map-styles';
import {
	AllLinks,
	ClicksMapData,
	ClicksMapDetails,
	ClicksMapModifierInterface
} from './clicks-map.model';
import { getClicksPercent, getElementId, getUniqClicksPercent } from './utils';
import {
	AsideService,
	ChangeClicksTypeService,
	DataService,
	StatisticsBackgroundService,
	TooltipService
} from './services';
import {
	CLICKS_MAP_MODIFIER_TOKEN,
	CLICKS_MAP_TOKEN,
	LinkEditor,
	OPEN_LINK_EDITOR_TOKEN
} from './tokens';
import {
	OpenClickDetails,
	OPEN_CLICK_DETAILS_TOKEN
} from './tokens/open-click-details-token';
import { ClicksMapState } from './state/clicks-map-state.service';

@Directive({
	selector: '[enClicksMap]',
	providers: [
		AsideService,
		ChangeClicksTypeService,
		DataService,
		TooltipService,
		StatisticsBackgroundService
	]
})
export class ClicksMapDirective implements OnInit, OnDestroy, AfterViewInit {
	constructor(
		public el: ElementRef,
		private renderer: Renderer2,
		private translate: TranslateService,
		private aside: AsideService,
		private data: DataService,
		@Inject(CLICKS_MAP_TOKEN) private clicksMapInterface: any,
		private tooltipService: TooltipService,
		private backgroundService: StatisticsBackgroundService,
		@Inject(OPEN_CLICK_DETAILS_TOKEN)
		private openClickDetails$: BehaviorSubject<OpenClickDetails>,
		@Inject(OPEN_LINK_EDITOR_TOKEN)
		private openLinkEditor$: BehaviorSubject<any>,
		@Inject(CLICKS_MAP_MODIFIER_TOKEN)
		private clicksMapModifier: BehaviorSubject<ClicksMapModifierInterface>,
		@Inject(WINDOW) readonly window: Window,
		private state: ClicksMapState
	) {}

	private _clicksMapData: ClicksMapData;
	private _linksEditorData: LinkEditor[];
	private inited = false;
	private init = new Subject();
	private windowHasBeenResized = false;
	private documentReady = new BehaviorSubject(false); // ready когда document.ready и все картинки загружены
	private totalHeight: number;
	private clicksMap: HTMLElement | null;
	private modifier: ClicksMapModifierInterface = this.state.modifier;
	private modifierChanged = false;
	private allLinks: AllLinks[];
	private subscriptions: Subscription[] = [];
	private listeners: Function[] = [];

	private modiferSubscribe: Subscription;
	private documentReadyListener: Subscription;

	@Input() set clicksMapData(clicksMapData: ClicksMapData) {
		if (!clicksMapData) return;
		this._clicksMapData = {
			...clicksMapData,
			details: clicksMapData.details || []
		};
		this.data.allClicks = {
			allClicks: clicksMapData.allClicks,
			allUniqClicks: clicksMapData.allUniqClicks
		};
		this.init.next(true);
	}

	get clicksMapData() {
		return this._clicksMapData;
	}

	@Input() set linksEditorData(linksEditorData: LinkEditor[]) {
		if (!linksEditorData) return;
		this._linksEditorData = linksEditorData;
		this.init.next(true);
	}

	get linksEditorData(): LinkEditor[] {
		return this._linksEditorData;
	}

	get doc(): Document {
		return this.el.nativeElement.contentWindow.document;
	}

	get linkUpdateModifier() {
		return this.modifier === 'linksUpdate';
	}

	ngOnInit() {
		// todo: проверить на слабом компьютере доступен ли this.doc
		// this.checkAllImagesLoaded();
		this.initInterfaceListener();
		// todo: добавить прелоадер (если был клик по карте кликов, но картинки ещё загружаются)
	}

	ngAfterViewInit() {
		if (this.state.visible)
			Promise.resolve().then(() => this.initClicksMap());
	}

	rerenderAll() {
		this.removeClicksMap();
		this.initClicksMap();
	}

	ngOnDestroy() {
		this.unsubscribeClicksMap();
		this.modiferSubscribe.unsubscribe();
	}

	private initInterfaceListener() {
		this.modiferSubscribe = this.clicksMapModifier
			.pipe(
				distinctUntilChanged(),
				tap(resp => {
					this.modifier = resp;
					this.modifierChanged = true;
				}),
				switchMap(() => {
					return this.clicksMapInterface.pipe(
						tap(action => {
							this.doAction(action);
						})
					);
				})
			)
			.subscribe(() => {
				this.modifierChanged = false;
			});
		this.documentReady.next(true);
	}

	private doAction(action: any) {
		switch (action) {
			case 'changeVisible': {
				if (this.windowHasBeenResized) {
					this.rerenderAll();
					break;
				}

				if (this.modifierChanged) {
					this.removeClicksMap();
					this.initClicksMap();
					break;
				}

				if (this.inited) {
					this.state.visible
						? this.hideClicksMap()
						: this.showClicksMap();
				}

				break;
			}
			case 'render': {
				this.initClicksMap();
				break;
			}
			case 'init': {
				if (this.inited) this.removeClicksMap();
				this.initInputDataListener();
				break;
			}
			case 'removeClicksMap': {
				if (this.inited) {
					this.removeClicksMap();
					this.inited = false;
				}
				break;
			}
			default:
				break;
		}
	}

	private hideClicksMap() {
		this.renderer.setStyle(this.clicksMap, 'display', 'none');
		this.state.visible = false;
	}

	private showClicksMap() {
		this.renderer.removeStyle(this.clicksMap, 'display');
		this.state.visible = true;
	}

	private removeClicksMap() {
		const parent = this.renderer.parentNode(this.clicksMap);
		this.renderer.removeChild(parent, this.clicksMap);
		this.clicksMap = null;

		this.unsubscribeClicksMap();
		this.data.reset();
	}

	// start of initialization block
	private initClicksMap() {
		this.state.visible = true;
		if (!this.documentReady.getValue()) {
			this.initDocumentReadyListener();
			return;
		}
		if (!this.clicksMapData) {
			this.initInputDataListener();
			return;
		}
		this.inited = true;
		this.windowHasBeenResized = false;

		this.data.doc = this.doc;
		this.data.clientHeight = this.doc.body.clientHeight;
		this.data.clientWidth = this.doc.body.clientWidth;

		this.initStyles();
		const aCollection = this.doc?.getElementsByTagName('a');
		this.clicksMap = this.createClicksMapWrapper();

		this.allLinks = [].map
			.call(aCollection, (a: HTMLElement) => {
				return {
					href: a.getAttribute('href'),
					...this.getCoords(a)
				} as AllLinks;
			})
			.filter(item => item !== null) as AllLinks[];

		this.data.allLinksWrappers = this.generateLinksWrappers(
			this.allLinks
		).filter(item => item !== null);

		if (!this.linkUpdateModifier) {
			this.generateLinksBackgrounds();
		}

		this.data.allLinksWrappers.forEach(linkWrapper =>
			this.renderer.appendChild(this.clicksMap, linkWrapper)
		);

		if (!this.linkUpdateModifier) {
			const aside = this.aside.createAside();
			this.renderer.appendChild(this.clicksMap, aside);
		}

		this.renderer.appendChild(this.doc.body, this.clicksMap);

		this.initAdaptive();
	}

	private createClicksMapWrapper() {
		const div = this.renderer.createElement('div');
		this.renderer.addClass(div, 'enkod-clicks-map-wrapper');

		this.totalHeight = this.getTotalHeight();
		const width = this.doc.body.offsetWidth + 15;
		this.renderer.setProperty(
			div,
			'style',
			`height: ${this.totalHeight}px; width: ${width}px`
		);

		return div;
	}

	private generateLinksWrappers(allLinks: AllLinks[]): HTMLElement[] {
		// details это массив деталей кликов полученный с бэка
		const details: ClicksMapDetails[] = this.clicksMapData.details.slice();
		// detailsIndex это индекс по которому будут браться детали из массива details
		let detailsIndex = 0;
		return allLinks.map((link: AllLinks) => {
			if (
				(this.linkUpdateModifier &&
					this.linksEditorData?.[detailsIndex]?.isDynamic) ||
				this.isUselessLink(link.href)
			) {
				detailsIndex++;
				return null;
			}
			const linkWrapper = this.renderer.createElement('div');
			this.renderer.setProperty(
				linkWrapper,
				'style',
				`top: ${link.top}px; left: ${link.left}px; height: ${link.height}px; width: ${link.width}px;`
			);

			const wrapperClass = this.linkUpdateModifier
				? 'enkod-link-wrapper-link-update'
				: 'enkod-link-wrapper';
			this.renderer.addClass(linkWrapper, wrapperClass);

			// проверяем отображется ли вообще ссылка
			if (!link.height && !link.width)
				this.renderer.setStyle(linkWrapper, 'display', 'none');

			// events for tooltips
			const mouseleave$ = fromEvent(linkWrapper, 'mouseleave');
			const mouseenter$ = fromEvent(linkWrapper, 'mouseenter');

			if (!this.linkUpdateModifier) {
				const mouseEnterSubscription = mouseenter$
					.pipe(
						switchMap(() =>
							concat(timer(220), NEVER).pipe(
								tap(() =>
									this.tooltipService.showTooltip(linkWrapper)
								),
								takeUntil(mouseleave$)
							)
						),
						distinctUntilChanged()
					)
					.subscribe();
				this.subscriptions.push(mouseEnterSubscription);
			}

			if (this.linkUpdateModifier) {
				const linkHelper = this.renderer.createElement('div');
				const text = this.renderer.createText(
					this.translate.instant('clicks_map.edit_action')
				);

				this.renderer.appendChild(linkHelper, text);
				this.renderer.appendChild(linkWrapper, linkHelper);
				this.renderer.setProperty(linkHelper, 'style', 'display: none');

				const mouseEnterSubscription = mouseenter$
					.pipe(
						tap(() => {
							this.renderer.setProperty(
								linkHelper,
								'style',
								`position: absolute;
                                font-family: 'Inter', sans-serif;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                color: #ffffff;
                                padding: 4px 8px;
                                top: ${link.height}px;
                                left: ${-2}px;
                                background-color: #234EC4;
                                opacity: 1;
                                z-index: 1`
							);
						})
					)
					.subscribe();

				const mouseLeaveSubscription = mouseleave$
					.pipe(
						tap(() => {
							this.renderer.setProperty(
								linkHelper,
								'style',
								'display: none'
							);
						})
					)
					.subscribe();

				this.subscriptions.push(mouseEnterSubscription);
				this.subscriptions.push(mouseLeaveSubscription);
			}

			// event for detail

			const clickEvent$ = fromEvent(linkWrapper, 'click');

			const clickSubscription = clickEvent$
				.pipe(
					tap(() => {
						this.onLinkWrapperClick(linkWrapper);
					})
				)
				.subscribe();
			this.subscriptions.push(clickSubscription);

			// цветной круглешок со статистикой
			const view = this.renderer.createElement('div');
			this.renderer.addClass(view, 'enkod-link-view');

			switch (true) {
				// если нет url, или закончился массив details, значит по ссылке не кликали, записываем нулевую статистику
				default:
					this.setStatistics(
						view,
						linkWrapper,
						detailsIndex,
						details[detailsIndex]
					);
					detailsIndex++;
					break;
			}
			// }

			return linkWrapper;
		});
	}

	private generateLinksBackgrounds() {
		this.data.allLinksWrappers.forEach((elem: HTMLElement, index) => {
			const backgroundElement = elem.firstElementChild as HTMLElement;
			const detail = this.data.allLinksData[index];

			this.backgroundService.generateBackground(
				backgroundElement,
				detail
			);
		});
	}

	private onLinkWrapperClick(linkWrapper: HTMLElement) {
		const id = getElementId(linkWrapper);
		const data = this.data.allLinksData[id];
		if (data.clicks && !this.linkUpdateModifier) {
			this.openClickDetails$.next({
				id: data.urlId,
				link: this.allLinks[data.backId].href as string,
				clicksType: this.data.clicksType
			});
		}

		if (this.linkUpdateModifier) {
			const id = data.backId;
			const linkEditorData = this.linksEditorData[id];

			this.openLinkEditor$.next({
				...linkEditorData,
				linkId: id
			});
		}

		// прокидываем в БС инфу с запроса на ссылки
	}

	// statistics in circles
	private setStatistics(
		view: HTMLElement,
		wrapper: HTMLElement,
		backId: number,
		details?: ClicksMapDetails
	) {
		const id = this.data.allLinksData.length;
		const linkDetails = details
			? { ...details, backId }
			: {
					clicks: 0,
					uniqClicks: 0,
					backId: -1,
					urlId: 0
			  };
		this.data.allLinksData.push(linkDetails);
		this.renderer.setAttribute(wrapper, 'id', `enkod-link-data-${id}`);

		const viewTextDiv = this.renderer.createElement('div');
		const textString = createStatisticText.call(this);
		const text = this.renderer.createText(textString);
		if (!this.linkUpdateModifier) {
			this.renderer.addClass(viewTextDiv, 'enkod-statistic-tex-wrapper');

			this.renderer.appendChild(viewTextDiv, text);
			this.renderer.appendChild(wrapper, view);
			this.renderer.appendChild(wrapper, viewTextDiv);
		}

		return wrapper;

		function createStatisticText(this: ClicksMapDirective): string {
			if (this.data.clicksType === 'general') {
				return `${details?.clicks || 0}(${getClicksPercent(
					details?.clicks,
					this.data.allClicks
				)}%)`;
			}
			return `${details?.uniqClicks || 0}(${getUniqClicksPercent(
				details?.uniqClicks,
				this.data.allClicks
			)}%)`;
		}
	}

	private initStyles() {
		const style = this.renderer.createElement('style');
		style.type = 'text/css';

		style.appendChild(this.renderer.createText(css));

		this.renderer.appendChild(this.doc.head, style);
	}

	private getCoords(elem: HTMLElement) {
		// возвращает координаты относительно документа, высоту и ширину
		const box = elem.getBoundingClientRect();
		return {
			top: box.top + this.el.nativeElement.contentWindow.pageYOffset,
			left: box.left + this.el.nativeElement.contentWindow.pageXOffset,
			height: box.height,
			width: box.width
		};
	}
	// end of initialization block
	//

	// метод проверки загруженности картинок
	// нужен как я понял для скачивания картинки карты кликов
	// но такого функционала нет, и не знаю, есть ли в этом методе смысол
	// на данный момент не используется
	private checkAllImagesLoaded() {
		if (this.doc.readyState !== 'complete') {
			// setTimeout, потому что евент readystatechange не срабатывает
			setTimeout(() => this.checkAllImagesLoaded(), 500);
			return;
		}

		const imgs: HTMLCollection = this.doc.images;

		if (!imgs.length) {
			// нет картинок - нечего загружать
			this.documentReady.next(true);
		}
		const { length } = imgs;
		let counter = 0;

		[].forEach.call(imgs, (img: HTMLImageElement) => {
			if (
				img.complete ||
				img.offsetParent === null ||
				(img.src.includes('%7B%7B') && img.src.includes('%7D%7D')) // проверка на динамическую ссылку
			)
				incrementCounter.call(this);
			else {
				const listener = this.renderer.listen(img, 'load', () => {
					incrementCounter.call(this);
					listener(); // отписка
				});
				this.listeners.push(listener);
			}
		});

		function incrementCounter(this: ClicksMapDirective) {
			counter++;
			if (counter === length) {
				// All images are loaded!
				this.documentReady.next(true);
			}
		}
	}

	private initDocumentReadyListener() {
		if (this.documentReadyListener) return;

		this.documentReadyListener = this.documentReady
			.pipe(
				tap(docReady => {
					if (docReady) this.initClicksMap();
				})
			)
			.subscribe();

		this.subscriptions.push(this.documentReadyListener);
	}

	private initInputDataListener() {
		const subscription = this.init
			.pipe(
				tap(() => {
					this.inited ? this.rerenderAll() : this.initClicksMap();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	private initAdaptive() {
		const resizeEvent$ = fromEvent(this.window, 'resize');
		const subscription = resizeEvent$
			.pipe(
				debounceTime(80),
				tap(() => {
					this.state.visible
						? this.rerenderAll()
						: (this.windowHasBeenResized = true);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	// отписывается от всей логики, которая происходит при отрисовке самой карты кликов
	private unsubscribeClicksMap() {
		this.subscriptions.forEach((subscription: Subscription) =>
			subscription.unsubscribe()
		);
		this.subscriptions = [];

		if (!this.linkUpdateModifier) {
			this.aside.unsubscribeAll();
		}

		// отписка от this.renderer.listen
		this.listeners.forEach(listener => listener());
		this.listeners = [];
	}

	private isUselessLink(link: string | null): boolean {
		if (!link) return true;

		if (
			this.linkUpdateModifier ||
			link.includes('tel:') ||
			link.includes('mailto:')
		)
			return false;

		const backTracks =
			link?.match(/^(http:\/\/|https:\/\/|ftp:\/\/|ftps:\/\/|\/\/).+/) ||
			link?.match(/{{.+?}}/);

		// эти линки на бэке не учитываются

		return (
			link === '{{link_unsubscribe_manager}}' ||
			link === '{{link_unsubscribe}}' ||
			link.includes('{{Unsubscribe(') ||
			link.includes('{{UnsubscribeRedirect(') ||
			link.includes('{{Subscribe(') ||
			link.includes('{{SubscribeRedirect(') ||
			link.includes('{{ConfirmRedirect(') ||
			!backTracks
		);
	}

	private getTotalHeight(): number {
		const bodyMarginTop = this.doc.body.style.marginTop;
		const bodyMarginBottom = this.doc.body.style.marginBottom;
		const html = this.renderer.parentNode(this.doc.body);

		const margin = getPx(bodyMarginTop) + getPx(bodyMarginBottom);

		const totalDocHeight = this.doc.body.offsetHeight + margin;

		const lastLink = this.doc.links.length
			? this.getCoords(this.doc.links[this.doc.links.length - 1])
			: null;

		const totalHeight = Math.max(
			this.doc.body.clientHeight,
			totalDocHeight,
			lastLink?.top
		);

		return totalHeight;

		function getPx(value: string): number {
			if (!value) return 8;

			if (value.includes('rem')) {
				const rem =
					html.style.fontSize.slice(
						0,
						html.style.fontSize.length - 2
					) || 16;
				return Number(value.slice(0, value.length - 3)) * rem;
			}

			return Number(value.slice(0, value.length - 2)) || 0;
		}
	}
}
