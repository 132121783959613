import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	Output
} from '@angular/core';

import { SelectItem } from 'primeng/api';

import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';

import { ContactExtraFields } from '@enKod/contacts-detail/_state/contacts-detail';
import { DATA_TYPE_OPTIONS } from '@enKod/extra-fields/components/extra-field-maker/data-type-options';
import { EditExtrafieldNameService } from '@enKod/contacts-detail/contacts-client-card/edit-extrafield-name/edit-extrafield-name.service';
import { EditExtafieldNameServiceForm } from './edit-extafield-name-form.service';

@Component({
	selector: 'en-edit-extrafield-name',
	templateUrl: './contacts-dialog-edit-field-name.component.html',
	styleUrls: ['./contacts-dialog-edit-field-name.component.scss'],
	providers: [
		EditExtafieldNameServiceForm,
		EditExtrafieldNameService,
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DATA_TYPE_OPTIONS
		},
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'extra_field_form.error_required'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsDialogEditFieldNameComponent {
	readonly loading$ = this.extrafieldDataService.loading$;

	@Input() visible = false;
	@Input() set extraField(value: ContactExtraFields) {
		const { type } = value;

		const convertedField = {
			...value,
			dataType: type
		};

		this.form.patchValue(convertedField);
	}

	@Output() onHide = new EventEmitter<void>();
	@Output() updateData = new EventEmitter<void>();

	constructor(
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly dataTypeOptions: SelectItem[],
		private extraFieldFormService: EditExtafieldNameServiceForm,
		private extrafieldDataService: EditExtrafieldNameService
	) {}

	get form() {
		return this.extraFieldFormService.form;
	}

	get nameHasError() {
		return this.form.get('name')?.hasError('required');
	}

	get formValue() {
		return this.form.value;
	}

	submit() {
		if (this.form.invalid) {
			this.form.get('name')?.markAsDirty();
			return;
		}
		this.updateData.emit(this.formValue);
	}
}
