import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AnyObject, ICRUDService } from '@enkod-core/interfaces';
import {
	convertPaginationAndSortParams,
	deleteEmptyKeys
} from '@enkod-core/utils';
import { Observable } from 'rxjs';

export abstract class CRUDService<D = unknown> implements ICRUDService<D> {
	readonly http = inject(HttpClient);

	constructor(readonly endpoint: string) {}

	list<T = D>(queryParams: AnyObject = {}) {
		const params = new HttpParams({
			fromObject: convertPaginationAndSortParams(
				deleteEmptyKeys(queryParams)
			)
		});
		return this.http.get<T>(this.endpoint, {
			params
		});
	}

	create<T = D, R = D>(data: T) {
		return this.http.post<R>(this.endpoint, data);
	}

	getDetail<T = D>(id: number | string, queryParams?: AnyObject) {
		const params = queryParams
			? new HttpParams({
					fromObject: convertPaginationAndSortParams(
						deleteEmptyKeys(queryParams)
					)
			  })
			: {};
		return this.http.get<T>(`${this.endpoint}${id}/`, {
			params
		});
	}

	update<T = D, R = D>(data: T, id: number | string) {
		return this.http.put<R>(`${this.endpoint}${id}/`, data);
	}

	delete(id: number | string) {
		return this.http.delete(`${this.endpoint}${id}/`);
	}

	export<T extends object>(
		endpoint: string,
		queryParams: T
	): Observable<HttpResponse<Blob>> {
		const params = new HttpParams({
			fromObject: convertPaginationAndSortParams(
				deleteEmptyKeys(queryParams) as {
					[param: string]: string;
				}
			)
		});
		return this.http.get(endpoint, {
			params,
			responseType: 'blob',
			observe: 'response'
		});
	}

	checkActive(id: number | string) {
		return this.http.get(`${this.endpoint}checkActiveMessages/${id}/`);
	}
}
