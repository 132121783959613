<en-dialog headerBackground="danger"
           headerIcon="en en-exclamation-circle-o"
           [header]="'common.impossible_delete' | translate"
           [(visible)]="dialogVisible"
           [draggable]="false"
           [modal]="true"
           (onHide)="closeDialog.emit()">
    <div class="info">
        <div class="info__text">{{ infoText | translate }}</div>
        <div *ngFor="let item of relatedData"
             class="info__detail">
            <span class="id-badge">id {{ item.id }}</span>
            <span class="detail__name"
                  (click)="clickOnItem(item.id)">{{ item.name }}</span>
        </div>
    </div>
    <p-footer>
        <button enButton
                type="button"
                style="margin-right: 1rem;"
                class="h36 en-button-secondary"
                [label]="'common.cancel' | translate"
                (click)="closeDialog.emit()">
        </button>
        <button enButton
                type="button"
                class="h36"
                [label]="'common.delete' | translate"
                [disabled]="true"></button>
    </p-footer>
</en-dialog>