import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TableColumn } from '@enkod-core/interfaces';
import { ColumnsSettings, EnabledColumn } from './table-columns-settings.model';

import { TableColumnsSettingsStore } from './table-columns-settings.store';
import { TableColumnsSettingsQuery } from './table-columns-settings.query';

@Injectable({ providedIn: 'root' })
export class TableColumnsSettingsService {
	private columns: TableColumn[] = [];

	constructor(
		private store: TableColumnsSettingsStore,
		private query: TableColumnsSettingsQuery,
		private router: Router,
		private route: ActivatedRoute
	) {}

	getColumns(columns: TableColumn[]): Observable<TableColumn[]> {
		this.columns = columns;
		return this.query.select(this.getStoreName(this.router.url)).pipe(
			map((enabled: EnabledColumn[]): TableColumn[] => {
				let columns = [];
				if (!enabled) return this.columns;
				const enable: string[] = enabled
					.filter(item => !item.disabled)
					.map(item => item.field);
				columns = <TableColumn[]>this.columns
					.map(column => {
						if (enable.includes(column.field)) {
							return column;
						}
						return null;
					})
					.filter(column => column !== null);
				return this.sortColumns(columns, enable);
			})
		);
	}

	// TODO: избавиться от этого метода, когда будует функционал с двумя табами или сделать более универсальнее
	getMessageColumns([messageColumns, scenarioColumns, whatsappColumns]: Array<
		TableColumn[]
	>): Observable<TableColumn[]> {
		return this.route.queryParams.pipe(
			switchMap((queryParams: Params) => {
				return this.query
					.select(this.getStoreName(this.router.url))
					.pipe(
						tap(() => {
							if (!queryParams.location)
								this.columns = messageColumns;
							if (queryParams.location === 'message')
								this.columns = messageColumns;
							if (queryParams.location === 'scenario')
								this.columns = scenarioColumns;
							if (queryParams.location === 'whatsapp')
								this.columns = whatsappColumns;
						}),
						map((enabled: EnabledColumn[]): TableColumn[] => {
							let columns = [];
							if (!enabled) return this.columns;
							const enable: string[] = enabled
								.filter(item => !item.disabled)
								.map(item => item.field);
							columns = <TableColumn[]>this.columns
								.map(column => {
									if (enable.includes(column.field)) {
										return column;
									}
									return null;
								})
								.filter(column => column !== null);
							return this.sortColumns(columns, enable);
						})
					);
			})
		);
	}

	getContactsColumns([commonColumns, removedColumns]: Array<
		TableColumn[]
	>): Observable<TableColumn[]> {
		return this.route.queryParams.pipe(
			switchMap((queryParams: Params) => {
				return this.query
					.select(this.getStoreName(this.router.url))
					.pipe(
						tap(() => {
							queryParams.location === 'common'
								? (this.columns = commonColumns)
								: (this.columns = removedColumns);
						}),
						map((enabled: EnabledColumn[]): TableColumn[] => {
							let columns = [];
							if (!enabled) return this.columns;
							const enable: string[] = enabled
								.filter(item => !item.disabled)
								.map(item => item.field);
							columns = <TableColumn[]>this.columns
								.map(column => {
									if (enable.includes(column.field)) {
										return column;
									}
									return null;
								})
								.filter(column => column !== null);
							return this.sortColumns(columns, enable);
						})
					);
			})
		);
	}

	reorder(columns: TableColumn[], typeColumns: TableColumn[]) {
		const enableCol = columns.filter(item => !item.reorderableDisabled);
		const currentField: string[] = enableCol.map(value => {
			return value.field;
		});
		typeColumns.forEach(value => {
			if (!currentField.includes(value.field)) {
				enableCol.push({ ...value, disabled: true });
			}
		});
		this.updateTableColumns({
			enableColumns: [...enableCol],
			blockColumns: [columns[0]]
		});
	}

	updateTableColumns(columns: ColumnsSettings): void {
		const enabled = <EnabledColumn[]>[
			...columns.blockColumns,
			...columns.enableColumns
		].map(column => {
			return { field: column.field, disabled: column.disabled };
		});
		this.store.update({ [this.getStoreName(this.router.url)]: enabled });
	}

	getDisabledColumns(): Observable<ColumnsSettings> {
		return this.route.queryParams.pipe(
			switchMap(() =>
				this.query.select(this.getStoreName(this.router.url)).pipe(
					map((enabled: EnabledColumn[]): TableColumn[] => {
						const columns = this.columns.map(column => {
							if (!enabled) return { ...column, disabled: false };
							const disable = enabled
								.filter(item => item.field === column.field)
								.map(item => item.disabled);
							return { ...column, disabled: disable[0] };
						});
						if (!enabled) return columns;
						return this.sortColumns(
							columns,
							enabled.map(item => item.field)
						);
					}),
					map((columns: TableColumn[]): ColumnsSettings => {
						const blockColumns = this.filterColumns(columns, true);
						const enableColumns = this.filterColumns(
							columns,
							false
						);
						return { blockColumns, enableColumns };
					})
				)
			)
		);
	}

	private sortColumns(
		columns: TableColumn[],
		enabled: string[]
	): TableColumn[] {
		return columns.sort((col1: TableColumn, col2: TableColumn) => {
			return enabled.indexOf(col1.field) - enabled.indexOf(col2.field);
		});
	}

	private filterColumns(
		columns: TableColumn[],
		condition: boolean
	): TableColumn[] {
		return columns
			.filter(column => !!column.reorderableDisabled === condition)
			.map(column => ({
				field: column.field,
				header: column.header,
				disabled: column.disabled as boolean
			}));
	}

	getStoreName(url: string) {
		const urlLocation = this.route.snapshot.queryParams.location || '';
		const rootEnd = url.indexOf('?');
		const root = url.substring(0, rootEnd !== -1 ? rootEnd : url.length);
		return `${root}/${urlLocation}`;
	}
}
