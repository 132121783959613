import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'channelName'
})
export class ChannelsNamePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(value: string): string {
		switch (value) {
			case 'email':
			case 'mail':
				return this.translate.instant('channels_name.email');
			case 'push':
				return this.translate.instant('channels_name.push');
			case 'telegram':
				return this.translate.instant('channels_name.telegram');
			case 'mobPush':
				return this.translate.instant('channels_name.mobPush');
			case 'sms':
			case 'phone':
				return this.translate.instant('channels_name.sms');
			case 'whatsapp':
				return this.translate.instant('channels_name.whatsapp');
			case 'viber':
				return this.translate.instant('channels_name.viber');
			case 'media':
				return this.translate.instant('channels_name.wa_media');
			case 'text':
				return this.translate.instant('channels_name.wa_text');
			default:
				return value;
		}
	}
}
