export abstract class AccordionBaseControls {
	abstract readonly _index: Set<number>;

	get index(): number[] {
		return Array.from(this._index);
	}

	openTab(index: number): void {
		this._index.add(index);
	}

	closeTab(index: number): void {
		this._index.delete(index);
	}

	openTabs(numberOfTabs: number): void {
		for (let i = 0; i < numberOfTabs; i++) {
			this._index.add(i);
		}
	}
}
