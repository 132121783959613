import {
	Component,
	ChangeDetectionStrategy,
	Input,
	ChangeDetectorRef,
	Inject,
	Output,
	EventEmitter
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib/notification';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { DetailsService, TAGS_DATA_SERVICE_TOKEN } from './tags-field.token';

@UntilDestroy()
@Component({
	selector: 'en-tags-field',
	templateUrl: './tags-field.component.html',
	styleUrls: ['./tags-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsFieldComponent {
	dialogTagsVisible = false;
	_id: number;
	@Input() tags: string[];
	@Input()
	set id(value: number) {
		this._id = value;
	}

	@Output() onTagsChanged = new EventEmitter();

	get id(): number {
		return this._id;
	}

	constructor(
		@Inject(TAGS_DATA_SERVICE_TOKEN)
		private detailsService: DetailsService,
		private cd: ChangeDetectorRef,
		private notification: NotificationsService
	) {}

	deleteTag(idx: number) {
		const tagValue = this.tags[idx];
		const contactTagsCopy = [...this.tags];
		contactTagsCopy.splice(idx, 1);
		this.tags = contactTagsCopy;
		const data = {
			tags: this.tags
		};
		this.detailsService
			.updateTags(this.id, data)
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.showSuccessToast();
					this.onTagsChanged.emit();
				}),
				catchError(err => {
					contactTagsCopy.splice(idx, 0, tagValue);
					this.tags = contactTagsCopy;
					this.cd.markForCheck();
					this.showErrorToast();
					return throwError(err);
				})
			)
			.subscribe();
	}

	updateTags(tags: string[]) {
		const data = {
			tags
		};
		this.detailsService
			.updateTags(this.id, data)
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.tags = tags;
					this.dialogTagsVisible = false;
					this.showSuccessToast();
					this.cd.markForCheck();
					this.onTagsChanged.emit();
				}),
				catchError(err => {
					this.showErrorToast();
					return throwError(err);
				})
			)
			.subscribe();
	}

	private showSuccessToast() {
		this.notification
			.show('', {
				label: 'message_detail_info.update_tags_toast_text',
				status: NotificationStatus.SUCCESS
			})
			.pipe(untilDestroyed(this))
			.subscribe();
	}

	private showErrorToast() {
		this.notification
			.show('toast.detail_request_error', {
				label: 'toast.summary_try_later',
				status: NotificationStatus.ERROR
			})
			.pipe(untilDestroyed(this))
			.subscribe();
	}
}
