import {
	Component,
	Input,
	Output,
	EventEmitter,
	forwardRef,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const TOGGLEBUTTON_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => EnToggleButtonComponent),
	multi: true
};

@Component({
	selector: 'en-togglebutton',
	templateUrl: './togglebutton.component.html',
	providers: [TOGGLEBUTTON_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
	// styleUrls: ['../button/button.scss']
})
export class EnToggleButtonComponent implements ControlValueAccessor {
	@Input() onLabel: string;

	@Input() offLabel: string;

	@Input() onIcon: string;

	@Input() offIcon: string;

	@Input() ariaLabelledBy: string;

	@Input() disabled: boolean;

	@Input() style: any;

	@Input() styleClass: string;

	@Input() inputId: string;

	@Input() tabindex: number;

	@Input() iconPos = 'left';

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	checked = false;

	onModelChange: Function = () => {};

	onModelTouched: Function = () => {};

	constructor(public cd: ChangeDetectorRef) {}

	toggle(event: Event) {
		if (!this.disabled) {
			this.checked = !this.checked;
			this.onModelChange(this.checked);
			this.onModelTouched();
			this.onChange.emit({
				originalEvent: event,
				checked: this.checked
			});

			this.cd.markForCheck();
		}
	}

	onBlur() {
		this.onModelTouched();
	}

	writeValue(value: any): void {
		this.checked = value;
		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	setDisabledState(val: boolean): void {
		this.disabled = val;
		this.cd.markForCheck();
	}

	get hasOnLabel(): boolean {
		return !!this.onLabel && this.onLabel.length > 0;
	}

	get hasOffLabel(): boolean {
		return !!this.onLabel && this.onLabel.length > 0;
	}
}
