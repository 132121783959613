import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	TemplateRef,
	ChangeDetectionStrategy
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthQuery } from '@enkod-core/authentication/_state';

import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { CustomValidators } from 'custom-validators';
import { environment } from 'environments/environment';

import { FormSubmitService } from './get-service-modal.service';

export interface MessageData {
	messageId: number;
	email: string;
	snippets: {
		email: string;
		phone: number;
		service: string;
	};
}

@UntilDestroy()
@Component({
	selector: 'en-get-service-modal',
	templateUrl: './get-service-modal.component.html',
	styleUrls: ['./get-service-modal.component.scss'],
	providers: [
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'get-service-modal-component.error-incorrect-email',
				invalidEmail:
					'get-service-modal-component.error-incorrect-email',
				pattern: 'get-service-modal-component.errpr-incorrect-phone'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GetServiceModalComponent implements OnInit {
	constructor(
		private authQuery: AuthQuery,
		private formSubmit: FormSubmitService,
		private translate: TranslateService
	) {}

	@Input() modal: boolean;

	@Input() modalTitle: string;

	@Input() description = 'get-service-modal-component.text';

	@Input() modalThanksTemplate: TemplateRef<any>;

	@Output() hide = new EventEmitter<boolean>();

	form: UntypedFormGroup;

	formSubmited = false;

	messageId = environment.production
		? this.translate.instant('get-service-modal-settings.prodMessageID')
		: this.translate.instant(
				'get-service-modal-settings.developmentMessageID'
		  );

	emailReceiver: string = environment.production
		? this.translate.instant(
				'get-service-modal-settings.prodMessageReciever'
		  )
		: this.translate.instant(
				'get-service-modal-settings.developmentMessageReciever'
		  );

	loading$ = this.formSubmit.loading$;

	maskPhone = {
		mask: '+0 (000) 000-00-00'
	};

	ngOnInit() {
		this.form = new UntypedFormGroup({
			email: new UntypedFormControl(this.authQuery.currentEmail, [
				Validators.required,
				CustomValidators.email
			]),
			phone: new UntypedFormControl('', [
				Validators.pattern('^([0-9-+() ]){18}$')
			])
		});
	}

	submit() {
		this.formSubmit
			.sendMessage({
				messageId: +this.messageId,
				email: this.emailReceiver,
				snippets: {
					email: this.form.value.email,
					phone: this.form.value.phone,
					service: this.modalTitle,
					unit: this.authQuery.currentAccountDisplayName
				}
			} as MessageData)
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.formSubmited = true;
				})
			)
			.subscribe();
	}
}
