export function sortBy(array: any[], field: string, order: -1 | 1 = 1): any {
	if (!array) return [];
	const arrayCopy = [...array];

	const copyField = field.charAt(0) === '-' ? field.slice(1) : field;

	arrayCopy.sort((a: any, b: any) => {
		const value1 = a[copyField];
		const value2 = b[copyField];
		let result = null;

		if (value1 == null && value2 != null) result = -1;
		else if (value1 != null && value2 == null) result = 1;
		else if (value1 == null && value2 == null) result = 0;
		else if (typeof value1 === 'string' && typeof value2 === 'string')
			result = value1.localeCompare(value2);
		// eslint-disable-next-line no-nested-ternary
		else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

		return order * result;
	});

	return arrayCopy;
}
