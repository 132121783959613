import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnRangeTagComponent } from './range-tag.component';

@NgModule({
	imports: [CommonModule],
	declarations: [EnRangeTagComponent],
	exports: [EnRangeTagComponent]
})
export class EnRangeTagModule {}
