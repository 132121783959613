import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
	transform(currency: string): string {
		switch (currency) {
			case 'RUB':
				return '₽';
			case 'BYN':
				return 'Br';
			case 'EUR':
				return '€';
			case 'USD':
				return '$';
			case 'BRL':
				return 'R$';
			case 'GBP':
				return '£';
			case 'GEL':
				return '₾';
			case 'KZT':
				return '₸';
			case 'CNY':
				return '¥';
			case 'PLN':
				return 'zł';
			case 'TRY':
				return '₺';
			case 'UAH':
				return '₴';
			default: {
				return '';
			}
		}
	}
}
