import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export const FORCE_RERENDER_TOKEN = new InjectionToken<Subject<void>>(
	'force rerender token'
);

export const FORCE_RERENDER_PROVIDER = [
	{
		provide: FORCE_RERENDER_TOKEN,
		useClass: Subject
	}
];
