import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Input,
	forwardRef,
	ViewChild
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormBuilder,
	UntypedFormGroup,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

import { DateRange } from '../calendar-range/calendar-range.model';
import { EnCalendarComponent } from '../calendar/calendar.component';

@UntilDestroy()
@Component({
	selector: 'en-calendar-range-future',
	templateUrl: './calendar-range-future.component.html',
	styleUrls: ['../calendar-range/calendar-range.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EnCalendarRangeFutureComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnCalendarRangeFutureComponent
	implements OnInit, ControlValueAccessor
{
	constructor(
		private fb: UntypedFormBuilder,
		private cd: ChangeDetectorRef
	) {}

	@Input() readonly showTime: boolean;

	@Input() readonly timeOnly: boolean;

	@Input() readonly backendTime: boolean; //  86340 - 23 часа 59 минут, прибавляем для бэка

	/** Для видимости этого компонента и его методов
	 * когда используется ViewChild на en-calendar-range-future */
	@ViewChild('startDate') startDate: EnCalendarComponent;

	@ViewChild('endDate') endDate: EnCalendarComponent;

	dateRange: UntypedFormGroup;

	today: Date = new Date();

	minDate: Date | null;

	maxDate: Date | null;

	ngOnInit(): void {
		this.setForm();
		this.setupFormListeners();
	}

	writeValue(value: DateRange): void {
		if (!Object.keys(value).length) return;
		this.dateRange.setValue(value);
		this.cd.markForCheck();
	}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	private setupFormListeners(): void {
		this.dateRange.valueChanges
			.pipe(
				untilDestroyed(this),
				map((value: DateRange) => {
					if (this.backendTime) {
						return {
							...value,
							endDate: value.endDate
								? +value.endDate + 86340
								: null
						};
					}
					return value;
				})
			)
			.subscribe((value: DateRange) => {
				this.maxDate = value?.endDate
					? this.fromUnix(value?.endDate)
					: null;
				this.minDate = value?.startDate
					? this.fromUnix(value?.startDate)
					: null;
				this.onChange(value);
			});
	}

	private setForm(): void {
		this.dateRange = this.fb.group({
			startDate: null,
			endDate: null
		});
	}

	private fromUnix(timestamp: number): Date {
		return new Date(timestamp * 1000);
	}
}
