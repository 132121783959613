import { TranslateService } from '@ngx-translate/core';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	OnInit
} from '@angular/core';
import { isNumber, TuiDestroyService, tuiPure } from '@taiga-ui/cdk';
import { fromEvent, timer } from 'rxjs';
import { repeatWhen, takeUntil } from 'rxjs/operators';
import {
	DEFAULT_ALERT_AUTOCLOSE_TIMEOUT,
	NotificationTokenOptions,
	NOTIFICATION_OPTIONS
} from '../../notification.model';
import { NotificationContentContext } from '../notifications-content-context';
import { NotificationAlert } from './Notification-alert';

@Component({
	selector: 'en-notification-alert',
	templateUrl: './notification-alert.component.html',
	styleUrls: ['./notification-alert.component.scss'],
	providers: [TuiDestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationAlertComponent<O, I> implements OnInit {
	@Input() item!: NotificationAlert<O, I>;

	constructor(
		@Inject(ElementRef)
		private translate: TranslateService,
		private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
		@Inject(NOTIFICATION_OPTIONS)
		public readonly options: NotificationTokenOptions
	) {}

	ngOnInit() {
		this.initAutoClose();
	}

	get context(): NotificationContentContext<O, I> {
		return this.calculateContext(this.item);
	}

	closeNotification() {
		this.item.observer.complete();
	}

	@tuiPure
	private calculateContext({
		status,
		data,
		observer
	}: NotificationAlert<O, I>): NotificationContentContext<O, I> {
		return {
			$implicit: status,
			data,
			closeHook: () => {
				observer.complete();
			},
			emitHook: (data: O) => {
				observer.next(data);
			},
			emitAndCloseHook: (data: O) => {
				observer.next(data);
				observer.complete();
			}
		};
	}

	private initAutoClose() {
		if (!this.item.autoClose) {
			return;
		}
		timer(
			isNumber(this.item.autoClose)
				? this.item.autoClose
				: DEFAULT_ALERT_AUTOCLOSE_TIMEOUT
		)
			.pipe(
				takeUntil(
					fromEvent(this.elementRef.nativeElement, 'mouseenter')
				),
				repeatWhen(() =>
					fromEvent(this.elementRef.nativeElement, 'mouseleave')
				),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.closeNotification());
	}
}
