import { Pipe, PipeTransform } from '@angular/core';

interface ClassList {
	[classes: string]: boolean;
}

@Pipe({
	name: 'getClassList'
})
export class ClassListPipe implements PipeTransform {
	transform(severity: string, isBackground: boolean): ClassList {
		const mainClasses = `en-message-${severity}`;
		const borderSeverity = `border-${severity}`;
		return {
			[mainClasses]: true,
			[borderSeverity]: isBackground
		};
	}
}
