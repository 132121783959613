export const defaultColors = [
	'#22A6BA',
	'#EBA742',
	'#1B77E0',
	'#8619E5',
	'#1DC296',
	'#1D9AC2',
	'#C2B11D',
	'#C21D8A',
	'#97794A',
	'#BEDAFA',
	'#B6F1FA',
	'#4597F5'
];
