import { DEFAULT_KEY_VALUE_MAP_CONFIG } from '@enkod-core/constants';
import { AnyObject } from '@enkod-core/interfaces/any-object';
import { KeyValueMapConfig } from '@enkod-core/interfaces/key-value-map-config';

/**
 * @param array массив в формате @example [{ value: smth, secondValue: smth } ...]
 * @param config объект, в который передается название ключа первого значения (в примере это value)
 * и второго (в примере это secondValue)
 */
export function tranformToKeyValue(
	array: AnyObject[],
	config: KeyValueMapConfig = DEFAULT_KEY_VALUE_MAP_CONFIG
): AnyObject {
	if (!Array.isArray(array))
		throw new Error(
			'В tranformToKeyValue или pipe keyValueMap попал не массив'
		);
	const map: AnyObject = {};
	array.forEach((element: AnyObject) => {
		map[element[config.key]] = element[config.value];
	});
	return map;
}
