<div class="row">
    <div *ngFor="let day of daysOfWeekMin"
         class="cell_weekday">{{ day | translate }}</div>
</div>
<ng-container *ngIf="isSingle;else rangeCalendar">
    <div *tuiLet="showDate | tuiCalendarSheet as sheet">
        <div *tuiRepeatTimes="let rowIndex of sheet.length"
             class="row row_day">
            <ng-container *tuiRepeatTimes="let colIndex of sheet[rowIndex].length">
                <ng-container *tuiLet="sheet[rowIndex][colIndex] as item">
                    <div class="cell">
                        <div class="item"
                             [class.item__selected]="selected(item)"
                             [class.item__disabled]="item | tuiMapper : disabledDays"
                             [class.item_today]="item | tuiMapper : itemIsToday"
                             (click)="onItemClick(item)">
                            {{ item.day }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-template #rangeCalendar>
    <div *tuiLet="showDateRange[position] | tuiCalendarSheet as sheet">
        <div *tuiRepeatTimes="let rowIndex of sheet.length"
             class="row row_day">
            <ng-container *tuiRepeatTimes="let colIndex of sheet[rowIndex].length">
                <ng-container *tuiLet="sheet[rowIndex][colIndex] as item">
                    <div class="cell"
                         [class.cell__range]="highlightRangeDate(item)"
                         [class.cell__start]="date[0].date.toString()== item"
                         [class.cell__start_only]=" date[0].date.toString()== item && date[1].date.toString() === nullDateNew.toString()"
                         [class.cell__end]="date[1].date.toString()== item"
                         [class.cell__start_end_same]="date[0].date.toString() === date[1].date.toString() === item
                         && date[0].date.toString()!==nullDateNew.toString()">
                        <div class="item"
                             [class.item__disabled]="item | tuiMapper : disabledDays"
                             [class.item_today]="item | tuiMapper : itemIsToday"
                             (click)="onItemClick(item)">
                            {{ item.day }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>