import { inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import {
	delay,
	mergeMap,
	retryWhen,
	switchMap,
	take,
	takeUntil,
	tap
} from 'rxjs/operators';

import { PollingConfig } from 'ui-lib';

/**
 * @description
 * При объявлении сервиса - прокидываем конфиг поллинга
 * Основной метод - initPolling
 * прокидываем в него запрос, который хотим повторять
 *
 * @Отписка не автоматическая! Необходимо отписаться,
 * вызвав метод destroyPolling
 */
export class PollingService {
	/** Дефолтное значение времени между отправками запроса при ошибке */
	private readonly ERROR_DELAY_ATTEMPT = 10000;
	/** Дефолтное значение попыток повторных запросов при ошибке */
	private readonly ERROR_COUNT_ATTEMPTS = 5;

	private readonly document: Document = inject(DOCUMENT);
	private readonly onFocus$ = new BehaviorSubject(false);

	readonly destroyPolling$ = new Subject();

	pollingWasInitiated = false;

	constructor(private readonly config: PollingConfig) {
		this.setVisibilityChangeListener();
	}

	setVisibilityChangeListener() {
		this.onFocus$.next(this.document.hasFocus());

		this.document.addEventListener(
			'visibilitychange',
			this.onVisibilityChange
		);
	}

	initPolling<T>(request: Observable<T>): Observable<T> {
		// дополнительная проверка, что поллинг true
		if (this.config.usePolling)
			return this.onFocus$.pipe(
				switchMap(val => {
					// если вкладка пропадает из фокуса - делаем 1 запрос
					if (!val) return request;

					// если вкладка появляется в фокусе - запускаем поллинг
					return timer(0, this.config.pollingPeriod).pipe(
						mergeMap(() => {
							return request.pipe(
								tap(() => {
									this.pollingWasInitiated = true;
								})
							);
						}, 2), // concurrent(2) ограничиваем число одновременно выполняющихся запросов
						takeUntil(this.destroyPolling$),
						retryWhen(errors => {
							return errors.pipe(
								delay(
									this.config.errorDelayAttempt ||
										this.ERROR_DELAY_ATTEMPT
								),
								takeUntil(this.destroyPolling$),
								take(
									this.config.errorCountAttempts ||
										this.ERROR_COUNT_ATTEMPTS
								)
							);
						})
					);
				})
			);

		return request;
	}

	destroyPolling() {
		this.destroyPolling$.next();
		this.pollingWasInitiated = false;
		this.config.usePolling = false;

		this.document.removeEventListener(
			'visibilitychange',
			this.onVisibilityChange
		);
	}

	private onVisibilityChange = () => {
		if (this.document.hidden) {
			this.onFocus$.next(false);
		} else {
			this.onFocus$.next(true);
		}
	};
}
