<progress class="progress__line"
          [class.full]="storageSizes.currentSize >= storageSizes.maxSize"
          [value]="storageSizes.currentSize"
          [max]="storageSizes.maxSize"></progress>
<ng-container *ngIf="isTextShowed">
    <div *ngIf="!loading; else loader"
         class="progress__text"
         [class.full]="storageSizes.currentSize >= storageSizes.maxSize">
        {{ storageSizes | convertStrgSize : roundingNumbers }}
    </div>
</ng-container>
<ng-template #loader>
    <skltn-root>
        <div skltn-bone
             class="skeleton"></div>
    </skltn-root>
</ng-template>
<div *ngIf="storageSizes.currentSize >= storageSizes.maxSize"
     class="progress__full">{{ errorText }}
</div>
