/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	NgModule,
	Component,
	OnDestroy,
	Input,
	Output,
	EventEmitter,
	AfterContentInit,
	Optional,
	ElementRef,
	ChangeDetectionStrategy,
	ContentChildren,
	QueryList,
	TemplateRef,
	ViewEncapsulation,
	ChangeDetectorRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	trigger,
	// state,
	style,
	transition,
	animate
} from '@angular/animations';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { EnTempalateDirective } from '@shared';
import { ClassListPipe } from './class-list.pipe';

export type ModeView = 'default' | 'template';

@Component({
	selector: 'en-messages',
	templateUrl: './messages.html',
	animations: [
		trigger('messageAnimation', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(-25%)' }),
				animate('{{showTransitionParams}}')
			]),
			transition(':leave', [
				animate(
					'{{hideTransitionParams}}',
					style({
						height: 0,
						marginTop: 0,
						marginBottom: 0,
						marginLeft: 0,
						marginRight: 0,
						overflow: 'hidden',
						opacity: 0
					})
				)
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class MessagesComponent implements AfterContentInit, OnDestroy {
	@Input() value: Message[] | null;

	@Input() style: any;

	@Input() styleClass: string;

	@Input() enableService = true;

	@Input() key: string;

	@Input() severity: string;

	@Input() summary: string;

	@Input() detail: string;

	@Input() showTransitionOptions = '300ms ease-out';

	@Input() hideTransitionOptions = '200ms cubic-bezier(0.86, 0, 0.07, 1)';

	@Input() isBackground = true;

	@Input() flexable = false;

	@Input() visible = false;

	@Input() viewMode: ModeView = 'default';

	@Input() closable: boolean;

	@Input() wrapperStyle = 'margin: 0.5rem 0';

	// withTab специально для табов, тк parentEl.offsetParent: null, из-за того, что таб скрывается через display: none
	@Input() withTab = false;

	@ContentChildren(EnTempalateDirective) templates: QueryList<any>;

	@Output() valueChange: EventEmitter<Message[]> = new EventEmitter<
		Message[]
	>();

	messageSubscription: Subscription;

	clearSubscription: Subscription;

	contentTemplate: TemplateRef<any>;

	borderSeverity = '';

	currentClasses: any;

	constructor(
		@Optional() public messageService: MessageService,
		public el: ElementRef,
		public cd: ChangeDetectorRef
	) {}

	ngAfterContentInit() {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'content':
					this.contentTemplate = item.template;
					break;

				default:
					this.contentTemplate = item.template;
					break;
			}
		});

		if (
			this.messageService &&
			this.enableService &&
			!this.contentTemplate
		) {
			this.messageSubscription =
				this.messageService.messageObserver.subscribe(
					(messages: any) => {
						if (messages) {
							if (messages instanceof Array) {
								const filteredMessages = messages.filter(
									m => this.key === m.key
								);
								this.value = this.value
									? [...this.value, ...filteredMessages]
									: [...filteredMessages];
							} else if (this.key === messages.key) {
								this.value = this.value
									? [...this.value, ...[messages]]
									: [messages];
							}

							this.cd.markForCheck();
						}
					}
				);

			this.clearSubscription =
				this.messageService.clearObserver.subscribe(key => {
					if (key) {
						if (this.key === key) {
							this.value = null;
						}
					} else {
						this.value = null;
					}

					this.cd.markForCheck();
				});
		}
	}

	hasMessages() {
		const parentEl = this.el.nativeElement.parentElement;
		// withTab специально для табов, тк parentEl.offsetParent: null, из-за того, что таб скрывается через display: none
		if ((parentEl && parentEl.offsetParent) || this.withTab) {
			return (
				this.contentTemplate != null ||
				(this.value && this.value.length > 0)
			);
		}

		return false;
	}

	clear() {
		this.value = [];
		this.valueChange.emit(this.value);
	}

	removeMessage(i: number) {
		this.value = this.value!.filter((_msg, index) => index !== i); // не пропускаает компилятор т.к msg не используется.
	}

	get icon(): string | null {
		const severity =
			this.severity ||
			(this.hasMessages() ? this.value![0].severity : null);

		if (this.hasMessages()) {
			switch (severity) {
				case 'success':
					return 'en-check-circle-o';
				case 'info':
					return 'en-info-circle-o';
				case 'error':
					return 'en-info-circle-o';
				case 'warn':
					return 'en-exclamation-triangle';
				case 'question':
					return 'en-help-triangle';
				default:
					return 'en-info-circle-o';
			}
		}
		return null;
	}

	ngOnDestroy() {
		if (this.messageSubscription) {
			this.messageSubscription.unsubscribe();
		}

		if (this.clearSubscription) {
			this.clearSubscription.unsubscribe();
		}
	}
}

@NgModule({
	imports: [CommonModule, TranslateModule],
	exports: [MessagesComponent],
	declarations: [MessagesComponent, ClassListPipe]
})
export class EnMessagesModule {}
