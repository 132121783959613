import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiActiveZoneModule, TuiFocusableModule } from '@taiga-ui/cdk';
import {
	TuiDropdownControllerModule,
	TuiHostedDropdownModule,
	TuiScrollbarModule
} from '@taiga-ui/core';
import { TuiLinkModule } from '@taiga-ui/core/components/link';
import { TuiMonthPipeModule } from '@taiga-ui/core/pipes';
import { EnPrimitiveMonthPickerModule } from '../primitive-month-picker/primitive-month-picker.module';
import { EnPrimitiveSpinButtonModule } from '../primitive-spin-button/primitive-spin-button.module';
import { EnPrimitiveYearPickerModule } from '../primitive-year-picker/primitive-year-picker.module';

import { PrimitiveYearMonthPaginationComponent } from './primitive-year-month-pagination.component';

@NgModule({
	imports: [
		CommonModule,
		TuiFocusableModule,
		EnPrimitiveSpinButtonModule,
		TuiHostedDropdownModule,
		TuiActiveZoneModule,
		EnPrimitiveYearPickerModule,
		EnPrimitiveMonthPickerModule,
		TuiDropdownControllerModule,
		TranslateModule,
		TuiLinkModule,
		TuiScrollbarModule,
		TuiMonthPipeModule
	],
	declarations: [PrimitiveYearMonthPaginationComponent],
	exports: [PrimitiveYearMonthPaginationComponent]
})
export class EnPrimitiveYearMonthPaginationModule {}
