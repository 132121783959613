import { Pipe, PipeTransform } from '@angular/core';
import { declinationWord, UnitsName } from 'utils';

@Pipe({ name: 'declinationWord' })
export class DeclinationWord implements PipeTransform {
	transform(word: keyof UnitsName, n: number): string {
		if (!word) return '';
		return declinationWord(word, n);
	}
}
