<div class="nav-button">
    <div class="nav-completed">
        <i class="en"
           [ngClass]="{
            'circle': !completed ,
            'en-done-circle-o': completed,
            'selected': selected,
            'disabled': disableButton
        }"></i>
    </div>
    <div [ngClass]="{
        'selected': selected,
        'disabled': disableButton
    }"
         class="nav-label">
        {{label}}
    </div>
</div>