import {
	Directive,
	Input,
	ElementRef,
	Renderer2,
	OnDestroy
} from '@angular/core';

@Directive({
	selector: '[enLoading]'
})
export class EnLoadingDirective implements OnDestroy {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@Input() set enLoading(val: boolean) {
		if (val)
			this.renderer.addClass(this.el.nativeElement, 'en-loading-button');
		else
			this.renderer.removeClass(
				this.el.nativeElement,
				'en-loading-button'
			);
	}

	ngOnDestroy() {
		this.renderer.removeClass(this.el.nativeElement, 'en-loading-button');
	}
}
