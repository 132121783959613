import { DATE_FILLER_LENGTH, TuiDay, TuiTimeMode } from '@taiga-ui/cdk';
import {
	TuiTextMaskPipeHandler,
	TuiWithOptionalMinMaxWithValue
} from '@taiga-ui/core';
import { tuiCreateAutoCorrectedTimePipe } from './calendar-time.pipe';

export function tuiCreateAutoCorrectedDateTimePipe(
	config: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay>,
	timeMode: TuiTimeMode
): TuiTextMaskPipeHandler {
	const timePipe = tuiCreateAutoCorrectedTimePipe(timeMode);
	return value => {
		if (value.length < DATE_FILLER_LENGTH) {
			return { value };
		}
		const [date, time] = value.split(', ');
		const formattedDate = normalizeDateValue(date, config);
		if (!time) {
			return { value: formattedDate };
		}
		const pipedTime = timePipe(time, {} as any);

		if (!pipedTime || typeof pipedTime === 'string') {
			return false;
		}
		return {
			value: `${formattedDate}, ${pipedTime.value}`,
			indexesOfPipedChars: pipedTime.indexesOfPipedChars
				? pipedTime.indexesOfPipedChars.map(
						(i: number) => i + date.length + 2
				  )
				: undefined
		};
	};
}

export function normalizeDateValue(
	dateValue: string,
	{ value, min, max }: TuiWithOptionalMinMaxWithValue<TuiDay | null, TuiDay>
): string {
	return value && value.toString() === dateValue
		? dateValue
		: TuiDay.normalizeParse(dateValue).dayLimit(min, max).toString();
}
