import { TuiLetModule } from '@taiga-ui/cdk';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from '@shared';

import { EnButtonModule } from '../../button/button';

import { EnReorderComponent } from './reorder.component';
import { EnTableSettingsModule } from '../table-settings.module';

@NgModule({
	imports: [
		CommonModule,
		EnTableSettingsModule,
		EnButtonModule,
		DragDropModule,
		TuiLetModule,
		SharedModule
	],
	declarations: [EnReorderComponent],
	exports: [EnReorderComponent]
})
export class EnReorderModule {}
