import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import {
	TuiHostedDropdownModule,
	TuiTextfieldControllerModule,
	TuiDropdownControllerModule
} from '@taiga-ui/core';
import { EnButtonModule } from 'ui-lib/button/button';
import { EnMultilevelDropdownComponent } from './dropdown-multilevel.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		TuiHostedDropdownModule,
		TuiActiveZoneModule,
		TuiTextfieldControllerModule,
		TuiDropdownControllerModule,
		TuiLetModule,
		MatMenuModule,
		EnButtonModule
	],
	declarations: [EnMultilevelDropdownComponent],
	exports: [EnMultilevelDropdownComponent]
})
export class EnMultilevelDropdownModule {}
