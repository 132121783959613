<div class="alert__icon-block">
    <span class="alert__icon en en-exclamation-circle-o"
          [ngClass]="(isAdvancedMessage(message) ? advancedMessage?.status || status : status) | alertIcon"></span>
</div>
<div class="alert__text-block">
    <ng-container *ngIf="isAdvancedMessage(message); else basic">
        <div *ngIf="advancedMessage.title"
             class="alert__title">
            <span>
                {{ advancedMessage.title | translate }}
                {{ advancedMessage.titleParam?.join(', ') || '' }}
            </span>
        </div>
        <div *ngIf="advancedMessage.desc"
             class="alert__desc">
            <span>{{ advancedMessage.desc | translate}}</span>
        </div>
    </ng-container>
    <ng-template #basic>
        <div class="alert__title">
            <span>{{ message | translate }}</span>
        </div>
    </ng-template>
</div>
