import { inject, InjectionToken } from '@angular/core';
import { TableColumn } from '@enkod-core/interfaces';
import { TABLE_COLS_TOKEN } from '@enkod-core/tokens';

import { Observable } from 'rxjs';

import { TableColumnsSettingsService } from '../_state/table-columns-settings.service';

export const REORDER_COLUMNS_TOKEN = new InjectionToken<
	Observable<TableColumn[]>
>('Observable of reorder table columns token');

export function getTableColumnsWithSettings(
	columns: TableColumn[]
): Observable<TableColumn[]> {
	return inject(TableColumnsSettingsService).getColumns(columns);
}

// TODO:
// Рефактор
// Чтобы работало, когда два таба с двумя разными таблицами и столбцами
export const REORDER_COLUMNS_PROVIDER = {
	provide: REORDER_COLUMNS_TOKEN,
	deps: [TABLE_COLS_TOKEN],
	useFactory: getTableColumnsWithSettings
};

export function getMessageTableColumnsWithSettings(
	columns: Array<TableColumn[]>
): Observable<TableColumn[]> {
	return inject(TableColumnsSettingsService).getMessageColumns(columns);
}

export function getContactsTableColumnWithSettings(
	columns: Array<TableColumn[]>
): Observable<TableColumn[]> {
	return inject(TableColumnsSettingsService).getContactsColumns(columns);
}
