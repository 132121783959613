import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	TuiDropdownControllerModule,
	TuiHostedDropdownModule,
	TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiActiveZoneModule, TuiAutoFocusModule } from '@taiga-ui/cdk';
import { TextMaskModule } from 'angular2-text-mask';
import { DropdownCalendarComponent } from './dropdown-calendar.component';
import { EnCalendarNewModule } from '../calendarNew/calendar.module';
import { EnInputModule } from '../input/input';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnCalendarNewModule,
		TranslateModule,
		EnInputModule,
		TextMaskModule,
		TuiHostedDropdownModule,
		TuiActiveZoneModule,
		TuiTextfieldControllerModule,
		TuiDropdownControllerModule,
		TuiAutoFocusModule
	],
	declarations: [DropdownCalendarComponent],
	exports: [DropdownCalendarComponent]
})
export class EnDropdownCalendarNewModule {}
