import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@enkod-core/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationStatus } from 'ui-lib';
import { WaTemplateDTO } from '../models/template.model';

@Injectable()
export class TemplateDataService {
	private readonly endPoint = 'ensend/whatsapp/template/';
	private readonly emptyTemplate: WaTemplateDTO = {
		id: 0,
		name: '',
		category: '',
		language: '',
		tags: [],
		params: {
			body_text: [],
			footer_text: []
		},
		buttons: [],
		autoReplies: [],
		components: [],
		type: 'text'
	};

	private readonly alreadyUseError =
		'already in use. Please choose a different name for your template.';

	constructor(
		private http: HttpClient,
		private notificationsService: NotificationsService
	) {}

	create(): Observable<WaTemplateDTO> {
		return this.http
			.post<WaTemplateDTO>(this.endPoint, this.emptyTemplate)
			.pipe(
				catchError(e => {
					this.showErrorToast();
					return throwError(e);
				})
			);
	}

	getById(id: number): Observable<WaTemplateDTO> {
		return this.http.get<WaTemplateDTO>(`${this.endPoint}${id}/`).pipe(
			catchError(e => {
				this.showErrorToast();
				return throwError(e);
			})
		);
	}

	saveAsDraft(template: WaTemplateDTO) {
		return this.http.put(this.endPoint, template).pipe(
			tap(() => {
				this.notificationsService
					.show('toast.detail_message_draft_create', {
						label: 'toast.created',
						status: NotificationStatus.SUCCESS,
						params: {
							id: template.id
						}
					})
					.subscribe();
			}),
			catchError(e => {
				e.status === 400
					? this.showAlreadyUseToast()
					: this.showErrorToast();
				return throwError(e);
			})
		);
	}

	sendToReview(
		template: WaTemplateDTO,
		isLoading$: BehaviorSubject<boolean>
	) {
		return this.http.post(`${this.endPoint}sendToReview/`, template).pipe(
			catchError(e => {
				if (e.status === 400) {
					this.showAlreadyUseToast();
				} else {
					e.error.error.includes(this.alreadyUseError)
						? this.showAlreadyUseToast()
						: this.showErrorToast();
				}
				isLoading$.next(false);
				return throwError(e);
			})
		);
	}

	showAlreadyUseToast(): void {
		this.notificationsService
			.show('whatsapp_wizard.name_toast_title', {
				label: 'whatsapp_wizard.name_toast_text',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	showErrorToast(): void {
		this.notificationsService
			.show('toast.detail_request_error', {
				label: 'toast.summary_try_later',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}
}
