import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnInit
} from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

export type NumberRange = {
	minValue: number;
	maxValue: number;
};

const DEFAULT_RANGE: NumberRange = {
	minValue: Number.MIN_SAFE_INTEGER,
	maxValue: Number.MAX_SAFE_INTEGER
};

@UntilDestroy()
@Directive({
	selector: '[enNumberRange]'
})
export class NumberRangeDirective implements OnInit {
	@Input()
	private readonly rangeConfig = DEFAULT_RANGE;

	constructor(private ngControl: NgControl, private elementRef: ElementRef) {}

	get formControl(): UntypedFormControl {
		return this.ngControl.control as UntypedFormControl;
	}

	@HostListener('blur')
	onBlur() {
		if (
			!this.formControl.value ||
			this.formControl.value < this.rangeConfig.minValue
		)
			this.formControl.patchValue(this.rangeConfig.minValue);
	}

	ngOnInit(): void {
		this.formControl?.valueChanges
			.pipe(untilDestroyed(this), distinctUntilChanged())
			.subscribe(value => {
				if (value > this.rangeConfig.maxValue)
					this.formControl.patchValue(this.rangeConfig.maxValue);
			});

		this.elementRef.nativeElement.min = this.rangeConfig.minValue;
		this.elementRef.nativeElement.max = this.rangeConfig.maxValue;
	}
}
