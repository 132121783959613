import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'en-table-list',
	templateUrl: './en-table-list.component.html',
	styleUrls: ['./en-table-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnTableListComponent {
	@Input()
	readonly loading: boolean;
}
