import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { CustomValidators } from 'custom-validators';
import { markAllAsDirty } from 'utils';
import { toUnix } from 'app/core/utils/time';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';

import { GlobalBlacklist } from '@state-admin/global-blacklist';
import { LocalBlackList } from '@state-enSend/local-blacklist';

@Component({
	selector: 'en-dialog-black-list-add',
	templateUrl: './dialog-black-list-add.component.html',
	styleUrls: ['./dialog-black-list-add.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'black_list_dialog.validator_enter_email',
				invalidEmail: 'black_list_dialog.validator_invalid_email'
			}
		}
	]
})
export class DialogBlackListAddComponent implements OnInit {
	constructor(private fb: UntypedFormBuilder) {}

	@Input() manualAddDialog: boolean;

	@Output() hide = new EventEmitter<void>();

	@Output() createEntity = new EventEmitter<
		Partial<LocalBlackList | GlobalBlacklist>
	>();

	form: UntypedFormGroup;

	ngOnInit() {
		this.form = this.fb.group({
			email: ['', [Validators.required, CustomValidators.email]],
			reasonInfo: ''
		});
	}

	closeDialogMaker() {
		this.hide.emit();
	}

	submit() {
		markAllAsDirty(this.form);

		if (this.form.invalid) return;

		const { email, reasonInfo } = this.form.value;
		this.createEntity.emit({
			email,
			addedAt: toUnix(new Date()),
			reason: 'manual',
			additionalInfo: {
				reasonInfo
			}
		});
	}
}
