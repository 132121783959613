import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export const SUBJECT_TOKEN = new InjectionToken<Subject<void>>('Subject token');

export const SUBJECT_PROVIDER = [
	{
		provide: SUBJECT_TOKEN,
		useClass: Subject
	}
];
