/* eslint-disable max-classes-per-file */
import { Component, Input, NgModule } from '@angular/core';
import { EnTooltipModule } from '../tool-tip/tooltip';

@Component({
	selector: 'en-info',
	templateUrl: './en-info.html'
})
export class EnInfoComponent {
	@Input() icon = 'en-info-circle-o';

	@Input() description: string;

	@Input() additionalText: string;

	@Input() tooltipPosition = 'top';
}

@NgModule({
	imports: [EnTooltipModule],
	exports: [EnInfoComponent],
	declarations: [EnInfoComponent]
})
export class EnInfoModule {}
