import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class LogoutGuard implements CanActivate {
	constructor(private router: Router, private authQuery: AuthQuery) {}

	canActivate(): boolean {
		if (!this.authQuery.isLoggedIn) return true;

		this.router.navigateByUrl('/');
		return false;
	}
}
