import { SelectItem } from 'primeng/api';

export const CHANNEL_OPTIONS: SelectItem<string>[] = [
	{
		label: 'segment_options.email_channel_options',
		value: 'email'
	},
	{
		label: 'segment_options.sms_channel_options',
		value: 'sms'
	},
	{
		label: 'segment_options.web_channel_options',
		value: 'web_push'
	},
	{
		label: 'segment_options.mob_channel_options',
		value: 'mob_push'
	}
];
