import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthQuery, AuthService, AuthState } from '@state-auth';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
	private isRefreshing = false;

	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<
		string | null
	>(null);

	constructor(
		private authService: AuthService,
		private authQuery: AuthQuery
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (
			!request.url.toLowerCase().startsWith('./assets') &&
			!request.url.includes('http') &&
			!request.url.toLowerCase().startsWith('/translations')
		) {
			// eslint-disable-next-line no-param-reassign
			request = request.clone({
				url: environment.baseUrl + request.url
			});
		}

		if (
			this.authQuery.jwtToken &&
			// !request.url.includes('.json') &&
			!request.url.includes('translate.googleapis.com') &&
			!request.url.includes('gitlab.enkod')
		) {
			// eslint-disable-next-line no-param-reassign
			request = this.addToken(request, this.authQuery.jwtToken);
		}

		return next.handle(request).pipe(
			catchError(error => {
				if (
					error instanceof HttpErrorResponse &&
					error.status === 401
				) {
					return this.handle401Error(request, next, error);
				}
				return throwError(error);
			})
		);
	}

	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
	}

	private handle401Error(
		request: HttpRequest<any>,
		next: HttpHandler,
		error: HttpErrorResponse
	) {
		const url = request.url.toLowerCase();
		const isAuthRequest = !!url.includes('/auth/');

		if (isAuthRequest) {
			return throwError(error);
		}

		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refreshToken().pipe(
				switchMap((auth: AuthState) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(auth.accessToken);
					return next.handle(
						this.addToken(request, auth.accessToken)
					);
				})
			);
		}
		return this.refreshTokenSubject.pipe(
			filter(token => token != null),
			take(1),
			switchMap(jwt => {
				return next.handle(this.addToken(request, jwt));
			})
		);
	}
}
