import { DateAccountTimezoneService } from '@enkod-core/services';
import { UnixPeriod } from '../constants';
import { GroupOptions } from '../models';

export function getEndDate(
	endDate: number,
	group: GroupOptions = 'day',
	timezoneService: DateAccountTimezoneService
) {
	const endDateAccountTz = +(
		timezoneService.toUTCTime(endDate / 1000) * 1000
	).toFixed();

	switch (group) {
		case 'month': {
			const date = new Date(endDateAccountTz);
			return new Date(
				`${date.getFullYear()} ${date.getMonth()}`
			).getTime();
		}
		case 'week': {
			const date = new Date(
				endDateAccountTz - 1 * Number(UnixPeriod[group])
			);
			const day = date.getDay(); // 👉️ get day of week

			// 👇️ day of month - day of week (-6 if Sunday), otherwise +1
			const diff = date.getDate() - day + (day === 0 ? -6 : 1);

			return new Date(date.setDate(diff)).getTime();
		}
		case 'day':
		default: {
			return endDateAccountTz - 1 * Number(UnixPeriod[group]);
		}
	}
}
