import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	OnInit,
	Optional,
	Self,
	ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import {
	AbstractTuiControl,
	isNativeFocused,
	TuiNativeFocusableElement,
	typedFromEvent
} from '@taiga-ui/cdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NumberRange } from '@enkod-core/directives';

export type SliderLabels = {
	group?: string;
	restGroup?: string;
	restGroupFinally?: string;
};

@UntilDestroy()
@Component({
	selector: 'en-split-slider',
	templateUrl: './split-slider.component.html',
	styleUrls: ['./split-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnSplitSliderComponent
	extends AbstractTuiControl<number>
	implements OnInit
{
	valueMin = 0;
	valueMax = 100;
	valueNow = 0;
	splitList: string[];

	leftValue: number;
	rightValue: number;

	keyCode = Object.freeze({
		left: 37,
		up: 38,
		right: 39,
		down: 40,
		pageUp: 33,
		pageDown: 34,
		end: 35,
		home: 36
	});

	rangeConfigTest: NumberRange = {
		minValue: 5,
		maxValue: 100
	};

	rangeConfigWinner: NumberRange = {
		minValue: 0,
		maxValue: 95
	};

	@Input('splitCount')
	set splitCountSetter(count: number) {
		const letterArray = ['A', 'B', 'C', 'D', 'E'];
		const arr = [];

		for (let i = 0; i < count; i++) {
			arr.push(letterArray[i]);
		}

		this.splitList = [...arr];
	}

	@Input()
	readonly labels: SliderLabels | null = null;

	@Input()
	readonly splitLabelsCustom: Array<string>;

	@ViewChild('highlight')
	protected highlightRef: ElementRef<HTMLElement>;

	protected railRef: ElementRef<HTMLElement> | null;
	@ViewChild('rail')
	set railElSetter(elementRef: ElementRef<HTMLElement> | null) {
		this.railRef = elementRef;
		this.initRailElSubscrition(elementRef);
		this.moveSliderTo(this.formatValue);
	}

	constructor(
		@Optional()
		@Self()
		@Inject(NgControl)
		control: NgControl | null,
		@Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
		@Inject(DOCUMENT) private readonly documentRef: Document
	) {
		super(control, changeDetectorRef);
	}

	ngOnInit() {
		this.leftValue = this.formatValue;
		this.rightValue = 100 - this.leftValue;
	}

	get nativeFocusableElement(): TuiNativeFocusableElement | null {
		return this.railRef ? this.railRef.nativeElement : null;
	}

	get focused(): boolean {
		return isNativeFocused(this.nativeFocusableElement);
	}

	get splitPercentage() {
		return Math.round((this.leftValue / this.splitList.length) * 10) / 10;
	}

	get formatValue() {
		return Math.round(this.value * 100);
	}

	get splitLabels() {
		return this.splitLabelsCustom || this.splitList;
	}

	private initRailElSubscrition(elementRef: ElementRef<HTMLElement> | null) {
		if (!elementRef) return;

		const { nativeElement } = elementRef;

		const keydown$ = typedFromEvent(nativeElement, 'keydown');
		const mousedown$ = typedFromEvent(nativeElement, 'mousedown');
		const click$ = typedFromEvent(nativeElement, 'click');

		keydown$
			.pipe(
				tap(event => {
					let flag = false;

					switch (event.keyCode) {
						case this.keyCode.left:
						case this.keyCode.down:
							this.moveSliderTo(this.valueNow - 1);
							flag = true;
							break;

						case this.keyCode.right:
						case this.keyCode.up:
							this.moveSliderTo(this.valueNow + 1);
							flag = true;
							break;

						case this.keyCode.pageDown:
							this.moveSliderTo(this.valueNow - 10);
							flag = true;
							break;

						case this.keyCode.pageUp:
							this.moveSliderTo(this.valueNow + 10);
							flag = true;
							break;

						case this.keyCode.home:
							this.moveSliderTo(this.valueMin);
							flag = true;
							break;

						case this.keyCode.end:
							this.moveSliderTo(this.valueMax);
							flag = true;
							break;

						default:
							break;
					}

					if (flag) {
						event.preventDefault();
						event.stopPropagation();
					}
				}),
				untilDestroyed(this)
			)
			.subscribe();

		click$
			.pipe(
				tap((event: MouseEvent | TouchEvent) => {
					const rect = (
						event.currentTarget as HTMLElement
					).getBoundingClientRect();

					const clientX =
						event instanceof MouseEvent
							? event.clientX
							: event.touches[0].clientX;

					this.valueNow = this.calculateValue(rect, clientX);
					this.moveSliderTo(this.valueNow);

					event.preventDefault();
					event.stopPropagation();
				}),
				untilDestroyed(this)
			)
			.subscribe();

		mousedown$
			.pipe(
				map((event: MouseEvent | TouchEvent) => {
					return (
						event.currentTarget as HTMLElement
					).getBoundingClientRect();
				}),
				switchMap(rect =>
					typedFromEvent(this.documentRef, 'mousemove').pipe(
						tap((event: MouseEvent | TouchEvent) => {
							const clientX =
								event instanceof MouseEvent
									? event.clientX
									: event.touches[0].clientX;

							this.valueNow = this.calculateValue(rect, clientX);
							this.moveSliderTo(this.valueNow);

							event.preventDefault();
							event.stopPropagation();
						}),
						takeUntil(typedFromEvent(this.documentRef, 'mouseup'))
					)
				),
				untilDestroyed(this)
			)
			.subscribe();
	}

	private moveSliderTo(value: number) {
		const { nativeElement } = this.highlightRef;
		let copyValue = value;

		if (value > this.valueMax) {
			copyValue = this.valueMax;
		}

		if (value < this.valueMin) {
			copyValue = this.valueMin;
		}

		this.valueNow = copyValue;

		nativeElement.style.flexBasis = `${100 - this.valueNow}%`;

		this.onValue(this.valueNow);
		this.leftValue = this.valueNow;
		this.rightValue = 100 - this.valueNow;
	}

	protected getFallbackValue(): number {
		return 0;
	}

	private calculateValue(rect: ClientRect, clientX: number) {
		const offsetLeft = rect.left;
		const diffX = clientX - offsetLeft;

		return Math.round(
			((this.valueMax - this.valueMin) * diffX) / rect.width
		);
	}

	onActiveZone(active: boolean) {
		this.updateFocused(active);
	}

	onValue(value: number) {
		this.updateValue(value / 100);
	}

	onFocused(focused: boolean) {
		this.updateFocused(focused);
	}

	onModelLeftChange(value: number | null) {
		if (value && value > this.rangeConfigTest.minValue)
			this.moveSliderTo(value);
	}

	onModelRightChange(value: number | null) {
		if (value) this.moveSliderTo(100 - value);
	}
}
