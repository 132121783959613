import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TagsValue } from '@enkod-core/enums';
import { EnStatusT } from 'ui-lib/types';

@Component({
	selector: 'en-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnTagComponent implements OnInit {
	@Input() value = '';

	@Input() defaultValue = '';

	@Input() showTooltip = false;

	@Input() loader = false;

	@Input() leftIcon: string;

	@Input() deletable = false;

	@Input() forceDeletable = false;

	@Input() disabled = false;

	@Input()
	@HostBinding('attr.data-en-host-status')
	status: EnStatusT = 'default';

	@HostBinding('class._disabled')
	get isDisabled(): boolean {
		return this.disabled;
	}

	@Output() removeItem = new EventEmitter();

	constructor(private translate: TranslateService) {}

	get displayText(): string {
		return this.value;
	}

	get tooltipText(): string {
		if (!this.showTooltip) return '';
		switch (true) {
			case this.disabled:
				return this.translate.instant('toast.tag_delete');
			case this.defaultValue === TagsValue.BOT:
				return this.translate.instant('tags.sys_tag_bot');
			default:
				return this.value;
		}
	}

	get isDeletable(): boolean {
		return (
			(this.deletable &&
				this.defaultValue !== TagsValue.SPLIT_TEST &&
				this.defaultValue !== TagsValue.BOT) ||
			this.forceDeletable
		);
	}

	ngOnInit() {
		if (
			this.status === 'message' &&
			this.defaultValue === TagsValue.SPLIT_TEST
		)
			this.status = 'messageSplit';

		if (this.status === 'message' && this.defaultValue === TagsValue.BOT)
			this.status = 'messageBot';
	}
}
