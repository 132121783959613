import { Injectable, Renderer2 } from '@angular/core';
import { ClicksMapDetails } from '../clicks-map.model';
import { DataService } from './data.service';

@Injectable()
export class StatisticsBackgroundService {
	constructor(private renderer: Renderer2, private data: DataService) {}

	generateBackground(elem: HTMLElement, detail?: ClicksMapDetails) {
		let clickHue: number;
		switch (this.data.clicksType) {
			case 'uniq': {
				const allUniqClicks = this.data.allLinksData.map(
					el => Number(el.uniqClicks) || 0
				);
				const uniqMax = Math.max(...allUniqClicks);
				const uniqPercent = (detail?.uniqClicks || 0) / uniqMax || 0;
				clickHue = 205 - uniqPercent * 205;
				break;
			}
			default: {
				// general clicks
				const allClicks = this.data.allLinksData.map(
					el => Number(el.clicks) || 0
				);

				const max = Math.max(...allClicks);
				const percent = (detail?.clicks || 0) / max || 0;
				clickHue = 205 - percent * 205;
			}
		}
		this.renderer.setProperty(
			elem,
			'style',
			`
					height: 50px; width: 50px;
					background: radial-gradient(44.87% 44.87% at 50% 50%, hsl(${
						clickHue - 30
					}, 100%, 40%) 0%, hsl(${
				clickHue - 15
			}, 60%, 58%) 30.4%, hsl(${clickHue}, 50%, 40%) 63%);
					filter: blur(6px);
				`
		);
	}
}
