import {
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	Inject,
	Input
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
	preventDefault,
	TuiScrollService,
	typedFromEvent
} from '@taiga-ui/cdk';
import { merge, Subject } from 'rxjs';
import { filter, map, mapTo, switchMap, takeUntil } from 'rxjs/operators';

import { TuiScrollbarComponent } from '@enkod-core/components';

const EVENT_Y_TO_X_COEFFICENT = 3;

@UntilDestroy()
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'horizontal-scroll-wrapper',
	template: `
		<tui-scrollbar [hidden]="hideScroll">
			<ng-content></ng-content>
		</tui-scrollbar>
	`,
	styleUrls: ['./horizontal-scroll-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalScrollWrapperComponent {
	private readonly scrollToStart$ = new Subject<void>();
	private readonly scrollToEnd$ = new Subject<void>();
	protected readonly destroy$ = new Subject<void>();

	@Input() hideScroll = true;

	constructor(
		@Inject(TuiScrollService) private tuiScrollService: TuiScrollService
	) {}

	@ViewChild(TuiScrollbarComponent)
	set scrollerSetter(scroller: TuiScrollbarComponent | null) {
		this.initScrollerSubscrition(scroller);
	}

	private shouldScroll({ scrollWidth, offsetWidth }: HTMLElement): boolean {
		return scrollWidth > offsetWidth;
	}

	private initScrollerSubscrition(scroller: TuiScrollbarComponent | null) {
		if (!scroller || !scroller.browserScrollRef) {
			return;
		}

		const { nativeElement } = scroller.browserScrollRef;

		const wheel$ = typedFromEvent(nativeElement, 'wheel', {
			passive: false
		}).pipe(
			filter(
				event => event.deltaX === 0 && this.shouldScroll(nativeElement)
			),
			preventDefault(),
			map(({ deltaY }) =>
				Math.max(
					nativeElement.scrollLeft + deltaY * EVENT_Y_TO_X_COEFFICENT,
					0
				)
			)
		);
		const start$ = this.scrollToStart$.pipe(untilDestroyed(this), mapTo(0));
		const end$ = this.scrollToEnd$.pipe(
			untilDestroyed(this),
			map(() => nativeElement.scrollWidth)
		);

		merge(wheel$, start$, end$)
			.pipe(
				untilDestroyed(this),
				switchMap(left =>
					this.tuiScrollService.scroll$(nativeElement, 0, left)
				),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}
}
