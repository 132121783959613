import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class GettedEnrecomGuard implements CanActivate {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const isAccessEnrecom = !!this.authQuery.isAccessEnrecom;
		if (isAccessEnrecom)
			this.router.navigateByUrl('enrecom/recommendations');
		return !isAccessEnrecom;
	}
}
