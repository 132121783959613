import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	CalendarService,
	MonthRange
} from 'app/ui-lib/new-calendar/calendar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiDay, TuiMapper, TuiMonth } from '@taiga-ui/cdk';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { TUI_ORDERED_SHORT_WEEK_DAYS, WEEK_DAYS_NAMES } from '@taiga-ui/core';
import { Observable } from 'rxjs';
import { DateTimeRange } from '../../calendar-range-new/calendar-range.model';
import {
	DateTime,
	DaysOfWeekMin,
	nullDate
} from '../../calendarNew/calendar.model';

@UntilDestroy()
@Component({
	selector: 'en-primitive-calendar',
	templateUrl: './primitive-calendar.component.html',
	styleUrls: ['./primitive-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveCalendarComponent implements OnInit {
	daysOfWeekMin = DaysOfWeekMin;
	showDate: TuiMonth;
	showDateRange: MonthRange;
	date: TuiDay | DateTimeRange | null;
	dateNumber: number;
	nullDateNew = nullDate;

	@Input() position: number;

	@Output() closeCalendar = new EventEmitter();
	@Output() onSelect = new EventEmitter();

	constructor(
		@Inject(TUI_ORDERED_SHORT_WEEK_DAYS)
		readonly weekDays$: Observable<WEEK_DAYS_NAMES>,
		private cd: ChangeDetectorRef,
		private calendarService: CalendarService,
		private timezoneService: DateAccountTimezoneService
	) {}

	get isSingle(): boolean {
		return this.calendarService.getIsSingle();
	}

	get dateRange(): DateTimeRange {
		return this.calendarService.getDateRange();
	}

	get dateSingle(): TuiDay {
		return this.calendarService.getDate();
	}

	get daySingle(): TuiDay {
		return this.calendarService.getDay();
	}

	get min() {
		return this.calendarService.getMinDate();
	}

	get max() {
		return this.calendarService.getMaxDate();
	}

	get type() {
		return this.calendarService.getType();
	}

	get period() {
		return this.calendarService.getPeriod();
	}

	ngOnInit(): void {
		this.dateNumber = this.calendarService.currentDateNumber;
		if (this.isSingle) this.showSingleCalendar();
		else this.showRangeCalendar();
	}

	disabledDays: TuiMapper<TuiDay, boolean> = item => {
		return item.dayBefore(this.min) || item.dayAfter(this.max);
	};

	itemIsToday: TuiMapper<TuiDay, boolean> = item => {
		const todayAccountTz = TuiDay.fromLocalNativeDate(
			new Date(this.timezoneService.dateWithAccountTimezone())
		);
		return todayAccountTz.daySame(item);
	};

	selected(item: TuiDay): boolean {
		const modifiedDate =
			this.type === 'dateTime'
				? this.checkDatesForSelected(this.date as TuiDay)
				: this.date;

		return modifiedDate?.toString() === item.toString();
	}

	highlightRangeDate: TuiMapper<TuiDay, boolean> = item => {
		if (
			!this.dateRange[0].date.daySame(nullDate) &&
			!this.dateRange[0].date.daySame(nullDate)
		)
			return (
				item.dayAfter(this.dateRange[0].date) &&
				item.dayBefore(this.dateRange[1].date)
			);
		return false;
	};

	onItemClick(item: TuiDay) {
		const modifiedItem =
			this.type === 'dateTime' ? this.checkDates(item) : item;

		if (this.isSingle) {
			this.calendarService.changeDate(modifiedItem.toString());
			this.closeCalendar.emit();
		}
		this.calendarService.changeRangeDate(item);
		this.onSelect.emit();
	}

	showRangeCalendar() {
		this.showDateRange = this.calendarService.getShowDateRange();
		this.date = this.dateRange;
		this.calendarService.currentShowDateRange
			.pipe(untilDestroyed(this))
			.subscribe((date: MonthRange) => {
				this.showDateRange = date;
				this.cd.markForCheck();
			});
		this.calendarService.currentDateRange
			.pipe(untilDestroyed(this))
			.subscribe((date: DateTimeRange) => {
				this.date = date;
				this.switchMonth(date);
				this.cd.markForCheck();
			});
	}

	switchMonth(date: DateTimeRange) {
		if (
			!date[0].date.daySame(nullDate) &&
			!date[1].date.daySame(nullDate) &&
			date[0].date.month !== date[1].date.month
		) {
			this.showDateRange = [
				new TuiMonth(date[0].date.year, date[0].date.month),
				new TuiMonth(date[1].date.year, date[1].date.month)
			];
		}
	}

	showSingleCalendar() {
		if (this.period !== 'all') {
			const today = new Date(
				this.timezoneService.dateWithAccountTimezone()
			);
			const tuiDayDate = TuiDay.fromLocalNativeDate(today);
			this.calendarService.changeMinMaxValue(tuiDayDate, this.max);
		}

		this.showDate = this.calendarService.getShowDate();
		this.date = this.daySingle.year ? this.daySingle : this.dateSingle;

		this.calendarService.currentShowDate
			.pipe(untilDestroyed(this))
			.subscribe((date: TuiMonth) => {
				this.showDate = date;
				this.cd.markForCheck();
			});
		this.calendarService.currentDate
			.pipe(untilDestroyed(this))
			.subscribe((date: DateTime) => {
				this.date = date?.date;
				this.dateNumber = this.calendarService.currentDateNumber;
				this.calendarService.resetCurrentDayFromInput();
				if (!date.date.daySame(nullDate)) {
					this.calendarService.changeShowDate(
						new TuiMonth(this.date.year, this.date.month)
					);
				}
				this.cd.markForCheck();
			});

		this.calendarService.currentDay
			.pipe(untilDestroyed(this))
			.subscribe(day => {
				const modifiedDay =
					this.type === 'dateTime' ? this.checkDates(day) : day;
				this.date = modifiedDay;
				if (!modifiedDay.daySame(nullDate)) {
					this.calendarService.changeShowDate(
						new TuiMonth(this.date.year, this.date.month)
					);
				}
				this.cd.markForCheck();
			});
	}

	checkDates(date: TuiDay) {
		const now = new Date();
		const nowDay = Number(now.toString().slice(8, 10));
		const accountDay = Number(
			new Date(this.timezoneService.dateWithAccountTimezone())
				.toString()
				.slice(8, 10)
		);
		let modifiedItem: TuiDay = date;

		if (nowDay < accountDay) {
			modifiedItem = new TuiDay(date.year, date.month, date.day - 1);
		}

		if (nowDay > accountDay) {
			modifiedItem = new TuiDay(date.year, date.month, date.day + 1);
		}

		return modifiedItem;
	}

	checkDatesForSelected(date: TuiDay) {
		let modifiedItem: TuiDay = date;

		if (this.dateNumber) {
			const day = Number(
				new Date(
					this.timezoneService.dateWithAccountTimezone(
						this.dateNumber
					)
				)
					.toString()
					.slice(8, 10)
			);

			modifiedItem = new TuiDay(date.year, date.month, day);
		}

		return modifiedItem;
	}
}
