<form [formGroup]="utmFormParams">
      <div class="en-field">
        <label for="utm_campaign">utm_campaign</label>
        <input id="utm_campaign"
               class="h32"
               type="text"
               enInput
               [maxlength]="50"
               formControlName="utm_campaign">
    </div>
    <div class="en-field">
        <label for="utm_content">utm_content</label>
        <input id="utm_content"
               class="h32"
               type="text"
               enInput
               [maxlength]="50"
               formControlName="utm_content">
    </div>
    <div class="en-field">
        <label for="utm_source">utm_source</label>
        <input id="utm_source"
               class="h32"
               type="text"
               enInput
               [maxlength]="50"
               formControlName="utm_source">
    </div>
    <div class="en-field">
        <label for="utm_medium">utm_medium</label>
        <input id="utm_medium"
               class="h32"
               type="text"
               enInput
               [maxlength]="50"
               formControlName="utm_medium">
    </div>
</form>
