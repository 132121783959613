import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnStep, EnStepper } from './en-stepper.directive';
import {
	EnStepperButtonNext,
	EnStepperButtonPrev
} from './en-stepper-button.directive';

/** CDK stepper */
@NgModule({
	imports: [CommonModule],
	declarations: [EnStep, EnStepper, EnStepperButtonNext, EnStepperButtonPrev],
	exports: [EnStep, EnStepper, EnStepperButtonNext, EnStepperButtonPrev]
})
export class EnStepperModule {}
