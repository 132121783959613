import { Directive, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';

@Directive({
	selector: '[enInputToFloat]'
})
export class EnInputToFloatDirective implements OnInit, OnDestroy {
	constructor(private ngControl: NgControl) {}

	get formControl(): UntypedFormControl {
		return this.ngControl.control as UntypedFormControl;
	}

	ngOnInit(): void {
		this.toFloat();
	}

	@HostListener('blur')
	onBlur() {
		this.toFloat();
	}

	private toFloat() {
		const { value } = this.formControl;

		if (Number.isInteger(+value) && value !== '') {
			this.formControl.patchValue(`${value}.00`);
		}
	}

	ngOnDestroy(): void {
		this.toFloat();
	}
}
