import { EntityState, PaginatorPlugin } from '@datorama/akita';

import { combineLatest, Observable } from 'rxjs';
import { tap, switchMap, finalize, share } from 'rxjs/operators';

import { PaginationService } from './pagination-service.class';

export interface PollingConfig {
	usePolling: boolean;
	pollingPeriod: number;
	errorDelayAttempt?: number;
	errorCountAttempts?: number;
	resetPageTabChange?: boolean;
}

export function paginationDataFactory(
	paginatorRef: PaginatorPlugin<EntityState<any>>,
	servicePagination: PaginationService,
	service: any,
	entityActions$?: Observable<any>
) {
	return combineLatest([
		paginatorRef.pageChanges,
		combineLatest([
			servicePagination.selectSortValue,
			servicePagination.selectPerPageValue,
			servicePagination.selectSearchValue,
			servicePagination.selectFilterValue
		]).pipe(
			tap(() => {
				if (!paginatorRef.metadata.get('initial')) {
					paginatorRef.clearCache();
				}
			})
		),
		...(entityActions$ ? [entityActions$] : [])
	]).pipe(
		switchMap(([page, [sort, perPage, search, filter]]) => {
			paginatorRef.metadata.set('sort', sort);
			paginatorRef.metadata.set('perPage', perPage);

			if (service.pollingConfig?.usePolling) {
				/** чистим кэш пагинатора, чтобы при каждом преключении page вызывался новый запрос
				 * нужно для поллинга, иначе при переключении назад данные берутся из кэша, а запрос продолжает идти
				 * с неактуальной инфой от пагинатора offset и тд.
				 */
				paginatorRef.clearCache();
			} else {
				/**
				 * Для пагинации в табах
				 * Для хренения кеша, когда переключаемся между табами
				 */
				paginatorRef.metadata.set('initial', false);
			}

			const req = () =>
				service.getList({
					page,
					sort,
					perPage,
					search,
					filter
				});
			return paginatorRef.getPage(req);
		}),
		finalize(() => {
			if (servicePagination.resetMode === 'auto')
				servicePagination.reset();

			if (service.pollingConfig?.resetPageTabChange)
				paginatorRef.setFirstPage();
			paginatorRef.metadata.set('initial', true);
			service.store?.setLoading(false);
		}),
		share()
	);
}
