import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConvertSizePipe } from './convert-size.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [ConvertSizePipe],
	exports: [ConvertSizePipe]
})
export class ConvertSizePipeModule {}
