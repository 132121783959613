<tui-hosted-dropdown [content]="dropdown"
                     class="hosted"
                     [tuiDropdownMaxHeight]="1000"
                     [(open)]="calendarVisible">
    <button class="dropdown"
            [class.focus]="calendarVisible"
            tuiDropdownLimitAlign="right">
        <span class="label">{{dropdownLabel | translate}}</span>

        <span *ngIf="showClearIcon"
              class="clear-icon-wrapper">
            <span class="clear-icon en en-cancel-fill"
                  (click)="resetDateRange()">
            </span>
        </span>
        <span class="drop-down-icon en en-sort-down"></span>
    </button>
</tui-hosted-dropdown>

<ng-template #dropdown
             let-activeZone>
    <div class="wrapper__range-calendar">
        <en-calendar-range-new [tuiActiveZoneParent]="activeZone"
                               [showAdjacent]="false"
                               [month]="firstMonth"
                               [(hoveredItem)]="hoveredItem"
                               (monthChange)="onMonthChangeFirst($event)"
                               (dayClick)="OnDayClick($event)"
                               (onSelect)="onSelect()"></en-calendar-range-new>
    </div>

</ng-template>
