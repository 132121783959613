/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { Calendar } from 'primeng/calendar';

import { DateAccountTimezoneService } from '@enkod-core/services';

import { fromUnix, toUnix } from 'app/core/utils/time';

@UntilDestroy()
@Component({
	selector: 'en-calendar',
	templateUrl: './calendar.component.html',
	styles: [
		`
			:host {
				display: inline-flex;
				width: 100%;
			}

			:host(.h28) {
				height: 1.75rem;
			}

			:host(.h32) {
				height: 2rem;
			}

			p-calendar {
				display: inline-flex;
				width: 100%;
			}

			:host ::ng-deep.p-calendar {
				width: 100%;
			}

			:host ::ng-deep.p-button-icon {
				color: var(--color-text-white);
			}

			:host ::ng-deep.p-datepicker-buttonbar {
				padding: 0.5rem 0;
			}

			:host.ng-invalid.ng-dirty ::ng-deep.p-calendar .p-inputtext {
				border-color: var(--valid-border-color);
			}

			:host ::ng-deep.h28 {
				height: 1.75rem;
			}

			:host ::ng-deep.h32 {
				height: 2rem;
			}
		`
	],
	host: { '[class]': 'styleClass' },
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EnCalendarComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnCalendarComponent implements OnInit, ControlValueAccessor {
	defaultDate = new Date(this.timezoneService.dateWithAccountTimezone());
	private onTodayClicked = false;

	constructor(
		private cd: ChangeDetectorRef,
		public el: ElementRef,
		private translate: TranslateService,
		private timezoneService: DateAccountTimezoneService,
		private primeConfig: PrimeNGConfig
	) {}

	@Input() readonly showTime: boolean;

	@Input() readonly timeOnly: boolean;

	@Input() readonly readonlyInput: boolean;

	@Input() readonly placeholder: string;

	private _styleClass: string;

	@Input()
	set styleClass(value: string) {
		if (!this.inline) this._styleClass = value;
	}

	get styleClass() {
		if (!this.inline) return this._styleClass || 'h32';
		return this._styleClass;
	}

	@Input() minDate: Date | null = null;

	@Input() maxDate: Date | null = null;

	@Input() height: number | string = 32;

	@Input() appendTo = '';

	@Input() showIcon = true;

	@Input() selectionMode = 'single';

	@Input() inline: boolean;

	@Input() showButtonBar = true;

	@Input() yearNavigator: boolean;

	@Input() monthNavigator: boolean;

	@Input() yearRange: string;

	@Input() inputId: string | null = null;

	/** Для видимости этого компонента и его методов
	 * когда используется ViewChild на en-calendar */
	@ViewChild(Calendar) calendar: Calendar;

	@Output() onShow = new EventEmitter();

	@Output() onClearClick = new EventEmitter();

	@Output() onClose = new EventEmitter();

	@Output() onSelect = new EventEmitter();

	@Input() disabled = false;

	dateControl: UntypedFormControl = new UntypedFormControl(null);

	writeValue(value: number | number[] | Date): void {
		if (!value) {
			this.dateControl.setValue(null);
			return;
		}

		if (typeof +value === 'number') {
			const withAccountTimezone = new Date(
				this.timezoneService.dateWithAccountTimezone(+value)
			);

			this.dateControl.setValue(withAccountTimezone);
		}

		if (value.constructor === Array) {
			const newValList = value.map(item =>
				item ? fromUnix(item) : null
			);
			this.dateControl.setValue(newValList);
		}

		if (value instanceof Date) {
			this.dateControl.setValue(value);
		}
		this.cd.markForCheck();
	}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	ngOnInit(): void {
		this.setCalendarLocale();
		this.setupFormListeners();
	}

	onTodayClick() {
		this.onTodayClicked = true;
		const date = new Date(this.timezoneService.dateWithAccountTimezone());
		this.dateControl.setValue(date);
	}

	private setupFormListeners(): void {
		this.dateControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value: Date | Array<Date | null>) => {
				if (value) {
					if (value.constructor === Array) {
						const newValList = value.map(item =>
							item ? toUnix(item) : null
						);
						this.onChange(newValList);
					} else {
						const date = this.onTodayClicked
							? value
							: new Date(this.checkDates(+value));

						this.onChange(toUnix(date as any));
					}
				} else this.onChange(value);
				this.onTodayClicked = false;
			});
	}

	private checkDates(value: number) {
		const now = new Date();
		const nowDay = Number(now.toString().slice(8, 10));
		const accountDay = Number(
			new Date(this.timezoneService.dateWithAccountTimezone())
				.toString()
				.slice(8, 10)
		);

		if (nowDay < accountDay) {
			return (value / 1000 + 86400) * 1000;
		}

		if (nowDay > accountDay) {
			return (value / 1000 - 86400) * 1000;
		}

		return value;
	}

	private setCalendarLocale(): void {
		const calendarLocale: Translation = {
			dayNames: [
				this.translate.instant('calendar.days_monday'),
				this.translate.instant('calendar.days_tuesday'),
				this.translate.instant('calendar.days_wednesday'),
				this.translate.instant('calendar.days_thursday'),
				this.translate.instant('calendar.days_friday'),
				this.translate.instant('calendar.days_saturday'),
				this.translate.instant('calendar.days_sunday')
			],
			dayNamesMin: [
				this.translate.instant('calendar.days_min_monday'),
				this.translate.instant('calendar.days_min_tuesday'),
				this.translate.instant('calendar.days_min_wednesday'),
				this.translate.instant('calendar.days_min_thursday'),
				this.translate.instant('calendar.days_min_friday'),
				this.translate.instant('calendar.days_min_saturday'),
				this.translate.instant('calendar.days_min_sunday')
			],
			monthNames: [
				this.translate.instant('calendar.months_january'),
				this.translate.instant('calendar.months_february'),
				this.translate.instant('calendar.months_march'),
				this.translate.instant('calendar.months_april'),
				this.translate.instant('calendar.months_may'),
				this.translate.instant('calendar.months_june'),
				this.translate.instant('calendar.months_july'),
				this.translate.instant('calendar.months_august'),
				this.translate.instant('calendar.months_september'),
				this.translate.instant('calendar.months_october'),
				this.translate.instant('calendar.months_november'),
				this.translate.instant('calendar.months_december')
			],
			today: this.timeOnly
				? this.translate.instant('calendar.now')
				: this.translate.instant('calendar.today'),
			clear: this.translate.instant('calendar.clear')
		};

		this.primeConfig.setTranslation(calendarLocale);
	}
}
