import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ContactsDetail } from './contacts-detail.model';

export interface ContactsDetailState extends EntityState<ContactsDetail> {}

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'contacts-detail',
	idKey: 'uuid'
})
export class ContactsDetailStore extends EntityStore<
	ContactsDetailState,
	ContactsDetail
> {
	constructor() {
		super({ viewSettings: null });
	}
}
