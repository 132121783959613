/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @angular-eslint/no-host-metadata-property */

import { DOCUMENT, CommonModule } from '@angular/common';
import {
	NgModule,
	Directive,
	Component,
	ElementRef,
	EventEmitter,
	AfterViewInit,
	Output,
	OnDestroy,
	Input,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	ContentChildren,
	AfterContentInit,
	TemplateRef,
	QueryList,
	Inject
} from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { TuiSvgModule } from '@taiga-ui/core';

import { PrimeTemplate } from 'primeng/api';

@Directive({
	selector: '[enButton]'
})
export class ButtonDirective implements AfterViewInit, OnDestroy {
	@Input() useTuiSvg: boolean;

	@Input() iconPos: 'left' | 'right' | 'top' | 'bottom' = 'left';

	@Input() iconColor = '';

	public _label: string;

	public _icon: string;

	public initialized: boolean;

	public _initialStyleClass: string;

	constructor(
		public el: ElementRef,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngAfterViewInit() {
		this._initialStyleClass = this.el.nativeElement.className;
		DomHandler.addMultipleClasses(
			this.el.nativeElement,
			this.getStyleClass()
		);

		if (this.icon) {
			const iconElement = this.document.createElement('span');
			iconElement.className = `en-button-icon ${this.iconColor}`;
			iconElement.setAttribute('aria-hidden', 'true');
			const iconPosClass = this.label
				? `en-button-icon-${this.iconPos}`
				: null;
			if (iconPosClass) {
				DomHandler.addClass(iconElement, iconPosClass);
			}
			DomHandler.addMultipleClasses(iconElement, this.icon);
			this.el.nativeElement.appendChild(iconElement);
		}

		const labelElement = this.document.createElement('span');
		if (this.icon && !this.label) {
			labelElement.setAttribute('aria-hidden', 'true');
			DomHandler.addClass(this.el.nativeElement, 'en-button-only');
		}
		labelElement.className = 'en-button-label';
		labelElement.appendChild(
			this.document.createTextNode(this.label || '&nbsp;')
		);
		this.el.nativeElement.appendChild(labelElement);
		this.initialized = true;
	}

	getStyleClass(): string {
		let styleClass = 'en-button en-component';
		if ((this.icon || this.useTuiSvg) && !this.label) {
			styleClass = `${styleClass} en-button-icon-only`;
		}

		return styleClass;
	}

	setStyleClass() {
		const styleClass = this.getStyleClass();
		this.el.nativeElement.className = `${styleClass} ${this._initialStyleClass}`;
	}

	@Input() get label(): string {
		return this._label;
	}

	set label(val: string) {
		this._label = val;

		if (this.initialized) {
			DomHandler.findSingle(
				this.el.nativeElement,
				'.en-button-label'
			).textContent = this._label || '&nbsp;';
			this.setStyleClass();
		}
	}

	@Input() get icon(): string {
		return this._icon;
	}

	set icon(val: string) {
		this._icon = val;

		if (this.initialized) {
			if (this.iconPos)
				DomHandler.findSingle(
					this.el.nativeElement,
					'.en-button-icon'
				).className = `en-button-icon en-button-icon-${this.iconPos} ${this._icon}`;
			else
				DomHandler.findSingle(
					this.el.nativeElement,
					'.en-button-icon'
				).className = `en-button-icon ${this._icon}`;

			this.setStyleClass();
		}
	}

	ngOnDestroy() {
		while (this.el.nativeElement.hasChildNodes()) {
			this.el.nativeElement.removeChild(this.el.nativeElement.lastChild);
		}

		this.initialized = false;
	}
}

@Component({
	selector: 'en-button',
	template: `
		<button
			[attr.type]="type"
			[class]="styleClass"
			[ngStyle]="style"
			[disabled]="disabled"
			[ngClass]="{
				'en-button en-component': true,
				'en-button-icon-only': (icon || iconSrc) && !label,
				'en-button-vertical':
					(iconPos === 'top' || iconPos === 'bottom') && label
			}"
			(click)="onClick.emit($event)"
			(focus)="onFocus.emit($event)"
			(blur)="onBlur.emit($event)"
		>
			<tui-svg *ngIf="iconSrc" [src]="iconSrc"></tui-svg>
			<ng-content></ng-content>
			<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
			<span
				[ngClass]="{
					'en-button-icon': true,
					'en-button-icon-left': iconPos === 'left' && label,
					'en-button-icon-right': iconPos === 'right' && label,
					'en-button-icon-top': iconPos === 'top' && label,
					'en-button-icon-bottom': iconPos === 'bottom' && label
				}"
				[class]="icon"
				*ngIf="icon"
				[attr.aria-hidden]="true"
			></span>
			<span class="en-button-label" [attr.aria-hidden]="icon && !label">
				{{ label || '&nbsp;' }}
			</span>
			<span [ngClass]="'en-badge'" *ngIf="badge" [class]="badgeClass">
				{{ badge }}
			</span>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements AfterContentInit {
	@Input() type = 'button';

	@Input() iconPos = 'left';

	@Input() icon: string;

	@Input() iconSrc: string;

	@Input() badge: string;

	@Input() label: string;

	@Input() disabled: boolean;

	@Input() style: any;

	@Input() styleClass: string;

	@Input() badgeClass: string;

	contentTemplate: TemplateRef<any>;

	@ContentChildren(PrimeTemplate) templates: QueryList<any>;

	@Output() onClick: EventEmitter<any> = new EventEmitter();

	@Output() onFocus: EventEmitter<any> = new EventEmitter();

	@Output() onBlur: EventEmitter<any> = new EventEmitter();

	ngAfterContentInit() {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'content':
					this.contentTemplate = item.template;
					break;

				default:
					this.contentTemplate = item.template;
					break;
			}
		});
	}
}

@NgModule({
	imports: [CommonModule, TuiSvgModule],
	exports: [ButtonDirective, ButtonComponent],
	declarations: [ButtonDirective, ButtonComponent]
})
export class EnButtonModule {}
