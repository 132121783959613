import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from './error.component';
import { AlertModule } from '../alert/alert.module';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, TranslateModule, AlertModule],
	declarations: [ErrorComponent],
	exports: [ErrorComponent]
})
export class ErrorModule {}
