import {
	Component,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { ChannelTestType } from './types';

@Component({
	selector: 'en-send-test',
	templateUrl: './send-test.component.html',
	styleUrls: ['./send-test.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendTestComponent {
	contactList: string[];

	@Input()
	testType: ChannelTestType;

	@Input()
	showTest: boolean;

	@Input()
	isLoading: boolean;

	@Input() readonly fromEmailValue: string;

	@Output()
	readonly onSendTest = new EventEmitter<string[]>();

	@Output()
	readonly closeModal = new EventEmitter<void>();

	get disabledCondition(): boolean {
		return (
			!this.contactList ||
			this.contactList.length === 0 ||
			this.duplicateNumbers
		);
	}

	get duplicateNumbers() {
		const list = this.contactList?.map(v => v.replaceAll(' ', ''));
		const duplicates = list?.filter((number, index, numbers) => {
			return numbers.indexOf(number.replaceAll(' ', '')) !== index;
		});
		return duplicates?.length > 0;
	}

	changeContactList(contactList: string[]) {
		this.contactList = contactList;
	}

	sendTest(): void {
		if (this.contactList) {
			this.onSendTest.emit([...this.contactList]);
		}
	}
}
