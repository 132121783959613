import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { finalize, tap } from 'rxjs/operators';

import { UserSettingsDataService } from './user-settings-data.service';
import { UserSettingsStore, Language } from './user-settings.store';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private store: UserSettingsStore,
		private userSettingsDataService: UserSettingsDataService,
		private translate: TranslateService
	) {}

	get language() {
		return this.store.getValue().language;
	}

	setLanguage(language: Language) {
		this.store.setLoading(true);
		return this.userSettingsDataService.set({ language }).pipe(
			tap(() => {
				this.store.update({ language });
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	updateLanguage(language: Language) {
		this.store.update({ language });

		if (
			language !== this.store.getValue().language ||
			language !== this.document.documentElement.lang
		) {
			this.translate.use(language);
			this.document.documentElement.lang = language;
		}
	}

	async get() {
		this.store.setLoading(true);
		const resp: any = await this.userSettingsDataService.get().toPromise();
		this.store.update(resp);
		this.translate.use(resp.language);
		this.store.setLoading(false);
	}
}
