import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnLoaderOverlayComponent } from './en-loader-overlay.component';

/** @deprecated новый лоадер SpinLoaderModule */
@NgModule({
	imports: [CommonModule],
	declarations: [EnLoaderOverlayComponent],
	exports: [EnLoaderOverlayComponent]
})
export class EnLoaderOverlayModule {}
