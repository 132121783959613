import { CommonTypeOptions } from '@enkod-core/types';
import { SelectItem } from 'primeng/api';

export const OPTIONTS_POOL: Record<
	CommonTypeOptions,
	SelectItem<CommonTypeOptions>
> = {
	number: {
		label: 'table_dropdown_datatype.number',
		value: 'number',
		icon: 'en en-number-type'
	},

	text: {
		label: 'table_dropdown_datatype.text',
		value: 'text',
		icon: 'en en-text-type'
	},

	float: {
		label: 'table_dropdown_datatype.float',
		value: 'float',
		icon: 'en en-float-type'
	},

	bool: {
		label: 'table_dropdown_datatype.bool',
		value: 'bool',
		icon: 'en en-boolean-type'
	},

	phone: {
		label: 'table_dropdown_datatype.phone',
		value: 'phone',
		icon: 'en en-phone-type'
	},

	email: {
		label: 'email',
		value: 'email',
		icon: 'en en-email'
	},

	date: {
		label: 'table_dropdown_datatype.date',
		value: 'date',
		icon: 'en en-date'
	},

	dateTime: {
		label: 'table_dropdown_datatype.date_time',
		value: 'dateTime',
		icon: 'en en-datetime-type'
	},

	sessionId: {
		label: 'table_dropdown_datatype.session',
		value: 'sessionId',
		icon: 'en en-web'
	}
};
