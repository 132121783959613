import {
	Directive,
	ElementRef,
	Input,
	Renderer2,
	OnDestroy,
	SecurityContext,
	HostListener,
	Inject
} from '@angular/core';
import { Observable } from 'rxjs';
import { isNil } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	TuiDestroyService,
	TuiResizeService,
	typedFromEvent
} from '@taiga-ui/cdk';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
	selector: '[enHtmlPreview]',
	providers: [TuiDestroyService, TuiResizeService]
})
export class HtmlPreviewDirective implements OnDestroy {
	private _html = '';
	private listeners: Array<Function> = []; // there is no type of function to 'unlisten' that returns renderer2 listener

	@Input() set html(value: string) {
		if (isNil(value)) return;

		this._html = this.purify(value);

		if (!this.elementRef.nativeElement.contentWindow) return;

		this.setIframeInnerHtml(this.html);

		if (this.customScroll) {
			this.setHeightIframeByContent();
		}
	}

	@Input() showScrollbar = true;
	@Input() customScroll = false;

	constructor(
		@Inject(ElementRef)
		private readonly elementRef: ElementRef<HTMLIFrameElement>,
		@Inject(Renderer2) private readonly renderer: Renderer2,
		@Inject(TuiResizeService)
		readonly tuiResize: Observable<ReadonlyArray<ResizeObserverEntry>>,
		private readonly dompurifySanitizer: NgDompurifySanitizer
	) {
		// TODO:
		// Перенести в метод initDOM и HostListener.
		// Есть подозрения, что инпуты не успевают инициализироваться
		this.elementRef.nativeElement.onload = () => {
			if (this.html) this.setIframeInnerHtml(this.html);

			this.bindListeners('click');
			this.renderer.setProperty(
				this.elementRef.nativeElement,
				'sandbox',
				''
			);
			if (!this.showScrollbar) this.removeScrollBar();
		};
	}

	get html() {
		return this._html;
	}

	get documentRef(): Document | null {
		return this.elementRef.nativeElement.contentDocument;
	}

	// General use reference to document. Attempt to use it only after load event!
	get computedDocument(): Document {
		const { contentDocument } = this.elementRef.nativeElement;

		if (!contentDocument) {
			throw new Error('Only use computedDocument after load event');
		}

		return contentDocument;
	}

	@HostListener('load')
	onLoad() {
		this.initDOM();
		this.initSubscriptions();
	}

	@HostListener('window:resize')
	onResize() {
		if (this.customScroll) {
			this.setHeightIframeByContent();
		}
	}

	private initDOM() {
		const styleTag: HTMLStyleElement = this.renderer.createElement('style');
		const styles: Text = this.renderer.createText(
			'html { height: max-content } body { height: max-content }'
		);

		styleTag.type = 'text/css';
		styleTag.appendChild(styles);

		this.renderer.appendChild(this.computedDocument.head, styleTag);
	}

	private initSubscriptions() {
		this.initResizeSubscription();
		this.initImageSubscription();
	}

	private initResizeSubscription() {
		this.tuiResize
			.pipe(untilDestroyed(this), debounceTime(120))
			.subscribe(() => {
				if (this.customScroll) this.setHeightIframeByContent();
			});
	}

	private initImageSubscription() {
		typedFromEvent(this.computedDocument, 'load', { capture: true })
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				if (this.customScroll) this.setHeightIframeByContent();
			});
	}

	private setIframeInnerHtml(value: string) {
		if (!this.documentRef) {
			return;
		}

		this.renderer.setProperty(
			this.documentRef.body,
			'innerHTML',
			value || ''
		);
	}

	// TODO: перенести в метод initDOM
	private removeScrollBar() {
		if (!this.documentRef) {
			return;
		}

		const css = '::-webkit-scrollbar { width: 0; height: 0 }';
		const html = this.documentRef.getElementsByTagName('html')[0];
		const style = this.documentRef.createElement('style');
		style.type = 'text/css';
		style.appendChild(this.documentRef.createTextNode(css));
		html.appendChild(style);
	}

	private purify(value: string): string {
		return this.dompurifySanitizer.sanitize(SecurityContext.HTML, value, {
			WHOLE_DOCUMENT: true
		});
	}

	private bindListeners(...events: string[]): void {
		if (!this.documentRef) return;

		events.forEach(eventName => {
			this.listeners.push(
				this.renderer.listen(this.documentRef, eventName, event => {
					event.preventDefault();
				})
			);
		});
	}

	private unbindListeners() {
		this.listeners.forEach(listener => {
			if (listener) {
				listener();
			}
		});
	}

	private setHeightIframeByContent() {
		if (!this.documentRef) {
			return;
		}

		if (
			this.elementRef.nativeElement.height ===
			String(this.documentRef.documentElement.offsetHeight)
		) {
			return;
		}

		// фикс бага с постепенным съездом письма вниз в просмотре
		if (
			Number(this.documentRef.documentElement.offsetHeight) -
				Number(this.elementRef.nativeElement.height) ===
			6
		) {
			return;
		}

		this.renderer.setAttribute(
			this.elementRef.nativeElement,
			'height',
			String(this.documentRef.documentElement.offsetHeight)
		);
	}

	ngOnDestroy() {
		this.unbindListeners();
	}
}
