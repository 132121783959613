import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnNotificationRootComponent } from './root.component';

@NgModule({
	imports: [CommonModule],
	exports: [EnNotificationRootComponent],
	declarations: [EnNotificationRootComponent]
})
export class EnNotificationRootModule {}
