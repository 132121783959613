import { Injectable } from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators
} from '@angular/forms';

import { Subject } from 'rxjs';

import { CustomValidators } from 'custom-validators';

import {
	TemplateButtonType,
	TitleComponentForm,
	TitleOptionsValue
} from '../models';

@Injectable()
export class TemplateFormService {
	private _form: UntypedFormGroup = this.fb.group({
		id: 0,
		name: ['', [Validators.required, Validators.maxLength(512)]],
		category: ['MARKETING', Validators.required],
		language: ['ru', Validators.required],
		params: this.fb.group({
			body_text: this.fb.array([]),
			footer_text: this.fb.array([]),
			header_text: this.fb.array([]),
			header_handle: this.fb.array([])
		}),
		text: ['', [Validators.required, Validators.maxLength(1024)]],
		footer: ['', Validators.maxLength(60)],
		components: [],
		tags: [[]],
		type: ['text'],

		titleType: ['none'],
		title: this.fb.group({}),
		buttons: this.fb.array([]),
		buttonType: ['none']
	});

	private _selectVariables$: Subject<string[]> = new Subject();
	private _draftEvent$: Subject<void> = new Subject();
	private _submitEvent$: Subject<void> = new Subject();

	public wasSaved = true;

	constructor(private fb: UntypedFormBuilder) {}

	get form(): UntypedFormGroup {
		return this._form;
	}

	get selectVariables$(): Subject<string[]> {
		return this._selectVariables$;
	}

	get selectDraftEvent$(): Subject<void> {
		return this._draftEvent$;
	}

	get selectSubmitEvent$(): Subject<void> {
		return this._submitEvent$;
	}

	getButtonForm(type: TemplateButtonType): UntypedFormGroup {
		const buttonForm = this.fb.group({
			type,
			text: ['', [Validators.required, Validators.maxLength(20)]]
		});
		switch (type) {
			case 'PHONE_NUMBER':
				buttonForm.addControl(
					'phone_number',
					new UntypedFormControl('', [
						Validators.required,
						CustomValidators.phone
					])
				);
				break;
			case 'URL':
				buttonForm.addControl(
					'url',
					new UntypedFormControl('', [
						Validators.required,
						CustomValidators.url
					])
				);
				break;
			case 'QUICK_REPLY':
				buttonForm.addControl(
					'text_reply',
					new UntypedFormControl('', Validators.required)
				);
				break;
			default:
				break;
		}
		return buttonForm;
	}

	addTitleForm(type: TitleOptionsValue): void {
		const form = {} as TitleComponentForm;
		switch (type) {
			case 'TEXT':
				this._form.controls.title = this.fb.group({
					type: 'HEADER',
					format: 'TEXT',
					text: ['', [Validators.required, Validators.maxLength(60)]]
				});
				break;
			case 'IMAGE':
			case 'DOCUMENT':
			case 'VIDEO':
				form.type = 'HEADER';
				form.format = type;
				this._form.controls.title = this.fb.group(form);
				break;
			default:
				this.form.controls.title = this.fb.group({});
				break;
		}
	}

	resetForm(): void {
		this.form.reset();
	}

	markFormAsDirty(form: UntypedFormGroup | UntypedFormArray): void {
		if (form instanceof UntypedFormGroup) {
			Object.keys(form.controls).forEach(name => {
				const control = form.controls[name];
				control.markAsDirty();
				if (control instanceof UntypedFormGroup) {
					this.markFormAsDirty(control);
				}
				if (control instanceof UntypedFormArray) {
					this.markFormAsDirty(control);
				}
			});
		}
		if (form instanceof UntypedFormArray) {
			form.controls.forEach(control =>
				this.markFormAsDirty(control as UntypedFormGroup)
			);
		}
	}
}
