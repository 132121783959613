import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ValidateChekerService {
	private validate$: Subject<void> = new Subject();

	setValidateCheck() {
		this.validate$.next();
	}

	get validateCheckClicked() {
		return this.validate$.asObservable();
	}
}
