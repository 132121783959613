import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export type Language = 'ru' | 'en' | 'fr' | 'de' | 'pt' | 'zh';

export interface UserSettingsState {
	loading: boolean;
	language: Language;
}

export function createInitialState(): UserSettingsState {
	return {
		loading: false,
		language: 'ru'
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-settings' })
export class UserSettingsStore extends Store<UserSettingsState> {
	constructor() {
		super(createInitialState());
	}
}
