import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiMapperPipeModule } from '@taiga-ui/cdk';
import {
	TuiDropdownControllerModule,
	TuiScrollbarModule
} from '@taiga-ui/core';
import { EnPrimitiveYearMonthPaginationModule } from '../new-calendar/primitive-year-month-pagination/primitive-year-month-pagination.module';
import { EnPrimitiveCalendarModule } from '../new-calendar/primitive-calendar/primitive-calendar.module';
import { EnPrimitiveYearPickerModule } from '../new-calendar/primitive-year-picker/primitive-year-picker.module';
import { EnPrimitiveDateTimeModule } from '../new-calendar/primitive-date-time-picker/primitive-date-time-picker.module';
import { EnCalendarRangeComponent } from './calendar-range.component';
import { EnPrimitiveRangePickerModule } from '../new-calendar/primitive-range-picker/primitive-range-picker.module';

@NgModule({
	imports: [
		CommonModule,
		EnPrimitiveYearMonthPaginationModule,
		EnPrimitiveCalendarModule,
		EnPrimitiveYearPickerModule,
		TuiScrollbarModule,
		TuiMapperPipeModule,
		EnPrimitiveRangePickerModule,
		TuiDropdownControllerModule,
		EnPrimitiveDateTimeModule
	],
	declarations: [EnCalendarRangeComponent],
	exports: [EnCalendarRangeComponent]
})
export class EnCalendarRangeNewModule {}
